var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.enabled
    ? _c(
        "router-link",
        _vm._b(
          {
            class: _vm.linkClass,
            attrs: { to: _vm.toWithReturnPath, disabled: !_vm.enabled },
            nativeOn: {
              click: function ($event) {
                return _vm.onClick.apply(null, arguments)
              },
            },
          },
          "router-link",
          _vm.$attrs,
          false
        ),
        [
          _vm.icon
            ? _c("span", [_c("span", { class: _vm.iconClass }), _vm._v(" ")])
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      )
    : _c("span", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }