var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-progress-bar",
        _vm.containerClass,
        { "is-over": _vm.isOver },
      ],
    },
    [
      _c("div", {
        class: [
          { "is-transparent": _vm.percentage === 0 },
          "gfu-progress-bar__progression",
          _vm.progressionClass,
        ],
        style: { width: `${_vm.percentage}%` },
      }),
      _vm._v(" "),
      _vm.canGoOver
        ? _c("div", {
            class: _vm.progressionOverClass,
            style: { transform: `translateX(${_vm.percentageOver}%)` },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }