import { stretchGoalTargetTypes } from '@/Models';

export function updateStretchGoalsProgress(stretchGoals, fundsGathered, backersCount, campaingDay) {
    const goals = [...stretchGoals];
    goals.forEach(stretchGoal => {
        switch (stretchGoal.targetType) {
        case stretchGoalTargetTypes.backersCount:
            stretchGoal.value = Math.min(stretchGoal.targetValue, backersCount);
            break;
        case stretchGoalTargetTypes.backedFunds:
            stretchGoal.value = Math.min(stretchGoal.targetValue, fundsGathered);
            break;
        case stretchGoalTargetTypes.campaingDay:
            stretchGoal.value = Math.min(stretchGoal.targetValue, campaingDay);
            break;
        default:
            break;
        }

        stretchGoal.progress = stretchGoal.value / stretchGoal.targetValue;
    });

    return goals;
}

export function unlockStretchGoalsStates(stretchGoals, newlyUnlockedStretchGoals) {
    const goals = [...stretchGoals];

    if (newlyUnlockedStretchGoals) {
        newlyUnlockedStretchGoals.forEach(unlockModel => {
            const stretchGoal = goals.find(c => c.projectStretchGoalID === unlockModel.projectStretchGoalID);
            stretchGoal.isUnlocked = true;
            stretchGoal.unlockedAt = unlockModel.unlockedAt;
        });
    }

    return goals;
}