
import { apiClient } from '@/Clients/ApiClient.js';
import { queueTaskStatuses } from '@/Models';
import QueueTaskService from '@/Services/Admin/QueueTaskService';

export default {
    data: () => ({
        processingMessage: '',
        isProcessingCounter: 0
    }),
    methods: {
        pushProcessing() {
            if (this.isProcessingCounter++ === 0) {
                this.$emit('processing-start');
            }

            return Promise.resolve();
        },
        popProcessing() {
            if (this.isProcessingCounter > 0) {
                if (this.isProcessingCounter-- === 1) {
                    this.$emit('processing-stop');
                }
            }

            return Promise.resolve();
        },
        startProcessing() {
            this.isProcessing = true;
            this.$emit('processing-start');

            return Promise.resolve();
        },
        stopProcessing() {
            this.isProcessing = false;
            this.$emit('processing-stop');

            return Promise.resolve();
        },
        /**
         * Wraps asynchronous work in startProcessing/stopProcessing and forwards any captured errors to the standard
         * error handler.
         * @template TResult
         * @param {PromiseLike<TResult>} task
         * @returns {Promise<TResult>}
         */
        wrapAsyncTask(task) {
            // wrapping startProcessing in case consumer overrides it with something that doesn't return a promise
            return Promise.resolve(this.pushProcessing())
                .then(() => arguments.length === 1 ? task : Promise.all(arguments))
                .catch(error => {
                    this.handleError(error);
                })
                .finally(this.popProcessing);
        },
        wrapQueueTask(task, options = {}) {
            return Promise.resolve(this.pushProcessing())
                .then(() => task)
                .then(response => this.getQueueTaskStatus(response.data.data, options))
                .finally(this.popProcessing);
        },
        handleQueueTaskStatusResponse(response, queueTaskID, options) {
            const queueStatus = response.data;
            if (typeof options.progressCallback === 'function') {
                options.progressCallback(queueStatus);
            }

            if (queueStatus.taskStatus === queueTaskStatuses.completed) {
                return queueStatus;
            }
            else {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(this.getQueueTaskStatus(queueTaskID, options));
                    }, options.delay || 3000); // check every 3 seconds
                });
            }
        },
        getQueueTaskStatus(queueTaskID, options) {
            return new QueueTaskService(apiClient)
                .getQueueTaskStatus(queueTaskID)
                .then(response => this.handleQueueTaskStatusResponse(response, queueTaskID, options))
                .catch(error => this.handleError(error));
        },
        withProcessing(task) {
            return (...args) => {
                this.pushProcessing();
                return task.apply(this, args)
                    .finally(this.popProcessing.bind(this));
            };
        },
        handleError(error) {
            // default implementation that should be overridden in derived components
            throw error;
        },
    },
    computed: {
        isProcessing: {
            get() {
                return this.isProcessingCounter > 0;
            },
            set(value) {
                this.isProcessingCounter = value ? 1 : 0;
            }
        }
    }
};
