import { defineStore } from 'pinia';

import UsersService from '@/Services/UsersService';

import { apiClient } from '@/Clients/ApiClient.js';
import InitialState from '@/InitialState';

const {
    avatarUrl,
    avatarThumbUrl,
    isAuthenticated,
    isImpersonated,
    user: {
        creatorPermissions,
        email,
        fullName,
        globalPermissions,
        nickname,
        projectPermissions,
        quirkFlags,
        roleFlags,
        systemPermissions,
        userID,
        restrictions,
        hasPublicProfile
    },
} = InitialState.userContext || {};

const followedUsersIDs = InitialState.followedUsersIDs;

export const useUserContextStore = defineStore({
    id: 'userContext',
    state: () => ({
        userContext: {
            avatarUrl,
            avatarThumbUrl,
            isAuthenticated,
            isImpersonated,
            user: {
                creatorPermissions,
                email,
                followedUsersIDs,
                fullName,
                globalPermissions,
                nickname,
                projectPermissions,
                quirkFlags,
                roleFlags,
                systemPermissions,
                userID,
                restrictions,
                hasPublicProfile
            },
        },
    }),
    actions: {
        async reloadUserContext() {
            const userService = new UsersService(apiClient);
            const response = await userService.getUserContext();
            this.userContext = response.data;
            return response;
        },
        async enableSocialFeatures({ nickname, isPublic }) {
            const userService = new UsersService(apiClient);
            const response = await userService.enableSocialFeatures({ nickname, isPublic });

            if (response.data.success) {
                const userContext = await this.reloadUserContext();
                return userContext;
            }
            else {
                throw new Error(response.data.message);
            }
        }
    },
    getters: {
        isAuthenticated() {
            return this.userContext.isAuthenticated;
        },
        isUserBanned() {
            return this.userContext?.user?.restrictions?.commentsRestriction;
        },
        isUserProfilePublic() {
            return this.userContext?.user?.hasPublicProfile;
        },
        nickname() {
            return this.userContext?.user?.nickname;
        },
        canFollowUsers() {
            return this.isAuthenticated && this.isUserProfilePublic && !!this.nickname;
        },
        hasUserQuirk() {
            return (quirk) => ((this.userContext?.user?.quirkFlags ?? 0) & quirk) !== 0;
        }
    }
});