import lazyScreen from '@/Components/Admin/LazyScreen';

const ProjectSettingsSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ '@/Components/Admin/Projects/ProjectSettingsSideNav.vue'));
const BackupPaymentMethodScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-backup-payment-method' */ './BackupPaymentMethodScreen.vue'));

const routes = [
    { path: '/project-settings/:creator?/:project?/backup-payment-method', name: 'project-backup-payment-method', components: { default: BackupPaymentMethodScreen, sidenav: ProjectSettingsSideNav } },
];

export {
    BackupPaymentMethodScreen,
    routes
};