var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.members.length > 0
    ? _c("base-slider", {
        staticClass: "gfu-base-slider",
        attrs: { autoplay: true, items: _vm.members },
        on: { change: _vm.onChange },
        scopedSlots: _vm._u(
          [
            {
              key: "view",
              fn: function () {
                return [
                  _c(
                    "transition-group",
                    {
                      staticClass: "gfu-team-members",
                      attrs: { name: _vm.transitionName, tag: "div" },
                    },
                    _vm._l(_vm.slides, function (slide) {
                      return _c(
                        "div",
                        { key: slide.name, class: ["gfu-team-member-card"] },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "gfu-team-member-card__media gfu-embed gfu-embed--1x1",
                            },
                            [
                              _c("base-image", {
                                staticClass: "gfu-embed__item",
                                attrs: {
                                  src: slide.imageUrl,
                                  alt: slide.name,
                                  loading: "lazy",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gfu-team-member-card__content" },
                            [
                              _c("h4", { staticClass: "gfu-hd gfu-hd--h3" }, [
                                _vm._v(_vm._s(slide.name)),
                              ]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(slide.position))]),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "nav",
              fn: function ({ onNext, onPrev }) {
                return [
                  _c("base-slider-navigation", {
                    staticClass: "gfu-partners-slider-nav--prev",
                    attrs: { type: "prev" },
                    on: { click: onPrev },
                  }),
                  _vm._v(" "),
                  _c("base-slider-navigation", {
                    staticClass: "gfu-partners-slider-nav--next",
                    attrs: { type: "next" },
                    on: { click: onNext },
                  }),
                ]
              },
            },
          ],
          null,
          false,
          809675200
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }