var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.projectContext.project
    ? _c(
        "side-nav-container",
        {
          attrs: { title: "Reward settings", icon: "trophy" },
          scopedSlots: _vm._u(
            [
              {
                key: "navigation-link",
                fn: function () {
                  return [
                    _c(
                      "side-nav-link-back",
                      {
                        attrs: {
                          to: _vm.parentRoute,
                          "data-qa": "admin-product-sidenav-link:Back",
                        },
                      },
                      [_vm._v("Rewards")]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            1151333502
          ),
        },
        [
          _vm._v(" "),
          _vm._l(_vm.links, function (link) {
            return _c(
              "side-nav-link",
              {
                key: link.urlName,
                attrs: {
                  to: link.to,
                  icon: link.iconName,
                  enabled: link.isEnabled,
                  "data-qa": `admin-project-reward-sidenav-link:${link.label}`,
                },
              },
              [_vm._v("\n        " + _vm._s(link.label) + "\n    ")]
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }