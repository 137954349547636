var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["gfu-featured-header", _vm.themeClass] }, [
    _c("div", { staticClass: "gfu-layout-wrapper" }, [
      _c(
        "div",
        { staticClass: "_flexbtwn _ai-c" },
        [
          _c("div", [
            _c(
              "h3",
              { staticClass: "gfu-featured-header__title gfu-hd _tc--inherit" },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _vm._v(" "),
            _vm.subtitle
              ? _c("p", { staticClass: "_mt-0" }, [
                  _vm._v(_vm._s(_vm.subtitle)),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.buttonText
            ? _c(
                "icon-base-button",
                {
                  staticClass: "_screen--none _screen--m",
                  attrs: {
                    type: null,
                    icon: "chevron-right",
                    theme: "transparentInverse",
                    size: "default",
                    "text-transform": "upper",
                    bold: true,
                    "icon-position": "right",
                    href: _vm.seeMoreHeaderUrl,
                    tag: "a",
                    wide: true,
                    "data-qa": `view-projects-button:${_vm.buttonText}`,
                  },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }