import { defineStore } from 'pinia';

import ProjectsService from '@/Services/ProjectsService';

import { apiClient }  from '@/Clients/ApiClient.js';

export const useAvailableProjectsStore = defineStore({
    id: 'availableProjects',
    state: () => ({
        availableProjects: [],
    }),
    actions: {
        async loadAvailableProjects() {
            const response = await new ProjectsService(apiClient).getAvailableProjects();
            const projects = response.data;

            this.availableProjects = projects;

            return projects;
        },
    }
});