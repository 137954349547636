var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "admin-router-link",
    { staticClass: "gfu-link--block _tc--accent _mb-3", attrs: { to: _vm.to } },
    [
      _c("base-icon", {
        staticClass: "gfu-sidenav__icon",
        attrs: { name: "chevron-left" },
      }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "gfu-sidenav__content" },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }