var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "product-card-list-provider",
    _vm._b(
      {
        attrs: { "edit-route-name": "rewards-general" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({
              productList,
              onItemAdded,
              getAddProductToCartModel,
            }) {
              return [
                _c(
                  "ul",
                  { staticClass: "gfu-grid gfu-grid--gutter" },
                  _vm._l(productList, function (reward) {
                    return _c(
                      "li",
                      {
                        key: `reward-box-${reward.productID}`,
                        staticClass: "gfu-grid__cell gfu-1of1",
                      },
                      [
                        _c(
                          "reward-card",
                          _vm._b(
                            {
                              ref: "rewardCard",
                              refInFor: true,
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "action",
                                    fn: function () {
                                      return [
                                        _c(
                                          "add-product-to-cart",
                                          _vm._b(
                                            {
                                              on: { "item-added": onItemAdded },
                                            },
                                            "add-product-to-cart",
                                            getAddProductToCartModel(reward),
                                            false
                                          )
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            "reward-card",
                            reward,
                            false
                          )
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            },
          },
        ]),
      },
      "product-card-list-provider",
      { products: _vm.$props.rewards, ..._vm.$props },
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }