var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "field-description",
              fn: function () {
                return [_vm._t("field-description")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      _vm._v(" "),
      _c(
        "rich-text-editor",
        _vm._b(
          {
            ref: "richTextEditor",
            attrs: {
              "validator-selector": "richTextEditor",
              formats: _vm.formats || _vm.defaultToolbarFormatValues,
              "toolbar-format-values": _vm.toolbarFormatValues,
              name: _vm.name,
              placeholder: _vm.placeholder,
            },
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          },
          "rich-text-editor",
          { ..._vm.$attrs, ..._vm.$props },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }