export function buildShippingNavigation(shippingModelId) {
    return [
        {
            to: { name: 'project-shipping-model-summary', params: { shippingModelId } },
            iconName: 'cog',
            label: 'General',
        },
        {
            to: { name: 'project-shipping-model-zone-availability', params: { shippingModelId } },
            iconName: 'globe',
            label: 'Zone availability',
        },
    ];
}
