import { isTouchDevice } from '@/Utils/WindowUtility';

export default {
    name: 'Touch',
    props: {
        enableMouseSwipe: { type: Boolean, default: true },
        timeThreshold: { type: Number, default: 100 },
        distanceThreshold: { type: Number, default: 50 }
    },
    data: () => ({
        touchStartX: 0,
        touchStartY: 0,
        touchStartTime: 0
    }),
    created() {
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
    },
    mounted() {
        if (isTouchDevice()) {
            this.$el.addEventListener('touchstart', this.onTouchStart, false);
            this.$el.addEventListener('touchend', this.onTouchEnd, false);
        }
        else if (this.enableMouseSwipe) {
            this.$el.addEventListener('mousedown', this.onTouchStart, false);
            this.$el.addEventListener('mouseup', this.onTouchEnd, false);
        }
    },
    methods: {
        onTouchStart(event) {
            const changedTouches = event.changedTouches ? event.changedTouches[0] : event;

            this.touchStartX = changedTouches.clientX;
            this.touchStartY = changedTouches.clientY;
            this.touchStartTime = Date.now();
        },
        onTouchEnd(event) {
            const changedTouches = event.changedTouches ? event.changedTouches[0] : event;

            const touchEndTime = Date.now();
            const deltaX = this.touchStartX - changedTouches.clientX;
            const deltaY = this.touchStartY - changedTouches.clientY;
            const touchTime = touchEndTime - this.touchStartTime;

            if (touchTime > this.timeThreshold) {
                if (Math.abs(deltaX) > this.distanceThreshold) {
                    const axisX = deltaX > 0 ? 'left' : 'right';
                    this.$emit(`swipe-${ axisX }`);
                }

                if (Math.abs(deltaY) > this.distanceThreshold) {
                    const axisY = deltaY > 0 ? 'up' : 'down';
                    this.$emit(`swipe-${ axisY }`);
                }
            }

            this.resetTouchData();
        },
        resetTouchData() {
            this.touchStartX = 0;
            this.touchStartY = 0;
            this.touchStartTime = 0;
        }
    },
    render() {
        return this.$slots.default[0];
    }
};