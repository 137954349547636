var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "gfu-btn-actions _mt-3" }, [
      _vm.canRemoveUnpaidItems
        ? _c(
            "a",
            {
              staticClass:
                "gfu-btn gfu-btn--primary gfu-btn--fixed gfu-btn-actions__item",
              attrs: { "data-qa": "order-details:RemoveUnpaidItems" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.removeUnpaidItems.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.Resources.YourPledge.RemoveUnpaidButton))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.canAddMoreItems
        ? _c(
            "a",
            {
              staticClass:
                "gfu-btn gfu-btn--primary gfu-btn--fixed gfu-btn-actions__item",
              attrs: { "data-qa": "project-user-pledge-button:AddItems" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addItems.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.Resources.YourPledge.AddMoreButton))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.canEditPledge
        ? _c(
            "button",
            {
              staticClass:
                "gfu-btn gfu-btn--primary gfu-btn--fixed gfu-btn-actions__item",
              attrs: {
                type: "button",
                "data-qa": "project-user-pledge-button:EditPledge",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.editPledge.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.Resources.YourPledge.EditPledgeButton) +
                  "\n        "
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "gfu-grid gfu-grid--gutter" }, [
      _vm.isDetailedInfoVisible
        ? _c("div", { staticClass: "gfu-grid__cell gfu-1of1" }, [
            _vm.orderDetails.shippingModel
              ? _c("div", { staticClass: "gfu-grid gfu-grid--small" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of1 gfu-1of3--m gfu-1of4--l _tal",
                    },
                    [_vm._v(_vm._s(_vm.Resources.YourPledge.ShippingInfoLabel))]
                  ),
                  _vm._v(" "),
                  _vm.orderDetails
                    ? _c(
                        "label",
                        {
                          staticClass:
                            "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of1 gfu-2of3--m gfu-3of4--l _tal",
                          attrs: { "data-qa": "order-details:Shipping" },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.shippingInfo) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.orderDetails &&
            _vm.orderDetails.orderStateNotes &&
            _vm.orderDetails.orderStateNotes.length
              ? _c("div", { staticClass: "gfu-grid gfu-grid--small" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of1 gfu-1of3--m gfu-1of4--l _tal",
                    },
                    [_vm._v(_vm._s(_vm.Resources.YourPledge.NotesLabel))]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of1 gfu-2of3--m gfu-3of4--l _tal",
                      attrs: { "data-qa": "order-details:OrderNotes" },
                    },
                    [
                      _c("pre", { staticClass: "_ma-0 _whs-pw" }, [
                        _vm._v(_vm._s(_vm.orderDetails.orderStateNotes)),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.orderDetails &&
            _vm.orderDetails.invoices &&
            _vm.projectContext.project.enableInvoicing
              ? _c("div", { staticClass: "gfu-grid gfu-grid--small" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of1 gfu-1of3--m gfu-1of4--l _tal",
                    },
                    [_vm._v(_vm._s(_vm.Resources.YourPledge.InvoicesLabel))]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of1 gfu-2of3--m gfu-3of4--l _tal",
                    },
                    [
                      _vm.orderDetails.invoices.length
                        ? _c(
                            "span",
                            [
                              _vm._l(
                                _vm.validInvoices,
                                function (invoice, index) {
                                  return [
                                    index > 0
                                      ? _c("span", { key: `comma-${index}` }, [
                                          _vm._v(", "),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        key: `link-${index}`,
                                        attrs: {
                                          href: "",
                                          "data-qa": "order-details:Invoice",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.downloadInvoice(invoice)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(invoice.invoiceNumber))]
                                    ),
                                  ]
                                }
                              ),
                            ],
                            2
                          )
                        : _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.Resources.YourPledge.NoInvoicesText
                                ) +
                                "\n                    "
                            ),
                          ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.canResumePayment || _vm.canCancelOrder
              ? _c("div", { staticClass: "gfu-grid gfu-grid--gutter" }, [
                  _vm.canResumePayment
                    ? _c(
                        "div",
                        { staticClass: "gfu-grid__cell gfu-1of1 _mt-3" },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "gfu-btn gfu-btn--accent gfu-btn--fixed _fr",
                              attrs: {
                                "data-qa": "order-details:ResumePayment",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.resumeOrder.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.Resources.YourPledge.ResumePaymentButton
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }