import CrowdfundingAddonCardList from './CrowdfundingAddonCardList.vue';
import CrowdfundingRewardCardList from './CrowdfundingRewardCardList.vue';
import ProjectSummary from './ProjectSummary/ProjectSummary.vue';
import MiniWizardManager from './MiniWizard/MiniWizardManager.vue';
import ProjectCommentsStreams from './ProjectCommentsStreams.vue';
import ProjectNavigation from './ProjectNavigation.vue';
import ProjectTestModeInfo from './ProjectTestModeInfo.vue';
import ProjectUpdates from './ProjectUpdates.vue';
import ProjectUpdate from './ProjectUpdate.vue';
import ProjectStretchGoals from './ProjectStretchGoals.vue';
import ProjectProductCardList from './ProjectProductCardList.vue';
import ProjectRecommendations from './Recommendations/ProjectRecommendations.vue';
import ProjectSlider from './ProjectSlider.vue';
import CrowdfundingDraftInformationModal from './CrowdfundingDraftInformationModal.vue';
import PledgeManagerSelectionWizard from './PledgeManagerSelectionWizard.vue';
import ProjectUserPledges from './ProjectUserPledges.vue';
import WithoutRewardSection from './WithoutRewardSection.vue';
import ProjectSectionContent from './Sections/ProjectSectionContent.vue';
import ProjectSectionStory from './Sections/ProjectSectionStory.vue';
import ProjectTitle from '@/Components/Projects/Sections/ProjectTitle.vue';
import ProjectSectionRewards from '@/Components/Projects/Sections/ProjectSectionRewards.vue';

export default {
    CrowdfundingAddonCardList,
    CrowdfundingRewardCardList,
    ProjectSummary,
    MiniWizardManager,
    PledgeManagerSelectionWizard,
    ProjectCommentsStreams,
    ProjectUpdates,
    ProjectUpdate,
    ProjectNavigation,
    ProjectTestModeInfo,
    ProjectStretchGoals,
    ProjectProductCardList,
    ProjectSlider,
    CrowdfundingDraftInformationModal,
    ProjectUserPledges,
    WithoutRewardSection,
    ProjectRecommendations,
    ProjectSectionContent,
    ProjectSectionStory,
    ProjectTitle,
    ProjectSectionRewards,
};
