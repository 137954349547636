var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("notificator", { ref: "notify", attrs: { singular: true } }),
      _vm._v(" "),
      _vm.model &&
      (_vm.model.totalQuantity > 0 || _vm.model.canDisplayEmptyCart)
        ? _c(
            "div",
            {
              staticClass: "gfu-table",
              attrs: { "data-qa": "cart-table-item-list:box" },
            },
            [
              _c("div", { staticClass: "gfu-table__head" }, [
                _c("div", { staticClass: "gfu-table__row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-table__cell _flexb50 _screen--all _screen--not-s",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.Resources.YourPledge.CartTableDetailsHeader)
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-table__cell _flexb55 _flexb75--s _flexb60--m _screen--none _screen--s",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.YourPledge.CartTableItemDetailsHeader
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-table__cell _tac _flexb15 _flexb10--s _screen--all _screen--not-m",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.YourPledge.CartTableQuantityShortHeader
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-table__cell _tac _flexb10 _screen--none _screen--m gfu-table__cell--actions",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.Resources.YourPledge.CartTableQuantityHeader)
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-table__cell _tar _flexb35 _flexb25--s _screen--all _screen--not-m",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.Resources.YourPledge.CartTablePriceHeader)
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-table__cell _tar _flexb15 _screen--none _screen--m",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.YourPledge.CartTableTotalPriceHeader
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.model.allowEditing
                    ? _c("div", {
                        staticClass:
                          "gfu-table__cell _tar _flexb5 _screen--none _screen--s",
                      })
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    "gfu-table__body gfu-table__body--no-scroll",
                    {
                      "gfu-table__body--underlined":
                        _vm.model.groupedOrderItems.length <= 1 &&
                        _vm.model.customOrderItems.length === 0,
                    },
                  ],
                  attrs: { "data-qa": "cart-table" },
                },
                [
                  _vm._l(
                    _vm.model.groupedOrderItems,
                    function (paymentGroup, groupNumber) {
                      return _c(
                        "div",
                        {
                          key: "grouped-order-item-" + groupNumber,
                          class: {
                            "gfu-table__row-group":
                              _vm.model.groupedOrderItems.length > 1,
                          },
                        },
                        [
                          _vm.model.groupedOrderItems.length > 1 ||
                          paymentGroup.isImported
                            ? _c(
                                "div",
                                { staticClass: "_ml-1" },
                                [
                                  paymentGroup.isImported
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "gfu-hd gfu-hd--light _ttu",
                                          attrs: {
                                            "data-qa":
                                              "cart-table:PreDefinedItems",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.Resources.YourPledge
                                                .CartTablePredefinedItemsTitle
                                            )
                                          ),
                                        ]
                                      )
                                    : paymentGroup.paymentStatus ===
                                      _vm.orderItemPaymentStatuses.unpaid
                                    ? [
                                        _vm.model.groupedOrderItems.find(
                                          (g) => g.isImported
                                        )
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gfu-hd gfu-hd--light _ttu",
                                                attrs: {
                                                  "data-qa":
                                                    "cart-table:YourItems",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.Resources.YourPledge
                                                      .CartTableYourItemsTitle
                                                  )
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gfu-hd gfu-hd--light _ttu",
                                                attrs: {
                                                  "data-qa":
                                                    "cart-table:UnpaidItems",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.Resources.YourPledge
                                                      .CartTableUnpaidItemsTitle
                                                  )
                                                ),
                                              ]
                                            ),
                                      ]
                                    : [
                                        groupNumber === 0
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gfu-hd gfu-hd--light _ttu",
                                                attrs: {
                                                  "data-qa":
                                                    "cart-table:OriginalOrder",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.Resources.YourPledge
                                                      .CartTableOriginalOrderTitle
                                                  )
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gfu-hd gfu-hd--light _ttu",
                                                attrs: {
                                                  "data-qa":
                                                    "cart-table:ItemsAddedOn",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.Resources.format(
                                                      _vm.Resources.YourPledge
                                                        .CartTableItemsAddedOnTitle,
                                                      paymentGroup.paymentDate
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                      ],
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(
                            paymentGroup.orderItemIDs,
                            function (orderItemID) {
                              return [
                                _c("cart-table-item-row", {
                                  key: "item-row-" + orderItemID,
                                  ref: "item-row-" + orderItemID,
                                  refInFor: true,
                                  attrs: {
                                    "allow-editing": _vm.model.allowEditing,
                                    "currency-symbol":
                                      _vm.model.cart.currencySymbol,
                                    "order-item-id": orderItemID,
                                    "payment-group": groupNumber,
                                    "product-order-items":
                                      _vm.model.productOrderItems,
                                    "project-id": _vm.projectId,
                                    "show-net-price": _vm.model.cart.handleTax,
                                    "show-tax-value": _vm.isTaxValueVisible,
                                    "data-qa": `cart-table-order-item:${orderItemID}`,
                                  },
                                  on: {
                                    "cart-modified": _vm.onCartModified,
                                    error: _vm.onError,
                                  },
                                }),
                                _vm._v(" "),
                                _vm.model.productOrderItems.find(
                                  (oi) => oi.orderItemID === orderItemID
                                ).product.type === _vm.productTypes.set
                                  ? _c(
                                      "div",
                                      {
                                        key: "set-item-table-" + orderItemID,
                                        staticClass:
                                          "gfu-table gfu-table--nested",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "gfu-field__label _tal",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.Resources.YourPledge
                                                  .CartTableSetContentLabel
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.getSortedSetItems(orderItemID),
                                          function (setItem) {
                                            return [
                                              _c("cart-table-set-item-row", {
                                                key:
                                                  "set-item-row-" +
                                                  setItem.orderItemID,
                                                ref:
                                                  "set-item-row-" +
                                                  setItem.orderItemID,
                                                refInFor: true,
                                                attrs: {
                                                  "allow-editing":
                                                    _vm.model.allowEditing,
                                                  "order-item-id":
                                                    setItem.orderItemID,
                                                  "payment-group": groupNumber,
                                                  "product-order-items":
                                                    _vm.model.productOrderItems,
                                                  "project-id": _vm.projectId,
                                                  "data-qa":
                                                    "cart-table-order-item:" +
                                                    setItem.orderItemID,
                                                },
                                                on: {
                                                  "cart-modified":
                                                    _vm.onCartModified,
                                                  error: _vm.onError,
                                                },
                                              }),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.model.productOrderItems.find(
                                  (oi) => oi.orderItemID === orderItemID
                                ).product.type === _vm.productTypes.set
                                  ? _c("div", {
                                      key: "set-item-row-striped" + orderItemID,
                                      staticClass:
                                        "gfu-table__row _screen--none",
                                    })
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      )
                    }
                  ),
                  _vm._v(" "),
                  _vm.model.customOrderItems.length
                    ? _c(
                        "div",
                        { staticClass: "gfu-table__custom-items" },
                        _vm._l(
                          _vm.customOrderItemsWithoutDiscount,
                          function (customOrderItem) {
                            return _c(
                              "div",
                              {
                                key:
                                  "custom-item-row-" +
                                  customOrderItem.orderItemID,
                              },
                              [
                                _c("cart-table-custom-item-row", {
                                  ref:
                                    "other-fee-row-" +
                                    customOrderItem.orderItemID,
                                  refInFor: true,
                                  attrs: {
                                    "allow-editing": _vm.model.allowEditing,
                                    "currency-symbol":
                                      _vm.model.cart.currencySymbol,
                                    "order-item-id":
                                      customOrderItem.orderItemID,
                                    "project-id": _vm.projectId,
                                    "product-order-items": [customOrderItem],
                                    "show-net-price": _vm.model.cart.handleTax,
                                    "show-tax-value": _vm.isTaxValueVisible,
                                    "data-qa":
                                      "cart-table-order-item:" +
                                      customOrderItem.orderItemID,
                                  },
                                  on: { "cart-modified": _vm.onCartModified },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "gfu-table__foot gfu-table__foot--light gfu-table__foot--lightweight",
                },
                [
                  _c("cart-table-total-row", {
                    attrs: {
                      "allow-editing": _vm.model.allowEditing,
                      "display-text":
                        _vm.Resources.YourPledge.CartTableSubtotalRowLabel,
                      quantity: _vm.model.totalQuantity,
                      "is-highlighted": !_vm.showTotals,
                      "currency-symbol": _vm.model.cart.currencySymbol,
                      "use-net-value": _vm.model.cart.handleTax,
                      "net-value": _vm.model.cart.totalNetValueConverted,
                      "gross-value": _vm.model.cart.totalValueConverted,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }