var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["gfu-navbar-item", { "is-active": _vm.link.isActive }] },
    [
      _vm.link.isRestricted && _vm.link.canBypassRestriction
        ? [
            _c(
              "tooltip",
              {
                staticClass: "gfu-navbar-tooltip",
                attrs: {
                  tag: "div",
                  href: _vm.link.url,
                  text: _vm.link.restrictionReason,
                  theme: "light",
                  "use-native-click": true,
                  "vertical-position": "down",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "gfu-navbar-link gfu-navbar-link--disabled",
                    attrs: {
                      href: _vm.link.url,
                      "data-qa": `project-navigation-link:${_vm.link.name}`,
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.link.name) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.isAdminTooltipVisible
              ? _c(
                  "tooltip",
                  {
                    staticClass:
                      "gfu-project-navigation-tooltip _screen--none _screen--l",
                    attrs: {
                      text: _vm.link.adminLinkText,
                      theme: "light",
                      "vertical-position": "down",
                      "data-qa": `enable-section-button:${_vm.link.name}`,
                    },
                  },
                  [
                    _c("router-link", {
                      staticClass:
                        "gfu-btn gfu-btn--circle gfu-btn--light-alt _fa _fa--eye-slash gfu-project-navigation-tooltip__btn",
                      attrs: {
                        to: _vm.link.adminLink,
                        "data-qa": `enable-section-button:${_vm.link.name}`,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm.link.isRestricted && !_vm.link.canBypassRestriction
        ? [
            _c(
              "tooltip",
              {
                attrs: {
                  text: _vm.link.restrictionReason,
                  theme: "light",
                  "vertical-position": "down",
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "gfu-navbar-link gfu-navbar-link--disabled _pen",
                    attrs: {
                      "data-qa": `project-navigation-link:${_vm.link.name}`,
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.link.name) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            ),
          ]
        : [
            _c(
              "a",
              {
                staticClass: "gfu-navbar-link",
                attrs: {
                  href: `${_vm.link.url}`,
                  "data-qa": `project-navigation-link:${_vm.link.name}`,
                },
              },
              [_vm._v(_vm._s(_vm.link.name))]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }