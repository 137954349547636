var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-project-mini-wizard__wrapper" }, [
    _vm.rewardsSection
      ? _c(
          "div",
          {
            staticClass: "gfu-box gfu-box--flat gfu-project-mini-wizard _fo",
            attrs: { "data-qa": "project-mini-wizard" },
          },
          [
            _vm.isPledgePlaced
              ? _c(
                  "div",
                  {
                    class: [
                      "gfu-project-mini-wizard__header",
                      {
                        "gfu-project-mini-wizard__header--alt-background":
                          _vm.isEditingPledge,
                        "gfu-project-mini-wizard__header--image":
                          _vm.isUnmodifiedPledge,
                      },
                    ],
                    attrs: { "data-qa": "mini-wizard-header" },
                  },
                  [
                    _vm.isEditingPledge
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "_mb-3",
                              attrs: { "data-qa": "mini-wizard-header:Text" },
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.Resources.ProjectHome
                                      .MiniWizardModifyingPledgeTitle
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _c("span", {
                              staticClass: "_fa _fa--exclamation-circle",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.Resources.ProjectHome
                                    .MiniWizardModifyingPledgeHint
                                ) +
                                "\n                "
                            ),
                          ]),
                        ]
                      : [
                          _c(
                            "div",
                            {
                              staticClass: "gfu-hd gfu-hd--h3 _tc--inherit",
                              attrs: { "data-qa": "mini-wizard-header:Backer" },
                            },
                            [
                              _vm.currentPledge.backerNumber
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "localize",
                                        {
                                          attrs: {
                                            resource:
                                              _vm.Resources.ProjectHome
                                                .MiniWizardBackerNumberText,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                "format-key": "0",
                                                "data-qa":
                                                  "mini-wizard-header:BackerNumber",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$format(
                                                    _vm.currentPledge
                                                      .backerNumber,
                                                    "N0"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.Resources.ProjectHome
                                          .MiniWizardBackerWithoutNumberText
                                      )
                                    ),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "_flexbtwn _ai-c" }, [
                            _c(
                              "a",
                              {
                                staticClass: "gfu-bt--caption _ttu",
                                attrs: {
                                  href: _vm.currentPledge.cartDetailsUrl,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.Resources.ProjectHome
                                      .MiniWizardPledgeValueText
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "gfu-project-mini-wizard__price _tc--inverse _fr",
                                attrs: {
                                  "data-qa":
                                    "mini-wizard-header:AlreadyPledged",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$format(
                                        _vm.pledge
                                          .totalDiscountedValueConverted,
                                        "C2",
                                        _vm.pledge.currencySymbol
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.userContext.isAuthenticated
                            ? _c("available-credits-box", {
                                attrs: { "credit-balance": _vm.creditBalance },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasTaxHandlingEnabled
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "gfu-project-mini-wizard__caption _tc--inverse _flexb100",
                                  attrs: {
                                    "data-qa": "mini-wizard-item:tax-warning",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.taxesSummaryLabel) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isAwaitingPaymentPledge
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--light-transparent _mt-2",
                                  attrs: {
                                    href: _vm.currentPledge.resumeOrderUrl,
                                    "data-qa":
                                      "mini-wizard-button:ResumePayment",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.Resources.ProjectHome
                                          .MiniWizardResumePaymentButton
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canEditPledge
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--transparent-inverse _mt-2",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.isProcessing,
                                    "data-qa": "mini-wizard-button:EditPledge",
                                  },
                                  on: { click: _vm.onEditPledge },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.Resources.ProjectHome
                                          .MiniWizardEditPledgeButton
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isCollapsible
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "gfu-project-mini-wizard__collapse",
                                  attrs: {
                                    "data-qa": "mini-wizard-button:ShowHide",
                                  },
                                  on: { click: _vm.toggleCollapsed },
                                },
                                [
                                  _c("div", {
                                    class: [
                                      "gfu-project-mini-wizard__collapse-icon",
                                      {
                                        "gfu-project-mini-wizard__collapse-icon--expanded":
                                          !_vm.isCollapsed,
                                      },
                                    ],
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.isCollapsed
                                            ? _vm.Resources.ProjectHome
                                                .MiniWizardCollapsibleShowItemsButton
                                            : _vm.Resources.ProjectHome
                                                .MiniWizardCollapsibleHideItemsButton
                                        ) +
                                        "\n                    "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isEditingPledge && _vm.userContext.isAuthenticated
              ? _c("available-credits-box", {
                  staticClass:
                    "gfu-project-mini-wizard__header gfu-project-mini-wizard__header--compact",
                  attrs: { "credit-balance": _vm.creditBalance },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isPledgeEmpty && !_vm.allItemsRemoved
              ? _c(
                  "div",
                  [
                    _vm.featuredReward
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "gfu-project-mini-wizard-featured-reward__image-wrapper",
                              attrs: {
                                "data-qa": "mini-wizard-featured-reward",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "gfu-embed gfu-embed--1x1 _fo",
                                  attrs: { to: _vm.featuredRewardRoute },
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "gfu-project-mini-wizard-featured-reward__image",
                                    attrs: { src: _vm.featuredReward.imageUrl },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "gfu-project-mini-wizard-featured-reward__tag-container gfu-project-mini-wizard-featured-reward__tag-container--bottom _ml-3",
                                },
                                [
                                  _c(
                                    "base-badge",
                                    {
                                      attrs: {
                                        "data-qa":
                                          "mini-wizard-featured-reward:FeaturedBadge",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.Resources.ProjectHome
                                              .MiniWizardFeaturedRewardBadge
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "detect-overflow",
              { staticClass: "gfu-project-mini-wizard__content" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.effectiveIsCollapsed,
                        expression: "!effectiveIsCollapsed",
                      },
                    ],
                    class: [
                      "_pt-3 _pl-3 _pr-3",
                      { "_pb-3": _vm.isPledgeEmpty && !_vm.allItemsRemoved },
                    ],
                  },
                  [
                    _vm.featuredReward
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showFeaturedReward,
                                expression: "showFeaturedReward",
                              },
                            ],
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "gfu-project-mini-wizard-featured-reward__title gfu-heading",
                                attrs: {
                                  "data-qa": "mini-wizard-featured-reward:Name",
                                },
                              },
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: _vm.featuredRewardRoute } },
                                  [_vm._v(_vm._s(_vm.featuredReward.name))]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("display-currency-price", {
                              attrs: {
                                "effective-price":
                                  _vm.featuredReward.effectivePrice,
                                price: _vm.featuredReward.price,
                                "is-discounted":
                                  _vm.featuredReward.isDiscounted,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "effective-price",
                                    fn: function ({ effectivePrice }) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "gfu-price gfu-bt--b1 gfu-price--text-base",
                                            attrs: {
                                              "data-qa":
                                                "mini-wizard-featured-reward-price:Effective",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(effectivePrice) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "price",
                                    fn: function ({ price }) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "gfu-price gfu-price--old _fw-n",
                                            attrs: {
                                              "data-qa":
                                                "mini-wizard-featured-reward-price:Old",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(price) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2133375504
                              ),
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "_cf" }),
                            _vm._v(" "),
                            _c("product-card-list-provider", {
                              attrs: {
                                "edit-route-name": "rewards-general",
                                products: [_vm.featuredReward],
                                "checkout-url": _vm.currentPledge.checkoutUrl,
                                "cart-url": _vm.currentPledge.cartDetailsUrl,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({
                                      onItemAdded,
                                      getAddProductToCartModel,
                                    }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-project-mini-wizard-featured-reward__action",
                                          },
                                          [
                                            _c(
                                              "add-product-to-cart",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    "data-qa": `mini-wizard-button-${_vm.showFeaturedReward}:SelectThisReward`,
                                                  },
                                                  on: {
                                                    "item-added": onItemAdded,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "buttonContents",
                                                        fn: function () {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.Resources
                                                                    .ProjectHome
                                                                    .MiniWizardSelectThisRewardButton
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                "add-product-to-cart",
                                                _vm.getAddToCartModel(
                                                  getAddProductToCartModel(
                                                    _vm.featuredReward
                                                  )
                                                ),
                                                false
                                              )
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3430914167
                              ),
                            }),
                            _vm._v(" "),
                            _c(
                              "divider-labeled",
                              {
                                staticClass:
                                  "gfu-project-mini-wizard__divider _mt-3 _mb-3",
                              },
                              [
                                _c("span", { staticClass: "_tc--gray" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.Resources.ProjectHome
                                          .MiniWizardDividerLabel
                                      ) +
                                      "\n                        "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.allItemsRemoved
                      ? [
                          _c("mini-wizard-items-content", {
                            attrs: {
                              addons: _vm.addons,
                              "addons-section": _vm.addonsSection,
                              "can-delete-items": _vm.canDeleteItems,
                              "currency-symbol": _vm.currencySymbol,
                              "delete-method": _vm.onDeleteItem,
                              discounts: _vm.discounts,
                              "is-unmodified-pledge": _vm.isUnmodifiedPledge,
                              "pledge-date": _vm.pledgeDate,
                              rewards: _vm.rewards,
                              "rewards-section": _vm.rewardsSection,
                              "show-addons": !_vm.isPledgeEmpty,
                              "show-delete-button": false,
                              "show-rewards": !_vm.allChargeableItemsRemoved,
                              "show-rewards-header": !_vm.showFeaturedReward,
                              tips: _vm.tips,
                            },
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allItemsRemoved
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "gfu-project-mini-wizard-item gfu-btn gfu-btn--block gfu-btn--thin gfu-btn--light-outline _fo _ttr",
                            attrs: { "data-qa": `mini-wizard-item:Empty` },
                          },
                          [
                            _c("span", { staticClass: "gfu-btn__text _lhb" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.Resources.ProjectHome
                                    .MiniWizardRemovedAllItemsText
                                )
                              ),
                            ]),
                          ]
                        )
                      : _vm.allChargeableItemsRemoved
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "gfu-project-mini-wizard-item gfu-btn gfu-btn--block gfu-btn--thin gfu-btn--light-outline _fo _ttr _mt-1",
                            attrs: { "data-qa": `mini-wizard-item:Empty` },
                          },
                          [
                            _c("span", { staticClass: "gfu-btn__text _lhb" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.Resources.ProjectHome
                                    .MiniWizardRemovedPaidItemsText
                                )
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.promoItem && _vm.isPledgeEmpty
                      ? _c("project-promo-item", {
                          staticClass:
                            "gfu-project-mini-wizard-promo-item gfu-bt gfu-bt--caption _mt-3",
                          attrs: {
                            "image-size": 36,
                            "data-qa":
                              "mini-wizard-expandable-project-promo-item",
                          },
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _vm._v(" "),
            !_vm.isPledgeEmpty || _vm.allItemsRemoved
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.effectiveIsCollapsed,
                        expression: "!effectiveIsCollapsed",
                      },
                    ],
                    class: [
                      "gfu-project-mini-wizard__footer",
                      { "_pt-0": _vm.isUnmodifiedPledge },
                    ],
                  },
                  [
                    !_vm.isUnmodifiedPledge
                      ? [
                          _vm.isEditingPledge
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "gfu-project-mini-wizard__summary",
                                  attrs: {
                                    "data-qa": "mini-wizard:YourPledge",
                                  },
                                },
                                [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "gfu-heading gfu-heading--light",
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.Resources.ProjectHome
                                              .MiniWizardModifyingPledgeCostBeforeLabel
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("display-currency-price", {
                                    staticClass: "_whs-nw",
                                    attrs: {
                                      "effective-price":
                                        _vm.pledge.parentOrder
                                          .totalDiscountedValueConverted,
                                      "reverse-tooltip-position": true,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "effective-price",
                                          fn: function ({ effectivePrice }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gfu-project-mini-wizard__price",
                                                  attrs: {
                                                    "data-qa":
                                                      "mini-wizard:OriginalPledge",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                " +
                                                      _vm._s(effectivePrice) +
                                                      "\n                            "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      967236138
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "gfu-project-mini-wizard__summary",
                              attrs: { "data-qa": "mini-wizard:YourPledge" },
                            },
                            [
                              _c(
                                "h6",
                                {
                                  staticClass: "gfu-heading gfu-heading--light",
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        !_vm.isPledgePlaced
                                          ? _vm.Resources.ProjectHome
                                              .MiniWizardPledgeValueText
                                          : _vm.Resources.ProjectHome
                                              .MiniWizardModifyingPledgeCostAfterLabel
                                      ) +
                                      "\n                        "
                                  ),
                                  _c(
                                    "tooltip",
                                    {
                                      attrs: {
                                        text: _vm.Resources.ProjectHome
                                          .MiniWizardNoShippingTooltip,
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "_fa _fa--info",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("display-currency-price", {
                                staticClass: "_whs-nw",
                                attrs: {
                                  "effective-price":
                                    _vm.pledge.totalDiscountedValueConverted,
                                  "reverse-tooltip-position": true,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "effective-price",
                                      fn: function ({ effectivePrice }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "gfu-project-mini-wizard__price",
                                              attrs: {
                                                "data-qa":
                                                  "mini-wizard:YourPledgePrice",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(effectivePrice) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2667991503
                                ),
                              }),
                              _vm._v(" "),
                              _vm.hasTaxHandlingEnabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "gfu-project-mini-wizard__caption _flexb100",
                                      attrs: {
                                        "data-qa":
                                          "mini-wizard-item:tax-warning",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.taxesSummaryLabel) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isProjectInFundingPhase &&
                          _vm.pledge.creditDiscountConverted
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "gfu-project-mini-wizard__summary",
                                },
                                [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "gfu-heading gfu-heading--light",
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.Resources.ProjectHome
                                              .MiniWizardCreditDiscountLabel
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "gfu-project-mini-wizard__price _tc--accent",
                                      attrs: {
                                        "data-qa":
                                          "mini-wizard:UsedCreditsAmount",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.$format(
                                              _vm.pledge
                                                .creditDiscountConverted,
                                              "C2",
                                              _vm.pledge.currencySymbol
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.promoItem
                      ? _c("project-promo-item", {
                          staticClass:
                            "gfu-project-mini-wizard-promo-item gfu-bt gfu-bt--caption _mb-3",
                          attrs: {
                            "image-size": 36,
                            "data-qa":
                              "mini-wizard-expandable-project-promo-item",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canSubmit
                      ? [
                          _c("go-to-checkout", {
                            attrs: {
                              "continue-url": _vm.currentPledge.checkoutUrl,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ onInvoke }) {
                                    return [
                                      _c(
                                        "tooltip",
                                        {
                                          attrs: {
                                            enabled: !_vm.canProceedToCheckout,
                                            text: _vm.currentPledge
                                              .canProceedToCheckoutValidationResult
                                              .message,
                                          },
                                        },
                                        [
                                          _c(
                                            "base-button",
                                            {
                                              attrs: {
                                                enabled:
                                                  _vm.canProceedToCheckout,
                                                theme: "accent",
                                                display: "block",
                                                width: "narrow",
                                                "data-qa":
                                                  "mini-wizard-button:SubmitPledge",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return onInvoke.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.isPledgePlaced
                                                      ? _vm.Resources
                                                          .ProjectHome
                                                          .MiniWizardSubmitChangesButton
                                                      : _vm.Resources
                                                          .ProjectHome
                                                          .MiniWizardSubmitPledgeButton
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2881483616
                            ),
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canCancel
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--accent",
                            attrs: {
                              href: "",
                              disabled: !_vm.currentPledge.canCancelPledge,
                              "data-qa": "mini-wizard-button:CancelPledge",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.onCancelPledge.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.Resources.ProjectHome
                                    .MiniWizardCancelPledgeButton
                                ) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isUnfinishedOrder && _vm.isEditingPledge
                      ? _c("div", { staticClass: "_tac _pt-1 _mt-1" }, [
                          _c(
                            "a",
                            {
                              staticClass: "gfu-link gfu-link--accent",
                              attrs: {
                                href: "",
                                "data-qa": "mini-wizard-button:RevertChanges",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onRevertChanges.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.Resources.ProjectHome
                                    .MiniWizardRevertChangesButton
                                )
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    (_vm.isPledgePlaced && !_vm.hasSameItemsAsParent) ||
                    !_vm.isPledgeEmpty
                      ? _c("div", { staticClass: "_tac _pt-1 _mt-1" }, [
                          _c(
                            "a",
                            {
                              staticClass: "gfu-link gfu-link--accent",
                              attrs: {
                                href: _vm.currentPledge.cartDetailsUrl,
                                "data-qa":
                                  "mini-wizard-button:ManageYourPledge",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.Resources.ProjectHome
                                      .MiniWizardManagePledgeButton
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }