export default {
    props: {
        to: {
            type: [String, Object, Array]
        },
        tag: {
            type: String,
            default: 'div'
        },
        exact: Boolean,
        activeClass: String,
        exactActiveClass: String
    },
    render(createElement) {
        const router = this.$router;
        const current = this.$route;
        const { route } = router.resolve(this.to, current, this.append);

        const classes = {};
        const globalActiveClass = router.options.linkActiveClass;
        const globalExactActiveClass = router.options.linkExactActiveClass;

        const activeClassFallback = globalActiveClass === null
            ? 'router-link-active'
            : globalActiveClass;

        const exactActiveClassFallback = globalExactActiveClass === null
            ? 'router-link-exact-active'
            : globalExactActiveClass;

        const activeClass = this.activeClass === null
            ? activeClassFallback
            : this.activeClass;

        const exactActiveClass = this.exactActiveClass === null
            ? exactActiveClassFallback
            : this.exactActiveClass;

        classes[exactActiveClass] = route.fullPath === current.fullPath;
        classes[activeClass] = this.exact
            ? classes[exactActiveClass]
            : current.fullPath.startsWith(route.fullPath);

        const data = {
            class: classes,
            on: this.$listeners
        };

        return createElement(this.tag, data, this.$slots.default);
    }
};