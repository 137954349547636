var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.partners.length > 0
    ? _c("base-slider", {
        staticClass: "gfu-base-slider",
        attrs: { autoplay: true, items: _vm.partners },
        on: { change: _vm.onChange },
        scopedSlots: _vm._u(
          [
            {
              key: "view",
              fn: function () {
                return [
                  _c(
                    "transition-group",
                    {
                      staticClass: "_flex _ov-h gfu-base-slider-wrapper",
                      attrs: { name: _vm.transitionName, tag: "div" },
                    },
                    _vm._l(_vm.slides, function (slide) {
                      return _c(
                        "div",
                        {
                          key: slide.name,
                          class: ["gfu-1of2 gfu-1of4--m _px-6"],
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "gfu-embed gfu-embed--1x1",
                              attrs: {
                                href: slide.creatorUrl,
                                title: slide.name,
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "gfu-embed__item _pa-1",
                                attrs: {
                                  src: `${slide.imageUrl}?v=2`,
                                  alt: slide.name,
                                  loading: "lazy",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "nav",
              fn: function ({ onNext, onPrev }) {
                return [
                  _c("base-slider-navigation", {
                    staticClass: "gfu-partners-slider-nav--prev",
                    attrs: { type: "prev" },
                    on: { click: onPrev },
                  }),
                  _vm._v(" "),
                  _c("base-slider-navigation", {
                    staticClass: "gfu-partners-slider-nav--next",
                    attrs: { type: "next" },
                    on: { click: onNext },
                  }),
                ]
              },
            },
          ],
          null,
          false,
          631269895
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }