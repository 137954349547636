var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.notifications.length > 0
    ? _c(
        "div",
        { staticClass: "gfu-toast-wrapper" },
        [
          _c(
            "transition-group",
            { attrs: { tag: "div", name: "gfu-notification-slide-and-fade" } },
            _vm._l(_vm.notifications, function (notification) {
              return _c(
                "base-toast",
                _vm._b(
                  {
                    key: notification.id,
                    staticClass: "_mt-1",
                    on: {
                      close: function ($event) {
                        return _vm.$notify.hide(notification)
                      },
                    },
                  },
                  "base-toast",
                  notification,
                  false
                )
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }