
import CartsService from '@/Services/CartsService';
import ComponentBase from '@/Components/ComponentBase';
import Bus from '@/Bus';

export default {
    extends: ComponentBase,
    props: {
        continueUrl: { type: String, required: true },
        redirectOnAlternativeAction: { type: Boolean, default: true },
        orderCode: { type: String, required: false },
    },
    data() {
        return {
            // This is to prevent race condition
            url: this.continueUrl,
        };
    },
    methods: {
        onInvoke(event) {
            return this.wrapAsyncTask(
                this.wrapApiAction(new CartsService(this.$http).checkCartItemsIntegrity(this.projectContext.projectID))
                    .then((result) => {
                        if (result.success) {
                            const payload = { };
                            payload.optionsWithMixedValues = result.data.optionsWithMixedValues;
                            payload.redirectOnAlternativeAction = this.redirectOnAlternativeAction;

                            if (payload.optionsWithMixedValues.length) {
                                Bus.Topics.Web.Request.ShowGoToCheckoutModal.publish(payload);
                            }
                            else {
                                window.location = this.url;
                            }
                        }
                        else {
                            window.location =  this.url;
                        }
                    }));
        },
        handleError() {
            // there is really small chance that the checkCartItemsIntegrity will not respond
            // then we just redirect user to checkout
            window.location = this.url;
        },
    },
    render() {
        return this.$scopedSlots.default({
            onInvoke: this.onInvoke,
        });
    }
};
