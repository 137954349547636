import { notificationTypes } from '@/Types/NotificatorTypes.js';

export const toastIconTypes = {
    [notificationTypes.info]: 'info-basic',
    [notificationTypes.success]: 'check',
    [notificationTypes.warning]: 'exclamation',
    [notificationTypes.error]: 'times',
};

export const toastActionTypes = {
    default: '',
    button: 'gfu-toast__action--button'
};
