var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "text-field",
    _vm._b(
      {
        attrs: { conceal: true, "auto-complete": "off" },
        on: { blur: _vm.onBlur, input: _vm.onChange, focus: _vm.onFocus },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      },
      "text-field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }