import lazyScreen from '@/Components/Admin/LazyScreen';

const ProjectPayoutsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ '@/Components/Admin/Projects/Finances/Payouts/ProjectPayoutsScreen.vue'));
const ProjectTransactionsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ '@/Components/Admin/Projects/Finances/Transactions/ProjectTransactionsScreen.vue'));
const ProjectTaxTransactionsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ '@/Components/Admin/Projects/Finances/ProjectTaxTransactionsScreen.vue'));
const ProjectFinancesSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ '@/Components/Admin/Projects/Finances/ProjectFinancesSideNav.vue'));

const routes = [
    { path: '/project-finances/:creator?/:project?/payouts', name: 'project-finances-payouts', components: { default: ProjectPayoutsScreen, sidenav: ProjectFinancesSideNav } },
    { path: '/project-finances/:creator?/:project?/transactions', name: 'project-finances-transactions', components: { default: ProjectTransactionsScreen, sidenav: ProjectFinancesSideNav } },
    { path: '/project-finances/:creator?/:project?/tax-transactions', name: 'project-finances-tax-transactions', components: { default: ProjectTaxTransactionsScreen, sidenav: ProjectFinancesSideNav } },
];

export {
    routes,
};