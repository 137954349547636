import { defineStore } from 'pinia';
import WebStorage, { storageTypes }  from '@/Utils/WebStorage.js';

const adminModeStorage = new WebStorage('store.admin.adminModeEnabled', storageTypes.localStorage);
const isAdminModeEnabled = !!adminModeStorage.get();

export const useAdminModeStore = defineStore({
    id: 'adminMode',
    state: () => ({
        isAdminModeEnabled,
        hasForegroundView: false,
    }),
    actions: {
        setAdminModeEnabled(isEnabled) {
            this.isAdminModeEnabled = isEnabled;
            adminModeStorage.set(isEnabled);
        },
        setHasForegroundView(hasForegroundView) {
            this.hasForegroundView = hasForegroundView;
        }
    }
});