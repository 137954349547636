export default {
    props: {
        threshold: { type: Number, default: 1 },
        root: { type: Object, default: null },
        rootMargin: { type: Number, default: 0 }
    },
    mounted() {
        this.observer = new IntersectionObserver(entries =>
            this.handleIntersect(entries[0]), {
            root: this.root,
            rootMargin: `${ this.rootMargin }px`,
            threshold: this.threshold
        });
        this.observer.observe(this.$el);
    },
    methods: {
        handleIntersect(entry) {
            this.$emit('intersected', entry, this.observer);
        }
    },
    destroyed() {
        this.observer.disconnect();
    },
    render() {
        return this.$slots.default[0];
    }
};
