var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "is-visible": _vm.isVisible,
        title: _vm.Resources.Comments.SetNicknameModalTitle,
        icon: "user",
      },
      on: { "after-enter": _vm.onOpen },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent _ttl",
                  attrs: { href: "", "data-qa": "modal-button:Cancel" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.close.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Common.ModalCancelButton))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "gfu-btn gfu-btn--accent gfu-btn--soft gfu-btn--wide gfu-btn--compact _ml-3",
                  attrs: { type: "button", "data-qa": "modal-button:Save" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Common.ModalSaveButton))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", [
        _vm._v(
          "\n        " +
            _vm._s(_vm.Resources.Comments.SetNicknameModalParagraph) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _c("text-field", {
            ref: "input",
            attrs: {
              "display-name":
                _vm.Resources.Comments.SetNicknameModalNicknameFieldLabel,
              name: "nickname",
              "data-qa": "modal-field:NickName",
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.submit.apply(null, arguments)
              },
            },
            model: {
              value: _vm.nickname,
              callback: function ($$v) {
                _vm.nickname = $$v
              },
              expression: "nickname",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }