var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isMobile
    ? _c("div", { class: ["gfu-sidenav__wrapper"] }, [
        _c(
          "div",
          { staticClass: "gfu-sidenav" },
          [
            _c(
              "div",
              {
                staticClass: "gfu-sidenav__heading",
                attrs: { "data-qa": "sidenav-header" },
              },
              [
                _vm._t("navigation-link"),
                _vm._v(" "),
                _c(
                  "h1",
                  { staticClass: "gfu-heading gfu-heading--main _tal" },
                  [
                    _vm.icon
                      ? _c("base-icon", {
                          staticClass: "gfu-sidenav__icon",
                          attrs: { name: _vm.icon },
                        })
                      : _vm._e(),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.title) +
                        "\n            "
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _vm._t("default"),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }