var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "gfu-content-head" }, [
      _c(
        "div",
        { staticClass: "gfu-project-updates__header" },
        [
          _c(
            "h2",
            {
              staticClass:
                "gfu-hd gfu-hd--h1 gfu-project-updates__header-title",
              attrs: { "data-qa": "page-title" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.Resources.ProjectUpdates.PageHeader) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c("multi-selector-field", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  !_vm.isProcessing &&
                  _vm.showUserContextFilter &&
                  _vm.allowFilter,
                expression:
                  "!isProcessing && showUserContextFilter && allowFilter",
              },
            ],
            staticClass: "gfu-project-updates__user-context-filter",
            attrs: {
              inline: true,
              "show-label": false,
              name: "selectFilters.projectUpdateUserContext",
              options: _vm.updateUserContextOptions,
            },
            on: { change: _vm.handleProjectUpdateUserContext },
            model: {
              value: _vm.selectFilters.projectUpdateUserContext,
              callback: function ($$v) {
                _vm.$set(_vm.selectFilters, "projectUpdateUserContext", $$v)
              },
              expression: "selectFilters.projectUpdateUserContext",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gfu-project-updates__wrapper" },
      [
        _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
        _vm._v(" "),
        _c("form", { on: { change: _vm.handleFilterChange } }, [
          _vm.allowFilter
            ? _c(
                "div",
                {
                  staticClass: "gfu-box gfu-project-updates__filters-box _mlb",
                  attrs: { "data-qa": "filterbox-section:UpdateSearch" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-grid gfu-grid--gutter gfu-grid--gutter-tight gfu-project-updates__filters",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "gfu-grid__cell gfu-1of1 _pt-0" },
                        [
                          _c("text-field", {
                            attrs: {
                              "display-name":
                                _vm.Resources.ProjectUpdates.SearchFieldLabel,
                              placeholder:
                                _vm.Resources.ProjectUpdates
                                  .SearchFieldPlaceholder,
                              "auto-complete": "off",
                              "data-qa": "filtersbox-field:UpdateSearch",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("field-label", {
                                        attrs: {
                                          inline: true,
                                          text: _vm.Resources.ProjectUpdates
                                            .SearchFieldLabel,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "gfu-link gfu-project-updates__filters-toggle",
                                          attrs: {
                                            "data-qa":
                                              "updates-element:AdvSearchButton",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.toggleEnableAdvancedSearch.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.Resources.ProjectUpdates
                                                  .SearchFieldActionLink
                                              ) +
                                              "\n                                    "
                                          ),
                                          _c("span", {
                                            staticClass:
                                              "_fa _fa--chevron-down _ml-1",
                                          }),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2275224296
                            ),
                            model: {
                              value: _vm.searchTerm,
                              callback: function ($$v) {
                                _vm.searchTerm = $$v
                              },
                              expression: "searchTerm",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.enableAdvancedSearch
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "gfu-grid__cell gfu-1of1 gfu-1of4--m",
                              },
                              [
                                _c("date-field", {
                                  staticClass: "_ma-0",
                                  attrs: {
                                    "display-name":
                                      _vm.Resources.ProjectUpdates
                                        .SearchFieldDateFrom,
                                    name: "selectFilters.dateFrom",
                                    "show-label": true,
                                    "data-qa": "date-field:MinDate",
                                  },
                                  model: {
                                    value: _vm.selectFilters.dateFrom,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectFilters,
                                        "dateFrom",
                                        $$v
                                      )
                                    },
                                    expression: "selectFilters.dateFrom",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "gfu-grid__cell gfu-1of1 gfu-1of4--m",
                              },
                              [
                                _c("date-field", {
                                  staticClass: "_ma-0",
                                  attrs: {
                                    "display-name":
                                      _vm.Resources.ProjectUpdates
                                        .SearchFieldDateTo,
                                    name: "selectFilters.dateTo",
                                    "show-label": true,
                                    "data-qa": "date-field:MaxDate",
                                  },
                                  model: {
                                    value: _vm.selectFilters.dateTo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.selectFilters, "dateTo", $$v)
                                    },
                                    expression: "selectFilters.dateTo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm._l(_vm.updates, function (update, index) {
          return [
            _c(
              "div",
              {
                key: update.id,
                staticClass: "gfu-project-update__container",
                class: { "_mt-6": index === 0 },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "gfu-project-update__badge",
                    class: { "gfu-project-update__trace": index > 0 },
                  },
                  [
                    _c("div", {
                      staticClass: "gfu-project-update__badge-inner",
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "gfu-box gfu-box--thick gfu-box--no-fx",
                    attrs: {
                      href: update.projectUpdateUrl,
                      "data-qa": "update-container:" + update.projectUpdateID,
                    },
                  },
                  [
                    update.pollInfo
                      ? _c(
                          "div",
                          { staticClass: "gfu-box__tags" },
                          [
                            _c(
                              "base-badge",
                              {
                                attrs: {
                                  theme: _vm.getPollLabel(update).theme,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.getPollLabel(update).text) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("project-update-banner", {
                      attrs: { update: update, "show-info": true },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "date",
                            fn: function () {
                              return [
                                update.updatedAt
                                  ? _c(
                                      "tooltip",
                                      {
                                        staticClass: "_mr-1",
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "content",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "localize",
                                                    {
                                                      attrs: {
                                                        tag: "div",
                                                        resource:
                                                          _vm.Resources
                                                            .ProjectUpdates
                                                            .OriginallyPublished,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "_fw-eb _ffb",
                                                          attrs: {
                                                            "format-key": "0",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$format(
                                                                update.publishedAt
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "localize",
                                                    {
                                                      attrs: {
                                                        tag: "div",
                                                        resource:
                                                          _vm.Resources
                                                            .ProjectUpdates
                                                            .LastModified,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "_fw-eb _ffb",
                                                          attrs: {
                                                            "format-key": "0",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$format(
                                                                update.updatedAt
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "_fa _fa--info",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$format(update.publishedAt))
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        }),
        _vm._v(" "),
        _vm.canLoadMore
          ? _c(
              "div",
              { staticClass: "gfu-project-update__load-more-wrapper" },
              [
                _c("div", { staticClass: "gfu-project-update__trace" }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "gfu-project-update__load-more gfu-btn gfu-btn--block gfu-btn--secondary-alt",
                    attrs: {
                      type: "button",
                      "data-qa": "updates-list-element:LoadMore",
                    },
                    on: { click: _vm.loadMoreUpdates },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.Resources.Common.LoadMoreButton) +
                        "\n                "
                    ),
                    _c("span", {
                      staticClass:
                        "_fa _fa--angle-down fa-2x _tc--accent _ml-1 _tvam",
                    }),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.updates.length === 0 && _vm.isInitalized
          ? _c(
              "div",
              {
                staticClass: "gfu-box _mt-6",
                attrs: { "data-qa": "updates-box" },
              },
              [
                _vm.isSearchResult
                  ? _c("empty-state", {
                      attrs: {
                        title:
                          _vm.Resources.ProjectUpdates.EmptyStateSearchTitle,
                        description:
                          _vm.Resources.ProjectUpdates
                            .EmptyStateSearchParagraph,
                      },
                    })
                  : [
                      _vm.isEditor
                        ? _c(
                            "empty-state",
                            {
                              attrs: {
                                title:
                                  _vm.Resources.ProjectUpdates
                                    .EmptyStateEditorTitle,
                                description:
                                  _vm.Resources.ProjectUpdates
                                    .EmptyStateEditorParagraph,
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "gfu-btn gfu-btn--accent gfu-btn--soft gfu-btn--wide",
                                  attrs: {
                                    to: {
                                      name: "project-communication-updates",
                                      params: {
                                        project:
                                          _vm.projectContext.project.urlName,
                                        creator:
                                          _vm.projectContext.project.creator
                                            .urlName,
                                      },
                                    },
                                    "data-qa": "updates-element:AddAnUpdate",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.Resources.ProjectUpdates
                                          .EmptyStateEditorParagraphButton
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c("empty-state", {
                            attrs: {
                              title:
                                _vm.Resources.ProjectUpdates.EmptyStateTitle,
                              description:
                                _vm.Resources.ProjectUpdates
                                  .EmptyStateParagraph,
                            },
                          }),
                    ],
              ],
              2
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }