
import { defineComponent } from 'vue';
import { splitStringWithPlaceholders } from '@/Utils/StringUtility';

export default defineComponent({
    props: {
        tag: { type: String, default: 'span' },
        resource: { type: String, required: true }
    },
    computed: {
        availablePlaceholders() {
            return splitStringWithPlaceholders(this.resource, this.availableComponents);
        },
        availableComponents() {
            const attributeName = 'format-key';
            return this.$slots.default.reduce((acc, component) => {
                if (component?.data?.attrs[attributeName]) {
                    acc[component.data.attrs[attributeName]] = component;
                }
                return acc;
            }, {});
        }
    },
    render(createElement) {
        return createElement(this.tag, this.availablePlaceholders.map((part) => {
            if (typeof part === 'object') {
                const component = this.availableComponents[part.placeholder];
                return component;
            }
            return part;
        }));
    }
});
