import { guid } from '@/Utils';

class TickServiceInstance {
    subscriptions = {};
    intervalIds = {};
    guids = {};

    constructor() {
        return TickServiceInstance.instance;
    }

    /**
     * Subscribe to synchronized interval calls
     * @param {Function} callback - function to synchronize interval calls
     * @param {Number} interval - interval between function calls
     * @returns {String} - subscription guid
     */
    subscribe(callback, interval) {
        const callbackGuid = guid();
        if (!this.subscriptions[interval]) {
            this.subscriptions[interval] = {};
            // TODO: For better parallel execution these callbacks could be wrapped in Promises
            // TODO: Intervals could be started in synchronized way to synchronize all ticks
            const handler = () =>
                Object.values(this.subscriptions[interval]).forEach(callback => callback());
            const intervalId = setInterval(handler, interval);
            this.intervalIds[interval] = intervalId;
        }

        this.subscriptions[interval][callbackGuid] = callback;
        this.guids[callbackGuid] = interval;

        return callbackGuid;
    }

    /**
     * Unsubscribes function from synchronized interval calls
     * @param {String} callbackGuid - subscription guid
     */
    unsubscribe(callbackGuid) {
        const interval = this.guids[callbackGuid];
        if (!interval) {
            return;
        }

        const subscriptions = this.subscriptions[interval];
        if (subscriptions[callbackGuid]) {
            delete subscriptions[callbackGuid];
        }

        if (Object.keys(subscriptions).length === 0) {
            const intervalId = this.intervalIds[interval];
            if (intervalId) {
                clearInterval(intervalId);
            }
            delete this.subscriptions[interval];
            delete this.intervalIds[interval];
            delete this.guids[callbackGuid];
        }
    }
}

const instance = new TickServiceInstance();
Object.seal(instance);

export default instance;