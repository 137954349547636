var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "custom-select-field",
    _vm._b(
      {
        attrs: {
          placeholder: _vm.placeholderText,
          "data-qa": `multi-checkbox-field:${_vm.displayName}`,
        },
      },
      "custom-select-field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    _vm._l(_vm.options, function (val, name) {
      return _c(
        "div",
        { key: `multi-checkbox-item-${val}` },
        [
          _c("checkbox-field", {
            attrs: {
              value: _vm.value,
              "display-name": name,
              "checked-value": val,
              inline: true,
              condensed: true,
              "data-qa": `multi-checkbox-item:${name}`,
            },
            on: { input: _vm.onInput },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }