var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isPledgeEmpty
        ? [
            _c(
              "h6",
              {
                staticClass: "gfu-heading gfu-heading--light",
                attrs: { "data-qa": "pledge-not-empty" },
              },
              [
                _vm._v(
                  _vm._s(_vm.Resources.ProjectHome.MiniWizardCartItemsHeader)
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "mini-wizard-items-list",
              _vm._b(
                {},
                "mini-wizard-items-list",
                _vm.getItemsListModel(false),
                false
              )
            ),
            _vm._v(" "),
            _c(
              "mini-wizard-items-list",
              _vm._b(
                {},
                "mini-wizard-items-list",
                _vm.getItemsListModel(true),
                false
              )
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showEmptyRewardsInfo
        ? [
            _vm.showRewardsHeader
              ? _c(
                  "div",
                  {
                    class: [
                      "gfu-project-mini-wizard__paragraph _mb-2",
                      { "_mt-2": !_vm.isPledgeEmpty },
                    ],
                    attrs: { "data-qa": "mini-wizard-element:NoRewardsYet" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.Resources.ProjectHome.MiniWizardNoCartItemsHeader
                        ) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._t("chooseRewardsButton", function () {
              return [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--primary",
                    attrs: {
                      to: {
                        name: "page-section",
                        params: { section: _vm.rewardsSection.urlName },
                      },
                      "data-qa": "mini-wizard-button:ChooseReward",
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "_fa _fa--plus gfu-btn__icon gfu-icon _fl",
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.Resources.ProjectHome
                            .MiniWizardChooseRewardsButton
                        ) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showEmptyAddonsInfo
        ? [
            _c(
              "div",
              {
                staticClass: "gfu-project-mini-wizard__paragraph _my-2",
                attrs: { "data-qa": "mini-wizard-element:NoAddonsYet" },
              },
              [
                _vm._v(
                  _vm._s(_vm.Resources.ProjectHome.MiniWizardNoAddonsSubheader)
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "_mst" },
              [
                _vm._t("chooseAddonsButton", function () {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--primary",
                        attrs: {
                          to: {
                            name: "page-section",
                            params: { section: _vm.addonsSection.urlName },
                          },
                          "data-qa": "mini-wizard-button:PickAddons",
                        },
                      },
                      [
                        _c("span", {
                          staticClass:
                            "_fa _fa--plus gfu-btn__icon gfu-icon _fl",
                        }),
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .MiniWizardChooseAddonsButton
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddMoreItems
        ? [
            _vm._t("addMoreItemsButton", function () {
              return [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--primary _mt-2",
                    attrs: {
                      to: {
                        name: "page-section",
                        params: { section: _vm.rewardsSection.urlName },
                      },
                      "data-qa": "mini-wizard-button:AddMoreItems",
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "_fa _fa--plus gfu-btn__icon gfu-icon _fl",
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.Resources.ProjectHome.MiniWizardAddMoreItemsButton
                        ) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }