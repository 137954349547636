import ServiceBase from './ServiceBase';

export default class PledgeService extends ServiceBase {
    constructor($http) {
        super($http);
    }

    authorizeDeferredPayment(paymentModel, projectID, providerPaymentID, numberOfInstallments) {
        const model = {
            projectID,
            providerPaymentID,
            numberOfInstallments,
            ...paymentModel
        };

        return this.request('post', 'pledge/authorizeDeferredPayment', model);
    }

    authorizeDeferredPaymentChange(paymentModel, projectID, orderCode) {
        const model = {
            projectID,
            orderCode,
            ...paymentModel,
        };

        return this.request('post', 'pledge/authorizeDeferredPaymentChange', model);
    }

    authorizeInstantPayment(paymentModel, projectID, orderCode, checkoutMode, providerPaymentID) {
        const model = {
            projectID,
            orderCode,
            checkoutMode,
            providerPaymentID,
            ...paymentModel
        };
        return this.request('post', 'pledge/authorizeInstantPayment', model);
    }

    createDeferredPayment(paymentModel, projectID) {
        const model = {
            projectID,
            ...paymentModel
        };

        return this.request('post', 'pledge/createDeferredPayment', model);
    }

    changeDeferredPayment(paymentModel, projectID, orderCode) {
        const model = {
            projectID,
            orderCode,
            ...paymentModel
        };

        return this.request('post', 'pledge/changeDeferredPayment', model);
    }

    createInstantPayment(paymentModel, projectID, orderCode, checkoutMode) {
        const model = {
            projectID,
            orderCode,
            checkoutMode,
            ...paymentModel
        };
        return this.request('post', 'pledge/createInstantPayment', model);
    }

    amendPaidPledgeWithoutPayment(orderCode, projectID) {
        const model = {
            orderCode,
            projectID
        };
        return this.request('post', 'pledge/AmendPaidPledgeWithoutPayment', model);
    }

    getDynamicConversionCurrencies(projectID, projectLocationID, orderCode) {
        const params = {
            projectID,
            projectLocationID,
            orderCode
        };

        return this.request('get', 'pledge/getDynamicConversionCurrencies', params);
    }

    getAvailablePaymentMethods({ projectID, currencyShortName, locationIsoCode, orderCode, reason }) {
        return this.request('get', 'pledge/getAvailablePaymentMethods', {
            projectID,
            currencyShortName,
            locationIsoCode,
            orderCode,
            reason
        });
    }

    payRemainingInstallments(orderCode) {
        return this.request('post', 'pledge/payRemainingInstallments', { orderCode });
    }

    retryInstallmentPayment(paymentID, orderCode) {
        return this.request('post', 'pledge/retryInstallmentPayment', { paymentID, orderCode });
    }
}