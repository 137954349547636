var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.classes }, [
    _c("div", { staticClass: "gfu-table__head" }, [_vm._t("head")], 2),
    _vm._v(" "),
    _c("div", { staticClass: "gfu-table__body" }, [_vm._t("body")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }