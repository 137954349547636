export default {
    name: 'ClickOutside',
    props: {
        call: {
            type: Function,
            default: () => {}
        }
    },
    mounted() {
        const handleClick = e => {
            if (e.target !== this.$el && !this.$el.contains(e.target)) {
                this.call();
            }
        };

        document.addEventListener('mousedown', handleClick);

        this.$once('hook:destroyed', () => {
            document.removeEventListener('mousedown', handleClick);
        });
    },
    render() {
        return this.$slots.default[0];
    }
};