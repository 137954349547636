var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-product-details-gallery",
        { "_mb-6": _vm.images.length > 1 },
      ],
    },
    [
      _c("base-slider", {
        staticClass: "gfu-product-details-slider",
        attrs: { autoplay: true },
        on: { change: _vm.onChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ currentItem }) {
              return _vm._l(_vm.images, function (image, index) {
                return _c(
                  "a",
                  {
                    key: image.productImage + index,
                    class: [
                      "gfu-product-details-slider__slide",
                      { "is-active": currentItem === index },
                    ],
                    attrs: { href: image.productZoomImage },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _vm.shouldLoad(index)
                      ? _c("img", {
                          staticClass: "gfu-product-details-slider__image",
                          attrs: { src: image.productImage, lazy: "true" },
                        })
                      : _vm._e(),
                  ]
                )
              })
            },
          },
          {
            key: "nav",
            fn: function ({ onNext, onPrev }) {
              return [
                _c("base-slider-navigation", {
                  attrs: { type: "prev" },
                  on: { click: onPrev },
                }),
                _vm._v(" "),
                _c("base-slider-navigation", {
                  attrs: { type: "next" },
                  on: { click: onNext },
                }),
              ]
            },
          },
          {
            key: "indicators",
            fn: function ({ currentItem, onSetCurrentIndex }) {
              return [
                _c("base-slider-indicators", {
                  staticClass: "gfu-product-details-slider__indicators",
                  attrs: {
                    "current-item": currentItem,
                    "items-count": _vm.images.length,
                  },
                  on: { selected: onSetCurrentIndex },
                }),
              ]
            },
          },
          {
            key: "actions",
            fn: function () {
              return [
                _vm.hasZoom
                  ? _c("base-slider-navigation", {
                      attrs: { type: "zoom" },
                      on: { click: _vm.onZoom },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "modal-box",
        { attrs: { "is-visible": _vm.modalIsVisible, width: "fullscreen" } },
        [
          _c(
            "div",
            { staticClass: "gfu-product-details-fullscreen" },
            [
              _c("base-slider", {
                staticClass: "gfu-product-details-fullscreen__wrap",
                attrs: { current: _vm.currentImage, "keyboard-enabled": true },
                on: { change: _vm.onChange },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ currentItem }) {
                      return _vm._l(
                        _vm.productZoomImages,
                        function (image, index) {
                          return _c(
                            "div",
                            {
                              key: image.imageUrl + index,
                              class: [
                                "gfu-product-details-fullscreen__slide",
                                { "is-active": currentItem === index },
                              ],
                              style: {
                                backgroundImage: _vm.getZoomImage(
                                  currentItem,
                                  index
                                ),
                              },
                            },
                            [
                              _c("progress-overlay", {
                                staticClass:
                                  "gfu-product-details-fullscreen__progress",
                                attrs: { visible: !image.isLoaded },
                              }),
                            ],
                            1
                          )
                        }
                      )
                    },
                  },
                  {
                    key: "nav",
                    fn: function ({ onNext, onPrev }) {
                      return [
                        _c("base-slider-navigation", {
                          attrs: { type: "prev" },
                          on: { click: onPrev },
                        }),
                        _vm._v(" "),
                        _c("base-slider-navigation", {
                          attrs: { type: "next" },
                          on: { click: onNext },
                        }),
                      ]
                    },
                  },
                  {
                    key: "indicators",
                    fn: function ({ currentItem, onSetCurrentIndex }) {
                      return [
                        _c("base-slider-indicators", {
                          staticClass:
                            "gfu-product-details-fullscreen__indicators",
                          attrs: {
                            "current-item": currentItem,
                            "items-count": _vm.productZoomImages.length,
                          },
                          on: { selected: onSetCurrentIndex },
                        }),
                      ]
                    },
                  },
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c("base-slider-navigation", {
                          attrs: { type: "close", theme: "primary" },
                          on: { click: _vm.onClose },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }