import ServiceBase from './ServiceBase';

export default class CreatorsService extends ServiceBase {
    getAvailableCreators() {
        return this.request('get', 'creators/getAvailableCreators');
    }

    createAccount(creator) {
        return this.request('post', 'creators/createCreatorAccount', creator);
    }

    searchCreatorsByTerm(searchTerm) {
        return this.request('get', 'creators/searchCreators', { params: { searchTerm } });
    }

    createCreator(creator) {
        return this.request('post', 'creators/createCreator', creator);
    }

    isCreatorNameValid(creatorID, name) {
        return this.request('get', 'creators/isCreatorNameValid', { params: { creatorID, name } });
    }

    isCreatorUrlValid(creatorID, urlName) {
        return this.request('get', 'creators/isCreatorUrlValid', { params: { creatorID, urlName } });
    }

    getCreatorIdentitySettings(creatorID) {
        return this.request('get', 'creators/getCreatorIdentitySettings', { params: { creatorID } });
    }

    reorderGalleryItem(galleryItem, creatorID) {
        return this.request('post', 'creators/reorderGalleryItem', {
            creatorID,
            ...galleryItem
        });
    }

    getCreatorCommunicationChannels(creatorID) {
        return this.request('get', 'creators/getCreatorCommunicationChannels', { params: { creatorID } });
    }

    updateCreatorCommunicationChannels(model) {
        return this.request('post', 'creators/updateCreatorCommunicationChannels', model);
    }

    getCreatorFeatureSettings(creatorID) {
        return this.request('get', 'creators/getCreatorFeatureSettings', { params: { creatorID } });
    }

    getCreatorSettings(creatorID) {
        return this.request('get', 'creators/getCreatorSettings', { params: { creatorID } });
    }

    getCreatorResources(creatorID) {
        return this.request('get', 'creators/getCreatorResources', { params: { creatorID } });
    }

    getCreatorBillingSettings(creatorID) {
        return this.request('get', 'creators/getCreatorBillingSettings', { params: { creatorID } });
    }

    updateCreatorGeneralSettings(creatorGeneralSettings) {
        return this.request('post', 'creators/updateCreatorGeneralSettings', creatorGeneralSettings);
    }

    updateCreatorBillingSettings(creatorBillingSettings) {
        return this.request('post', 'creators/updateCreatorBillingSettings', creatorBillingSettings);
    }

    updateCreatorFeatureSettings(creatorFeatureSettings) {
        return this.request('post', 'creators/updateCreatorFeatureSettings', creatorFeatureSettings);
    }
}
