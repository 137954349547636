var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-project-mini-wizard__wrapper" }, [
    _c(
      "div",
      {
        staticClass: "gfu-box gfu-box--flat gfu-project-mini-wizard _fo",
        attrs: { "data-qa": "project-mini-wizard" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "gfu-project-mini-wizard__header gfu-project-mini-wizard__header--image",
            attrs: { "data-qa": "mini-wizard-header" },
          },
          [
            _c(
              "div",
              {
                staticClass: "gfu-hd gfu-hd--h3 _tc--inherit",
                attrs: { "data-qa": "mini-wizard-header:Backer" },
              },
              [
                _vm.currentPledge.backerNumber
                  ? _c(
                      "span",
                      [
                        _c(
                          "localize",
                          {
                            attrs: {
                              resource:
                                _vm.Resources.ProjectHome
                                  .MiniWizardBackerNumberText,
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: {
                                  "format-key": "0",
                                  "data-qa": "mini-wizard-header:BackerNumber",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$format(
                                      _vm.currentPledge.backerNumber,
                                      "N0"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.ProjectHome
                            .MiniWizardBackerWithoutNumberText
                        )
                      ),
                    ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "_flexbtwn _ai-c" }, [
              _c(
                "a",
                {
                  staticClass: "gfu-bt--caption _ttu",
                  attrs: { href: _vm.currentPledge.cartDetailsUrl },
                },
                [
                  _vm._v(
                    _vm._s(_vm.Resources.ProjectHome.MiniWizardPledgeValueText)
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "gfu-project-mini-wizard__price _tc--inverse _fr",
                  attrs: { "data-qa": "mini-wizard-header:AlreadyPledged" },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$format(
                          _vm.effectiveTotalValueConverted,
                          "C2",
                          _vm.pledge.currencySymbol
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.hasTaxHandlingEnabled
              ? _c(
                  "div",
                  {
                    staticClass:
                      "gfu-project-mini-wizard__caption _tc--inverse _flexb100",
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.taxesSummaryLabel) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.userContext.isAuthenticated
          ? _c("available-credits-box", {
              staticClass:
                "gfu-project-mini-wizard__header gfu-project-mini-wizard__header--compact",
              attrs: { "credit-balance": _vm.creditBalance },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "detect-overflow",
          { staticClass: "gfu-project-mini-wizard__content" },
          [
            _c(
              "div",
              {
                class: [
                  "_pt-3 _pl-3 _pr-3",
                  {
                    "_pb-3":
                      _vm.isPledgeEmpty &&
                      _vm.hasSameItemsAsParent &&
                      (!_vm.allItemsRemoved || _vm.isPledgeImplicitEditMode),
                  },
                ],
              },
              [
                !_vm.allItemsRemoved
                  ? _c("mini-wizard-items-content", {
                      attrs: {
                        "currency-symbol": _vm.currencySymbol,
                        rewards: _vm.rewards,
                        addons: _vm.addons,
                        "is-unmodified-pledge": _vm.isUnmodifiedPledge,
                        "can-delete-items": _vm.canDeleteItems,
                        "show-addons": !_vm.isPledgeEmpty,
                        "pledge-date": _vm.pledgeDate,
                        "show-delete-button": false,
                        "show-rewards-header": false,
                        tips: _vm.tips,
                        discounts: _vm.discounts,
                        "delete-method": _vm.onDeleteItem,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.allItemsRemoved
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "gfu-project-mini-wizard-item gfu-btn gfu-btn--block gfu-btn--thin gfu-btn--light-outline _fo _ttr",
                        attrs: { "data-qa": `mini-wizard-item:Empty` },
                      },
                      [
                        _c("span", { staticClass: "gfu-btn__text _lhb" }, [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .MiniWizardRemovedAllItemsText
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        !_vm.isPledgeEmpty || _vm.allItemsRemoved
          ? _c(
              "div",
              {
                class: [
                  "gfu-project-mini-wizard__footer",
                  { "_pt-0": _vm.isUnmodifiedPledge },
                ],
              },
              [
                !_vm.hasSameItemsAsParent
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "gfu-project-mini-wizard__summary",
                          attrs: { "data-qa": "mini-wizard:YourPledge" },
                        },
                        [
                          _c(
                            "h6",
                            { staticClass: "gfu-heading gfu-heading--light" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.Resources.ProjectHome
                                      .MiniWizardModifyingPledgeCostBeforeLabel
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("display-currency-price", {
                            staticClass: "_whs-nw",
                            attrs: {
                              "effective-price":
                                _vm.pledge.parentOrder
                                  .totalDiscountedValueConverted,
                              "reverse-tooltip-position": true,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "effective-price",
                                  fn: function ({ effectivePrice }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "gfu-project-mini-wizard__price",
                                          attrs: {
                                            "data-qa":
                                              "mini-wizard:OriginalPledge",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(effectivePrice) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              967236138
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "gfu-project-mini-wizard__summary",
                          attrs: { "data-qa": "mini-wizard:YourPledge" },
                        },
                        [
                          _c(
                            "h6",
                            { staticClass: "gfu-heading gfu-heading--light" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.Resources.ProjectHome
                                      .MiniWizardModifyingPledgeCostAfterLabel
                                  ) +
                                  "\n                        "
                              ),
                              _c(
                                "tooltip",
                                {
                                  attrs: { text: "Does not include shipping." },
                                },
                                [_c("span", { staticClass: "_fa _fa--info" })]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("display-currency-price", {
                            staticClass: "_whs-nw",
                            attrs: {
                              "effective-price":
                                _vm.pledge.totalDiscountedValueConverted,
                              "reverse-tooltip-position": true,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "effective-price",
                                  fn: function ({ effectivePrice }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "gfu-project-mini-wizard__price",
                                          attrs: {
                                            "data-qa":
                                              "mini-wizard:YourPledgePrice",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(effectivePrice) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2667991503
                            ),
                          }),
                          _vm._v(" "),
                          _vm.hasTaxHandlingEnabled
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "gfu-project-mini-wizard__caption _flexb100",
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.taxesSummaryLabel) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isProjectInFundingPhase &&
                      _vm.pledge.creditDiscountConverted
                        ? _c(
                            "div",
                            { staticClass: "gfu-project-mini-wizard__summary" },
                            [
                              _c(
                                "h6",
                                {
                                  staticClass: "gfu-heading gfu-heading--light",
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.Resources.ProjectHome
                                          .MiniWizardCreditDiscountLabel
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "gfu-project-mini-wizard__price _tc--accent",
                                  attrs: {
                                    "data-qa": "mini-wizard:UsedCreditsAmount",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$format(
                                          _vm.pledge.creditDiscountConverted,
                                          "C2",
                                          _vm.pledge.currencySymbol
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.rewardsSection && _vm.canAddNewItems
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--primary _mb-2",
                        attrs: {
                          to: {
                            name: "page-section",
                            params: { section: _vm.rewardsSection.urlName },
                          },
                          "data-qa": "mini-wizard-button:AddMoreItems",
                        },
                      },
                      [
                        _c("span", {
                          staticClass:
                            "_fa _fa--plus gfu-btn__icon gfu-icon _fl",
                        }),
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .MiniWizardUpgradePledgeButton
                            ) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.addonsSection && _vm.canAddNewItems
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--primary _mb-2",
                        attrs: {
                          to: {
                            name: "page-section",
                            params: { section: _vm.addonsSection.urlName },
                          },
                          "data-qa": "mini-wizard-button:PickAddons",
                        },
                      },
                      [
                        _c("span", {
                          staticClass:
                            "_fa _fa--plus gfu-btn__icon gfu-icon _fl",
                        }),
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .MiniWizardMoreAddOnsButton
                            ) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("go-to-checkout", {
                  attrs: { "continue-url": _vm.currentPledge.checkoutUrl },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ onInvoke }) {
                          return [
                            _c(
                              "tooltip",
                              {
                                attrs: {
                                  text: _vm.currentPledge
                                    .canProceedToCheckoutValidationResult
                                    .message,
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--accent",
                                    attrs: {
                                      href: _vm.currentPledge.checkoutUrl,
                                      disabled:
                                        !_vm.currentPledge
                                          .canProceedToCheckoutValidationResult
                                          .isValid,
                                      "data-qa":
                                        "mini-wizard-button:SubmitPledge",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return onInvoke.apply(null, arguments)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.Resources.ProjectHome
                                            .MiniWizardCompletePledgeButton
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1004082207
                  ),
                }),
                _vm._v(" "),
                _vm.isUnfinishedOrder &&
                _vm.isEditingPledge &&
                !_vm.hasSameItemsAsParent
                  ? _c("div", { staticClass: "_tac _pt-1 _mt-1" }, [
                      _c(
                        "a",
                        {
                          staticClass: "gfu-link gfu-link--accent",
                          attrs: {
                            href: "",
                            "data-qa": "mini-wizard-button:RevertChanges",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onRevertChanges.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .MiniWizardRevertChangesButton
                            )
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                (_vm.isPledgePlaced && !_vm.hasSameItemsAsParent) ||
                !_vm.isPledgeEmpty
                  ? _c("div", { staticClass: "_tac _pt-1 _mt-1" }, [
                      _c(
                        "a",
                        {
                          staticClass: "gfu-link gfu-link--accent",
                          attrs: {
                            href: _vm.currentPledge.cartDetailsUrl,
                            "data-qa": "mini-wizard-button:ManageYourPledge",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .MiniWizardManagePledgeButton
                            )
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }