import { slugify } from '@/Utils/StringUtility';
import { orderItemPaymentStatuses, projectVersions, projectPhases, productPurchaseAvailabilities } from '@/Models';

export function isEditingLimitedByPhase(publishedDuringPhase, project) {
    // As long as we support v1 projects we have to have this fallback
    if (project.version === projectVersions.v1) {
        return false;
    }

    return (publishedDuringPhase === projectPhases.crowdfunding || publishedDuringPhase === projectPhases.crowdfundingDraft)
        && project.phase > projectPhases.crowdfunding;
}

export function getEffectivePurchaseLimit({ productID, remainingStockLimit, remainingPerUserLimit }, getAvailableQuantityFromPledgeCallback, projectVersion) {
    if (remainingStockLimit === null && remainingPerUserLimit === null) {
        return { status: productPurchaseAvailabilities.available, value: 99999 };
    }

    if (remainingPerUserLimit === null
        || remainingStockLimit !== null && remainingStockLimit <= remainingPerUserLimit) {
        const effectiveStockLimit = getAvailableQuantityFromPledgeCallback({ productID, remainingStockLimit, deductQuantityFromCurrentItems: true }, projectVersion);

        if (effectiveStockLimit > 0) {
            return { status: productPurchaseAvailabilities.available, value: effectiveStockLimit };
        }

        return { status: productPurchaseAvailabilities.stockLimitReached, value: 0 };
    }

    const effectiveUserLimit = getAvailableQuantityFromPledgeCallback({ productID, remainingStockLimit: remainingPerUserLimit, deductQuantityFromCurrentItems: true }, projectVersion);

    if (effectiveUserLimit > 0) {
        return { status: productPurchaseAvailabilities.available, value: effectiveUserLimit };
    }

    return { status: productPurchaseAvailabilities.perUserLimitReached, value: 0 };
}

export function groupProductOptions(productSetItems) {
    try {
        const groupedOptions = productSetItems.reduce((acc, item) => {
            item.setItem.options.forEach((option) => {
                const existentOption = acc.find(o => o.text === option.text);

                if (existentOption) {
                    const hasSameValues = checkForSameValues(existentOption.values, option.values);
                    const hasSameDefaults = checkForSameDefaults(existentOption.values, option.values);

                    if (hasSameValues && hasSameDefaults) {
                        addToExistentOption(existentOption, item, option.values);
                    }
                    else {
                        throw new Error(`Product option "${ option.text }" has different values or defaults in different set items.`);
                    }
                }
                else {
                    const values = option.values.map(value => createGroupedOptionValue(value, item));

                    const groupedOption = {
                        text: option.text,
                        values,
                        setItems: [item.setItem],
                        selectedOptionValueId: values.find(v => v.isDefault).groupedOptionValueId,
                    };

                    acc.push(groupedOption);
                }
            });

            return acc;
        }, []);

        const hasMultipleUniqueSetItems = groupedOptions.some(v => {
            const hasMultipleItems = v.setItems.length > 1;
            const hasUniqueItems = new Set(v.setItems.map(v => v.productID)).size === v.setItems.length;
            return hasMultipleItems && hasUniqueItems;
        });

        return hasMultipleUniqueSetItems ? groupedOptions : [];
    }
    catch (error) {
        return [];
    }
}

export function getTotalProductQuantity(orderItems, onlyPaidItems, productID) {
    return orderItems.reduce((acc, obj) =>
        obj.productID === productID && (!onlyPaidItems || obj.paymentStatus !== orderItemPaymentStatuses.unpaid) ? acc + obj.quantity : acc, 0
    );
}

function normalizeLabel(label) {
    return label.trim().toLowerCase();
}

function checkForSameValues(existentValues, optionValues) {
    const values = existentValues
        .map(v => v.text)
        .filter(a => !optionValues.map(v => normalizeLabel(v.text)).includes(normalizeLabel(a)));

    return values.length === 0;
}

function checkForSameDefaults(existentValues, optionValues) {
    const existentValue = existentValues.find(v => v.isDefault);
    const optionValue = optionValues.find(v => v.isDefault);
    return normalizeLabel(existentValue.text) === normalizeLabel(optionValue.text);
}

function addToExistentOption(existentOption, item, optionValues) {
    existentOption.setItems.push(item.setItem);

    existentOption.values.forEach(existentOptionValue => {
        const value = optionValues.find(v => normalizeLabel(v.text) === normalizeLabel(existentOptionValue.text));

        existentOptionValue.groupedOptionValues.push({
            productOptionID: value.productOptionID,
            productOptionValueID: value.productOptionValueID,
        });

        if (value.priceModifier) {
            const priceModifier = value.priceModifier * item.quantity;
            if (typeof existentOptionValue.priceModifier === 'number') {
                existentOptionValue.priceModifier += priceModifier;
            }
            else {
                existentOptionValue.priceModifier = priceModifier;
            }
        }
    });
}

function createGroupedOptionValue(value, item) {
    const priceModifier = value.priceModifier ? value.priceModifier * item.quantity : value.priceModifier;
    return {
        isDefault: value.isDefault,
        priceModifier,
        groupedOptionValueId: slugify(value.text),
        groupedOptionValues: [{
            productOptionID: value.productOptionID,
            productOptionValueID: value.productOptionValueID,
        }],
        sortOrder: value.sortOrder,
        text: value.text
    };
}
