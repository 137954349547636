var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-view", { staticClass: "gfu-layout__main" }, [
    _c("div", { class: ["_flex _gap-6", { "_fd-c": _vm.isMobile }] }, [
      _c(
        "div",
        {
          class: [
            { "_flex-basis-70 _as-fs _pos-sticky _top-13": !_vm.isMobile },
          ],
        },
        [
          _c(
            "h1",
            {
              staticClass:
                "gfu-heading gfu-heading--main gfu-heading--inline _py-6",
              attrs: { "data-qa": "payment-success:Title" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.Resources.PaymentSuccess.DetailsSectionHeader) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "box",
            { staticClass: "_tac" },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "gfu-hd gfu-hd--h2 gfu-heading--top-divider-center",
                },
                [
                  _c(
                    "localize",
                    {
                      attrs: {
                        resource: _vm.Resources.PaymentSuccess.PageHeader,
                      },
                    },
                    [
                      _c("span", { attrs: { "format-key": "0" } }, [
                        _vm._v(_vm._s(_vm.projectName)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.subheader))]),
              _vm._v(" "),
              _vm.backerNumber
                ? _c(
                    "div",
                    {
                      staticClass:
                        "_ba-2 _bc--accent _rounded--common _tc--accent _inline-block gfu-cloud _mt-3",
                    },
                    [
                      _c(
                        "localize",
                        {
                          attrs: {
                            resource:
                              _vm.Resources.PaymentSuccess.BackerNumberMessage,
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: {
                                "format-key": "0",
                                "data-qa": "pledge-success:BackerNumber",
                              },
                            },
                            [_vm._v(_vm._s(_vm.backerNumber))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "_flex _jc-c _ai-c _mt-3" },
                [
                  _c("base-image", {
                    staticClass: "_elevate--base _rounded--common",
                    attrs: {
                      width: "120",
                      height: "120",
                      src: _vm.projectThumbnail,
                      loading: "lazy",
                      alt: "project image",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "gfu-success-box-logo" }),
                  _vm._v(" "),
                  _c("base-image", {
                    staticClass: "gfu-avatar gfu-avatar--clean",
                    attrs: {
                      src: _vm.userAvatarUrl,
                      width: "120",
                      height: "120",
                      loading: "lazy",
                      alt: "user avatar",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "_mt-3 _mb-6",
                      attrs: {
                        tag: "a",
                        href: _vm.yourPledgeUrl,
                        theme: "accent",
                        "data-qa": "pledge-success-link:YourPledge",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.Resources.PaymentSuccess.ReturnActionButton
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isOvercharged
                ? _c("base-notificator", {
                    staticClass: "_mb-6",
                    attrs: {
                      type: "error",
                      "can-close": false,
                      message:
                        _vm.Resources.PaymentSuccess
                          .NotificatorOverpaidOrderErrorMessage,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.orderStatusExplanation
                ? _c(
                    "div",
                    {
                      class: [
                        "_flex _gap-3 _mb-6",
                        { _flexwrap: _vm.isMobile },
                      ],
                    },
                    [
                      _c(
                        "box",
                        {
                          class: ["_tac _flex", _vm.classes],
                          attrs: { slim: "", nested: "" },
                        },
                        [
                          _c("circle-icon", {
                            staticClass: "_mr-2 _flex-no-shrink",
                            attrs: {
                              icon: "check",
                              theme: "success",
                              size: "large",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "_tal" }, [
                            _c("h3", { staticClass: "gfu-hd gfu-hd--h3" }, [
                              _vm._v(_vm._s(_vm.orderStatusExplanation.text)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "_mt-0" }, [
                              _vm._v(_vm._s(_vm.orderStatusExplanation.sub)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "box",
                        {
                          class: ["_tac _flex", _vm.classes],
                          attrs: { slim: "", nested: "" },
                        },
                        [
                          _c("circle-icon", {
                            staticClass: "_mr-2 _flex-no-shrink",
                            attrs: {
                              icon: "clock",
                              theme: "disabled",
                              size: "large",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "_tal" }, [
                            _c("h3", { staticClass: "gfu-hd gfu-hd--h3" }, [
                              _vm._v(
                                _vm._s(_vm.Resources.PaymentSuccess.StepHeader)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "_mt-0" }, [
                              _vm._v(_vm._s(_vm.orderStatusExplanation.next)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasProjectMessage
                ? _c(
                    "box",
                    {
                      staticClass: "_tac _mb-6",
                      attrs: {
                        nested: "",
                        "data-qa": "payment-success:SpecialThanks",
                      },
                    },
                    [
                      _c(
                        "h1",
                        { staticClass: "gfu-hd gfu-hd--h2 _mb-3" },
                        [
                          _c(
                            "localize",
                            {
                              attrs: {
                                resource:
                                  _vm.Resources.PaymentSuccess
                                    .PaymentSuccessCreatorCustomMessageTitle,
                              },
                            },
                            [
                              _c("span", { attrs: { "format-key": "0" } }, [
                                _vm._v(_vm._s(_vm.creatorName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "gfu-richtext",
                        attrs: {
                          "data-qa": "payment-success:SpecialThanksMessage",
                        },
                        domProps: {
                          innerHTML: _vm._s(_vm.paymentSuccessCreatorMessage),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStretchGoalsSectionVisible
                ? _c(
                    "payment-success-stretch-goals",
                    _vm._b(
                      {},
                      "payment-success-stretch-goals",
                      _vm.paymentSuccessStretchGoals,
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isMobile
            ? _c("project-recommendations", {
                staticClass: "_mt-6",
                attrs: {
                  quantity: 4,
                  title: _vm.Resources.Comments.ProjectRecommendationsTitle,
                  "exclude-project-ids": [_vm.projectContext.projectID],
                  source: "paymentSuccess",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "box",
            { staticClass: "_mt-6" },
            [
              _c(
                "payment-success-faq-section",
                _vm._b(
                  {},
                  "payment-success-faq-section",
                  _vm.faqSectionModel,
                  false
                )
              ),
              _vm._v(" "),
              _vm.hasNewsletterSubscriptionBanner
                ? _c(
                    "div",
                    { staticClass: "_mt-6" },
                    [_c("newsletter-subscription-banner")],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isMobile
        ? _c("div", { staticClass: "_flex-basis-30" }, [
            _c(
              "h3",
              {
                staticClass:
                  "gfu-heading gfu-heading--main gfu-heading--inline _py-6",
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.Resources.PaymentSuccess.RecommendationSectionHeader
                    ) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "_flexcol _gap-6" },
              _vm._l(_vm.recommendations, function (recommendation) {
                return _c(
                  "project-card",
                  _vm._b(
                    { key: recommendation.project.projectID },
                    "project-card",
                    recommendation.project,
                    false
                  )
                )
              }),
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }