var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-project-quickaction" },
    [
      _vm.showCart
        ? [_c("cart-box", { staticClass: "gfu-project-quickaction-cart" })]
        : [
            _c(
              "div",
              { staticClass: "gfu-navbar-item _screen--none _screen--flex-m" },
              [_c("share-link"), _vm._v(" "), _c("share-facebook")],
              1
            ),
            _vm._v(" "),
            _c("transition", { attrs: { name: "gfu-shrink" } }, [
              _vm.isDraftPhase && !_vm.userIsFollowingProject
                ? _c(
                    "div",
                    {
                      staticClass:
                        "gfu-navbar-item gfu-navbar-item__button-wrapper _screen--none _screen--flex-m _pl-1 _mr-1",
                    },
                    [
                      _vm.projectContext.projectID
                        ? _c("follow-project-button", {
                            attrs: {
                              "project-id": _vm.projectContext.projectID,
                              "project-name": _vm.projectContext.project.name,
                              "is-authenticated": _vm.isAuthenticated,
                              "is-stacked": true,
                              "show-overlay": false,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.isBackProjectButtonVisible
              ? _c(
                  "div",
                  {
                    staticClass:
                      "gfu-navbar-item gfu-navbar-item__button-wrapper _screen--none _screen--flex-m _pl-1 _pr-1",
                  },
                  [
                    _c("back-project-button", {
                      attrs: { "is-stacked": true },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }