import lazyScreen from '@/Components/Admin/LazyScreen';

const ProjectSettingsSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-currencies' */ '../Projects/ProjectSettingsSideNav.vue'));
const CurrenciesListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-currencies' */ './CurrenciesListScreen.vue'));
const ProjectCurrenciesListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project-currencies' */ './ProjectCurrenciesListScreen.vue'));

const routes = [
    { path: '/project-settings/:creator/:project/currencies', name: 'project-settings-currencies', components: { default: ProjectCurrenciesListScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/currencies', redirect: { name: 'project-settings-currencies' } },
    { path: '/:creator/:project/currencies', redirect: { name: 'project-settings-currencies' } },

    { path: '/system/currencies', name: 'system-currencies', component: CurrenciesListScreen }
];

export {
    routes
};