import ServiceBase from './ServiceBase';

export default class CommentsService extends ServiceBase {
    addComment(commentThreadID, message, parentID) {
        const params = {
            commentThreadID,
            message,
            parentID
        };

        return this.request('post', 'comments/addComment', params);
    }

    addOrderComment(model) {
        return this.request('post', 'admin/orderComments/addOrderComment', model);
    }

    deleteComment(commentID) {
        return this.request('post', 'comments/deleteComment', { commentID });
    }

    getComments(filter) {
        return this.request('post', 'comments/getComments', filter);
    }

    getCommentsAuthors(authorIDs, commentThreadID) {
        const model = {
            authorIDs,
            commentThreadID
        };

        return this.request('post', 'comments/getCommentsAuthors', model);
    }

    reportComment(model) {
        return this.request('post', 'comments/reportComment', model);
    }

    searchTags(commentThreadID, term, limit) {
        const params = {
            commentThreadID,
            term,
            limit
        };

        return this.request('get', 'comments/searchTags', { params });
    }

    toggleCommentApproval(commentID) {
        return this.request('post', 'comments/toggleCommentApproval', { commentID });
    }

    toggleLikeComment(commentID) {
        return this.request('post', 'comments/toggleLikeComment', { commentID });
    }

    togglePinComment(commentID, commentThreadID) {
        return this.request('post', 'comments/togglePinComment', { commentID, commentThreadID });
    }

    updateComment(commentID, text) {
        return this.request('post', 'comments/updateComment', { commentID, text });
    }

    static get SortTypes() {
        return {
            Recent: 0,
            Popular: 1,
            Oldest: 2
        };
    }

    hideComment({ commentThreadID, commentID, reason, reasonDescription }) {
        const params = {
            commentThreadID,
            commentID,
            reason,
            reasonDescription
        };

        return this.request('post', 'comments/hideComment', params);
    }
}
