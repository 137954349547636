import { checkElementViewportFit } from '@/Utils';

export default {
    props: {
        activeClass: { type: String, default: 'is-active' },
        inactiveClass: { type: String, default: 'is-inactive' },
        autoCollapse: { type: Boolean, default: true },
        collapseOnScroll: { type: Boolean, default: false },
        containerName: { type: [Number, String], required: false },
        fixedContainer: { type: Boolean, default: false },
        handleClick: { type: Boolean, default: true },
        focusChildren: { type: Boolean, default: false },
        tag: { type: String, default: 'div' },
        containerSearchRangeUp: { type: Number, default: 0 },
        ensureContainerPosition: { type: Boolean, default: false },
        containerFullScreenWidth: { type: Boolean, default: false },
        beforeActive: { type: Function, default: () => Promise.resolve() }
    },
    data() {
        return {
            containerComponent: null
        };
    },
    computed: {
        isActive() {
            return this.containerComponent && this.containerComponent.$data.isActive;
        }
    },
    methods: {
        onFocusOut(event) {
            if (!this.autoCollapse)
                return;

            event.preventDefault();

            if (event.relatedTarget) {
                if (this.$el.contains(event.relatedTarget) || this.containerComponent.$el.contains(event.relatedTarget)) {
                    event.relatedTarget.addEventListener('focusout', this.onFocusOut.bind(this), { once: true });
                    return;
                }
            }

            this.collapse();
        },
        collapse() {
            if (this.containerComponent != null) {
                this.containerComponent.$data.isActive = false;
            }
            this.$emit('collapse');
        },
        stopListeningAndCollapse() {
            window.removeEventListener('scroll', this.stopListeningAndCollapse);
            this.collapse();
        },
        expand() {
            // If the container element has changed, we need to find it again
            if (this.containerName && this.containerComponent.name != this.containerName) {
                this.containerComponent = this.findContainer(this.$parent, this.containerSearchRangeUp);
            }

            this.ensureContentPosition();
            this.makeFullWidth();
            if (this.collapseOnScroll) {
                window.addEventListener('scroll', this.stopListeningAndCollapse, true);
            }
            this.containerComponent.$data.isActive = true;

            if (this.autoCollapse) {
                if (this.focusChildren) {
                    this.$el.children[0].focus();
                }
                else {
                    this.$el.focus();
                }
            }

            this.$emit('expand');
        },
        ensureContentPosition() {
            if (this.fixedContainer || this.ensureContainerPosition) {
                const triggerBoundingRect = this.$el.parentElement.getBoundingClientRect();
                const screenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
                const element = this.containerComponent.$el;

                element.style.left = 'unset';
                element.style.right = 'unset';

                const left = triggerBoundingRect.left;
                // Avoid sticking the container to the edge of the screen
                if (!checkElementViewportFit(element).right) {
                    element.style.right = `${ screenWidth - this.$el.offsetWidth - left }px`;
                }
                else {
                    // check if container fits on the screen
                    if (left + element.offsetWidth <= screenWidth) {
                        element.style.left = `${ left > 0 ? left : 0 }px`;
                    }
                    else {
                        element.style.left = `${ screenWidth - element.offsetWidth }px`;
                    }
                }

                if (this.fixedContainer) {
                    element.style.position = 'fixed';
                    element.style.top = `${ triggerBoundingRect.bottom }px`;
                }
            }
        },
        makeFullWidth() {
            if (this.containerFullScreenWidth) {
                const element = this.containerComponent.$el;

                if (this.fixedContainer) {
                    element.style.width = '100vw';
                    element.style.left = '0';
                    return;
                }

                const triggerBoundingRect = this.$el.parentElement.getBoundingClientRect();

                element.style.width = '100vw';
                element.style.right = 'unset';
                element.style.left = `${ -triggerBoundingRect.left }px`;
            }
        },
        findContainer(scope, searchRangeUp) {
            let result = scope.$children.find(c => {
                return c.$options._componentTag === 'toggle-container' && (this.containerName ? c.name === this.containerName : true);
            });

            if (!result && searchRangeUp > 0 && scope.$parent) {
                result = this.findContainer(scope.$parent, searchRangeUp - 1);
            }

            return result;
        }
    },
    render(createElement) {
        const data = {
            on: {
                dragover: event => {
                    event.preventDefault();
                    this.$emit('dragover', event);
                },
                dragenter: event => {
                    event.preventDefault();
                    this.$emit('dragenter', event);
                },
                dragleave: event => {
                    event.preventDefault();
                    this.$emit('dragleave', event);
                },
                drop: event => {
                    event.preventDefault();
                    this.$emit('drop', event);
                },
                click: event => {
                    Promise.resolve()
                        .then(() => {
                            if (this.handleClick) {
                                event.preventDefault();
                                event.stopPropagation();

                                if (this.containerComponent.$data.isActive) {
                                    this.collapse();
                                }
                                else {
                                    return this.beforeActive()
                                        .then(this.expand);
                                }
                            }

                            return Promise.resolve();
                        })
                        .then(() => {
                            this.$emit('click', event);
                        });
                }
            },
            class: {}
        };

        data.class[this.activeClass] = this.isActive;
        data.class[this.inactiveClass] = !this.isActive;

        if (this.autoCollapse) {
            data.on.focusout = this.onFocusOut;
        }

        return createElement(this.tag, data, this.$slots.default);
    },
    mounted() {
        this.containerComponent = this.findContainer(this.$parent, this.containerSearchRangeUp);
    }
};
