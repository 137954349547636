// -------------------------------------------------------------------------------------
// CultureInfo
// -------------------------------------------------------------------------------------

import { cloneDeep } from '@/Utils';

export default class CultureInfo {
    constructor(name, numberFormat, dateTimeFormat) {
        this.name = name;
        this.numberFormat = numberFormat;
        this.dateTimeFormat = dateTimeFormat;
    }

    _getDateTimeFormats() {
        if (!this._dateTimeFormats) {
            const dtf = this.dateTimeFormat;
            this._dateTimeFormats = [dtf.monthDayPattern,
                dtf.yearMonthPattern,
                dtf.shortDatePattern,
                dtf.shortTimePattern,
                dtf.longDatePattern,
                dtf.longTimePattern,
                dtf.fullDateTimePattern,
                dtf.rfc1123Pattern,
                dtf.sortableDateTimePattern,
                dtf.universalSortableDateTimePattern];
        }
        return this._dateTimeFormats;
    }

    _getIndex(value, a1, a2) {
        const upper = this._toUpper(value);
        let i = Array.indexOf(a1, upper);

        if (i === -1) {
            i = Array.indexOf(a2, upper);
        }
        return i;
    }

    _getMonthIndex(value) {
        if (!this._upperMonths) {
            this._upperMonths = this._toUpperArray(this.dateTimeFormat.monthNames);
            this._upperMonthsGenitive = this._toUpperArray(this.dateTimeFormat.monthGenitiveNames);
        }
        return this._getIndex(value, this._upperMonths, this._upperMonthsGenitive);
    }

    _getAbbrMonthIndex(value) {
        if (!this._upperAbbrMonths) {
            this._upperAbbrMonths = this._toUpperArray(this.dateTimeFormat.abbreviatedMonthNames);
            this._upperAbbrMonthsGenitive = this._toUpperArray(this.dateTimeFormat.abbreviatedMonthGenitiveNames);
        }
        return this._getIndex(value, this._upperAbbrMonths, this._upperAbbrMonthsGenitive);
    }

    _getDayIndex(value) {
        if (!this._upperDays) {
            this._upperDays = this._toUpperArray(this.dateTimeFormat.dayNames);
        }
        return Array.indexOf(this._upperDays, this._toUpper(value));
    }

    _getAbbrDayIndex(value) {
        if (!this._upperAbbrDays) {
            this._upperAbbrDays = this._toUpperArray(this.dateTimeFormat.abbreviatedDayNames);
        }
        return Array.indexOf(this._upperAbbrDays, this._toUpper(value));
    }

    _toUpperArray(arr) {
        const result = [];
        for (let i = 0, il = arr.length; i < il; i++) {
            result[i] = this._toUpper(arr[i]);
        }
        return result;
    }

    _toUpper(value) {
        return value.split('\u00A0').join(' ')
            .toUpperCase();
    }

    static get currentCulture() {
        return _currentCulture;
    }

    static set currentCulture(value) {
        _currentCulture = value;
        _currentCultureCache = {};
    }

    static get invariantCulture() {
        return _invariantCulture;
    }

    static parse(value) {
        const dtf = value.dateTimeFormat;
        if (dtf && !dtf.eras) {
            dtf.eras = value.eras;
        }
        return new CultureInfo(value.name, value.numberFormat, dtf);
    }

    static forCurrency(currencySymbol) {
        if (_currentCultureCache[currencySymbol]) {
            return _currentCultureCache[currencySymbol];
        }

        const newCultureInfo = cloneDeep(CultureInfo.currentCulture);
        newCultureInfo.numberFormat.currencySymbol = currencySymbol;

        _currentCultureCache[currencySymbol] = newCultureInfo;

        return newCultureInfo;
    }
}

const _invariantCulture = CultureInfo.parse({
    'name': '',
    'numberFormat': {
        'currencyDecimalDigits': 2,
        'currencyDecimalSeparator': '.',
        'isReadOnly': true,
        'currencyGroupSizes': [3],
        'numberGroupSizes': [3],
        'percentGroupSizes': [3],
        'currencyGroupSeparator': ',',
        'currencySymbol': '\u00A4',
        'naNSymbol': 'NaN',
        'currencyNegativePattern': 0,
        'numberNegativePattern': 1,
        'percentPositivePattern': 0,
        'percentNegativePattern': 0,
        'negativeInfinitySymbol': '-Infinity',
        'negativeSign': '-',
        'numberDecimalDigits': 2,
        'numberDecimalSeparator': '.',
        'numberGroupSeparator': ',',
        'currencyPositivePattern': 0,
        'positiveInfinitySymbol': 'Infinity',
        'positiveSign': '+',
        'percentDecimalDigits': 2,
        'percentDecimalSeparator': '.',
        'percentGroupSeparator': ',',
        'percentSymbol': '%',
        'perMilleSymbol': '\u2030',
        'nativeDigits': ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        'digitSubstitution': 1
    },
    'dateTimeFormat': {
        'amDesignator': 'AM',
        'calendar': {
            'minSupportedDateTime': '@-62135568000000@',
            'maxSupportedDateTime': '@253402300799999@',
            'algorithmType': 1,
            'calendarType': 1,
            'eras': [1],
            'twoDigitYearMax': 2029,
            'isReadOnly': true
        },
        'dateSeparator': '/',
        'firstDayOfWeek': 0,
        'calendarWeekRule': 0,
        'fullDateTimePattern': 'dddd, dd MMMM yyyy HH:mm:ss',
        'longDatePattern': 'dddd, dd MMMM yyyy',
        'longTimePattern': 'HH:mm:ss',
        'monthDayPattern': 'MMMM dd',
        'pmDesignator': 'PM',
        'rfc1123Pattern': 'ddd, dd MMM yyyy HH\':\'mm\':\'ss \'GMT\'',
        'shortDatePattern': 'MM/dd/yyyy',
        'shortTimePattern': 'HH:mm',
        'sortableDateTimePattern': 'yyyy\'-\'MM\'-\'dd\'T\'HH\':\'mm\':\'ss',
        'timeSeparator': ':',
        'universalSortableDateTimePattern': 'yyyy\'-\'MM\'-\'dd HH\':\'mm\':\'ss\'Z\'',
        'yearMonthPattern': 'yyyy MMMM',
        'abbreviatedDayNames': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'shortestDayNames': ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        'dayNames': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        'abbreviatedMonthNames': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', ''],
        'monthNames': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', ''],
        'isReadOnly': true,
        'nativeCalendarName': 'Gregorian Calendar',
        'abbreviatedMonthGenitiveNames': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', ''],
        'monthGenitiveNames': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', '']
    },
    'eras': [1, 'A.D.', null, 0]
});

let _currentCulture = _invariantCulture;
let _currentCultureCache = {};
