import Vue from 'vue';

const _bus = new Vue();
export class EventBus {
    constructor(name, debug) {
        this.name = name;
        this.debug = debug && true;
        this.Topics = {};
    }

    publish(topic, payload = null) {
        if (this.debug)
            // eslint-disable-next-line no-console
            console.log(`EventBus.${ this.name }: publishing in topic '${ topic }'. Payload: `, payload);

        _bus.$emit(topic, payload);
    }

    subscribe(topic, callback) {
        _bus.$on(topic, callback);
    }

    unsubscribe(topic, callback) {
        _bus.$off(topic, callback);
    }

    createTopic(name, isLifecycleTopic = false, dispatchCustomEvent = false) {
        return new EventBusTopic(name, this, isLifecycleTopic, dispatchCustomEvent);
    }
}

export class EventBusTopic {
    constructor(topic, bus, isLifecycleEvent = false, dispatchCustomEvent = false) {
        this.topic = topic;
        this.bus = bus;
        this.isLifecycleEvent = isLifecycleEvent;
        this.dispatchCustomEvent = dispatchCustomEvent;
        this.status = { eventCount: 0, lastPayload: null };
    }

    publish(payload = null) {
        if (this.isLifecycleEvent && this.status.eventCount > 0) {
            throw new Error('Cannot fire a lifecycle event more than once.');
        }

        if (this.dispatchCustomEvent) {
            this.customEvent(payload);
        }

        this.status.eventCount++;
        this.status.lastPayload = payload;
        this.bus.publish(this.topic, payload);

    }

    subscribe(callback) {
        if (this.isLifecycleEvent && this.status.eventCount > 0) {
            callback.call(this, this.status.lastPayload);
        }

        this.bus.subscribe(this.topic, callback);
    }

    unsubscribe(callback) {
        this.bus.unsubscribe(this.topic, callback);
    }

    customEvent(payload = null) {
        document.dispatchEvent(
            new CustomEvent(this.topic, { payload, cancelable: true })
        );
    }
}
