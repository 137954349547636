import ServiceBase from '../ServiceBase';

export default class ProjectsAdminService extends ServiceBase {

    searchProjects(creatorID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            creatorID,
            pageSize,
            pageIndex,
            ...filters || {}
        };

        return this.request('post', 'admin/projects/searchProjects', params);
    }

    enableStablePledge({
        projectID,
        awarenessCannotDeactivatedStablePledge,
        agreementToAdditionalSection,
        declareToShowTheEstimatedPrice,
        confirmBackersPossibilityToRefund,
    }) {
        return this.request('post', 'admin/projects/enableStablePledge', {
            projectID,
            awarenessCannotDeactivatedStablePledge,
            agreementToAdditionalSection,
            declareToShowTheEstimatedPrice,
            confirmBackersPossibilityToRefund,
        });
    }

    confirmTaxRates({
        projectID,
        declareResponsibility,
        taxVerified
    }) {
        return this.request('post', 'admin/projects/confirmTaxRates', {
            projectID,
            declareResponsibility,
            taxVerified
        });
    }

    cancelCampaign({
        projectID,
        isIrreversibleActionConfirmed,
        isChangesBlockingActionConfirmed,
        isUpdatePostPublishedConfirmed,
        reason,
        password
    }) {
        return this.request('post', 'admin/projects/cancelCampaign', {
            projectID,
            isIrreversibleActionConfirmed,
            isChangesBlockingActionConfirmed,
            isUpdatePostPublishedConfirmed,
            reason,
            password
        });
    }

    getProjectDescriptionSectionsToCopy(copyFrom, copyTo, projectID) {
        const params = {
            copyFrom,
            copyTo,
            projectID
        };
        return this.request('post', 'admin/projects/getProjectDescriptionSectionsToCopy', params);
    }

    copyProjectDescriptions(copyTo, projectDescriptionSectionsIDs, projectID) {
        const params = {
            copyTo, projectDescriptionSectionsIDs, projectID
        };
        return this.request('post', 'admin/projects/copyProjectDescriptions', params);
    }

    getAvailableProjectBackupPaymentMethods({ projectID }) {
        return this.request('get', 'admin/projectBackupPaymentMethod/getAvailableProjectBackupPaymentMethods', { projectID });
    }

    getProjectBackupPaymentMethodData({ projectID }) {
        return this.request('get', 'admin/projectBackupPaymentMethod/getProjectBackupPaymentMethodData', { projectID });
    }

    saveProjectBackupPaymentMethod(paymentModel, projectID) {
        const model = {
            projectID,
            ...paymentModel
        };

        return this.request('post', 'admin/projectBackupPaymentMethod/saveProjectBackupPaymentMethod', model);
    }

    authorizeProjectBackupPaymentMethod(paymentModel, projectID, providerPaymentID) {
        const model = {
            projectID,
            providerPaymentID,
            ...paymentModel,
        };

        return this.request('post', 'admin/projectBackupPaymentMethod/authorizeProjectBackupPaymentMethod', model);
    }
}
