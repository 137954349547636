import lazyScreen from '@/Components/Admin/LazyScreen';

const ProjectSubmissionEntryListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project-moderation' */ './ProjectSubmissionEntryListScreen'));

const routes = [
    {
        path: '/business/project-moderation',
        name: 'project-moderation-submission-list',
        component: ProjectSubmissionEntryListScreen
    }
];

export {
    routes
};
