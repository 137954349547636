import { projectPhases } from '@/Models';
import { useAdminUserContextStore } from '@/Stores/Admin/userContextStore';
import { useAvailableCreatorsStore } from '@/Stores/Admin/availableCreatorsStore';
import { useProjectSetupCompletionStepsStore } from '@/Stores/Admin/projectSetupCompletionStepsStore';
import { useAvailableProjectsStore } from '@/Stores/Admin/availableProjectsStore';
import { useProjectAdditionalInfoStore } from '@/Stores/Admin/projectAdditionalInfoStore';

const _localStorageKeys = {
    projectContext: 'store.admin.projectContext',
};

const adminModule = {
    namespaced: true,
    state: {
        projectContext: null,
    },
    mutations: {
        updateProjectContext(state, projectContext) {
            state.projectContext = projectContext;

            if (state.projectAdditionalInfo?.projectID !== projectContext.projectID) {
                state.projectAdditionalInfo = null;
            }

            localStorage.setItem(_localStorageKeys.projectContext, JSON.stringify(projectContext));
        },
        refreshProjectContext(state, projects) {
            if (state.projectContext.projectID) {
                const freshProject = projects.find(c => c.projectID === state.projectContext.projectID);
                if (freshProject) {
                    state.projectContext.project = projects.find(c => c.projectID === state.projectContext.projectID);
                }
            }
        }
    },
    actions: {
        initialize({ commit, dispatch, state }) {
            const adminUserContextStore = useAdminUserContextStore();
            const availableCreatorsStore = useAvailableCreatorsStore();
            const projectSetupCompletionStepsStore = useProjectSetupCompletionStepsStore();
            const availableProjectsStore = useAvailableProjectsStore();

            return Promise.all(
                [
                    adminUserContextStore.loadUserContext(),
                    dispatch('initializeProjectContext'),
                    availableCreatorsStore.loadAvailableCreators(),
                    availableProjectsStore.loadAvailableProjects()
                        .then(projects => {
                            dispatch('refreshProjectContext', projects);
                        })
                        .then(() => {
                            const projectAdditionalInfo = useProjectAdditionalInfoStore();
                            projectAdditionalInfo.$reset();
                        }),
                    projectSetupCompletionStepsStore.refreshProjectSetupCompletionSteps(state.projectContext)
                ]);
        },
        initializeProjectContext({ commit }) {
            const projectContext = {
                creator: null,
                creatorID: null,
                hasProject: false,
                project: null,
                projectID: null
            };

            try {
                const serializedProjectContext = localStorage.getItem(_localStorageKeys.projectContext);

                if (serializedProjectContext) {
                    Object.assign(projectContext, JSON.parse(serializedProjectContext));
                }
            }
            catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }

            commit('updateProjectContext', projectContext);

            return Promise.resolve();
        },
        updateProjectContext({ commit }, projectContext) {
            return commit('updateProjectContext', projectContext);
        },
        refreshProjectContext({ commit }, projects ) {
            commit('refreshProjectContext', projects);
        },
    },
    getters: {
        isCrowdfundingCompleted(state) {
            return state.projectContext?.project !== null && state.projectContext.project.phase > projectPhases.crowdfunding;
        },
        isLatePledge(state) {
            return state.projectContext?.project.phase === projectPhases.latePledge;
        }
    }
};

export default adminModule;
