var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-navbar-item gfu-navbar-dropdown",
      attrs: { "data-qa": "header-actions:CartBox" },
    },
    [
      _c(
        "toggle-trigger",
        {
          staticClass:
            "gfu-navbar-btn gfu-navbar-btn--header gfu-navbar-dropdown-pointer",
          attrs: {
            tag: "a",
            href: "#",
            "active-class": "is-visible",
            "container-full-screen-width": _vm.isFullScreenWidth,
            "data-qa": "header-actions:CartBoxButton",
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "gfu-icon gfu-icon--big _fa _fa--shopping-cart",
              class: { "gfu-header-shake": _vm.isAddingToCart },
              attrs: { "data-qa": "header-actions:ShoppingCart" },
            },
            [
              _vm.totalQuantity > 0
                ? _c(
                    "span",
                    {
                      class: [
                        "gfu-cart-badge",
                        { "gfu-cart-badge--big": _vm.isAddingToCart },
                      ],
                      attrs: { "data-qa": "cart-box:QuantityBadge" },
                    },
                    [_vm._v(_vm._s(_vm.itemsCount))]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("span", {
            staticClass:
              "gfu-icon gfu-icon--small gfu-icon--uc _fa _fa--chevron-down _tc--accent _screen--none _screen--inline-flex-m",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "toggle-container",
        {
          staticClass: "gfu-navbar-dropdown__content",
          attrs: { tag: "div", "active-class": "is-visible", tabindex: "0" },
        },
        [
          _vm.isCartAvailable
            ? _c(
                "div",
                {
                  staticClass:
                    "gfu-balloon gfu-balloon--wide gfu-balloon--no-arrow",
                  attrs: { "data-qa": "header-actions:CartBoxBalloon" },
                },
                [
                  _c("div", { staticClass: "gfu-balloon__top" }, [
                    _c(
                      "div",
                      {
                        staticClass: "gfu-balloon__top-title",
                        attrs: { "data-qa": "cart-box:ProjectName" },
                      },
                      [_vm._v(_vm._s(_vm.model.cart.project.name))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "gfu-balloon__top-subtitle" }, [
                      _c(
                        "div",
                        {
                          staticClass: "_fl",
                          attrs: { "data-qa": "cart-box:Quantity" },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.Resources.format(
                                  _vm.Resources.Layout
                                    .CartBoxTotalItemsQuantity,
                                  _vm.totalQuantity
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "_tar" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$format(
                              _vm.totalPriceConverted,
                              "C2",
                              _vm.model.cart.currencySymbol
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-balloon__content" },
                    [
                      !_vm.pledgeIsEmpty
                        ? [
                            _vm.model.cart.parentOrder
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gfu-balloon__actions gfu-balloon__actions--no-content _mb-3",
                                    attrs: { "data-qa": "cart-box:ModifyText" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.Resources.format(
                                            _vm.Resources.Layout
                                              .CartBoxModifyText,
                                            _vm.orderPublicID
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.unpaidItems, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.orderItemID,
                                  staticClass: "gfu-balloon__content-item",
                                },
                                [
                                  _c("div", { staticClass: "gfu-grid" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "gfu-grid__cell gfu-1of4",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.model.cartDetailsUrl,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "gfu-balloon__img-wrapper",
                                              },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "gfu-balloon__img",
                                                  attrs: {
                                                    src: item.imageUrl,
                                                    alt: item.displayName,
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gfu-grid__cell gfu-3of4 _pt-1 _pb-1",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.model.cartDetailsUrl,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "gfu-balloon__content-item-title",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(item.displayName) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-balloon__content-item-desc",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.Resources.format(
                                                    _vm.Resources.Layout
                                                      .CartBoxItemQuantity,
                                                    item.quantity
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                            item.options.length > 0
                                              ? _c(
                                                  "span",
                                                  _vm._l(
                                                    item.options,
                                                    function (option, index) {
                                                      return _c(
                                                        "span",
                                                        {
                                                          key: option.productOptionID,
                                                        },
                                                        [
                                                          index === 0
                                                            ? _c("span", [
                                                                _vm._v("("),
                                                              ])
                                                            : _vm._e(),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                option.valueText
                                                              )
                                                            ),
                                                          ]),
                                                          index + 1 <
                                                          item.options.length
                                                            ? _c("span", [
                                                                _vm._v(", "),
                                                              ])
                                                            : _vm._e(),
                                                          index + 1 ===
                                                          item.options.length
                                                            ? _c("span", [
                                                                _vm._v(")"),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm.model.productOrderItems.length > 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gfu-balloon__content-item _tac",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "gfu-link gfu-link--accent",
                                        attrs: {
                                          href: _vm.model.cartDetailsUrl,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.Resources.Layout
                                              .CartBoxViewAllButton
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.model.productOrderItems.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gfu-balloon__actions gfu-balloon__actions",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "gfu-btn gfu-btn--primary gfu-btn--soft gfu-btn--compact _mb-1 _mr-1",
                                        attrs: {
                                          href: _vm.model.cartDetailsUrl,
                                          "data-qa": "header-actions:ViewCart",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.Resources.Layout
                                              .CartBoxViewCartButton
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("go-to-checkout", {
                                      attrs: {
                                        "continue-url": _vm.model.checkoutUrl,
                                        "order-code": _vm.parentOrderCode,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ onInvoke }) {
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "gfu-btn gfu-btn--accent gfu-btn--soft gfu-btn--compact _mb-1 _ml-1",
                                                    attrs: {
                                                      href: _vm.model
                                                        .checkoutUrl,
                                                      "data-qa":
                                                        "header-actions:CheckOut",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return onInvoke.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.Resources.Layout
                                                          .CartBoxCheckoutButton
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4092390502
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _c(
                            "div",
                            {
                              staticClass:
                                "gfu-balloon__actions gfu-balloon__actions--no-content",
                              attrs: { "data-qa": "cart-box:EmptyText" },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.Resources.Layout.CartBoxEmptyPledge
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                    ],
                    2
                  ),
                ]
              )
            : _vm.hasMultipleCarts
            ? _c("div", { staticClass: "gfu-balloon gfu-balloon--no-arrow" }, [
                _c("div", { staticClass: "gfu-balloon__top" }, [
                  _c("div", { staticClass: "gfu-balloon__top-title" }, [
                    _vm._v(
                      _vm._s(_vm.Resources.Layout.CartBoxMultiplePledgesTitle)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "gfu-balloon__top-subtitle" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.Resources.format(
                            _vm.Resources.Layout.CartBoxMultiplePledgesText,
                            _vm.model.projectsCount
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "gfu-balloon__content" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-balloon__actions gfu-balloon__actions--no-content",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "gfu-balloon__actions-button gfu-btn gfu-btn--primary gfu-btn--soft gfu-btn--compact",
                          attrs: { href: _vm.model.cartDetailsUrl },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.Layout.CartBoxViewPledgesButton
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ])
            : _c("div", { staticClass: "gfu-balloon gfu-balloon--no-arrow" }, [
                _c("div", { staticClass: "gfu-balloon__top" }, [
                  _c("div", { staticClass: "gfu-balloon__top-title" }, [
                    _vm._v(_vm._s(_vm.Resources.Layout.CartBoxNoPledgesTitle)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "gfu-balloon__top-subtitle",
                      attrs: { "data-qa": "cart-box:ItemsQuantity" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.Resources.format(
                              _vm.Resources.Layout.CartBoxTotalItemsQuantity,
                              0
                            )
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "gfu-balloon__content" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-balloon__actions gfu-balloon__actions--no-content",
                      attrs: { "data-qa": "cart-box:EmptyText" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.Resources.Layout.CartBoxEmptyPledge) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
              ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }