import lazyScreen from '@/Components/Admin/LazyScreen';

const UsersListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-users' */ './UsersListScreen.vue'));
const UserGroupsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-users' */ './UserGroupsListScreen.vue'));
const UserSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-users' */ './UserSummaryScreen.vue'));

const routes = [
    { path: '/users/:creator?/:project?/list', name: 'users-list', component: UsersListScreen },
    { path: '/:creator?/:project?/users', redirect: { name: 'users-list' } },
    { path: '/users/:creator?/:project?/groups', name: 'users-user-groups', component: UserGroupsListScreen },
    { path: '/:creator/:project/userGroups', redirect: { name: 'users-user-groups' } },
    { path: '/users/:creator?/:project?/:userId/summary', name: 'user-summary', component: UserSummaryScreen, props: route => ({ userId: parseInt(route.params.userId) }) },
    { path: '/:creator?/:project?/users/:userId/summary', redirect: { name: 'user-summary' } },
];

export {
    UsersListScreen,
    UserGroupsListScreen,
    UserSummaryScreen,
    routes
};