import Resources from '@/Resources.js';

import {
    projectPhaseSearchType,
    projectSearchPlayerAge,
    projectSearchPlayerCount,
    playTimeSearchType,
    projectContributionTypes,
    userCommunitySearchType
} from '@/Models';

export const filterProjectSearchTypes = {
    projectPhase: 0,
    category: 1,
    creator: 2,
    searchTerm: 3,
    playerAges: 4,
    playerCounts: 5,
    playTimes: 6,
    projectContribution: 7,
    userCommunity: 8
};

export function getFilterGroupDisplayName(filterSearchType) {
    const filterGroupDisplayNames = {
        0: Resources.Search.SearchFiltersGroupStatusLabel,
        1: Resources.Search.SearchFiltersGroupCategoryLabel,
        2: Resources.Search.SearchFiltersGroupCreatorLabel,
        3: Resources.Search.SearchFiltersGroupSearchPhraseLabel,
        4: Resources.Search.SearchFiltersGroupAgeLabel,
        5: Resources.Search.SearchFiltersGroupPlayableLabel,
        6: Resources.Search.SearchFiltersGroupPlaytimeLabel,
        7: Resources.Search.SearchFiltersGroupContributionLabel,
        8: Resources.Search.SearchFiltersGroupCommunityLabel,
    };
    return filterGroupDisplayNames[filterSearchType];
}

export const pushFilterFromEnum = (filters, obj, filterSearchType) => (value) => {
    filters.push({
        key: obj[value].name,
        label: obj[value].displayName,
        group: getFilterGroupDisplayName(filterSearchType),
        filterSearchType,
        value
    });
};

export function buildDefaultSearchFilterParams(params = {}) {
    return {
        creatorID: params.creatorID || null,
        creatorName: params.creatorName || null,
        sortType: params.sortType || 0,
        term: params.term || '',
        projectPhaseSearchTypes: params.projectPhaseSearchTypes || [],
        projectCategories: params.projectCategories || [],
        playerAges: params.playerAges || [],
        playerCounts: params.playerCounts || [],
        playTimes: params.playTimes || [],
        creator: {
            creatorID: params.creatorID || null,
            name: params.creatorName || null,
        },
    };
}

export function buildProjectSearchFilterParams(params = {}) {
    const result = buildDefaultSearchFilterParams(params);
    result.userCommunitySearchTypes = params.userCommunitySearchTypes || [];
    return result;
}

export function buildUserProjectSearchFilterParams(params = {}) {
    const result = buildDefaultSearchFilterParams(params);
    result.projectContributionTypes = params.projectContributionTypes || [];
    return result;
}

export function buildProjectSearchCurrentFilter(searchParams, projectCategories) {
    const filters = [];

    if (searchParams?.projectPhaseSearchTypes?.length > 0) {
        searchParams.projectPhaseSearchTypes
            .forEach(pushFilterFromEnum(filters, projectPhaseSearchType, filterProjectSearchTypes.projectPhase));
    }

    if (searchParams?.projectContributionTypes?.length > 0) {
        searchParams.projectContributionTypes
            .forEach(pushFilterFromEnum(filters, projectContributionTypes, filterProjectSearchTypes.projectContribution));
    }

    if (searchParams.projectCategories?.length > 0) {
        searchParams.projectCategories
            .forEach(v => {
                const category = projectCategories
                    .find(c => c.projectCategoryID === v);
                if (category) {
                    filters.push({
                        key: category.urlName,
                        label: category.name,
                        group: getFilterGroupDisplayName(filterProjectSearchTypes.category),
                        filterSearchType: filterProjectSearchTypes.category,
                        value: category.projectCategoryID
                    });
                }
            });
    }

    if (searchParams.userCommunitySearchTypes?.length > 0) {
        searchParams.userCommunitySearchTypes
            .forEach(pushFilterFromEnum(
                filters,
                userCommunitySearchType,
                filterProjectSearchTypes.userCommunity
            ));
    }

    if (searchParams.playerAges?.length > 0) {
        searchParams.playerAges
            .forEach(pushFilterFromEnum(
                filters,
                projectSearchPlayerAge,
                filterProjectSearchTypes.playerAges
            ));
    }

    if (searchParams.playerCounts?.length > 0) {
        searchParams.playerCounts
            .forEach(pushFilterFromEnum(
                filters,
                projectSearchPlayerCount,
                filterProjectSearchTypes.playerCounts
            ));
    }

    if (searchParams.playTimes?.length > 0) {
        searchParams.playTimes
            .forEach(pushFilterFromEnum(
                filters,
                playTimeSearchType,
                filterProjectSearchTypes.playTimes
            ));
    }

    if (searchParams.creatorID) {
        filters.push({
            key: 'creator',
            group: getFilterGroupDisplayName(filterProjectSearchTypes.creator),
            label: searchParams.creatorName,
            filterSearchType: filterProjectSearchTypes.creator
        });
    }

    if (searchParams.term) {
        filters.push({
            key: 'searchTerm',
            group: getFilterGroupDisplayName(filterProjectSearchTypes.searchTerm),
            label: searchParams.term,
            filterSearchType: filterProjectSearchTypes.searchTerm
        });
    }

    return filters;
}

export function removeProjectSearchSelectedFilter(filter, searchParams) {
    const result = searchParams;
    const filterValue = v => v !== filter.value;

    if (filter.filterSearchType === filterProjectSearchTypes.projectPhase) {
        result.projectPhaseSearchTypes
            = searchParams.projectPhaseSearchTypes.filter(filterValue);
    }
    else if (filter.filterSearchType === filterProjectSearchTypes.projectContribution) {
        result.projectContributionTypes
            = searchParams.projectContributionTypes.filter(filterValue);
    }
    else if (filter.filterSearchType === filterProjectSearchTypes.category) {
        result.projectCategories
            = searchParams.projectCategories.filter(filterValue);
    }
    else if (filter.filterSearchType === filterProjectSearchTypes.playerAges) {
        result.playerAges
            = searchParams.playerAges.filter(filterValue);
    }
    else if (filter.filterSearchType === filterProjectSearchTypes.playerCounts) {
        result.playerCounts
            = searchParams.playerCounts.filter(filterValue);
    }
    else if (filter.filterSearchType === filterProjectSearchTypes.playTimes) {
        result.playTimes
            = searchParams.playTimes.filter(filterValue);
    }
    else if (filter.filterSearchType === filterProjectSearchTypes.creator) {
        result.creator = null;
        result.creatorID = null;
        result.creatorName = null;
    }
    else if (filter.filterSearchType === filterProjectSearchTypes.searchTerm) {
        result.term = null;
    }
    else if (filter.filterSearchType === filterProjectSearchTypes.userCommunity) {
        result.userCommunitySearchTypes
            = searchParams.userCommunitySearchTypes.filter(filterValue);
    }

    return result;
}

export function updateSearchParams(searchResultQuery, data = null) {
    const location = new URL(window.location);
    const search = new URLSearchParams(searchResultQuery);
    location.search = search;
    window.history.replaceState(data, null, location.toString());
}

export function convertArrayParamsToCommaSeparatedList(object) {
    return Object.entries(object).reduce((acc, [key, val]) =>
        ({ ...acc, [key]: Array.isArray(val) ? val.join(',') : val }), {});
}