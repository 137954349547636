import { defineStore } from 'pinia';

import OrdersService from '@/Services/OrdersService';

import { apiClient } from '@/Clients/ApiClient.js';

export const useUserPledgesStore = defineStore({
    id: 'userPledgesStore',
    state: () => ({
        pledges: []
    }),
    actions: {
        async getUserPledges(projectID) {
            const response = await new OrdersService(apiClient).getUserPledges(projectID);
            this.pledges = response.data;
        },
    },
    getters: {
        firstPledge(state) {
            return state.pledges.length > 0 ? state.pledges[0] : null;
        }
    }
});