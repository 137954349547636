import Resources from '@/Resources.js';

export function createDefaultFilters(projectID, isAuthenticated) {
    return [
        {
            name: Resources.Comments.MyCommentsFilterButton,
            value: 'GetCommentsForCurrentUser',
            isEnabled: !!isAuthenticated,
            qa: 'MyComments'
        },
        {
            name: Resources.Comments.CreatorCommentsFilterButton,
            value: 'getCommentsWithCreatorInput',
            isEnabled: !!projectID,
            qa: 'CreatorComments'
        }
    ].filter(f => f.isEnabled);
}