var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "intersection-trigger",
    _vm._b(
      { on: { intersected: _vm.onIntersected } },
      "intersection-trigger",
      { threshold: _vm.threshold, root: _vm.root, rootMargin: _vm.rootMargin },
      false
    ),
    [_c("div", { staticClass: "_tac _pt-1" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }