import { orderPaymentStatuses, orderStages, projectPhases, projectVersions } from '@/Models';
import { orderRules } from '@/Config';
import Resources from '@/Resources.js';

// separated for brevity in following maps and buildOrderStateExplanation() function
const {
    pledged,
    placed,
    preparingForShipping,
    readyForShipping,
    partiallyShipped,
    shipped,
    completed,
} = orderStages;

const {
    none,
    awaitingPayment,
    paid,
    partiallyPaid,
    scheduled,
    scheduledAndAwaitingPayment
} = orderPaymentStatuses;

const {
    crowdfunding,
    crowdfundingEnded,
    latePledge,
    pledgeManager,
} = projectPhases;

export function getOrderStageName(stage) {
    return orderStages[stage].displayName;
}

export function getOrderPaymentStatusName(paymentStatus) {
    return orderPaymentStatuses[paymentStatus].displayName;
}

export function getOrderPaymentStatusDescription(paymentStatus) {
    const paymentStatusDescriptions = {
        [awaitingPayment]: Resources.OrderStatus.AwaitingPaymentStatusParagraph,
        [paid]: Resources.OrderStatus.PaidStatusParagraph,
        [partiallyPaid]: Resources.OrderStatus.PartiallyPaidStatusParagraph,
        [scheduled]: Resources.OrderStatus.ScheduledStatusParagraph,
        [scheduledAndAwaitingPayment]: Resources.OrderStatus.ScheduledAndAwaitingPaymentStatusParagraph,
    };

    return paymentStatusDescriptions[paymentStatus];
}

export function buildOrderStateExplanation(stage, paymentStatus, isEditing, phase, canRetryInstallment, canAddNewItems) {
    // --- notificators ---
    // installment problem
    if (canRetryInstallment && !isEditing) {
        return {
            type: 'notificator',
            text: Resources.OrderStatus.NotificatorProblemWithCollectingInstallmentsMessage
        };
    }
    // cta - finish pledge
    if (isEditing) {
        const text = stage === pledged && phase === pledgeManager
            ? Resources.OrderStatus.NotificatorShippingAddressMissingMessage
            : Resources.OrderStatus.NotificatorPledgeNotFinishedMessage;

        return {
            type: 'notificator',
            text,
            button: Resources.OrderStatus.FinishPledgeButton,
            action: 'checkout'
        };
    }
    // cta - pay more
    if (paymentStatus === awaitingPayment && !isEditing) {
        return {
            type: 'notificator',
            text: Resources.OrderStatus.ResumePaymentMessage,
            button: Resources.OrderStatus.ResumePaymentButton,
            action: 'resumePayment'
        };
    }
    if (paymentStatus === scheduledAndAwaitingPayment && !isEditing) {
        return {
            type: 'notificator',
            text: Resources.OrderStatus.ScheduledAndAwaitingPaymentStatusParagraph,
            button: Resources.OrderStatus.ResumePaymentButton,
            action: 'resumePayment'
        };
    }
    if (paymentStatus === partiallyPaid && !isEditing) {
        return {
            type: 'notificator',
            text: Resources.OrderStatus.NotificatorPartiallyPaidMessage,
            button: Resources.OrderStatus.ResumePaymentButton,
            action: 'resumePayment'
        };
    }

    // --- green ticks ---
    // all set for now - before pledge manager
    if (paymentStatus === none && stage === pledged && (phase === crowdfunding || phase === crowdfundingEnded)) {
        return {
            type: 'tick',
            text: Resources.OrderStatus.OrderStatusExplanationAllSetHeader,
            sub: Resources.OrderStatus.OrderStatusExplanationPaymentNoneParagraph,
            next: Resources.OrderStatus.OrderStatusExplanationPaymentNoneNextStepParagraph,
        };
    }

    if (paymentStatus === paid && stage === pledged && phase === crowdfunding) {
        return {
            type: 'tick',
            text: Resources.OrderStatus.OrderStatusExplanationAllSetHeader,
            sub: Resources.OrderStatus.OrderStatusExplanationLatePledgePaymentPaidParagraph,
            next: Resources.OrderStatus.OrderStatusExplanationCrowdfundingPaidNextStepParagraph,
        };
    }

    if (paymentStatus === paid && stage === pledged && phase === crowdfundingEnded) {
        return {
            type: 'tick',
            text: Resources.OrderStatus.OrderStatusExplanationAllSetHeader,
            sub: Resources.OrderStatus.OrderStatusExplanationCrowdfundingEndedPaymentPaidParagraph,
            next: Resources.OrderStatus.OrderStatusExplanationCrowdfundingEndedPaymentPaidNextStepParagraph,
        };
    }
    if (paymentStatus === paid && stage === pledged && phase === latePledge) {
        return {
            type: 'tick',
            text: Resources.OrderStatus.OrderStatusExplanationAllSetHeader,
            sub: Resources.OrderStatus.OrderStatusExplanationLatePledgePaymentPaidParagraph,
            next: Resources.OrderStatus.OrderStatusExplanationLatePledgePaymentPaidNextStepParagraph,
        };
    }
    if (paymentStatus === scheduled && stage === pledged && phase === crowdfunding) {
        return {
            type: 'tick',
            text: Resources.OrderStatus.OrderStatusExplanationAllSetHeader,
            sub: Resources.OrderStatus.OrderStatusExplanationCrowdfundingPaymentScheduledInstallmentsParagraph,
            next: Resources.OrderStatus.OrderStatusExplanationCrowdfundingPaymentScheduledInstallmentsNextStepParagraph,
        };
    }
    if (paymentStatus === scheduled && stage === pledged && phase === crowdfundingEnded) {
        return {
            type: 'tick',
            text: Resources.OrderStatus.OrderStatusExplanationAllSetHeader,
            sub: Resources.OrderStatus.OrderStatusExplanationCrowdfundingEndedPaymentScheduledInstallmentsParagraph,
            next: Resources.OrderStatus.OrderStatusExplanationCrowdfundingEndedPaymentPaidNextStepParagraph,
        };
    }
    if (paymentStatus === scheduled && stage === pledged && phase === latePledge) {
        return {
            type: 'tick',
            text: Resources.OrderStatus.OrderStatusExplanationAllSetHeader,
            sub: Resources.OrderStatus.OrderStatusExplanationLatePledgePaymentScheduledInstallmentsParagraph,
            next: Resources.OrderStatus.OrderStatusExplanationLatePledgePaymentScheduledInstallmentsNextStepParagraph,
        };
    }
    // pledge manager statuses
    if (paymentStatus === paid && stage === placed && phase === pledgeManager) {
        return {
            type: 'tick',
            text: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPaymentPaidHeader,
            sub: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPaymentPaidParagraph,
            next: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPaymentPaidNextStepParagraph,
        };
    }
    if (paymentStatus === scheduled && stage === placed && phase === pledgeManager) {
        return {
            type: 'tick',
            text: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPaymentScheduledHeader,
            sub: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPaymentScheduledParagraph,
            next: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPaymentScheduledNextStepParagraph,
        };
    }

    if ((paymentStatus === paid || paymentStatus === scheduled) && phase === pledgeManager) {
        if (stage === preparingForShipping) {
            if (canAddNewItems) {
                return {
                    type: 'tick',
                    text: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPreparingForShippingCanAddNewItemsHeader,
                    sub: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPreparingForShippingCanAddNewItemsParagraph,
                    next: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPreparingForShippingCanAddNewItemsNextStepParagraph
                };
            }

            return {
                type: 'tick',
                text: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPreparingForShippingHeader,
                sub: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPreparingForShippingParagraph,
                next: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPreparingForShippingNextStepParagraph
            };
        }
        if (stage === readyForShipping) {
            return {
                type: 'tick',
                text: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPreparingShippingHeader,
                sub: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPreparingShippingParagraph,
                next: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPreparingShippingNextStepParagraph,
            };
        }
        if (stage === partiallyShipped) {
            return {
                type: 'tick',
                text: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPartiallyShippedHeader,
                sub: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPartiallyShippedParagraph,
                next: Resources.OrderStatus.OrderStatusExplanationPledgeManagerPartiallyShippedNextStepParagraph,
            };
        }
        if (stage === shipped) {
            return {
                type: 'tick',
                text: Resources.OrderStatus.OrderStatusExplanationPledgeManagerShippedHeader,
                sub: Resources.OrderStatus.OrderStatusExplanationPledgeManagerShippedParagraph,
            };
        }
        if (stage === completed) {
            return {
                type: 'tick',
                text: Resources.OrderStatus.OrderStatusExplanationPledgeManagerCompletedHeader,
            };
        }
    }
    return null;
}

export function getAvailableOrderStagesForUserContext() {

    return Object.values(orderStages)
        .filter(arrayItem => orderRules.availableOrderStagesForCreatorChange.includes(arrayItem.value))
        .sort((a, b) => a.order - b.order);
}

export function getDisplayOrderStagesForProjectContext(projectContext, availableProjects = null) {
    const availableStages = Object.values(orderStages)
        .filter(arrayItem => arrayItem.displayName && !orderRules.excludedOrderStagesInOrdersFilter.includes(arrayItem.value))
        .sort((a, b) => a.order - b.order);

    if (projectContext.project?.version === projectVersions.v1
        || !projectContext.project && availableProjects?.every(c => c.version === projectVersions.v1)) {
        return availableStages.filter(arrayItem => !orderRules.v2VersionOnlyOrderStages.includes(arrayItem.value));
    }

    return availableStages;
}

export function getDisplayOrderPaymentStatuesForProjectContext(projectContext, availableProjects = null) {
    const availableOrderPaymentStatuses = Object.values(orderPaymentStatuses);

    if (projectContext.project && projectContext.project.version !== projectVersions.v2
        || !projectContext.project && availableProjects?.every(c => c.version !== projectVersions.v2)) {
        return availableOrderPaymentStatuses.filter(arrayItem => !orderRules.v2VersionOnlyOrderPaymentStatuses.includes(arrayItem.value));
    }

    return availableOrderPaymentStatuses;
}