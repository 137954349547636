import { myProjectActionTypes as projectActionTypes } from '@/Models.js';
import Resources from '@/Resources.js';

export function getMyProjectActionLabel(actionType) {
    const myProjectActionLabels = {
        [projectActionTypes.unfinishedCart]: Resources.MyProjects.MyProjectUnfinishedCartActionLabel,
        [projectActionTypes.pendingEdit]: Resources.MyProjects.MyProjectPendingEditActionLabel,
        [projectActionTypes.claimYourGift]: Resources.MyProjects.MyProjectClaimYourGiftActionLabel,
        [projectActionTypes.completeYourPledge]: Resources.MyProjects.MyProjectCompletePledgeActionLabel,
        [projectActionTypes.completePayment]: Resources.MyProjects.MyProjectCompletePaymentActionLabel,
        [projectActionTypes.viewYourPledge]: Resources.MyProjects.MyProjectViewYourPledgeActionLabel,
    };
    return myProjectActionLabels[actionType];
}

export const actionDataQa = {
    [projectActionTypes.unfinishedCart]: 'UnfinishedPledge',
    [projectActionTypes.pendingEdit]: 'UnsubmittedPledge',
    [projectActionTypes.claimYourGift]: 'CollectGift',
    [projectActionTypes.completeYourPledge]: 'Shipping',
    [projectActionTypes.completePayment]: 'UnfinishedPayment',
    [projectActionTypes.viewYourPledge]: 'ErrorWithInstallment'
};