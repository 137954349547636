var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasIcon
    ? _c(
        "icon-base-button",
        _vm._b(
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onClick.apply(null, arguments)
              },
            },
          },
          "icon-base-button",
          _vm.$attrs,
          false
        ),
        [_vm._t("default")],
        2
      )
    : _c(
        "base-button",
        _vm._b(
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onClick.apply(null, arguments)
              },
            },
          },
          "base-button",
          _vm.$attrs,
          false
        ),
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }