import { defineStore } from 'pinia';
import InitialState from '@/InitialState';
import { updateStretchGoalsProgress, unlockStretchGoalsStates } from '@/Utils/StretchGoalUtility';

export const useStrechGoalsStore = defineStore({
    id: 'strechGoals',
    state: () => ({
        currentStretchGoalID: InitialState.projectState?.statistics?.currentStretchGoalID || null,
        stretchGoals: InitialState.projectState?.stretchGoals || [],
    }),
    actions: {
        updateStretchGoalStates(projectState) {
            if (this.stretchGoals?.length) {
                if (projectState.newlyUnlockedStretchGoals) {
                    this.stretchGoals = unlockStretchGoalsStates(this.stretchGoals, projectState.newlyUnlockedStretchGoals);
                }

                this.currentStretchGoalID = projectState.currentStretchGoalID;
            }
        },
        updateStretchGoalsProgress({ fundsGathered, backersCount, campaingDay }) {
            this.stretchGoals = updateStretchGoalsProgress(this.stretchGoals, fundsGathered, backersCount, campaingDay);
        }
    },
    getters: {
        currentStretchGoal() {
            return this.stretchGoals.find(c => c.projectStretchGoalID === this.currentStretchGoalID);
        },
        unlockedStretchGoals() {
            return this.stretchGoals.filter(c => c.isUnlocked === true)
                .sort((a, b) => new Date(b.unlockedAt) - new Date(a.unlockedAt));
        },
        lockedStretchGoals() {
            return this.stretchGoals.filter(c => c.isUnlocked === false)
                .sort((a, b) => b.isAutomatic - a.isAutomatic || b.progress - a.progress);
        },
        hasStrechGoals() {
            return this.stretchGoals.length > 0;
        }
    }
});