var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("main", { staticClass: "gfu-layout-wrapper" }, [
      _c("div", { staticClass: "gfu-grid gfu-grid--gutter" }, [
        _c(
          "div",
          {
            staticClass:
              "gfu-grid__cell gfu-1of3--l gfu-left-1of3--l gfu-4of6--m gfu-left-4of6--m gfu-1of1",
          },
          [
            _c("div", { staticClass: "gfu-box _mt-6 _mb-3" }, [
              _c(
                "h2",
                {
                  staticClass:
                    "gfu-heading gfu-heading--soft gfu-box__title gfu-box__title--success",
                },
                [
                  _vm._v(
                    "\n                    Your information has been updated.\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "_tac" }, [
                _c("p", { staticClass: "_mb-3 _pt-1" }, [
                  _vm._v(
                    "\n                        Verification status will be handled by payment provided in the near future.\n                    "
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }