var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-nav gfu-nav--secondary gfu-nav--large-screen" },
    [
      _c(
        "div",
        { staticClass: "gfu-nav__content gfu-nav__content--center" },
        [
          _c(
            "slider-container",
            {
              attrs: {
                "blur-edges": false,
                "percentage-to-scroll": 50,
                "background-style": "dark",
                "center-element-class": "gfu-nav__item--selected",
              },
            },
            _vm._l(_vm.navigationItems, function (item) {
              return _c(
                "div",
                {
                  key: item.pageName,
                  class: item.classes,
                  attrs: { "data-qa": `slider-navigation:${item.pageName}` },
                },
                [
                  _c(
                    "a",
                    { staticClass: "gfu-link", attrs: { href: item.url } },
                    [_vm._v(_vm._s(item.name))]
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }