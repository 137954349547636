var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "_flexcol _mb-4",
      attrs: { "data-qa": "applicable-tax-wrapper" },
    },
    [
      _c(
        "h2",
        { staticClass: "gfu-heading gfu-heading--top-divider _mt-3 _mb-2" },
        [
          _vm._v(
            _vm._s(_vm.Resources.Checkout.CheckoutLocationDetailsSectionTitle)
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-1of1 gfu-1of2--m" },
        [
          _c("auto-complete-field", {
            attrs: {
              "display-name": _vm.Resources.Checkout.UserShippingCountryLabel,
              name: "model.projectLocationID",
              items: _vm.projectLocations,
              description: _vm.inputDescription,
              "min-chars": 0,
              placeholder:
                _vm.Resources.Checkout.UserShippingCountryPlaceholder,
              "read-only": _vm.isReadOnly,
              "item-name-finder": (item) => item.name,
              "item-value-finder": (item) => item.projectLocationID,
              "data-qa": "applicable-tax-shipping-section",
            },
            on: { input: _vm.onProjectLocationChanged },
            model: {
              value: _vm.model.projectLocationID,
              callback: function ($$v) {
                _vm.$set(_vm.model, "projectLocationID", $$v)
              },
              expression: "model.projectLocationID",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLocationWithSubLocations
        ? _c(
            "div",
            { staticClass: "gfu-1of1 gfu-1of2--m" },
            [
              _c("auto-complete-field", {
                attrs: {
                  "display-name": _vm.Resources.Checkout.UserSubLocationLabel,
                  name: "model.projectSubLocationID",
                  items: _vm.projectSubLocations,
                  placeholder:
                    _vm.Resources.Checkout.UserSubLocationPlaceholder,
                  "min-chars": 0,
                  "read-only": _vm.isReadOnly,
                  "item-name-finder": (item) => item.name,
                  "item-value-finder": (item) => item.projectSubLocationID,
                },
                on: { input: _vm.onProjectSubLocationChanged },
                model: {
                  value: _vm.model.projectSubLocationID,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "projectSubLocationID", $$v)
                  },
                  expression: "model.projectSubLocationID",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.model.isRegisteredVatPayer
        ? _c(
            "div",
            {
              staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
              attrs: { "data-qa": "tax-identification-number-section" },
            },
            [
              _c("text-field", {
                attrs: {
                  name: "model.vatNumber",
                  "display-name": _vm.Resources.Checkout.UserVatNumberLabel,
                  disabled: _vm.isReadOnly,
                },
                on: { blur: _vm.onBlur },
                model: {
                  value: _vm.model.vatNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "vatNumber", $$v)
                  },
                  expression: "model.vatNumber",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_mt-1" },
        [
          _vm.hasCountryEnabledVatReverse
            ? _c(
                "checkbox-field",
                {
                  staticClass: "_mt-0",
                  attrs: {
                    disabled: _vm.isReadOnly,
                    condensed: true,
                    "data-qa": "registered-vat-payer-section",
                  },
                  on: { input: _vm.onCheckboxChange },
                  model: {
                    value: _vm.model.isRegisteredVatPayer,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "isRegisteredVatPayer", $$v)
                    },
                    expression: "model.isRegisteredVatPayer",
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.Resources.Checkout
                          .CheckboxVatPayerConfirmationMessage
                      ) +
                      " \n            "
                  ),
                  _c(
                    "tooltip",
                    {
                      attrs: {
                        text: _vm.Resources.Checkout
                          .CheckboxVatPayerConfirmationTooltipText,
                        position: "right",
                      },
                    },
                    [_c("em", [_c("span", { staticClass: "_fa _fa--info" })])]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }