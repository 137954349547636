
export function calculatePercentage(value, max) {
    if (max === 0) {
        return 0;
    }

    return 100 * value / max;
}

export function convertToPercentage(value, decimalPrecision = 0) {
    if (!value) {
        return value;
    }

    const multiplier = Math.pow(10, decimalPrecision);

    return Math.round(value * 100 * multiplier) / multiplier;
}

export function convertFromPercentage(value, decimalPrecision = 0) {
    if (!value) {
        return value;
    }

    const multiplier = Math.pow(10, decimalPrecision);

    return Math.round(value * multiplier) / (multiplier * 100);
}