import Bus from '../Bus';

export default {
    error(text, title = null, callbackOkBtn = null) {
        return this.showMessageBox('error', text, this.getTitleOrDefault(title, 'Could not complete operation.'), callbackOkBtn);
    },
    message(text, title = null, callbackOkBtn = null) {
        return this.showMessageBox('message', text, this.getTitleOrDefault(title, 'Message'), callbackOkBtn);
    },
    success(text, title = null, callbackOkBtn = null) {
        return this.showMessageBox('success', text, this.getTitleOrDefault(title, 'Operation successfully completed.'), callbackOkBtn);
    },
    question(text, title = null, yesCallback = null, noCallback = null) {
        return this.showMessageBox('question', text, this.getTitleOrDefault(title, 'Confirm'), yesCallback, noCallback);
    },
    getTitleOrDefault(title, defaultTitle) {
        if (title && typeof title === 'function') {
            return title();
        }
        if (typeof title === 'undefined' || title === null) {
            return defaultTitle;
        }

        return title;
    },
    showMessageBox(type, text, title = null, callbackOkBtn = null, callbackNoBtn = null) {
        return new Promise(resolve => {
            Bus.Topics.Web.Request.ShowMessageBox.publish(
                {
                    type,
                    text,
                    title,
                    callbackOkBtn: () => {
                        if (typeof callbackOkBtn === 'function')
                            callbackOkBtn();

                        resolve(true);
                    },
                    callbackNoBtn: () => {
                        if (typeof callbackNoBtn === 'function')
                            callbackNoBtn();

                        resolve(false);
                    }
                });

        });
    }
};
