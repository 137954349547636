
    import ComponentBase from '@/Components/ComponentBase';
    import PledgeService from '@/Services/PledgeService';

    import Notificator from '@/Components/Notificator.vue';

    export default {
        extends: ComponentBase,
        components: {
            Notificator
        },
        props: {
            checkoutMode: { type: Number, required: true },
            orderCode: { type: String, default: null },
        },
        render() {
            return null;
        },
        data() {
            return {
                model: {
                    paymentMethod: {
                        encryptedCardNumber: 'encryptedCardNumber',
                        encryptedExpiryMonth: 'encryptedExpiryMonth',
                        encryptedExpiryYear: 'encryptedExpiryYear',
                        encryptedSecurityCode: 'encryptedSecurityCode',
                        encryptedExpiryDate: 'encryptedExpiryDate',
                        type: 'scheme',
                        holderName: 'tester',
                        email: 'test@gamefound.com',
                    },
                    termsAccepted: true,
                    digitalDeliveryAccepted: true,
                    browserInfo: {
                        acceptHeader: null,
                        colorDepth: 0,
                        screenHeight: 0,
                        screenWidth: 0,
                        timeZoneOffset: 0,
                        javaEnabled: null,
                        language: null,
                        userAgent: null,
                    }
                },
            };
        },
        methods: {
            submitActionPromise() {
                return new PledgeService(this.$http).createInstantPayment(this.model, this.projectContext.projectID, this.orderCode, this.effectiveCheckoutMode);
            },
            submit() {
                this.startProcessing();

                this.wrapApiAction(this.submitActionPromise())
                    .then(this.handlePaymentResult)
                    .catch(error => {
                        this.handleError(error);
                        this.stopProcessing();
                    });
            },
            handlePaymentResult(result) {
                const successUrl = result.data.redirectUrl;
                this.$nextTick(() => {
                    this.allowUnsavedChangesReminder = false;
                    window.location = successUrl;
                });
            },
            startProcessing() {
                this.$emit('start-processing');
            },
            stopProcessing() {
                this.$emit('stop-processing');
            },
            handleError(error) {
                this.$emit('error', error);
            }
        },
        computed: {
            effectiveCheckoutMode() {
                return this.currentCheckoutMode ?? this.checkoutMode;
            },
        },
        mounted() {
            this.model.email = this.userContext.user.email;
        }
    };
