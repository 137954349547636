export function generateDragImageFromImage(image, width, height) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;
    context.drawImage(image, 0, 0, width, height);
    canvas.style.backgroundColor = 'transparent';
    canvas.style.position = 'absolute';
    canvas.style.top = '-100vh';
    canvas.style.left = '-100vw';

    return canvas;
}

export function generateDragImageFromElement(element) {
    const crt = element.cloneNode(true);

    crt.style.position = 'absolute';
    crt.style.top = '-100vw';
    crt.style.right = '-100vh';
    crt.style.width = `${ element.offsetWidth }px`;
    crt.style.height = `${ element.offsetHeight }px`;

    return crt;
}