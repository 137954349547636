var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("cart-table", {
    attrs: {
      "cart-behavior": _vm.initialCartBehavior,
      "copy-shipping-info": _vm.initialCopyShippingInfo,
      "include-current-cart": _vm.initialIncludeCurrentCart,
      "order-code": _vm.initialOrderCode,
      "project-id": _vm.initialProjectId,
      "recalculate-order": _vm.initialRecalculateOrder,
    },
    on: {
      "table-emptied": _vm.onTableEmptied,
      "cart-invalid": _vm.onCartInvalid,
      "cart-valid": _vm.onCartValid,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }