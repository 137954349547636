export const teamMembers = [
    {
        name: 'Marcin Świerkot',
        position: 'CEO',
        imageUrl: '/content/images/static/about-us/team/mswierkot.svg',
    },
    {
        name: 'Szymon Matyjaszek',
        position: 'CTO',
        imageUrl: '/content/images/static/about-us/team/smatyjaszek.svg',
    },
    {
        name: 'Maciej Kuc',
        position: 'COO',
        imageUrl: '/content/images/static/about-us/team/mkuc.svg',
    },
    {
        name: 'Luke Prior',
        position: 'Key Account Manager',
        imageUrl: '/content/images/static/about-us/team/lprior.svg',
    },
    {
        name: 'Mateusz Cieżak',
        position: 'Marketing Manager',
        imageUrl: '/content/images/static/about-us/team/mciezak.svg',
    },
    {
        name: 'Marcin Grudziński',
        position: 'Delivery Manager',
        imageUrl: '/content/images/static/about-us/team/mgrudzinski.svg',
    },
    {
        name: 'Paweł Rychel',
        position: 'Head of Backend Dev',
        imageUrl: '/content/images/static/about-us/team/prychel.svg',
    },
    {
        name: 'Jacek Cegiel',
        position: 'Head of UI Dev',
        imageUrl: '/content/images/static/about-us/team/jcegiel.svg',
    },
    {
        name: 'Dorota Jasińska',
        position: 'Content Writer',
        imageUrl: '/content/images/static/about-us/team/djasinska.svg',
    },
    {
        name: 'Alexander Radcliffe',
        position: 'CMO',
        imageUrl: '/content/images/static/about-us/team/aradcliffe.svg',
    },
];

export const partners = [
    {
        name: 'Awaken Realms',
        imageUrl: '/content/images/static/about-us/partners/awaken-realms.jpg',
        creatorUrl: '/creators/awaken-realms',
    },
    {
        name: 'Lucky Duck Games',
        imageUrl: '/content/images/static/about-us/partners/ldg.png',
        creatorUrl: '/creators/lucky-duck-games',
    },
    {
        name: 'Portal Games',
        imageUrl: '/content/images/static/about-us/partners/portal-games.jpg',
        creatorUrl: '/creators/portalgames',
    },
    {
        name: 'PSC Games',
        imageUrl: '/content/images/static/about-us/partners/psc.png',
        creatorUrl: '/creators/psc-games',
    },
    {
        name: 'Grey Fox Games',
        imageUrl: '/content/images/static/about-us/partners/grey-fox.png',
        creatorUrl: '/creators/grey-fox-games',
    },
    {
        name: 'Board&Dice',
        imageUrl: '/content/images/static/about-us/partners/board&dice.jpg',
        creatorUrl: '/creators/board-and-dice',
    },
    {
        name: 'Lazy Squire Games',
        imageUrl: '/content/images/static/about-us/partners/lazy-squire-games.jpg',
        creatorUrl: '/creators/lazy-squire-games',
    },
    {
        name: 'Infinite Black',
        imageUrl: '/content/images/static/about-us/partners/infinite-black.jpg',
        creatorUrl: '/creators/infiniteblack',
    },
    {
        name: 'Creative Games Studio',
        imageUrl: '/content/images/static/about-us/partners/creative-games-studio.jpg',
        creatorUrl: '/creators/wearecgs',
    },
    {
        name: 'Archon Studio',
        imageUrl: '/content/images/static/about-us/partners/archon-studio.jpg',
        creatorUrl: '/creators/archon-studio',
    },
    {
        name: 'Modiphius Entertainment',
        imageUrl: '/content/images/static/about-us/partners/modiphius.jpg',
        creatorUrl: '/creators/modiphius',
    },
    {
        name: 'Phalanx',
        imageUrl: '/content/images/static/about-us/partners/phalanx.jpg',
        creatorUrl: '/creators/phalanx',
    },
    {
        name: 'Game Brewer',
        imageUrl: '/content/images/static/about-us/partners/game-brewer.png',
        creatorUrl: '/creators/gamebrewer',
    },
    {
        name: 'Mythic Games',
        imageUrl: '/content/images/static/about-us/partners/mythic.png',
        creatorUrl: '/creators/mythic-games',
    },
    {
        name: 'Boardcubator',
        imageUrl: '/content/images/static/about-us/partners/boardcubator.jpg',
        creatorUrl: '/creators/boardcubator',
    },
];