var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "intersection-trigger",
    {
      staticClass: "gfu-announcement-box _mb-4",
      on: { intersected: _vm.onIntersected },
    },
    [
      _c("base-slider", {
        staticClass: "gfu-announcement-box__slider",
        attrs: {
          autoplay: true,
          "max-indicators": 9,
          "autoplay-speed": 15000,
          "stop-on-hover": true,
        },
        on: { change: _vm.onSlideChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ onAction, currentItem }) {
              return _vm._l(
                _vm.filteredBanners,
                function ({ actionUrl, item }, index) {
                  return _c("announcement-box-content", {
                    key: index,
                    class: [
                      "gfu-base-slider-slide",
                      { "is-active": index === currentItem },
                    ],
                    attrs: { slide: item, "action-url": actionUrl },
                    on: { click: onAction },
                  })
                }
              )
            },
          },
          {
            key: "indicators",
            fn: function ({ onSetCurrentIndex, currentItem, indicators }) {
              return [
                _c(
                  "nav",
                  { staticClass: "gfu-base-slider-indicators" },
                  _vm._l(indicators, function (item, index) {
                    return _c(
                      "a",
                      {
                        key: index,
                        class: [
                          "gfu-base-slider-indicators__indicator",
                          { "is-active": index === currentItem },
                        ],
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return onSetCurrentIndex(index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(index) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "nav",
            fn: function ({ onNext, onPrev }) {
              return [
                _c("base-slider-navigation", {
                  staticClass:
                    "gfu-announcement-box-slider-nav gfu-announcement-box-slider-nav--prev",
                  attrs: { type: "prev" },
                  on: {
                    click: function ($event) {
                      return _vm.onNavClick(onPrev)
                    },
                  },
                }),
                _vm._v(" "),
                _c("base-slider-navigation", {
                  staticClass:
                    "gfu-announcement-box-slider-nav gfu-announcement-box-slider-nav--next",
                  attrs: { type: "next" },
                  on: {
                    click: function ($event) {
                      return _vm.onNavClick(onNext)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }