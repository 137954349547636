import ServiceBase from './ServiceBase';

export default class InvoicesService extends ServiceBase {
    bulkPrintInvoices(invoiceIDs, projectID) {
        return this.$http.post('Invoices/BulkPrintInvoices', { invoiceIDs, projectID });
    }

    bulkUpdateInvoiceStates(model) {
        return this.$http.post('Invoices/bulkUpdateInvoiceStates', model);
    }

    correctPrepaymentInvoicesByUnshippedAmount(projectID, orderIDs, correctionReason, forcedIssueDate) {
        const model = {
            projectID, orderIDs, correctionReason, forcedIssueDate
        };
        return this.$http.post('Invoices/correctPrepaymentInvoicesByUnshippedAmount', model);
    }

    forceTotalCorrectionOfInvoices(projectID, invoiceIDs, correctionReason, forcedIssueDate) {
        const model = {
            projectID, invoiceIDs, correctionReason, forcedIssueDate
        };
        return this.$http.post('Invoices/forceTotalCorrectionOfInvoices', model);
    }

    getInvoiceIDsFromFile(sourceFile, projectID) {
        const formData = new FormData();
        formData.append('sourceFile', sourceFile);
        formData.append('projectID', projectID);

        return this.$http.post('Invoices/getInvoiceIDsFromFile', formData);
    }

    getImportInvoiceNumbersExampleFile() {
        return this.$http.get('Invoices/getImportInvoiceNumbersExampleFile', { responseType: 'blob' });
    }

    regenerateInvoices(invoiceIDs, projectID) {
        return this.$http.post('Invoices/RegenerateInvoices', { invoiceIDs, projectID });
    }

    exportInvoices(invoiceIDs, creatorID, projectID, exportType, options) {
        const params = {
            invoiceIDs,
            projectID,
            creatorID,
            exportType,
            options
        };
        return this.$http.post('Invoices/ExportInvoices', params);
    }

    getInvoiceIDs(filters, creatorID, projectID) {
        const params = {
            invoiceNumber: null,
            orderPublicID: null,
            minIssueDate: null,
            maxIssueDate: null,
            minCreateDate: null,
            maxCreateDate: null,
            invoiceState: null,
            invoiceType: null,
            creatorID,
            projectID,
            ...filters
        };

        return this.$http.post('Invoices/GetInvoiceIDs', params);
    }

    generateInvoices(model) {
        return this.$http.post('Invoices/GenerateInvoices', model);
    }

    generateOrderInvoices(model) {
        return this.$http.post('Invoices/GenerateOrderInvoices', model);
    }

    generateCorrectionInvoices(request) {
        return this.$http.post('Invoices/generateCorrectionInvoices', request);
    }

    printInvoice(invoiceID, projectID) {
        return this.$http.get('Invoices/PrintInvoice', { params: { invoiceID, projectID }, responseType: 'blob' });
    }

    searchInvoices(filters, creatorID, projectID, pageSize, pageIndex) {
        const params = {
            invoiceNumber: null,
            orderPublicID: null,
            minIssueDate: null,
            maxIssueDate: null,
            minCreateDate: null,
            maxCreateDate: null,
            invoiceState: null,
            invoiceType: null,
            creatorID,
            projectID,
            pageSize,
            pageIndex,
            ...filters
        };

        return this.$http.post('Invoices/SearchInvoices', params);
    }

    getInvoiceExportTypes(projectID, creatorID) {
        return this.$http.get('Invoices/GetInvoiceExportTypes', { params: { projectID, creatorID } });
    }

    getInvoice(invoiceID, creatorID, projectID) {
        return this.$http.get('Invoices/getInvoice', { params: { invoiceID, creatorID, projectID } });
    }
}
