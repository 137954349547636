var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        title: _vm.Resources.ProjectHome.AddToCartSuccessModalTitle,
        icon: "check",
        "is-visible": true,
      },
      scopedSlots: _vm._u(
        [
          _vm.isPledgePlaced
            ? {
                key: "additionalInfo",
                fn: function () {
                  return [
                    _c("div", { staticClass: "_flex _mt-3" }, [
                      _c("div", {
                        staticClass: "_fa _fa--info _tc--accent _mr-1",
                      }),
                      _vm._v(" "),
                      _c("div", {
                        attrs: {
                          "v-html":
                            _vm.Resources.ProjectHome
                              .AddToCartSuccessSubmitChangesHint,
                        },
                      }),
                    ]),
                  ]
                },
                proxy: true,
              }
            : null,
          {
            key: "actions",
            fn: function () {
              return [
                _vm._t(
                  "actions",
                  function () {
                    return [
                      _c(
                        "a",
                        {
                          staticClass:
                            "gfu-link gfu-link--accent gfu-link--inline",
                          attrs: {
                            href: "",
                            "data-qa": "modal-button:ContinueShopping",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.close.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .AddToCartSuccessContinueBrowsingButton
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "gfu-btn gfu-btn--accent gfu-btn--compact gfu-btn--soft gfu-btn--wide",
                          attrs: { "data-qa": "modal-button:Checkout" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.submit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .AddToCartSuccessCheckoutButton
                            )
                          ),
                        ]
                      ),
                    ]
                  },
                  { close: _vm.close, submit: _vm.submit }
                ),
              ]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _c("div", { staticClass: "gfu-table gfu-table__row--dark" }, [
        _c("div", { staticClass: "gfu-table__row" }, [
          _c("div", { staticClass: "gfu-table__cell-row" }, [
            _c(
              "div",
              {
                staticClass:
                  "gfu-table__cell gfu-table__cell--centered gfu-table__cell--image gfu-table__cell--image-big _mr-3",
              },
              [
                _c("div", { staticClass: "gfu-table__thumb" }, [
                  _c("img", {
                    attrs: { src: _vm.imageUrl, alt: _vm.productName },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "gfu-table__cell _tac" }, [
              _c(
                "span",
                {
                  staticClass: "gfu-table__text--big",
                  attrs: { "data-qa": "quantity-added" },
                },
                [_vm._v(_vm._s(_vm.quantity) + "x")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "gfu-table__text--strong gfu-table__text--big",
                  attrs: { "data-qa": "product-name" },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.productName) +
                      "\n                    "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.shouldShowInstallmentsInformation
        ? _c("add-product-to-cart-installments-information", {
            attrs: { "data-qa": "stretch-pay-info" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._v(" "),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { visible: _vm.isProcessing, sticky: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }