import { CategoriesServiceBase } from './BaseServices';

export default class CategoriesService extends CategoriesServiceBase {
    constructor($http, errorHandler) {
        super($http, errorHandler);
    }

    getCategories(projectID) {
        return this.request('get', 'categories/getCategories', { projectID });
    }

    reorderCategory(category) {
        const params = {
            ...category
        };

        return this.request('post', 'categories/reorderCategory', params);
    }
}