var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAuthenticated
    ? _c(
        "div",
        {
          staticClass:
            "gfu-navbar-item gfu-navbar-dropdown _screen--none _screen--flex-m",
        },
        [
          _c(
            "toggle-trigger",
            {
              class: [
                "gfu-navbar-btn gfu-navbar-btn--header gfu-navbar-dropdown-pointer",
                { "gfu-header-action-avatar": _vm.hasAvatar },
              ],
              attrs: {
                tag: "a",
                "active-class": "is-visible",
                href: "",
                tabindex: "0",
                "data-qa": "header-icon:Userbox",
              },
            },
            [
              _vm.isImpersonated
                ? _c("span", {
                    staticClass:
                      "gfu-icon gfu-icon--big _fa _fa--id-card _tc--accent",
                  })
                : _vm.hasAvatar
                ? _c("img", {
                    staticClass: "gfu-avatar gfu-avatar--navbar",
                    attrs: {
                      alt: "Avatar",
                      src: _vm.userProfileAvatarUrl,
                      "data-qa": "header-actions:Avatar",
                    },
                  })
                : _c("span", {
                    staticClass: "gfu-icon gfu-icon--big _fa _fa--user-cog",
                  }),
              _vm._v(" "),
              _c("span", {
                staticClass:
                  "gfu-icon gfu-icon--small gfu-icon--uc _fa _fa--chevron-down _tc--accent _screen--none _screen--inline-flex-m _ml-1",
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "toggle-container",
            {
              staticClass: "gfu-navbar-dropdown__content",
              attrs: {
                tag: "div",
                "active-class": "is-visible",
                tabindex: "0",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "gfu-balloon gfu-balloon--no-arrow",
                  attrs: { "data-qa": "user-box" },
                },
                [
                  _c("div", { staticClass: "gfu-balloon__top" }, [
                    _vm.isImpersonated
                      ? _c("div", { staticClass: "gfu-balloon__top-alert" }, [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.Layout.UserBoxImpersonatingLabel
                            )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "gfu-balloon__top-title",
                        attrs: { "data-qa": "user-box:FullName" },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.userContext.user.fullName) + " "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "gfu-balloon__top-subtitle",
                        attrs: { "data-qa": "user-box:Email" },
                      },
                      [_vm._v(" " + _vm._s(_vm.userContext.user.email) + " ")]
                    ),
                    _vm._v(" "),
                    _vm.userProfileUrl
                      ? _c(
                          "div",
                          {
                            staticClass: "gfu-balloon__top-subtitle",
                            attrs: { "data-qa": "user-box:Profile" },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "gfu-link-underline _tc--accent",
                                attrs: { href: _vm.userProfileUrl },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "gfu-link-underline__content",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.Resources.Layout.UserBoxProfileLink
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("base-icon", {
                                  attrs: { name: "chevron-right" },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isImpersonated
                      ? _c(
                          "div",
                          { staticClass: "gfu-balloon__top-actions" },
                          [
                            _c("revert-impersonation-button", {
                              attrs: { "data-qa": "user-box-button:Revert" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-balloon__content" },
                    [
                      _vm._l(_vm.links, function (link) {
                        return _c(
                          "a",
                          {
                            key: link.key,
                            staticClass: "gfu-balloon__content-link",
                            attrs: {
                              href: link.url,
                              "data-qa": `user-box-button:${link.key}`,
                            },
                            on: { click: link.action },
                          },
                          [
                            _c("span", {
                              class: `gfu-balloon__content-icon fa-fw _fa ${link.icon}`,
                            }),
                            _vm._v(
                              "\n                    " +
                                _vm._s(link.label) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      !_vm.isImpersonated
                        ? _c("div", { staticClass: "gfu-balloon__actions" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "gfu-balloon__actions-button gfu-btn gfu-btn--primary gfu-btn--soft gfu-btn--compact",
                                attrs: {
                                  href: _vm.logoutUrl,
                                  "data-qa": "user-box-button:Logout",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.Resources.Layout.UserBoxLogoutButton
                                  )
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      )
    : _c("div", { staticClass: "_flex" }, [
        _c(
          "a",
          {
            staticClass: "gfu-navbar-item _screen--none _screen--flex-m",
            attrs: { href: _vm.loginUrl, "data-qa": "header-button:login" },
          },
          [
            _c("span", { staticClass: "gfu-navbar-btn" }, [
              _vm._v(_vm._s(_vm.Resources.Layout.HeaderLoginLink)),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "gfu-navbar-item _screen--none _screen--flex-m",
            attrs: {
              href: _vm.createAccountUrl,
              "data-qa": "header-button:signup",
            },
          },
          [
            _c("span", { staticClass: "gfu-navbar-btn" }, [
              _vm._v(_vm._s(_vm.Resources.Layout.HeaderSignupLink)),
            ]),
          ]
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }