export default {
    methods: {
        handleError(error) {
            if (error === undefined || error === null) {
                return;
            }

            if (this.$notify) {
                this.$notify.popupServerError(error);
            }
            else {
                throw error;
            }
        }
    },
    computed: {
        notificator() {
            return this.$attrs.notificator
                ? this.$attrs.notificator
                : this.$refs.notify;
        }
    }
};