import { defineStore } from 'pinia';

export const usePageSectionsStore = defineStore({
    id: 'pageSections',
    state: () => ({
        pageSections: [],
    }),
    actions: {
        addPageSection({ section, parentSection }) {
            if (parentSection) {
                parentSection.sections.push(section);
            }
            else {
                const updatedSections = [...this.pageSections];
                updatedSections.push(section);
                this.pageSections = updatedSections;
            }
        }
    }
});