var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "gfu-layout__main gfu-layout__main--narrow _mt-6" },
    [
      _c(
        "h1",
        {
          staticClass: "gfu-heading gfu-heading--main",
          attrs: { "data-qa": "page-title" },
        },
        [_vm._v(_vm._s(_vm.Resources.Checkout.CheckoutHeader))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-subheading gfu-subheading--main" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.Resources.Checkout.ResumePaymentPageSubHeader) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "box",
        { staticClass: "_mt-3 _mb-6" },
        [
          _c(
            "cart-table",
            _vm._b(
              {
                attrs: { "project-id": _vm.cartTableModel.projectID },
                on: {
                  "cart-loaded": _vm.onCartLoaded,
                  "cart-changed": _vm.onCartChanged,
                  "location-change": _vm.onCartChanged,
                },
              },
              "cart-table",
              _vm.cartTableModel,
              false
            )
          ),
          _vm._v(" "),
          _vm.handleTaxWithoutShipping
            ? _c(
                "order-details-location",
                _vm._b(
                  {},
                  "order-details-location",
                  _vm.orderDetailsLocationModel,
                  false
                )
              )
            : [
                _c(
                  "h2",
                  {
                    staticClass:
                      "gfu-heading gfu-heading--top-divider _mt-3 _mb-2",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.Resources.Checkout.CheckoutShippingStepLabel)
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "_mb-4" },
                  [
                    _c("base-icon", { attrs: { name: "info" } }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.Resources.Checkout.ResumePaymentNotificatorMessage
                        ) +
                        "\n            "
                    ),
                  ],
                  1
                ),
              ],
          _vm._v(" "),
          _c("order-summary-list", { attrs: { "order-id": _vm.orderID } }),
          _vm._v(" "),
          _c("dynamic-currency-conversion-wrapper", {
            attrs: {
              "project-id": _vm.projectID,
              "order-id": _vm.orderID,
              "order-code": _vm.orderCode,
              reason: _vm.paymentMethodListReason,
              "can-be-paid-in-installments": _vm.canBePaidInInstallments,
              "handle-tax-without-shipping": _vm.handleTaxWithoutShipping,
              "payment-currency-strategy": _vm.paymentCurrencyStrategy,
            },
          }),
          _vm._v(" "),
          _vm.isEligibleForInstallments
            ? _c("payment-types", {
                attrs: {
                  "current-payment-type": _vm.paymentType,
                  "effective-payable-amount": _vm.effectivePayableAmount,
                  "order-code": _vm.orderCode,
                  "data-qa": "installments-wrapper",
                },
                on: { "payment-type-changed": _vm.onPaymentTypeChanged },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h3", { staticClass: "gfu-hd gfu-hd--h3 _mt-5" }, [
            _vm._v(
              _vm._s(_vm.Resources.Checkout.CheckoutPaymentMethodSectionTitle)
            ),
          ]),
          _vm._v(" "),
          _c(
            "adyen-order-payment-form",
            _vm._b(
              { attrs: { "data-qa": "payment-section" } },
              "adyen-order-payment-form",
              _vm.paymentOptionsModel,
              false
            )
          ),
          _vm._v(" "),
          _c("checkout-footer"),
          _vm._v(" "),
          _c("progress-overlay", { attrs: { visible: _vm.isProcessingView } }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }