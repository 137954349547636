import ServiceBase from './ServiceBase';

export default class OrdersService extends ServiceBase {

    bulkUpdateOrderStage(orderIDs, projectID, orderStage, notes, privateNotes, refundPayments) {
        const params = {
            orderIDs,
            projectID,
            orderStage,
            notes,
            privateNotes,
            refundPayments
        };

        return this.$http.post('Orders/bulkUpdateOrderStage', params);
    }

    updateOrderStage(orderID, projectID, orderStage, notes, privateNotes) {
        const params = {
            orderID,
            projectID,
            orderStage,
            notes,
            privateNotes
        };

        return this.$http.post('Orders/UpdateOrderStage', params);
    }

    createCustomOrderItem(projectID, orderID, discountItem) {
        const params = {
            projectID,
            orderID,
            ...discountItem
        };

        return this.$http.post('Orders/createCustomOrderItem', params);
    }

    createProductOrderItem(projectID, orderID, model) {
        const params = {
            projectID,
            orderID,
            ...model
        };

        return this.$http.post('Orders/createProductOrderItem', params);
    }

    getUserPledges(projectID) {
        return this.$http.get('orders/getUserPledges', { params: { projectID } });
    }

    updateCustomOrderItem(projectID, orderID, discountItem) {
        const params = {
            projectID,
            orderID,
            ...discountItem
        };

        return this.$http.post('Orders/updateCustomOrderItem', params);
    }

    createOrderComment(projectID, orderComment) {
        const params = {
            projectID,
            orderComment
        };

        return this.$http.post('Orders/createOrderComment', params);
    }

    exportOrders(orderIDs, projectID, creatorID, exportType, format, normalizeOutput) {
        const params = {
            orderIDs,
            projectID,
            creatorID,
            exportType,
            format,
            normalizeOutput
        };

        return this.$http.post('Orders/ExportOrders', params);
    }

    getImportOrderItemsExampleFile() {
        return this.$http.get('Orders/getImportOrderItemsExampleFile', { responseType: 'blob' });
    }

    getUpgradeOrderItemsExampleFile() {
        return this.$http.get('Orders/getUpgradeOrderItemsExampleFile', { responseType: 'blob' });
    }

    getOrderDetails(orderID) {
        const params = {
            orderID
        };

        return this.$http.get('orders/getOrderDetails', { params });
    }

    getOrderIDs(filters, creatorID, projectID) {
        const params = {
            orderPublicID: null,
            customerFullName: null,
            customerEmail: null,
            orderState: null,
            minCreateDate: null,
            maxCreateDate: null,
            creatorID,
            projectID,
            ...filters
        };

        return this.$http.post('Orders/GetOrderIDs', params);
    }

    getShippingMethods() {
        return this.$http.get('Orders/GetShippingMethods');
    }

    getOrderItemDetails(orderItemID) {
        return this.request('get', 'orders/getOrderItemDetails', { orderItemID });
    }

    updateOrderItem(request) {
        return this.request('post', 'orders/updateOrderItem', request);
    }

    deleteImportedOrderItems(projectID, userIDs) {
        return this.$http.post('orders/DeleteImportedOrderItems', { projectID, userIDs });
    }

    importOrderItems(sourceFile, lockItemsInCart, projectID) {
        const formData = new FormData();
        formData.append('sourceFile', sourceFile);
        formData.append('lockItemsInCart', lockItemsInCart);
        formData.append('projectID', projectID);

        return this.$http.post('orders/ImportOrderItems', formData);
    }

    removeUnpaidOrderItems(orderID) {
        const params = {
            orderID
        };

        return this.$http.post('orders/removeUnpaidOrderItems', params);
    }

    searchOrders(filters, creatorID, projectID, pageSize, pageIndex) {
        const params = {
            orderPublicID: null,
            customerFullName: null,
            customerEmail: null,
            orderState: null,
            minCreateDate: null,
            maxCreateDate: null,
            ...filters,
            creatorID,
            projectID,
            pageSize,
            pageIndex
        };

        return this.$http.post('Orders/searchOrders', params);
    }

    splitOrderItem(orderItem, extractedQuantity) {
        const params = {
            orderItemID: orderItem.orderItemID,
            extractedQuantity
        };

        return this.$http.post('orders/splitOrderItem', params);
    }

    reverseSplitOrderItem(orderItemID) {
        return this.$http.post('orders/reverseSplitOrderItem', { orderItemID });
    }

    getDeleteOrderItemConfirmationInfo(orderItemID, projectID) {
        return this.$http.post('orders/getDeleteOrderItemConfirmationInfo', { orderItemID, projectID });
    }

    deleteOrderItem(orderItemID, projectID) {
        return this.$http.post('orders/deleteOrderItem', { orderItemID, projectID });
    }

    updateOrderAddressData(projectID, orderID, addressData) {
        const params = {
            projectID,
            orderID,
            addressData
        };

        return this.$http.post('orders/updateOrderAddressData', params);
    }

    updateOrderDetails(model) {
        return this.$http.post('orders/updateOrderDetails', model);
    }

    updateOrderTags(tags, orderID, creatorID) {
        const model = {
            tags,
            orderID,
            creatorID
        };

        return this.$http.post('orders/updateOrderTags', model);
    }

    resendDigitalCodeNotification(projectID, orderItemID) {
        const model = {
            projectID,
            orderItemID
        };

        return this.$http.post('orders/resendDigitalCodeNotification', model);
    }

    getAvailableTags(creatorID) {
        const params = {
            creatorID
        };

        return this.$http.get('orders/getAvailableTags', { params });
    }

    markOrderItemAsPaid(projectID, orderItemID) {
        return this.request('post', 'orders/markOrderItemAsPaid', { projectID, orderItemID });
    }
}
