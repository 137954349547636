var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-project-summary-action",
      attrs: { "data-qa": "project-summary-follow" },
    },
    [
      _vm.userIsFollowingProject
        ? [
            _c("div", { staticClass: "gfu-media gfu-media--middle" }, [
              _c("span", {
                class: [
                  "gfu-project-summary-action__side",
                  "_fa",
                  "_fa--heart",
                  _vm.unfollowContentColor,
                ],
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gfu-media__body" },
                [
                  _vm.followersCount > 1
                    ? [
                        _c("div", { staticClass: "gfu-bt gfu-bt--b2 _ma-0" }, [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.youAndOthersAreFollowingText
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              class: ["gfu-link", _vm.unfollowContentColor],
                              attrs: {
                                href: "#",
                                "data-qa": "unsubscribe-link",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onUnfollow.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.Resources.ProjectHome
                                    .UnfollowProjectButton
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]
                    : [
                        _c("div", { staticClass: "gfu-bt gfu-bt--b2 _ma-0" }, [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .ProjectSummaryYouAreFollowingText
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            class: ["gfu-link", _vm.unfollowContentColor],
                            attrs: { href: "#", "data-qa": "unsubscribe-link" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onUnfollow.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.ProjectHome.UnfollowProjectButton
                              )
                            ),
                          ]
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c("span", { staticClass: "gfu-project-summary-action__side" }),
            ]),
          ]
        : _vm.projectContext.projectID
        ? [
            _c("follow-project-button", {
              staticClass: "gfu-1of1",
              attrs: {
                "is-authenticated": _vm.isAuthenticated,
                "project-id": _vm.projectContext.projectID,
                "project-name": _vm.projectContext.project.name,
                theme: _vm.buttonTheme,
              },
            }),
            _vm._v(" "),
            _vm.isButtonFollowerCountVisible
              ? _c("div", {
                  staticClass: "_tac _mt-1",
                  domProps: { innerHTML: _vm._s(_vm.othersAreFollowingText) },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }