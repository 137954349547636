const noOp = () => {};

export default function smartQueue(handler, opts) {
    opts = opts || {};
    let timer; let tmp; let running;
    const queue = opts.initial || [];
    const max = opts.max || Infinity;
    const int = opts.interval || 10e3;
    const onEmpty = opts.onEmpty || noOp;
    const onPause = opts.onPause || noOp;

    function batch(all) {
        clearInterval(timer);
        const removed = queue.splice(0, max);
        if (removed.length) {
            handler(removed, queue);
        }
        /* If queue backlog has no items */
        if (!queue.length) {
            running = false;
            // return onEnd(queue, 'end')
            return onEmpty(queue);
        }

        if (all) {
            return batch();
        }
        return ticker();
    }

    function ticker() {
        running = true;
        timer = setInterval(batch, int);
    }

    // Start queue if items
    if (queue.length) {
        ticker();
    }

    return {
        flush(all) {
            batch(all);
        },
        resume: batch,
        push(val) {
            tmp = queue.push(val);
            /* Clear if overflow */
            if (tmp >= max && !opts.throttle) {
                batch(); // immediately process overflow in queue
            }
            if (!running) {
                ticker();
            }
            return tmp;
        },
        size() {
            return queue.length;
        },
        pause(toFlush) {
            if (toFlush) batch();
            clearInterval(timer);
            running = false;
            onPause(queue);
        }
    };
}