import ServiceBase from './ServiceBase';

export default class CheckoutService extends ServiceBase {
    constructor($http) {
        super($http);
    }

    getAmendableOrders(projectID) {
        return this.request('get', 'checkout/getAmendableOrders', { params: { projectID } });
    }

    getCartBasicInfo(projectID, orderCode) {
        return this.request('get', 'checkout/getCartBasicInfo', { params: { projectID, orderCode } });
    }

    saveCartBasicInfo(cartInfo) {
        return this.request('post', 'checkout/saveCartBasicInfo', cartInfo);
    }

    saveCartLocation(locationInfo) {
        return this.request('post', 'checkout/saveCartLocation', locationInfo);
    }

    calculateCartInstallmentsPlan(projectID, exchangeRate, numberOfInstallments, orderCode = null) {
        return this.request('get', 'checkout/calculateCartInstallmentsPlan', { params: { projectID, exchangeRate, numberOfInstallments, orderCode } });
    }
}