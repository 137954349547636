export function enumToDisplayNames(enumArray, filterFunction = () => true) {
    return Object.values(enumArray)
        .reduce((acc, cur) => {
            if (cur.displayName && filterFunction(cur)) {
                acc[cur.value] = cur.displayName;
            }
            return acc;
        }, {});
}

export function enumToSelectOptions(enumArray, filterFunction = () => true, sortFunction = (s) => s) {
    return Object.values(enumArray)
        .filter(c => !!c.displayName)
        .sort(sortFunction)
        .reduce((acc, cur) => {
            if (filterFunction(cur)) {
                acc[cur.displayName] = cur.value;
            }
            return acc;
        }, {});
}

export function excludeEnumValues(object, keys) {
    const filteredEntries = Object.entries(object)
        .filter(([key, value]) => !keys.includes(parseInt(key)) && !keys.includes(value));

    return Object.fromEntries(filteredEntries);
}

export function hasFlag(enumValue, flag) {
    if (enumValue === null || enumValue === undefined) {
        return false;
    }
    return (enumValue & flag) === flag;
}

export function filterEnumByKey(object, keys) {
    const keysAsString = keys.map(k => k.toString());
    const filteredEntries = Object.keys(object)
        .reduce((acc, cur) => {
            if (keysAsString.includes(cur)) {
                acc.push(object[cur]);
            }
            return acc;
        }, []);
    return filteredEntries;
}

export function displayNameFromResources(resource, enumValue) {
    if (resource[enumValue.displayName]) {
        return resource[enumValue.displayName];
    }
    throw new Error(`Resource ${ enumValue.displayName } not found`);
}

export function mapEnumDisplayNameFromResource(resource, options) {
    return Object.entries(options)
        .reduce((acc, [key, value]) => {
            if (value.displayName) {
                acc[key] = { ...value, displayName: displayNameFromResources(resource, value) };
            }
            return acc;
        }, {});
}
