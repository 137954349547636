var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.areRecommendationsVisible
    ? _c(
        "div",
        { staticClass: "gfu-recommendations" },
        [
          _c("h2", { staticClass: "gfu-hd gfu-hd--h2 _mb-1" }, [
            _vm._v(
              _vm._s(_vm.Resources.ProjectHome.ProjectRecommendationsTitle)
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.Resources.ProjectHome.ProjectRecommendationsText) +
                "\n        "
            ),
            _c(
              "a",
              {
                staticClass: "gfu-link gfu-link--accent",
                attrs: { href: "/projects/search" },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.Resources.ProjectHome.ProjectRecommendationsSearchLink
                  )
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "processing-wrapper",
            { attrs: { active: _vm.isProcessing } },
            [
              _c(
                "intersection-trigger",
                {
                  attrs: { "root-margin": _vm.intersectionRootMarginPx },
                  on: { intersected: _vm.onViewportIntersect },
                },
                [
                  _c(
                    "slider-container",
                    {
                      staticClass: "gfu-recommendations__slider",
                      attrs: {
                        centered: false,
                        "arrow-style": "circle",
                        "slides-to-scroll": _vm.slidesToShow,
                        "blur-edges": false,
                        "is-arrow-position-top": true,
                        "background-style": "transparent",
                        "slides-wrapper-class":
                          "gfu-recommendations__slides-wrapper",
                        "slides-class": "gfu-recommendations__slides",
                      },
                    },
                    [
                      _vm._l(_vm.recommendations, function (recommendation) {
                        return _c(
                          "div",
                          {
                            key: recommendation.project.projectID,
                            staticClass: "gfu-recommendations__item",
                            attrs: {
                              "data-qa": `project-card-ID:${recommendation.project.projectID}`,
                            },
                          },
                          [
                            _c(
                              "recommendation-project-card",
                              _vm._b(
                                {},
                                "recommendation-project-card",
                                recommendation.project,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      !_vm.isInitialized
                        ? _vm._l(_vm.placeholders, function (placeholder) {
                            return _c(
                              "div",
                              {
                                key: placeholder,
                                staticClass:
                                  "_mr-3 _my-3 gfu-recommendations__item",
                              },
                              [
                                _c("base-card", {
                                  attrs: { "media-size": "third" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "media",
                                        fn: function () {
                                          return [
                                            _c("div", {
                                              staticClass:
                                                "gfu-embed gfu-embed--1x1",
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }