var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { class: [{ "_tc--error": _vm.isMarked }] }, [
    _vm.isIconVisible
      ? _c("span", { class: ["_fa", _vm.iconClassName, "_mr-1"] })
      : _vm._e(),
    _vm._v("\n    " + _vm._s(_vm.label) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }