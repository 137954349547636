import lazyScreen from '@/Components/Admin/LazyScreen';

const OrdersListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-orders' */ './OrdersListScreen.vue'));
const OrderSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-orders' */ './OrderSummaryScreen.vue'));

const routes = [
    { path: '/orders/:creator?/:project?/list', name: 'orders-list', component: OrdersListScreen },
    { path: '/:creator?/:project?/orders', redirect: { name: 'orders-list' } },
    { path: '/orders/:creator?/:project?/:orderId/summary', name: 'order-summary', component: OrderSummaryScreen, props: route => ({ orderId: parseInt(route.params.orderId) }) },
    { path: '/orders/:orderId/summary', redirect: { name: 'order-summary' } }
];

export {
    OrdersListScreen,
    OrderSummaryScreen,
    routes
};