var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-layout-wrapper",
        _vm.firstPledge
          ? "gfu-layout-wrapper--narrow"
          : "gfu-layout-wrapper--extranarrow",
      ],
    },
    [
      _c("div", { staticClass: "gfu-content-head" }, [
        _vm.isLoaded
          ? _c(
              "h1",
              {
                staticClass: "gfu-hd gfu-hd--h1",
                attrs: { "data-qa": "page-title" },
              },
              [_vm._v(_vm._s(_vm.Resources.YourPledge.YourPledgePageTitle))]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.firstPledge && _vm.project
        ? [
            _c("project-user-pledge", {
              attrs: {
                "initial-expanded-state": true,
                "is-open-for-orders": _vm.isOpenForOrders,
                pledge: _vm.firstPledge,
                "add-new-items-url": _vm.addNewItemsUrl,
                project: _vm.project,
                "data-qa": "project-user-pledge:CurrentPledge",
              },
              on: {
                "cart-table-loaded": _vm.onCurrentPledgeTableLoaded,
                "pledge-changed": _vm.onPledgeChanged,
              },
            }),
            _vm._v(" "),
            _vm.previousPledges.length > 0
              ? _c(
                  "div",
                  {
                    attrs: { "data-qa": "project-user-pledge:PreviousPledges" },
                  },
                  [
                    _c(
                      "h1",
                      {
                        staticClass: "gfu-hd gfu-hd--h1 _mt-6",
                        attrs: { "data-qa": "page-title" },
                      },
                      [_vm._v("Previous pledges")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.previousPledges, function (pledge) {
                      return _c(
                        "div",
                        {
                          key: `pledge-${pledge.orderID}`,
                          ref: `pledge-${pledge.orderCode}`,
                          refInFor: true,
                        },
                        [
                          _c("project-user-pledge", {
                            attrs: {
                              "is-open-for-orders": _vm.isOpenForOrders,
                              pledge: pledge,
                              "is-expandable": true,
                              "add-new-items-url": _vm.addNewItemsUrl,
                              "initial-expanded-state":
                                pledge.orderCode === _vm.selectedOrderCode,
                              project: _vm.project,
                              "data-qa": `project-user-pledge:${pledge.orderID}`,
                            },
                            on: { "pledge-changed": _vm.onPledgeChanged },
                          }),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]
        : _vm.isLoaded
        ? _c(
            "box",
            {
              staticClass: "_mt-3 _mb-4",
              attrs: { "data-qa": "project-user-pledge:EmptyState" },
            },
            [
              _c(
                "empty-state",
                {
                  attrs: {
                    title: _vm.Resources.YourPledge.EmptyPledgeTitle,
                    description: _vm.emptyStateDescription,
                  },
                },
                [
                  _vm.isOpenForOrders
                    ? _c(
                        "a",
                        {
                          staticClass: "gfu-btn gfu-btn--accent gfu-btn--soft",
                          attrs: {
                            href: _vm.addNewItemsUrl,
                            "data-qa": "project-user-pledge-button:AddItems",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.Resources.YourPledge.EmptyPledgeButton)
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("project-recommendations", {
        staticClass: "_mt-6 _mb-4",
        attrs: {
          title: _vm.Resources.ProjectHome.ProjectRecommendationsTitle,
          "exclude-project-ids": [_vm.projectContext.project.projectID],
          quantity: 12,
          source: "yourPledge",
          "data-qa": `Recommendations`,
        },
      }),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { visible: _vm.isProcessing, sticky: true },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }