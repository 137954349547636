var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-dropdown gfua-editing-control" },
    [
      _vm.productState !== _vm.productStates.enabled
        ? _c(
            "tooltip",
            {
              staticClass: "_flex",
              attrs: {
                text: "This product is not visible to any user because it hasn't been published.",
              },
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "gfu-btn gfu-btn--accent gfu-btn--circle gfu-btn--circle-big",
                  attrs: {
                    type: "button",
                    "data-qa": "product-visibility-unpublished",
                  },
                },
                [_c("span", { staticClass: "_fa _fa--exclamation-triangle" })]
              ),
            ]
          )
        : _vm.visibleToUserGroupNames.length
        ? _c(
            "tooltip",
            {
              staticClass: "_flex",
              attrs: {
                text: `This product is visible only to following user group(s): ${_vm.userGroupNames}`,
              },
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "gfu-btn gfu-btn--accent gfu-btn--circle gfu-btn--circle-big",
                  attrs: {
                    type: "button",
                    "data-qa": "product-visibility-group",
                  },
                },
                [_c("span", { staticClass: "_fa _fa--exclamation-triangle" })]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }