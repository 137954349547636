var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-navbar-item" }, [
    _c(
      "button",
      { staticClass: "gfu-navbar-btn", on: { click: _vm.openModal } },
      [
        _c("img", {
          staticClass: "gfu-icon gfu-icon--elevate",
          attrs: {
            width: "28",
            src: _vm.selectedLanguage.icon,
            alt: _vm.selectedLanguage.value,
            "data-qa": "language-selecotr:FlagButton",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }