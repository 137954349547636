import lazyScreen from '@/Components/Admin/LazyScreen';

const AdyenPaymentNotificationsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/AdyenPaymentNotificationsListScreen.vue'));
const AdyenPaymentNotificationLogSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/AdyenPaymentNotificationLogSummaryScreen.vue'));
const AdminUtilsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/AdminUtilsScreen.vue'));
const AuditLogsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/AuditLogsListScreen.vue'));
const AuditLogSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/AuditLogSummaryScreen.vue'));
const ProjectCategoriesListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/Projects/ProjectCategoriesListScreen.vue'));
const SystemDashboard = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/SystemDashboard.vue'));
const SettingsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/Settings/SettingsListScreen.vue'));
const CommentModeration = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/CommentModeration/CommentModeration.vue'));
const CommunicationSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/Communication/CommunicationSideNav.vue'));
const ProjectsSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/Projects/ProjectsSideNav.vue'));
const ProjectsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/Projects/ProjectsListScreen.vue'));
const ProjectsExposureSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/Projects/ProjectsExposureSettingsScreen.vue'));
const SystemPollsSettingsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/Polls/SystemPollsSettingsListScreen.vue'));
const SystemPollSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/Polls/SystemPollSettingsScreen.vue'));
const TestingSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/Testing/TestingSideNav.vue'));
const TestUtilsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/Testing/TestUtils/TestUtilsScreen.vue'));
const TestProjectsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/Testing/TestProjectsListScreen.vue'));
const InfoBannerListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/InfoBanner/InfoBannerListScreen.vue'));
const InfoBannerSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/InfoBanner/InfoBannerSummaryScreen.vue'));
const ShortLinksListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ '@/Components/Admin/System/ShortLinks/ShortLinksListScreen.vue'));

const routes = [
    {
        path: '/system/utils',
        name: 'system-utils',
        component: AdminUtilsScreen,
    },
    {
        path: '/system/adyenNotifications',
        name: 'system-adyen-notifications',
        component: AdyenPaymentNotificationsListScreen,
    },
    {
        path: '/system/adyenNotification/:adyenPaymentNotificationLogId/summary',
        name: 'system-adyen-notification-summary',
        component: AdyenPaymentNotificationLogSummaryScreen,
        props: (route) => ({
            adyenPaymentNotificationLogId: parseInt(
                route.params.adyenPaymentNotificationLogId
            ),
        }),
    },
    {
        path: '/system/audits',
        name: 'system-audit',
        component: AuditLogsListScreen,
    },
    {
        path: '/system/audits/:auditLogId/summary',
        name: 'system-audit-summary',
        component: AuditLogSummaryScreen,
        props: (route) => ({ auditLogId: parseInt(route.params.auditLogId) }),
    },
    {
        path: '/system/dashboard/:creator?/:project?',
        name: 'system-dashboard',
        component: SystemDashboard,
    },
    {
        path: '/system/comments/moderation',
        name: 'comment-moderation',
        components: {
            default: CommentModeration,
            sidenav: CommunicationSideNav,
        },
    },
    {
        path: '/system/projects-list',
        name: 'system-projects-list',
        components: { default: ProjectsListScreen, sidenav: ProjectsSideNav },
    },
    {
        path: '/system/project-categories',
        name: 'system-project-categories',
        components: {
            default: ProjectCategoriesListScreen,
            sidenav: ProjectsSideNav,
        },
    },
    {
        path: '/system/projects-exposure',
        name: 'system-projects-exposure',
        components: {
            default: ProjectsExposureSettingsScreen,
            sidenav: ProjectsSideNav,
        },
    },
    {
        path: '/system/settings-list',
        name: 'system-settings-list',
        components: { default: SettingsListScreen },
    },
    {
        path: '/system/test-utils',
        name: 'test-utils',
        components: { default: TestUtilsScreen, sidenav: TestingSideNav }
    },
    {
        path: '/system/test-projects-list',
        name: 'test-projects-list',
        components: { default: TestProjectsListScreen, sidenav: TestingSideNav }
    },
    {
        path: '/system/polls',
        name: 'system-communication-polls',
        components: {
            default: SystemPollsSettingsListScreen,
            sidenav: CommunicationSideNav,
        },
    },
    {
        path: '/system/polls/new',
        name: 'system-polls-new',
        components: {
            default: SystemPollSettingsScreen,
            sidenav: CommunicationSideNav,
        },
        props: { default: { pollID: 0 } },
    },
    {
        path: '/system/polls/:pollID',
        name: 'system-polls-settings',
        components: {
            default: SystemPollSettingsScreen,
            sidenav: CommunicationSideNav,
        },
        props: {
            default: (route) => ({ pollID: parseInt(route.params.pollID) }),
        },
    },
    {
        path: '/system/system-info-banners',
        name: 'system-info-banner-list',
        components: {
            default: InfoBannerListScreen,
            sidenav: CommunicationSideNav,
        },
    },
    {
        path: '/system/system-info-banners/new',
        name: 'system-info-banner-new',
        components: {
            default: InfoBannerSummaryScreen,
            sidenav: CommunicationSideNav,
        },
        props: { infoBannerID: 0 },
    },
    {
        path: '/system/system-info-banners/:infoBannerID',
        name: 'system-info-banner-summary',
        components: {
            default: InfoBannerSummaryScreen,
            sidenav: CommunicationSideNav,
        },
        props: {
            default: (route) => ({ infoBannerID: parseInt(route.params.infoBannerID) }),
        },
    },
    {
        path: '/system/short-links',
        name: 'system-short-links',
        components: {
            default: ShortLinksListScreen,
            sidenav: CommunicationSideNav,
        },
    },

];

export {
    AdminUtilsScreen,
    AuditLogsListScreen,
    ProjectCategoriesListScreen,
    routes
};