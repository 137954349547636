var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    _vm._l(
      _vm.sections,
      function (
        {
          title,
          subtitle,
          seeMoreUrl,
          seeMoreHeaderUrl,
          seeMoreLabel,
          theme,
          projects,
          showSeeMore,
        },
        index
      ) {
        return _c(
          "div",
          { key: title, staticClass: "_pb-6" },
          [
            projects.length > 0
              ? [
                  _c(
                    "featured-header",
                    _vm._b(
                      {},
                      "featured-header",
                      {
                        title,
                        subtitle,
                        theme,
                        showSeeMore,
                        buttonText: seeMoreLabel,
                        seeMoreUrl,
                        ...{ seeMoreHeaderUrl },
                      },
                      false
                    )
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-layout-wrapper" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "gfu-card-list gfu-scrollable",
                          attrs: { "data-qa": `projects-section:${title}` },
                        },
                        _vm._l(projects, function (project) {
                          return _c(
                            "div",
                            {
                              key: project.projectID,
                              class: [
                                "gfu-card-list__item gfu-5of6 gfu-2of5--m gfu-1of3--l",
                                _vm.getCardItemClassNames(projects.length),
                              ],
                            },
                            [
                              _c(
                                "project-card",
                                _vm._b({}, "project-card", project, false)
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "_tar _pt-3 _screen--none _screen--m" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "gfu-link gfu-link--accent",
                              attrs: {
                                href: seeMoreUrl,
                                "data-qa": `promoted-projects:${seeMoreLabel}`,
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(seeMoreLabel)
                              ),
                              _c("span", {
                                staticClass: "_fr _ml-1 _fa _fa--chevron-right",
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      seeMoreLabel
                        ? _c("div", { staticClass: "_pa-4 _tac" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "gfu-btn _screen--not-m gfu-btn--primary gfu-btn--soft _ttu",
                                attrs: { href: seeMoreUrl },
                              },
                              [_vm._v(_vm._s(seeMoreLabel))]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.shouldShowBanner(index)
                        ? _c("announcement-box", {
                            attrs: {
                              banners: _vm.banners,
                              "data-qa": "home-page-box:InfoBanners",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        )
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }