export default {
    props: {
        disabled: { default: false, type: Boolean },
        name: { default: null, type: String },
        placeholder: { default: null, type: [String, Number, Boolean] },
        readOnly: { default: false, type: Boolean },
        small: { default: false, type: Boolean },
        value: {}
    },
    data () {
        return {
            model: this.value
        };
    },
    methods: {
        focus() {
            if (this.$refs.input) {
                this.$refs.input.focus.apply(this.$refs.input);
            }
        },
        onBlur(evt) {
            this.$emit('blur', evt);
        },
        onFocus(evt) {
            this.$emit('focus', evt);
        },
        onKeydown(evt) {
            this.$emit('keydown', evt);
        }
    },
    watch: {
        'model' (newValue) {
            if (newValue !== this.value) {
                this.$emit('input', newValue);
            }
        },
        'value' (newValue) {
            this.model = newValue;
        }
    }
};
