var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      { attrs: { "show-label": false, "display-name": _vm.displayName } },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      _c(
        "label",
        {
          staticClass:
            "gfu-field__toggle gfu-field-toggle--switch gfu-field-switch",
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            staticClass: "gfu-field-switch__input",
            attrs: {
              type: "checkbox",
              name: _vm.name,
              disabled: _vm.readOnly || _vm.disabled,
            },
            domProps: {
              value: _vm.checkedValue,
              checked: Array.isArray(_vm.model)
                ? _vm._i(_vm.model, _vm.checkedValue) > -1
                : _vm.model,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.model,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.checkedValue,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.model = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.model = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.model = $$c
                  }
                },
                _vm.onChange,
              ],
            },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "gfu-field-switch__slider",
            class: { "gfu-field-switch__slider--readonly": _vm.readOnly },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "gfu-field-switch__label",
              on: { click: _vm.onClick },
            },
            [
              _vm._t("default", function () {
                return [_vm._v(_vm._s(_vm.displayName))]
              }),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }