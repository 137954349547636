import Formatter from '@/Utils/Formatter';
import { convertToPercentage } from '@/Utils/MathUtility';
import { orderItemTypes, orderStates, projectPhases, orderPaymentStatuses } from '@/Models';
import Resources from '@/Resources.js';

function getTaxNameByLocation(location) {
    return location?.name;
}

export function buildOrderSummaryModel(cartDetails, pledge, locations) {
    const isEditModeEnabled = cartDetails.cart && pledge?.parentOrder;
    const hasCustomerProjectLocation = cartDetails.cart.customerProjectLocationID !== null;
    let locationTaxName = null;

    if (hasCustomerProjectLocation) {
        const customerCurrentLocation = locations.find(e => e.projectLocationID === cartDetails.cart.customerProjectLocationID);
        const customerCurrentSubLocation = customerCurrentLocation?.subLocations.find(e => e.projectSubLocationID === cartDetails.cart.customerProjectSubLocationID);
        locationTaxName = customerCurrentSubLocation ? getTaxNameByLocation(customerCurrentSubLocation) : getTaxNameByLocation(customerCurrentLocation);
    }

    const currencySymbol = cartDetails.cart.currencySymbol;
    const isHandleTax = cartDetails.cart.handleTax;
    const isVatReverseEnabled = cartDetails.cart.isVatRefundable;
    const isOvercharged = cartDetails.cart.effectivePayableAmount < 0;

    const pledgedOrderStates = [
        orderStates.pledged,
        orderStates.pledgedAndPaid,
        orderStates.pledgedWithInstallments
    ];

    const orderStatesToShowOutstandingAmount = [
        orderStates.unfinished,
        orderStates.placedAwaitingPayment,
        orderStates.placedWithPaymentError
    ];

    const showCreditDiscount = [
        cartDetails.showDiscountsAndPremiums,
        cartDetails.cart.orderState !== orderStates.canceled,
        cartDetails.cart.effectiveOutstandingCreditPaymentAmount > 0
    ].every(element => element);

    // has order outstanding amount conditions
    const hasOrderOutstandingAmount = [
        orderStatesToShowOutstandingAmount.includes(cartDetails.cart.orderState),
        cartDetails.cart.parentOrder !== null,
        cartDetails.cart.effectiveOutstandingAmountAfterCreditDiscount > 0 && cartDetails.cart.orderState !== orderStates.canceled
    ].some(element => element);

    // is not crowdfunding project or pledged is in edit mode
    const isPledgeInEditModeAndNotInCrowdfunding = !cartDetails.hasSameItemsAsParent && !pledgedOrderStates.includes(cartDetails.cart.orderState)
        || cartDetails.project.phase !== projectPhases.crowdfunding;

    // final condition for display outstanding amount.
    const effectiveShowOutstandingAmount = [
        hasOrderOutstandingAmount,
        isPledgeInEditModeAndNotInCrowdfunding
    ].every(element => element);

    // show total paid amount conditions
    const showTotalPaidAmount = [
        cartDetails.cart.orderState !== orderStates.unfinished,
        cartDetails.cart.parentOrder === null,
        cartDetails.cart.effectivePaidAmount > 0
    ].every(element => element);

    // show total pledged value condition
    const effectiveShowTotalPledged = cartDetails.showPledgedTotal && cartDetails.hasSameItemsAsParent
    || cartDetails.project.phase === projectPhases.crowdfunding && pledgedOrderStates.includes(cartDetails.cart.orderState) && cartDetails.cart.orderPaymentStatus === orderPaymentStatuses.none;

    const hasInstallmentsInProgress = [
        cartDetails.cart.orderState === orderStates.pledgedWithInstallments,
        showTotalPaidAmount,
        cartDetails.cart.payableAmountConverted === 0
    ].every(t => t);

    const shouldShowTotalPaidAndScheduledAmountBelowLine = () => {
        if (isOvercharged) return false;

        return cartDetails.cart.effectivePayableAmount === 0;
    };

    const totalDiscountMessages = cartDetails.customOrderItems
        .filter(( { orderItemType } ) => orderItemType === orderItemTypes.discount)
        .map( ( { displayName } ) => displayName)
        .join('; ');

    const shouldShowToPay = !isOvercharged && (hasInstallmentsInProgress ? false : effectiveShowOutstandingAmount) && cartDetails.cart.effectivePayableAmount > 0;
    const shouldShowToPayInEditMode = isEditModeEnabled && !isOvercharged && (hasInstallmentsInProgress ? false : effectiveShowOutstandingAmount);

    const shouldShowTotalPaidAboveLine = (isOvercharged || cartDetails.cart.effectivePayableAmount > 0) && cartDetails.cart.effectivePaidAmount > 0;
    const shouldShowTotalPaidAboveLineInEditMode = isOvercharged || cartDetails.cart.effectivePayableAmount === 0 && cartDetails.cart.effectivePaidAmount > 0 && isEditModeEnabled;

    const shouldShowScheduledToPayAboveLine = (isOvercharged || cartDetails.cart.effectivePayableAmount > 0) && cartDetails.cart.scheduledAmount;
    const shouldShowScheduledToPayAboveLineInEditMode = (isOvercharged || cartDetails.cart.effectivePayableAmount === 0) && cartDetails.cart.scheduledAmount && isEditModeEnabled;

    const taxDetailsRows = cartDetails.cart.taxInfos
        ? cartDetails.cart.taxInfos
            .filter(taxInfo => taxInfo.taxValue !== 0)
            .map(taxInfo => {
                return {
                    text: `${ convertToPercentage(taxInfo.taxRate, 2) }% - ${ Formatter.format(taxInfo.taxValue, 'C2', currencySymbol) }`,
                    dataQa: `tax-tooltip-row:${ taxInfo.taxRateType }`
                };
            })
        : [];

    const taxDetails = taxDetailsRows.length > 0
        ? [{ text: 'Tax details:', dataQa: 'tax-tooltip-row:TaxDetails' }].concat(taxDetailsRows)
        : null;

    const orderSummaryValuesModel = [
        {
            condition: true,
            title: isHandleTax
                ? Resources.YourPledge.OrderSummaryNetSubtotalLabel
                : Resources.YourPledge.OrderSummarySubtotalLabel,
            value: Formatter.format(isHandleTax ? cartDetails.cart.totalNetValueConverted : cartDetails.cart.totalValueConverted, 'C2', currencySymbol),
        },
        {
            condition: cartDetails.cart.effectiveTotalDiscount,
            title: isHandleTax
                ? Resources.YourPledge.OrderSummaryNetTotalDiscountsLabel
                : Resources.YourPledge.OrderSummaryTotalDiscountsLabel,
            tooltipText: totalDiscountMessages,
            value: Formatter.format(isHandleTax ? cartDetails.cart.effectiveTotalNetDiscount : cartDetails.cart.effectiveTotalDiscount, 'C2', currencySymbol)
        },
        {
            condition: true,
            title: isHandleTax
                ? Resources.YourPledge.OrderSummaryNetShippingCostLabel
                : Resources.YourPledge.OrderSummaryShippingCostLabel,
            value: cartDetails.showShippingCost
                ? Formatter.format(isHandleTax ? cartDetails.cart.shippingNetCostConverted : cartDetails.cart.shippingCostConverted, 'C2', currencySymbol)
                : '-',
            tooltipText: cartDetails.showShippingCost
                ? null
                : Resources.YourPledge.OrderSummaryShippingCostCollectedAfterCrowdfundingHint,
        },
        {
            condition: isHandleTax && !isVatReverseEnabled,
            title: cartDetails.cart.taxName
                ? Resources.format(Resources.YourPledge.OrderSummaryTaxWithNameLabel, cartDetails.cart.taxName)
                : Resources.YourPledge.OrderSummaryTaxLabel,
            hasSubtitle: !!locationTaxName,
            subtitle: locationTaxName,
            hasSubtitleTooltip: !!taxDetails,
            subtitleTooltipContent: taxDetails,
            value: cartDetails.cart.taxRate || cartDetails.cart.taxRate === 0 ? Formatter.format(Math.max(0, cartDetails.cart.totalTaxConverted), 'C2', currencySymbol) : '-',
        },
        {
            condition: cartDetails.showDiscountsAndPremiums && cartDetails.cart.totalVatDeductionConverted < 0,
            title: Resources.YourPledge.OrderSummaryTotalVatDeductionLabel,
            value: Formatter.format(cartDetails.cart.totalVatDeductionConverted, 'C2', currencySymbol),
        },
        {
            condition: shouldShowTotalPaidAboveLine || shouldShowTotalPaidAboveLineInEditMode,
            title: Resources.YourPledge.OrderSummaryTotalPaidLabel,
            value: Formatter.format(-cartDetails.cart.effectivePaidAmount, 'C2', currencySymbol),
        },
        {
            condition: shouldShowScheduledToPayAboveLine || shouldShowScheduledToPayAboveLineInEditMode,
            title: Resources.YourPledge.OrderSummaryRemainingInstallmentsLabel,
            value: Formatter.format(-cartDetails.cart.scheduledAmount, 'C2', currencySymbol),
        },
        {
            condition: showCreditDiscount,
            title: Resources.YourPledge.OrderSummaryCreditDiscountLabel,
            value: Formatter.format(cartDetails.cart.effectiveOutstandingCreditPaymentAmount * -1, 'C2', currencySymbol),
        },
        // divider to Separate single data from grouped data.
        {
            condition: effectiveShowTotalPledged || showTotalPaidAmount || effectiveShowOutstandingAmount,
            title: 'divider',
            isDivider: true,
        },
        {
            condition: effectiveShowTotalPledged,
            title: Resources.YourPledge.OrderSummaryTotalPledgedLabel,
            isHighlighted: true,
            theme: 'accented',
            value: Formatter.format(cartDetails.cart.effectiveOutstandingAmountAfterCreditDiscount, 'C2', currencySymbol),
        },
        {
            condition: shouldShowTotalPaidAndScheduledAmountBelowLine() && !isEditModeEnabled && cartDetails.cart.effectivePaidAmount > 0,
            title: Resources.YourPledge.OrderSummaryTotalPaidLabel,
            theme: 'accented',
            isHighlighted: true,
            value: Formatter.format(cartDetails.cart.effectivePaidAmount, 'C2', currencySymbol),
        },
        {
            condition: cartDetails.cart.effectiveRefundedAmount < 0,
            title: Resources.YourPledge.OrderSummaryRefundedLabel,
            isHighlighted: true,
            value: Formatter.format(cartDetails.cart.effectiveRefundedAmount, 'C2', currencySymbol),
        },
        {
            condition: shouldShowTotalPaidAndScheduledAmountBelowLine() && cartDetails.cart.scheduledAmount && !isEditModeEnabled,
            title: Resources.YourPledge.OrderSummaryRemainingInstallmentsLabel,
            isHighlighted: true,
            value: Formatter.format(cartDetails.cart.scheduledAmount, 'C2', currencySymbol),
        },
        {
            // #13894 - Have to turn off the "To Pay" property if there is nothing to pay in ongoing installments in the CF phase
            condition: shouldShowToPay || shouldShowToPayInEditMode,
            title: Resources.YourPledge.OrderSummaryToPayLabel,
            isHighlighted: true,
            value: Formatter.format(cartDetails.cart.effectivePayableAmount, 'C2', currencySymbol),
        },
        {
            condition: isOvercharged,
            title: Resources.YourPledge.OrderSummaryOverchargedLabel,
            isHighlighted: true,
            theme: 'invalid',
            value: Formatter.format(cartDetails.cart.effectivePayableAmount * -1, 'C2', currencySymbol),
        },
    ];

    return orderSummaryValuesModel.filter(orderSummaryItem => {
        if (orderSummaryItem.condition) {
            delete orderSummaryItem.condition;
            return orderSummaryItem;
        }
        return;
    });
}