import TickServiceInstance from '@/Services/TickServiceInstance.js';
import ServerTimeProvider from '@/Utils/ServerTimeProvider.js';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOURS = MINUTE * 60;
const DAY = HOURS * 24;

export default {
    data: () => ({
        currentTime: ServerTimeProvider.now(),
        tickSubscription: null
    }),
    props: {
        timestamp: { type: Date, required: true },
        padNumbers: { type: Boolean, default: false }
    },
    methods: {
        updateCurrentTime() {
            this.currentTime = ServerTimeProvider.now();
        },
        format(number) {
            if (this.padNumbers) {
                if (number < 10) {
                    return `0${ number }`;
                }
            }

            return number;
        }
    },
    computed: {
        timeLeft() {
            const timeDelta = Math.max(this.timestamp - this.currentTime, 0);

            return {
                seconds: this.format(Math.floor(timeDelta / SECOND) % 60),
                minutes: this.format(Math.floor(timeDelta / MINUTE) % 60),
                hours: this.format(Math.floor(timeDelta / HOURS) % 24),

                totalSeconds: this.format(Math.floor(timeDelta / SECOND)),
                totalMinutes: this.format(Math.floor(timeDelta / MINUTE)),
                totalHours: this.format(Math.floor(timeDelta / HOURS)),
                totalDays: this.format(Math.floor(timeDelta / DAY)),
            };
        },
    },
    mounted() {
        this.tickSubscription = TickServiceInstance.subscribe(this.updateCurrentTime.bind(this), SECOND);
    },
    beforeDestroy() {
        if (this.tickSubscription) {
            TickServiceInstance.unsubscribe(this.tickSubscription);
        }
    }
};
