import Vue from 'vue';
import Bus from '@/Bus';
import Config from '@/Config';
import Store from '@/Store';
import Stores from '@/Stores';
import StoreAdminModule from '@/Store/Admin';

// Register admin routes
import '@/Apps/Admin/RouteConfig';

/**
 * Editing control's
 */
const init = () => {
    const editControlClass = 'gfua-editing-control';
    const editControlVisibleClass = 'gfua-editing-control--visible';

    let shouldAutoShowAllControls = false;

    const showAllControls = () => {
        document.querySelectorAll(`.${ editControlClass }`).forEach((element) => {
            element.classList.add(editControlVisibleClass);
        });
    };

    const hideAllControls = () => {
        document.querySelectorAll(`.${ editControlClass }`).forEach((element) => {
            element.classList.remove(editControlVisibleClass);
        });
    };

    Bus.Topics.Admin.Notify.NavigationBarVisible.subscribe(() => {
        showAllControls();
        shouldAutoShowAllControls = true;
    });

    Bus.Topics.Admin.Notify.NavigationBarHidden.subscribe(hideAllControls);

    if (shouldAutoShowAllControls) {
        showAllControls();
    }
};

if (Config.currentUser.hasAccessToAdminMode) {
    Store.registerModule('admin', StoreAdminModule);

    window.addEventListener('DOMContentLoaded', () => {
        import(/* webpackChunkName: 'gamefound-admin-main-app' */ '@/Apps/Admin/MainApp.vue')
            .then((module) => {
                const el = document.createElement('div');
                const mainApp = new Vue({
                    name: 'App.Admin',
                    render: h => h(module.default),
                    pinia: Stores
                });

                document.body.appendChild(el);
                mainApp.$mount(el);
                init();
            });
    });
}
