import ServiceBase from '../ServiceBase';

export default class ProductsService extends ServiceBase {
    constructor($http, errorHandler) {
        super($http, errorHandler);
    }

    createProduct(projectID, product) {
        return this.request('post', 'admin/products/createProduct', { projectID, ...product });
    }

    createProductGroup(productGroup) {
        const params = {
            ...productGroup
        };

        return this.request('post', 'admin/products/createProductGroup', params);
    }

    createProductGroupDiscount(projectID, productGroupDiscount) {
        const params = {
            projectID,
            ...productGroupDiscount
        };

        return this.request('post', 'admin/products/createProductGroupDiscount', params);
    }

    createProductGroupProduct(productGroupProduct) {
        const params = {
            ...productGroupProduct
        };

        return this.request('post', 'admin/products/createProductGroupProduct', params);
    }

    createProductGroupUserGroup(productGroupUserGroup) {
        const params = {
            ...productGroupUserGroup
        };

        return this.request('post', 'admin/products/createProductGroupUserGroup', params);
    }

    createProductOption(productID, option) {
        return this.request('post', 'admin/products/createProductOption', { productID, ...option });
    }

    createProductOptionValue(productID, value) {
        return this.request('post', 'admin/products/createProductOptionValue', { productID, ...value });
    }

    createProductSetItem(productSetItem, projectID) {
        const params = {
            ...productSetItem,
            projectID
        };

        return this.request('post', 'admin/products/createProductSetItem', params);
    }

    createReward(projectID, model) {
        const params = {
            projectID,
            ...model
        };

        return this.request('post', 'admin/products/createReward', params);
    }

    deleteProduct(productID, projectID) {
        const params = {
            productID,
            projectID
        };
        return this.request('post', 'admin/products/deleteProduct', params);
    }

    deleteProductDigitalCode(productDigitalCodeID, projectID) {
        const params = {
            productDigitalCodeID,
            projectID
        };

        return this.request('post', 'admin/products/deleteProductDigitalCode', params);
    }

    deleteProductGroup(productGroupID, projectID) {
        const params = {
            productGroupID,
            projectID
        };

        return this.request('post', 'admin/products/deleteProductGroup', params);
    }

    deleteProductGroupDiscount(projectID, productGroupDiscountID) {
        const params = {
            projectID,
            productGroupDiscountID
        };

        return this.request('post', 'admin/products/deleteProductGroupDiscount', params);
    }

    deleteProductGroupProduct(productGroupID, productID, projectID) {
        const params = {
            productGroupID, productID,
            projectID
        };

        return this.request('post', 'admin/products/deleteProductGroupProduct', params);
    }

    deleteProductGroupUserGroup(productGroupID, userGroupID, projectID) {
        const params = {
            productGroupID, userGroupID,
            projectID
        };

        return this.request('post', 'admin/products/deleteProductGroupUserGroup', params);
    }

    deleteProductOption(productID, option) {
        return this.request('post', 'admin/products/deleteProductOption', { productID, ...option });
    }

    deleteProductOptionValue(productID, value) {
        return this.request('post', 'admin/products/deleteProductOptionValue', { productID, ...value });
    }

    deleteProductSetItem(productSetItemID, projectID) {
        const params = {
            productSetItemID,
            projectID
        };

        return this.request('post', 'admin/products/deleteProductSetItem', params);
    }

    duplicateProduct(productID) {
        return this.request('post', 'admin/products/duplicateProduct', { productID });
    }

    getProduct(productID, projectID) {
        return this.request('get', 'admin/products/getProduct', { productID, projectID });
    }

    getProductAddToCartModel(productID, projectID) {
        return this.request('get', 'products/getProductAddToCartModel', { productID, projectID });
    }

    getProductBaseInfo(productID, projectID) {
        return this.request('get', 'admin/products/getProductBaseInfo', { productID, projectID });
    }

    getProductCategoryInfo(productID, projectID) {
        return this.request('get', 'admin/products/getProductCategoryInfo', { productID, projectID });
    }

    getProductGroup(productGroupID, projectID) {
        const params = {
            productGroupID,
            projectID
        };

        return this.request('get', 'admin/products/getProductGroup', params);
    }

    getProductOptions(productID, projectID, orderItemID = null) {
        return this.request('get', 'admin/products/getProductOptions', { productID, projectID, orderItemID });
    }

    getProductSetItem(productSetItemID, projectID) {
        const params = {
            productSetItemID,
            projectID
        };

        return this.request('get', 'admin/products/getProductSetItem', params);
    }

    getRewards(projectID) {
        return this.request('get', 'admin/products/getRewards', { params: { projectID } });
    }

    importProductDigitalCodes(sourceFile, projectID, productID) {
        const formData = new FormData();
        formData.append('sourceFile', sourceFile);
        formData.append('projectID', projectID);
        formData.append('productID', productID);

        return this.request('post', 'admin/products/importProductDigitalCodes', formData);
    }

    reorderProduct(projectID, productID, sortOrder) {
        const params = {
            projectID,
            productID,
            sortOrder
        };

        return this.request('post', 'admin/products/reorderProduct', params);
    }

    reorderProductSetItem(projectID, productSetItemID, sortOrder) {
        const params = {
            projectID,
            productSetItemID,
            sortOrder
        };

        return this.request('post', 'admin/products/reorderProductSetItem', params);
    }

    revealProductDigitalCode(productDigitalCodeID, projectID) {
        const params = {
            productDigitalCodeID,
            projectID
        };

        return this.request('get', 'admin/products/revealProductDigitalCode', params);
    }

    searchProductDigitalCodes(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters
        };

        return this.request('post', 'admin/products/searchProductDigitalCodes', params);
    }

    searchProductGroupDiscounts(projectID, filters = {}, pageSize = 0, pageIndex = 0) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters
        };

        return this.request('get', 'admin/products/searchProductGroupDiscounts', params);
    }

    searchProductGroupProducts(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters
        };

        return this.request('get', 'admin/products/searchProductGroupProducts', params);
    }

    searchProductGroups(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters
        };

        return this.request('post', 'admin/products/searchProductGroups', params);
    }

    searchProductGroupUserGroups(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters
        };

        return this.request('get', 'admin/products/searchProductGroupUserGroups', params);
    }

    searchProducts(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters
        };
        return this.request('post', 'admin/products/searchProducts', params);
    }

    searchProductSetItems(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters
        };
        return this.request('post', 'admin/products/searchProductSetItems', params);
    }

    searchProductSkuMappings(projectID, productID, filters = {}, pageSize = null, pageIndex = null) {
        const searchParams = {
            projectID,
            productID,
            ...filters,
            pageSize,
            pageIndex
        };
        return this.request('post', 'admin/products/searchProductSkuMappings', searchParams);
    }

    updateProductAvailabilitySettings(projectID, request) {
        return this.request('post', 'admin/products/updateProductAvailabilitySettings', { projectID, ...request });
    }

    updateProductDescription(projectID, request) {
        return this.request('post', 'admin/products/updateProductDescription', { projectID, ...request });
    }

    updateProductEmailDeliveryMessage(projectID, model) {
        return this.request('post', 'admin/products/updateProductEmailDeliveryMessage', { projectID, ...model });
    }

    updateProductGeneralSettings(projectID, request) {
        return this.request('post', 'admin/products/updateProductGeneralSettings', { projectID, ...request });
    }

    updateProductGroup(productGroup) {
        const params = {
            ...productGroup
        };

        return this.request('post', 'admin/products/updateProductGroup', params);
    }

    updateProductGroupDiscount(projectID, productGroupDiscountID, productGroupID,
        userGroupID, discountRatio) {
        const params = {
            projectID,
            productGroupDiscountID,
            productGroupID,
            userGroupID,
            discountRatio
        };

        return this.request('post', 'admin/products/updateProductGroupDiscount', params);
    }

    updateProductOption(productID, option) {
        return this.request('post', 'admin/products/updateProductOption', { productID, ...option });
    }

    updateProductOptionValue(productID, value) {
        return this.request('post', 'admin/products/updateProductOptionValue', { productID, ...value });
    }

    updateProductSetItem(productSetItem, projectID) {
        const params = {
            productSetItemID: productSetItem.productSetItemID,
            setItemID: productSetItem.setItemID,
            quantity: productSetItem.quantity,
            projectID
        };

        return this.request('post', 'admin/products/updateProductSetItem', params);
    }

    updateProductShippingConfiguration(projectID, shippingConfiguration) {
        const params = {
            ...shippingConfiguration,
            projectID
        };

        return this.request('post', 'admin/products/updateProductShippingConfiguration', params);
    }

    updateProductSkuMapping(projectID, productSkuMapping) {
        return this.request('post', 'admin/products/updateProductSkuMapping', { projectID, productSkuMapping });
    }

    updateRewardGeneralSettings(projectID, model) {
        const params = {
            projectID,
            ...model
        };

        return this.request('post', 'admin/products/updateRewardGeneralSettings', params);
    }

    updateRewardSections(projectID, rewards) {
        const params = {
            projectID,
            rewards
        };

        return this.request('post', 'admin/products/updateRewardSections', params);
    }
}