import lazyScreen from '@/Components/Admin/LazyScreen';

const PaymentsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-orders' */ './PaymentsListScreen.vue'));

const routes = [
    { path: '/payments/:creator?/:project?/list', name: 'orders-payments', component: PaymentsListScreen },
];

export {
    PaymentsListScreen,
    routes
};