var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._t("label")]
              },
              proxy: true,
            },
            {
              key: "field-description",
              fn: function () {
                return [_vm._t("description")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      _vm._v(" "),
      !_vm.readOnly && _vm.type === "text"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            ref: "input",
            class: _vm.classes,
            attrs: {
              type: "text",
              lang: _vm.langCode,
              autocomplete: _vm.autoComplete,
              name: _vm.name,
              placeholder: _vm.placeholder,
              disabled: _vm.disabled,
              spellcheck: !_vm.conceal,
              maxlength: _vm.maxlength,
              "data-qa": "text-field-input",
            },
            domProps: { value: _vm.model },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.model = $event.target.value
                },
                _vm.onInput,
              ],
              keyup: _vm.onKeyUp,
              blur: _vm.onBlur,
              focus: _vm.onFocus,
              keydown: _vm.onKeydown,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.readOnly && _vm.type === "password"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            ref: "input",
            class: _vm.classes,
            attrs: {
              type: "password",
              lang: _vm.langCode,
              name: _vm.name,
              autocomplete: _vm.autoComplete,
              placeholder: _vm.placeholder,
              disabled: _vm.disabled,
              spellcheck: !_vm.conceal,
              maxlength: _vm.maxlength,
              "data-qa": "text-field-input",
            },
            domProps: { value: _vm.model },
            on: {
              keyup: _vm.onKeyUp,
              blur: _vm.onBlur,
              focus: _vm.onFocus,
              keydown: _vm.onKeydown,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.model = $event.target.value
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.model && _vm.readOnly
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            ref: "input",
            class: _vm.classes,
            attrs: {
              type: "text",
              lang: _vm.langCode,
              readonly: "readonly",
              name: _vm.name,
              spellcheck: !_vm.conceal,
              maxlength: _vm.maxlength,
              "data-qa": "text-field-input",
            },
            domProps: { value: _vm.model },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.model = $event.target.value
              },
            },
          })
        : _vm.readOnly
        ? _c(
            "span",
            {
              class: [
                "gfu-field__input gfu-field__input--faux-expanded gfu-field__input--readonly",
                { "gfu-field__input--small": _vm.small },
                { "gfu-field__input--conceal": _vm.conceal },
              ],
              attrs: { readonly: "readonly", "data-qa": "text-field-input" },
            },
            [
              _vm._t("default", function () {
                return [_vm._v(_vm._s(_vm.readOnlyPlaceholder))]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }