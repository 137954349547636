var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-become-creator-section" },
    [
      _c("div", { staticClass: "gfu-become-creator-values" }, [
        _c(
          "div",
          {
            staticClass:
              "gfu-layout-wrapper gfu-layout-wrapper--extranarrow _pt-4",
          },
          [
            _c("become-a-creator-values-title"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "_flexwrap _tac _jc-c" },
              _vm._l(_vm.benefits, function (value) {
                return _c(
                  "values-section-item",
                  _vm._b(
                    {
                      key: value.title,
                      staticClass: "gfu-1of2 gfu-1of4--m _pa-4",
                    },
                    "values-section-item",
                    value,
                    false
                  )
                )
              }),
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "_pa-4 _tac" }, [
              _c(
                "a",
                {
                  staticClass: "gfu-btn gfu-btn--primary gfu-btn--soft _ttu",
                  attrs: { href: _vm.becomeACreatorUrl },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.Resources.Homepage.BecomeACreatorButton) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "become-a-creator-stats",
        _vm._b(
          { attrs: { layout: "extranarrow", theme: "accent" } },
          "become-a-creator-stats",
          _vm.creatorStatistics,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }