var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-header__actions gfu-navbar-nav",
      attrs: { "data-qa": "navbar-header-actions" },
    },
    [
      _vm.isCurrencySelectorVisible
        ? _c(
            "div",
            {
              staticClass: "gfu-navbar-item _screen--none _screen--flex-m",
              attrs: { "data-qa": "header-actions:CurrencySelector" },
            },
            [
              _c("currency-selector", {
                staticClass: "gfu-navbar-btn gfu-navbar-btn--header",
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAdminInterfaceSwitchVisible
        ? _c("admin-interface-switch")
        : _vm._e(),
      _vm._v(" "),
      _vm.isLanguageSelectorVisible ? _c("language-selector") : _vm._e(),
      _vm._v(" "),
      _c("cart-box"),
      _vm._v(" "),
      _c("user-box"),
      _vm._v(" "),
      _c("mobile-menu"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }