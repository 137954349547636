var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-navbar-item" }, [
    _c(
      "button",
      {
        staticClass: "gfu-navbar-btn gfu-navbar-btn--header",
        attrs: { type: "button", "data-qa": "admin-interface-switch" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.toggle.apply(null, arguments)
          },
        },
      },
      [
        _c("span", {
          class: [
            "gfu-icon gfu-icon--nofx gfu-icon--big _fa _fa--tools",
            { "_tc--accent": _vm.isAdminModeEnabled },
          ],
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }