import lazyScreen from '@/Components/Admin/LazyScreen';

const BulkNotificationsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-notifications' */ './BulkNotificationsListScreen.vue'));
const BulkNotificationSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-notifications' */ './BulkNotificationSummaryScreen.vue'));

const routes = [
    { path: '/system/bulk-notifications', name: 'bulk-notifications', component: BulkNotificationsListScreen },
    { path: '/system/bulk-notifications/:bulkNotificationId/summary', name: 'bulk-notification-summary', component: BulkNotificationSummaryScreen, props: route => ({ bulkNotificationId: parseInt(route.params.bulkNotificationId) }) }
];

export {
    BulkNotificationsListScreen,
    BulkNotificationSummaryScreen,
    routes
};
