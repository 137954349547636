import lazyScreen from '@/Components/Admin/LazyScreen';

const ProjectSettingsSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectSettingsSideNav.vue'));
const ProjectCommunicationSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectCommunicationSideNav.vue'));

const ProjectSetupWizard = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectSetupWizard/ProjectSetupWizard.vue'));

const ProjectNavigationMissing = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectNavigationMissing.vue'));
const ProjectGeneralSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectGeneralSettingsScreen.vue'));
const ProjectAboutSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectAboutSettingsScreen.vue'));
const ProjectGallerySettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectGallerySettingsScreen.vue'));
const ProjectDescriptionSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectDescriptionSettingsScreen.vue'));
const ProjectPaymentSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectPaymentSettingsScreen.vue'));
const AdyenPaymentSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './AdyenPaymentSettingsScreen.vue'));
const ProjectCommunicationSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectCommunicationSettingsScreen.vue'));
const ProjectAnalyticsSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectAnalyticsSettingsScreen.vue'));
const ProjectInvoicingSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectInvoicingSettingsScreen.vue'));
const LegacyProjectOrderSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './LegacyProjectOrderSettingsScreen.vue'));
const ProjectOrderSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectOrderSettingsScreen.vue'));
const ProjectInstallmentsSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project-installments' */ './Installments/ProjectInstallmentsSettingsScreen.vue'));
const ProjectSubLocationsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectSubLocationsListScreen.vue'));
const ProjectSiteAdminSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectSiteAdminSettingsScreen.vue'));

const ProjectFAQSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectFAQSettingsScreen.vue'));
const ProjectUpdatesListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectUpdatesListScreen.vue'));
const ProjectUpdateSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectUpdateSummaryScreen.vue'));

const ProjectLegacyReferralLinkListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectLegacyReferralLinkListScreen.vue'));
const ProjectReferralLinkListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectReferralLinkListScreen.vue'));

const ProjectDashboardManager = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './Dashboards/ProjectDashboardManager.vue'));
const PromoItemsSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './PromoItemsSettingsScreen.vue'));

const ProjectPollSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ '../Polls/ProjectPollSettingsScreen.vue'));
const ProjectPollsSettingsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ '../Polls/ProjectPollsSettingsListScreen.vue'));

const ProjectTranslationsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectTranslationsScreen.vue'));
const CommentsManagementListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-creator' */ '@/Components/Admin/Comments/CommentsManagementListScreen.vue'));

const routes = [
    { path: '/creator/:creator/projects/create', name: 'creator-project-create', component: ProjectSetupWizard },
    { path: '/:creator/projects/create', redirect: { name: 'creator-project-create' } },
    { path: '/:creator/:project/settings', redirect: { name: 'project-settings-general' } },
    {
        path: '/project/:creator/:project/dashboard',
        name: 'project-dashboard',
        components: { default: ProjectDashboardManager },
        meta: { isScreenBarHidden: true }
    },
    { path: '/project/:creator/:project/navigation-missing', name: 'project-navigation-missing', components: { default: ProjectNavigationMissing } },

    { path: '/project/:creator/:project/legacyreferrallinks', name: 'project-legacy-referral-links', components: { default: ProjectLegacyReferralLinkListScreen } },
    { path: '/project/:creator/:project/referrallinks', name: 'project-referral-links', components: { default: ProjectReferralLinkListScreen } },

    { path: '/project-settings/:creator/:project/general', name: 'project-settings-general', components: { default: ProjectGeneralSettingsScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/general', redirect: { name: 'project-settings-general' } },

    { path: '/project-settings/:creator/:project/about', name: 'project-settings-about', components: { default: ProjectAboutSettingsScreen, sidenav: ProjectSettingsSideNav } },

    { path: '/project-settings/:creator/:project/gallery', name: 'project-settings-gallery', components: { default: ProjectGallerySettingsScreen, sidenav: ProjectSettingsSideNav } },

    { path: '/project-settings/:creator/:project/description', name: 'project-settings-description', components: { default: ProjectDescriptionSettingsScreen, sidenav: ProjectSettingsSideNav } },

    { path: '/project-settings/:creator/:project/gifts', name: 'project-settings-gifts', components: { default: PromoItemsSettingsScreen, sidenav: ProjectSettingsSideNav } },

    { path: '/project-settings/:creator/:project/faq', redirect: { name: 'project-communication-faq' } },
    { path: '/project-communication/:creator/:project/faq', name: 'project-communication-faq', components: { default: ProjectFAQSettingsScreen, sidenav: ProjectCommunicationSideNav } },
    {
        path: '/project-communication/:creator/:project/updates',
        name: 'project-communication-updates',
        components: { default: ProjectUpdatesListScreen, sidenav: ProjectCommunicationSideNav }
    },
    {
        path: '/project-communication/:creator/:project/updates/:projectUpdateID/summary',
        name: 'project-update-summary',
        components: { default: ProjectUpdateSummaryScreen, sidenav: ProjectCommunicationSideNav },
        props: { default: route => ({ projectUpdateId: parseInt(route.params.projectUpdateID) }) }
    },
    {
        path: '/project-communication/:creator/:project/updates/create',
        name: 'project-update-create',
        components: { default: ProjectUpdateSummaryScreen, sidenav: ProjectCommunicationSideNav },
        props: { default: { projectUpdateId: 0 } }
    },

    { path: '/project-settings/:creator/:project/site-admin', name: 'project-settings-site-admin', components: { default: ProjectSiteAdminSettingsScreen, sidenav: ProjectSettingsSideNav } },

    { path: '/project-settings/:creator/:project/crowdfunding-payments', name: 'project-settings-crowdfunding-payments', components: { default: AdyenPaymentSettingsScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project-settings/:creator/:project/payments', name: 'project-settings-payments', components: { default: ProjectPaymentSettingsScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/payments', redirect: { name: 'project-settings-payments' } },

    { path: '/project-settings/:creator/:project/communication', name: 'project-settings-communication', components: { default: ProjectCommunicationSettingsScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/communication', redirect: { name: 'project-settings-communication' } },

    { path: '/project/:creator/:project/comments', name: 'project-comments', components: { default: CommentsManagementListScreen } },

    { path: '/project-communication/:creator/:project/comments', name: 'project-communication-comments', components: { default: CommentsManagementListScreen, sidenav: ProjectCommunicationSideNav } },

    { path: '/project-settings/:creator/:project/analytics', name: 'project-settings-analytics', components: { default: ProjectAnalyticsSettingsScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/analytics', redirect: { name: 'project-settings-analytics' } },

    { path: '/project-settings/:creator/:project/invoices', name: 'project-settings-invoices', components: { default: ProjectInvoicingSettingsScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/invoices', redirect: { name: 'project-settings-invoices' } },

    { path: '/project-settings/:creator?/:project?/orders/legacy', name: 'legacy-project-settings-orders', components: { default: LegacyProjectOrderSettingsScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator?/:project?/orders/legacy', redirect: { name: 'legacy-project-settings-orders' } },
    { path: '/:creator?/:project?/orders/legacy', redirect: { name: 'legacy-project-settings-orders' } },

    { path: '/project-settings/:creator?/:project?/orders', name: 'project-settings-orders', components: { default: ProjectOrderSettingsScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator?/:project?/orders', redirect: { name: 'project-settings-orders' } },
    { path: '/project-settings/:creator?/:project?/stretch-pay', name: 'project-settings-stretch-pay', components: { default: ProjectInstallmentsSettingsScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/:creator?/:project?/orders', redirect: { name: 'project-settings-orders' } },

    { path: '/project-settings/:creator/:project/shipping/sublocations', name: 'project-settings-shipping-sublocations', components: { default: ProjectSubLocationsListScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/shipping/sublocations', redirect: { name: 'project-settings-shipping-sublocations' } },
    { path: '/:creator?/:project?/sub-locations', redirect: { name: 'project-settings-shipping-sublocations' } },
    {
        path: '/project-communication/:creator/:project/polls',
        name: 'project-communication-polls',
        components: { default: ProjectPollsSettingsListScreen, sidenav: ProjectCommunicationSideNav }
    },
    {
        path: '/project-communication/:creator/:project/polls/new',
        name: 'project-polls-new',
        components: { default: ProjectPollSettingsScreen, sidenav: ProjectCommunicationSideNav },
        props: { default: { pollID: 0 } }
    },
    {
        path: '/project-communication/:creator/:project/polls/:pollID',
        name: 'project-polls-settings',
        components: { default: ProjectPollSettingsScreen, sidenav: ProjectCommunicationSideNav },
        props: { default: route => ({ pollID: parseInt(route.params.pollID) }) }
    },
    {
        path: '/project-settings/:creator/:project/translations',
        name: 'project-translations',
        components: { default: ProjectTranslationsScreen, sidenav: ProjectSettingsSideNav },
    }
];

export {
    routes
};
