import FileSaver from 'file-saver';

export default {
    saveFileFromApiResponse(response) {
        const fileName = response.headers['content-disposition'].match(/filename=(.+)$/i)[1];
        FileSaver.saveAs(response.data, fileName);
    },
    saveFileFromApiFile(file) {
        const blob = this.b64toBlob(file.fileBytes, file.contentType);
        FileSaver.saveAs(blob, file.fileName);
    },

    /**
     * @param {any} b64Data
     * @param {any} contentType
     * @param {any} sliceSize
     */
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
};