import { parseJSON } from '@/Utils/JsonUtility';

export const storageTypes = {
    localStorage: 'localStorage',
    sessionStorage: 'sessionStorage'
};

export default class WebStorage {
    constructor(key, storageType = 'localStorage') {
        if (!key) {
            throw new Error('Missing \'key\' argument');
        }

        this.key = key;

        if (!storageTypes[storageType]) {
            throw new Error('Wrong storage type.');
        }

        this.storageType = storageType;
    }

    get() {
        try {
            const value = window[this.storageType].getItem(this.key) ?? '';
            return parseJSON(value);
        }
        catch (e) {
            return null;
        }
    }

    set(value) {
        const strValue = JSON.stringify(value);
        window[this.storageType].setItem(this.key, strValue);
    }

    remove() {
        window[this.storageType].removeItem(this.key);
    }
}