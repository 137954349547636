var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["gfua-screen", { "gfua-screen--fill": _vm.fill }] },
    [
      _vm.isStacked
        ? _c(
            "div",
            {
              staticClass:
                "gfua-screen__navigation gfua-screen__navigation--top",
            },
            [
              _vm.hasParentScreen
                ? _c(
                    "a",
                    {
                      staticClass:
                        "gfu-btn gfu-btn--end gfu-btn--square gfu-btn--gray-alt",
                      attrs: { href: "", "data-qa": "page-button:Return" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.goToParentScreen.apply(null, arguments)
                        },
                      },
                    },
                    [_c("span", { staticClass: "_fa _fa _fa--chevron-left" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.hasParentScreen
                ? _c(
                    "a",
                    {
                      staticClass:
                        "gfu-btn gfu-btn--end gfu-btn--square gfu-btn--gray-alt",
                      attrs: { href: "", "data-qa": "page-button:Close" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.close.apply(null, arguments)
                        },
                      },
                    },
                    [_c("span", { staticClass: "_fa _fa _fa--window-close" })]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: {
          visible: _vm.isProcessing,
          sticky: true,
          message: _vm.processingMessage,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.screenWrapperClasses },
        [
          _vm._t("header", function () {
            return [
              _vm.title || _vm.iconClass
                ? _c("div", { staticClass: "gfua-screen-header" }, [
                    _c(
                      "div",
                      { staticClass: "gfua-screen-header__title" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "_flex _ai-c",
                            attrs: { "data-qa": "page-title" },
                          },
                          [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "gfu-heading gfu-heading--main gfu-heading--inline",
                              },
                              [
                                _c("span", { class: _vm.iconClass }),
                                _vm._v(" " + _vm._s(_vm.title)),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.subtitle
                              ? _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "gfu-subheading gfu-subheading--subtle gfu-subheading--inline",
                                  },
                                  [_vm._v(_vm._s(_vm.subtitle))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasLabel
                              ? _vm._l(_vm.labelsModel, function (label) {
                                  return _c(
                                    "icon-base-label",
                                    _vm._b(
                                      {
                                        key: label.key,
                                        staticClass: "_ml-1",
                                        attrs: { "data-qa": label.dataQa },
                                      },
                                      "icon-base-label",
                                      label,
                                      false
                                    )
                                  )
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasBadgesSlot ? _vm._t("badges") : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm._t("title-additional-info"),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "gfua-screen-header__actions" },
                      [_vm._t("actions")],
                      2
                    ),
                  ])
                : _vm._e(),
            ]
          }),
          _vm._v(" "),
          _vm.notificatorMessage
            ? _c(
                "base-notificator",
                {
                  staticClass: "_mb-2",
                  attrs: { type: "info", "can-close": false },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.notificatorMessage) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasStickyBar ? _c("div", [_vm._t("sticky-bar")], 2) : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gfua-screen__content",
              class: { "gfua-screen__content--full-width": _vm.fullWidth },
            },
            [_vm._t("default")],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gfua-screen__footer",
              attrs: { "data-qa": "screen-footer" },
            },
            [_vm._t("footer")],
            2
          ),
          _vm._v(" "),
          _vm.hasScrollbar
            ? _c("div", { staticClass: "gfua-screen__navigation" }, [
                _c("span", { staticClass: "_pr-1" }, [_vm._v("scroll top")]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "gfu-btn gfu-btn--top gfu-btn--square gfu-btn--gray-alt",
                    attrs: { href: "" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.scrollTop.apply(null, arguments)
                      },
                    },
                  },
                  [_c("span", { staticClass: "_fa _fa--chevron-up" })]
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }