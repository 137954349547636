
    export default {
        props: {
            description: { type: String, default: null },
            showDivider: { type: Boolean, default: true },
            title: { type: String, default: null }
        },
        functional: true,
        render(createElement, { props, children, data }) {
            const elements = [];

            if (props.showDivider) {
                elements.push(createElement('div', { class: 'gfu-hr' }));
            }
            if (props.title) {
                elements.push(createElement('span', { class: 'gfu-hd gfu-hd--h1' }, props.title));
            }
            if (props.description) {
                elements.push(createElement('span', { class: 'gfu-bt gfu-bt--b2 _mt-3 _mb-3' }, props.description));
            }
            elements.push(children);

            return createElement(
                'div',
                { class: ['gfu-empty-state', data.class, data.staticClass], attrs: data.attrs },
                elements);
        },
    };
