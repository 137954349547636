var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.rewards, function (item) {
        return _c(
          "mini-wizard-item",
          _vm._b(
            {
              key: item.orderItemID,
              on: {
                delete: function ($event) {
                  return _vm.deleteMethod(item)
                },
              },
            },
            "mini-wizard-item",
            _vm.getItemModel(item, _vm.productLockReason(item)),
            false
          )
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.addons, function (item) {
        return _c(
          "mini-wizard-item",
          _vm._b(
            {
              key: item.orderItemID,
              on: {
                delete: function ($event) {
                  return _vm.deleteMethod(item)
                },
              },
            },
            "mini-wizard-item",
            _vm.getItemModel(item, _vm.productLockReason(item)),
            false
          )
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.tips, function (item) {
        return _c(
          "mini-wizard-item",
          _vm._b(
            {
              key: item.orderItemID,
              on: {
                delete: function ($event) {
                  return _vm.deleteMethod(item)
                },
              },
            },
            "mini-wizard-item",
            _vm.getItemModel(
              item,
              "You can't remove paid tips.",
              _vm.formatAmount(item.netPriceConverted)
            ),
            false
          )
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.discounts, function (item) {
        return _c(
          "mini-wizard-item",
          _vm._b(
            {
              key: item.orderItemID,
              on: {
                delete: function ($event) {
                  return _vm.deleteMethod(item)
                },
              },
            },
            "mini-wizard-item",
            _vm.getItemModel(
              item,
              "You can't remove discount.",
              _vm.formatAmount(item.netPriceConverted),
              item.orderItemTypeName
            ),
            false
          )
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }