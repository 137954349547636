import Vue from 'vue';
import { PiniaVuePlugin } from 'pinia';
import VeeValidate from 'vee-validate';

import { apiClient } from '@/Clients/ApiClient';
import Resources from '@/Resources.js';
import Formatter from '@/Utils/Formatter';
import notify from '@/Plugins/Notify.js';

import ToastWrapper from '@/Components/ToastWrapper.vue';
import Localize from '@/Components/Utils/Localize.js';

import HelpUrls from '@/Data/HelpUrls.json';

window.Vue = Vue;
window.Vue.config.productionTip = false;
window.VeeValidate = VeeValidate;

// Add pinia to all Vue instances
Vue.use(PiniaVuePlugin);

// Add VeeValidate to all Vue instances
Vue.use(VeeValidate);

// Add $http to all Vue instances
Vue.use((Vue) => {
    Object.defineProperties(Vue.prototype, {
        $http: {
            get() {
                return apiClient;
            },
        },
    });
});

// Add Resources to all Vue instances
Vue.use((Vue) => {
    Object.defineProperties(Vue.prototype, {
        Resources: {
            get() {
                return Resources;
            },
        },
    });
});

// Add HelpUrls to all Vue instances
Vue.use((Vue) => {
    Object.defineProperties(Vue.prototype, {
        $helpUrls: {
            get() {
                return HelpUrls;
            },
        },
    });
});

// Add localize component as global component
Vue.component('localize', Localize);

// Add $format to all Vue instances
Vue.use((Vue) => {
    Object.defineProperties(Vue.prototype, {
        $format: {
            get() {
                return Formatter.format;
            },
        },
    });
});

// Add $notify to all Vue instances
Vue.use(notify, { extensionName: '$notify', components: [ToastWrapper] });
