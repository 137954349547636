import { defineStore } from 'pinia';

import ProjectsService from '@/Services/ProjectsService';

import { apiClient } from '@/Clients/ApiClient.js';

export const useProjectAdditionalInfoStore = defineStore({
    id: 'projectAdditionalInfo',
    state: () => ({
        // TODO: Rewrite projectAdditionalInfo to something smaller
        projectAdditionalInfo: null
    }),
    actions: {
        loadProjectAdditionalInfo(projectContextId) {
            if (this.projectAdditionalInfo === null || this.projectAdditionalInfo.projectID !== projectContextId) {
                return this.refreshProjectAdditionalInfo(projectContextId);
            }

            return Promise.resolve();
        },
        async refreshProjectAdditionalInfo(projectContextId) {
            try {
                const response = await new ProjectsService(apiClient).getProjectAdditionalInfo(projectContextId);

                this.projectAdditionalInfo = response.data;

                return response;
            }
            catch (error) {
                throw new Error(error);
            }
        },
    }
});