import Vuex from 'vuex';
import InitialState from '@/InitialState';
import CartsService from '@/Services/CartsService';
import { projectVersions } from '@/Models';
import { apiClient } from '@/Clients/ApiClient.js';
import { useCartStore } from '@/Stores/Web/cartStore.js';
import { useStrechGoalsStore } from '@/Stores/strechGoalsStore.js';
import { useCartSummaryStore } from '@/Stores/cartSummaryStore';
import { useProjectContextStore } from '@/Stores/Web/projectContextStore';

const store = new Vuex.Store({
    modules: {
        web: {
            state: {
                projectUserContext: {
                    projectID: null,
                    userID: null,
                    creditBalance: 0,
                    backerNumber: 0,
                    hasOrderAwaitingPayment: false,
                    relationType: null
                },
                crowdfundingProjectStatistics: InitialState.projectState?.statistics || {},
                crowdfundingProjectFacts: InitialState.projectState?.facts || {},
                orderAwaitingPayment: null
            },
            namespaced: true,
            mutations: {
                updateProjectStatistics(state, projectStateStatistics) {
                    state.crowdfundingProjectStatistics = projectStateStatistics;

                    const strechGoalsStore = useStrechGoalsStore();
                    if (strechGoalsStore.hasStrechGoals) {
                        strechGoalsStore.updateStretchGoalsProgress(projectStateStatistics);
                    }
                },
                setProjectUserContext(state, { projectID, userID, creditBalance, backerNumber, hasOrderAwaitingPayment, relationType }) {
                    state.projectUserContext = { projectID, userID, creditBalance, backerNumber, hasOrderAwaitingPayment, relationType };
                },
                setOrderAwaitingPayment(state, orderAwaitingPayment) {
                    state.orderAwaitingPayment = orderAwaitingPayment;
                },
            },
            actions: {
                initialize({ dispatch, state }) {
                    const cartStore = useCartStore();
                    const cartSummaryStore = useCartSummaryStore();
                    const projectContextStore = useProjectContextStore();

                    return Promise.all([
                        projectContextStore.loadProjectContext(),
                        cartSummaryStore.loadCartSummary(projectContextStore.projectContext.projectID, cartStore.preventCartLoading)
                    ]);
                },
                loadProjectUserContext({ commit, dispatch }, payload) {
                    const projectContextStore = useProjectContextStore();

                    commit('setProjectUserContext', payload);
                    const projectContext = projectContextStore.projectContext;

                    if (payload?.hasOrderAwaitingPayment && projectContext.project?.version === projectVersions.v2) {
                        return dispatch('loadOrderAwaitingPayment');
                    }
                    return Promise.resolve(true);
                },
                loadOrderAwaitingPayment({ commit }) {
                    const projectContextStore = useProjectContextStore();
                    const projectContext = projectContextStore.projectContext;

                    return new CartsService(apiClient).getOrderAwaitingPayment(projectContext.projectID)
                        .then(response => {
                            if (response.data) {
                                commit('setOrderAwaitingPayment', response.data);
                            }
                        });
                },
            }
        }
    },
    state: {
        initializePromise: null
    },
    mutations: {
        updateInitializePromise(state, initializePromise) {
            state.initializePromise = initializePromise;
        }
    },
    actions: {
        initialize({ commit, dispatch, state }) {
            if (state.initializePromise !== null) {
                return state.initializePromise;
            }

            const moduleInitializePromises = [];

            for (const [moduleName] of Object.entries(this._modulesNamespaceMap)) {
                moduleInitializePromises.push(dispatch(`${ moduleName }initialize`));
            }

            const initializePromise = Promise.all(moduleInitializePromises);
            commit('updateInitializePromise', initializePromise);

            return initializePromise;
        }
    }
});

export default store;
