import lazyScreen from '@/Components/Admin/LazyScreen';

// TODO: This should be loaded as lazy but it caused errors: task #6380

import ProductCategoriesSideNav from './ProductCategoriesSideNav.vue';
import ProductSettingsSideNav from './ProductSettingsSideNav.vue';
import RewardSideNav from './RewardSideNav.vue';

const ProductGeneralSettingsScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductGeneralSettingsScreen.vue'));
const ProductDescriptionSettingsScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductDescriptionSettingsScreen.vue'));
const ProductDigitalDeliverySettingsScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductDigitalDeliverySettingsScreen.vue'));
const ProductGallerySettingsScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductGallerySettingsScreen.vue'));
const ProductShippingSettingsScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductShippingSettingsScreen.vue'));
const ProductGroupsListScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductGroupsListScreen.vue'));
const ProductGroupSummaryScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductGroupSummaryScreen.vue'));
const ProductsSkuMappingsListScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductsSkuMappingsListScreen.vue'));
const ProductCategoryDetailsScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductCategoryDetailsScreen.vue'));
const ProductDigitalCodesListScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductDigitalCodesListScreen.vue'));
const ProductsListScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductsListScreen.vue'));
const RewardGeneralSettingsScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './RewardGeneralSettingsScreen.vue'));
const ProductSetItemsScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductSetItemsScreen.vue'));
const ProductOptionsScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductOptionsScreen.vue'));
const ProjectRewardsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */ './ProjectRewardsListScreen.vue'));
const ProjectRewardsAdvancedSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */ './ProjectRewardsAdvancedSettingsScreen.vue'));
const ProductAvailabilitySettingsScreen  = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-products' */  './ProductAvailabilitySettingsScreen.vue'));

const focusDefaultProps = route => ({
    focus: true
});

const productSideNavDefaultProps = route => ({
    productId: parseInt(route.params.productId),
    goBackAction: { name: 'products-list' }
});
const focusProductSideNavDefaultProps = route => ({
    focus: true,
    productId: parseInt(route.params.productId),
    goBackAction: { name: 'products-list-focus' }
});
const productSettingsDefaultProps = route => ({
    productId: parseInt(route.params.productId)
});
const focusProductSettingsDefaultProps = route => ({
    focus: true,
    ...productSettingsDefaultProps(route)
});

const routes = [
    {
        path: '/products/:creator/:project/category/:categoryId?/settings',
        name: 'products-category',
        components: { default: ProductCategoryDetailsScreen, sidenav: ProductCategoriesSideNav },
        props: {
            default: route => ({ categoryId: parseInt(route.params.categoryId) }),
            sidenav: route => ({ categoryId: parseInt(route.params.categoryId) })
        }
    },
    {
        path: '/products/:creator/:project/category/:categoryId?/settings/focus',
        name: 'products-category-focus',
        components: { default: ProductCategoryDetailsScreen, sidenav: ProductCategoriesSideNav },
        props: {
            default: route => ({ categoryId: parseInt(route.params.categoryId) }),
            sidenav: route => ({
                categoryId: parseInt(route.params.categoryId),
                ...focusDefaultProps(route)
            })
        }
    },
    {
        path: '/products/:creator/:project/category/:categoryId?',
        name: 'products-list',
        components: { default: ProductsListScreen, sidenav: ProductCategoriesSideNav },
        props: {
            default: route => ({ categoryId: parseInt(route.params.categoryId) }),
            sidenav: route => ({ categoryId: parseInt(route.params.categoryId) })
        }
    },
    {
        path: '/products/:creator/:project/category/:categoryId/focus',
        name: 'products-list-focus',
        components: { default: ProductsListScreen, sidenav: ProductCategoriesSideNav },
        props: {
            default: route => ({ categoryId: parseInt(route.params.categoryId) }),
            sidenav: route => ({
                categoryId: parseInt(route.params.categoryId),
                ...focusDefaultProps(route)
            })
        }
    },
    { path: '/:creator/:project/products-and-categories/:categoryId?', redirect: { name: 'products-list' } },

    {
        path: '/products/:creator/:project/products/:productId/summary/:categoryId?',
        name: 'product-summary',
        components: { default: ProductGeneralSettingsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => productSettingsDefaultProps(route),
            sidenav: route => productSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/summary/focus/:categoryId?',
        name: 'product-summary-focus',
        components: { default: ProductGeneralSettingsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => focusProductSettingsDefaultProps(route),
            sidenav: route => focusProductSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/description',
        name: 'product-description',
        components: { default: ProductDescriptionSettingsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => productSettingsDefaultProps(route),
            sidenav: route => productSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/description/focus',
        name: 'product-description-focus',
        components: { default: ProductDescriptionSettingsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => focusProductSettingsDefaultProps(route),
            sidenav: route => focusProductSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/gallery',
        name: 'product-gallery',
        components: { default: ProductGallerySettingsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => productSettingsDefaultProps(route),
            sidenav: route => productSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/gallery/focus',
        name: 'product-gallery-focus',
        components: { default: ProductGallerySettingsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => focusProductSettingsDefaultProps(route),
            sidenav: route => focusProductSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/setitems',
        name: 'product-set-items',
        components: { default: ProductSetItemsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => productSettingsDefaultProps(route),
            sidenav: route => productSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/setitems/focus',
        name: 'product-set-items-focus',
        components: { default: ProductSetItemsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => focusProductSettingsDefaultProps(route),
            sidenav: route => focusProductSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/options',
        name: 'product-options',
        components: { default: ProductOptionsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => productSettingsDefaultProps(route),
            sidenav: route => productSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/options/focus',
        name: 'product-options-focus',
        components: { default: ProductOptionsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => focusProductSettingsDefaultProps(route),
            sidenav: route => focusProductSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/:productId/shipping',
        name: 'product-shipping',
        components: { default: ProductShippingSettingsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => productSettingsDefaultProps(route),
            sidenav: route => productSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/:productId/shipping/focus',
        name: 'product-shipping-focus',
        components: { default: ProductShippingSettingsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => focusProductSettingsDefaultProps(route),
            sidenav: route => focusProductSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/skuMappings',
        name: 'product-sku-mappings',
        components: { default: ProductsSkuMappingsListScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => productSettingsDefaultProps(route),
            sidenav: route => productSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/skuMappings/focus',
        name: 'product-sku-mappings-focus',
        components: { default: ProductsSkuMappingsListScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => focusProductSettingsDefaultProps(route),
            sidenav: route => focusProductSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/availability',
        name: 'product-availability-settings',
        components: { default: ProductAvailabilitySettingsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => productSettingsDefaultProps(route),
            sidenav: route => productSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/availability/focus',
        name: 'product-availability-settings-focus',
        components: { default: ProductAvailabilitySettingsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => focusProductSettingsDefaultProps(route),
            sidenav: route => focusProductSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/digital-stock',
        name: 'product-digital-stock',
        components: { default: ProductDigitalCodesListScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => productSettingsDefaultProps(route),
            sidenav: route => productSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/digital-stock/focus',
        name: 'product-digital-stock-focus',
        components: { default: ProductDigitalCodesListScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => focusProductSettingsDefaultProps(route),
            sidenav: route => focusProductSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/digital-delivery',
        name: 'product-digital-delivery',
        components: { default: ProductDigitalDeliverySettingsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => productSettingsDefaultProps(route),
            sidenav: route => productSideNavDefaultProps(route)
        }
    },
    {
        path: '/products/:creator/:project/products/:productId/digital-delivery/focus',
        name: 'product-digital-delivery-focus',
        components: { default: ProductDigitalDeliverySettingsScreen, sidenav: ProductSettingsSideNav },
        props: {
            default: route => focusProductSettingsDefaultProps(route),
            sidenav: route => focusProductSideNavDefaultProps(route)
        }
    },
    { path: '/:creator/:project/products/:productId/summary', redirect: { name: 'product-summary' } },
    { path: '/:creator/:project/products/:productId/skuMappings', redirect: { name: 'products-sku-mappings' } },
    { path: '/products/:creator/:project/productGroups', name: 'products-groups', component: ProductGroupsListScreen },
    { path: '/:creator/:project/productGroups', redirect: { name: 'products-groups' } },
    { path: '/products/:creator/:project/productGroups/:productGroupId/summary', name: 'products-group-summary', component: ProductGroupSummaryScreen, props: route => ({ productGroupId: parseInt(route.params.productGroupId) }) },
    { path: '/productGroups/:productGroupId/summary', redirect: { name: 'products-group-summary' } },
    {
        path: '/rewards/:creator/:project/new',
        name: 'rewards-new',
        components: { default: RewardGeneralSettingsScreen, sidenav: RewardSideNav },
        props: { default: route => ({ productId: 0 }), sidenav: route => ({ productId: 0 }) }
    },
    {
        path: '/rewards/:creator/:project/:productId/general',
        name: 'rewards-general',
        components: { default: RewardGeneralSettingsScreen, sidenav: RewardSideNav },
        props: { default: route => ({ productId: parseInt(route.params.productId) }), sidenav: route => ({ productId: parseInt(route.params.productId) }) }
    },
    {
        path: '/rewards/:creator/:project/:productId/items',
        name: 'rewards-items',
        components: { default: ProductSetItemsScreen, sidenav: RewardSideNav },
        props: { default: route => ({ productId: parseInt(route.params.productId), productTypeName: 'reward' }), sidenav: route => ({ productId: parseInt(route.params.productId) }) }
    },
    {
        path: '/rewards/:creator/:project/:productId/description',
        name: 'rewards-description',
        components: { default: ProductDescriptionSettingsScreen, sidenav: RewardSideNav },
        props: { default: route => ({ productId: parseInt(route.params.productId), productTypeName: 'reward' }), sidenav: route => ({ productId: parseInt(route.params.productId) }) }
    },
    {
        path: '/rewards/:creator/:project/:productId/gallery',
        name: 'rewards-gallery',
        components: { default: ProductGallerySettingsScreen, sidenav: RewardSideNav },
        props: { default: route => ({ productId: parseInt(route.params.productId), productTypeName: 'reward' }), sidenav: route => ({ productId: parseInt(route.params.productId) }) }
    },
    {
        path: '/rewards/:creator/:project/:productId/shipping',
        name: 'rewards-shipping',
        components: { default: ProductShippingSettingsScreen, sidenav: RewardSideNav },
        props: { default: route => ({ productId: parseInt(route.params.productId), productTypeName: 'reward' }), sidenav: route => ({ productId: parseInt(route.params.productId) }) }
    },
    {
        path: '/rewards/:creator/:project/:productId/availability',
        name: 'rewards-availability-settings',
        components: { default: ProductAvailabilitySettingsScreen, sidenav: RewardSideNav },
        props: { default: route => ({ productId: parseInt(route.params.productId), productTypeName: 'reward' }), sidenav: route => ({ productId: parseInt(route.params.productId) }) }
    },
    {
        path: '/products/:creator/:project/rewards',
        name: 'products-rewards',
        components: { default: ProjectRewardsListScreen }
    },
    {
        path: '/products/:creator/:project/rewards/advanced-settings',
        name: 'project-rewards-advanced-settings',
        components: { default: ProjectRewardsAdvancedSettingsScreen }
    },
];

export {
    ProductGroupsListScreen,
    ProductGroupSummaryScreen,
    ProductsSkuMappingsListScreen,
    routes
};