var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        title: "New products available",
        icon: "plus",
        "is-visible": _vm.isVisible,
        "is-processing": _vm.isProcessing,
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "icon-base-button",
                {
                  attrs: {
                    size: "default",
                    width: "compact",
                    "text-transform": "none",
                    wide: true,
                    bold: true,
                    theme: "accent",
                    icon: "check",
                    enabled: !_vm.isProcessing && _vm.startFromScratch !== null,
                    "data-qa": "modal-button:Continue",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Continue\n        ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(
        "\n\n    Pledge manager is now live and you can finish your pledge. We thought you might want to know that creators usually add new products to the pledge manager.\n    "
      ),
      _c(
        "div",
        { staticClass: "_mt-3 _mb-a" },
        [
          _c(
            "checkbox-field",
            {
              staticClass: "gfu-selection-wizard-checkbox",
              attrs: {
                "checked-value": false,
                condensed: true,
                name: "startFromScratch",
                type: "radio",
                "validator-layout": "none",
                "data-qa": "modal-checkbox:UseExistingPledge",
              },
              model: {
                value: _vm.startFromScratch,
                callback: function ($$v) {
                  _vm.startFromScratch = $$v
                },
                expression: "startFromScratch",
              },
            },
            [
              _c("span", { staticClass: "gfu-hd gfu-hd--h3 _flex" }, [
                _vm._v(
                  "\n                Use my existing pledge\n            "
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "gfu-bt gfu-bt--b2 _tc--light" }, [
                _vm._v(
                  "\n                You'll keep your original pledge but you can still edit it. You can add products to your pledge or proceed to shipping straight away.\n            "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_mt-3 _mb-a" },
        [
          _c(
            "checkbox-field",
            {
              staticClass: "gfu-selection-wizard-checkbox",
              attrs: {
                "checked-value": true,
                condensed: true,
                name: "startFromScratch",
                type: "radio",
                "validator-layout": "none",
                "data-qa": "modal-checkbox:StartFromScratch",
              },
              model: {
                value: _vm.startFromScratch,
                callback: function ($$v) {
                  _vm.startFromScratch = $$v
                },
                expression: "startFromScratch",
              },
            },
            [
              _c("span", { staticClass: "gfu-hd gfu-hd--h3 _flex" }, [
                _vm._v("\n                Start from scratch\n            "),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "gfu-bt gfu-bt--b2 _tc--light" }, [
                _vm._v(
                  "\n                You'll be able to compose your pledge from the updated product list. These changes can be reverted anytime you want.\n            "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }