var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-summary-list-item",
      attrs: { "data-qa": `summary-list-item:${_vm.title}` },
    },
    [
      _c(
        "div",
        { class: [{ _flex: _vm.hasTooltip }] },
        [
          _c("p", { staticClass: "_my-0" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _vm.hasTooltip
            ? _c("tooltip", {
                staticClass: "_ml-1",
                attrs: { text: _vm.tooltipText, "is-info-icon": true },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.hasSubtitle
            ? _c(
                "p",
                {
                  staticClass: "_my-0 gfu-summary-list-item__subtitle",
                  attrs: { "data-qa": "label" },
                },
                [
                  _vm._v(_vm._s(_vm.subtitle) + "\n            "),
                  _vm.hasSubtitleTooltip
                    ? _c("tooltip", {
                        attrs: {
                          "is-info-icon": true,
                          "data-qa": "taxrate-info-tooltip",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return _vm._l(
                                  _vm.subtitleTooltipContentRows,
                                  function (subtitleTooltipRow, index) {
                                    return _c("p", {
                                      key: index,
                                      attrs: {
                                        "data-qa": subtitleTooltipRow.dataQa,
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          subtitleTooltipRow.text
                                        ),
                                      },
                                    })
                                  }
                                )
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          901520800
                        ),
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          class: [
            "_my-0",
            "gfu-summary-list-item__value",
            { "gfu-summary-list-item__value--highlighted": _vm.isHighlighted },
            _vm.themeClass,
          ],
          attrs: { "data-qa": "value" },
        },
        [_vm._v(_vm._s(_vm.value))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }