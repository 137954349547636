import { defineStore } from 'pinia';
import CartsService from '@/Services/CartsService';
import PledgeService from '@/Services/PledgeService';
import { orderStages, paymentMethodReasons } from '@/Models.js';
import { apiClient } from '@/Clients/ApiClient';
import { useCurrencyStore } from '@/Stores/Currency/currencyStore';
import { useUserPledgesStore } from '@/Stores/userPledgesStore';
import { buildOrderSummaryModel } from '@/Components/Orders/OrderSummaryUtils';
import { buildAvailablePaymentMethods } from '@/Components/Payments/PaymentUtils';

import LocationsService from '@/Services/LocationsService';
import Formatter from '@/Utils/Formatter';

const pledgeService = new PledgeService(apiClient);
const cartsService = new CartsService(apiClient);
const locationsService = new LocationsService(apiClient);

const locationRequests = {};
const paymentMethodsRequests = {};

export const useCartsDetailsStore = defineStore({
    id: 'cartsDetails',
    state: () => {
        return {
            isReady: false,
            currentCartId: null,
            cartsDetails: {},
            orderSummaryListModel: {},
            disabledPaymentMethods: [],
            availablePaymentMethods: [],
            projectLocations: [],
            selectedPaymentMethod: null,
        };
    },
    actions: {
        async updateCartDetailsChange(model) {
            try {
                const { data } = await cartsService.getCartDetails(model);
                this.setCartDetails(data);
                const locations = await this.getProjectLocations(data.project.projectID);
                this.setOrderSummaryListModel(data, locations);

                return data;
            }
            catch (error) {
                throw error.message;
            }
        },
        setSelectedPaymentMethod(paymentDataSource) {
            this.selectedPaymentMethod = paymentDataSource;
        },
        setCartDetails(cartDetails) {
            const { orderID } = cartDetails.cart;
            this.currentCartId = orderID;
            this.cartsDetails = {
                ...this.cartsDetails,
                [orderID]: cartDetails
            };
        },
        setOrderSummaryListModel(model, locations) {
            const userPledgesStore = useUserPledgesStore();
            this.orderSummaryListModel = {
                ...this.orderSummaryListModel,
                [model.cart.orderID]: buildOrderSummaryModel(
                    model,
                    userPledgesStore.firstPledge,
                    locations
                )
            };
        },
        async getAvailablePaymentMethods(locationIsoCode = this.locationIsoCode, reason = paymentMethodReasons.unknown, projectID = this.projectID) {
            const hasOrderCode = this.getCurrentCartDetails()?.cart?.orderStage !== orderStages.unfinished
                || this.getCurrentCartDetails()?.cart?.parentOrder !== null;

            const { data } = await this.loadAvailablePaymentMethods({
                currencyShortName: this.currencyShortName,
                projectID,
                locationIsoCode,
                orderCode: hasOrderCode ? this.currentOrderCode : null,
                reason
            });

            this.setAvailablePaymentMethods(buildAvailablePaymentMethods(data, this.disabledPaymentMethods));
        },
        async getProjectLocations(projectID) {
            // If the locations are already loaded, return them.
            if (locationRequests[projectID]) {
                const response = await locationRequests[projectID];
                return response.data;
            }

            const requestPromise = locationsService.getProjectLocations(projectID);
            locationRequests[projectID] = requestPromise;
            const response = await requestPromise;
            this.projectLocations = response.data;

            return this.projectLocations;
        },
        async loadAvailablePaymentMethods(params) {
            const paramsKey = JSON.stringify(params);
            // If the payment methods are already loaded, return them.
            if (paymentMethodsRequests[paramsKey]) {
                return await paymentMethodsRequests[paramsKey];
            }
            const requestPromise = pledgeService.getAvailablePaymentMethods(params);
            paymentMethodsRequests[paramsKey] = requestPromise;
            return await requestPromise;
        },
        setDisabledPaymentMethods(disabledPaymentMethods = []) {
            this.disabledPaymentMethods = disabledPaymentMethods;
        },
        setAvailablePaymentMethods(paymentMethods) {
            // If the selected payment method is not available, reset it.
            if (paymentMethods.length === 1) {
                this.setSelectedPaymentMethod(paymentMethods[0].paymentDataSource);
            }
            else if (!paymentMethods.some(pm => pm.paymentDataSource === this.selectedPaymentMethod)) {
                this.setSelectedPaymentMethod(null);
            }

            this.availablePaymentMethods = paymentMethods;
        },
    },
    getters: {
        getCurrentCartDetails: state => (orderId = state.currentCartId) => {
            return state.cartsDetails[orderId] || null;
        },
        pledgeAmountWithSymbol() {
            const currencyStore = useCurrencyStore();
            const selectedDccCurrency = currencyStore.selectedCurrency;
            const cartDetails = this.getCurrentCartDetails();
            if (!cartDetails) {
                return null;
            }
            const currencySymbol = selectedDccCurrency
                ? selectedDccCurrency.symbol
                : cartDetails.cart.currencySymbol;

            return Formatter.format(this.payableAmount, 'C2', currencySymbol);
        },
        payableAmount() {
            const currencyStore = useCurrencyStore();
            const selectedDccCurrency = currencyStore.selectedCurrency;
            const cartDetails = this.getCurrentCartDetails();

            if (!cartDetails) {
                return null;
            }

            const effectivePayableAmount = cartDetails.cart.effectivePayableAmount;

            return selectedDccCurrency
                ? currencyStore.selectedCurrency.amountConverted
                : effectivePayableAmount;

        },
        currencyShortName() {
            const currencyStore = useCurrencyStore();
            const selectedDccCurrency = currencyStore.selectedCurrency;
            const cartDetails = this.getCurrentCartDetails();

            if (!cartDetails) {
                return null;
            }

            return selectedDccCurrency
                ? selectedDccCurrency.shortName
                : cartDetails.cart.currencyShortName;
        },
        currentOrderCode() {
            if (this.getCurrentCartDetails()?.cart?.orderCode) {
                return this.getCurrentCartDetails().cart.orderCode;
            }
            return null;
        },
        projectID() {
            const cartDetails = this.getCurrentCartDetails();

            return cartDetails ? cartDetails.cart.projectID : null;
        },
        locationIsoCode() {
            const cartDetails = this.getCurrentCartDetails();
            return cartDetails ? cartDetails.cart.shippingProjectLocationIsoCode ?? cartDetails.cart.customerProjectLocationIsoCode : null;
        },
        idealIssuers: state => {
            const iDeal = state.availablePaymentMethods.find(pm => pm.name === 'iDEAL');
            return iDeal ? iDeal.issuers : [];
        }
    }
});