var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "gfu-grid gfu-grid--small",
        attrs: { "data-qa": "address:FullName" },
      },
      [
        _c(
          "label",
          {
            staticClass:
              "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
            attrs: { "data-qa": "label" },
          },
          [_vm._v(_vm._s(_vm.Resources.Common.AddressFullNameLabel))]
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass:
              "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
            attrs: { "data-qa": "value" },
          },
          [_vm._v(_vm._s(_vm.address.fullName))]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "gfu-grid gfu-grid--small",
        attrs: { "data-qa": "address:Country" },
      },
      [
        _c(
          "label",
          {
            staticClass:
              "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
            attrs: { "data-qa": "label" },
          },
          [_vm._v(_vm._s(_vm.Resources.Common.AddressCountryLabel))]
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass:
              "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
            attrs: { "data-qa": "value" },
          },
          [_vm._v(_vm._s(_vm.addresLocationValue))]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "gfu-grid gfu-grid--small",
        attrs: { "data-qa": "address:City" },
      },
      [
        _c(
          "label",
          {
            staticClass:
              "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
            attrs: { "data-qa": "label" },
          },
          [_vm._v(_vm._s(_vm.Resources.Common.AddressCityLabel))]
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass:
              "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
            attrs: { "data-qa": "value" },
          },
          [_vm._v(_vm._s(_vm.address.city))]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "gfu-grid gfu-grid--small",
        attrs: { "data-qa": "address:StateProvince" },
      },
      [
        _c(
          "label",
          {
            staticClass:
              "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
            attrs: { "data-qa": "label" },
          },
          [_vm._v(_vm._s(_vm.Resources.Common.AddressSublocationLabel))]
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass:
              "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
            attrs: { "data-qa": "value" },
          },
          [_vm._v(_vm._s(_vm.addressSublocationValue))]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "gfu-grid gfu-grid--small",
        attrs: { "data-qa": "address:PostalCode" },
      },
      [
        _c(
          "label",
          {
            staticClass:
              "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
            attrs: { "data-qa": "label" },
          },
          [_vm._v(_vm._s(_vm.Resources.Common.AddressPostalCodeLabel))]
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass:
              "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
            attrs: { "data-qa": "value" },
          },
          [_vm._v(_vm._s(_vm.address.postalCode))]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "gfu-grid gfu-grid--small",
        attrs: { "data-qa": "address:AddressLine1" },
      },
      [
        _c(
          "label",
          {
            staticClass:
              "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
            attrs: { "data-qa": "label" },
          },
          [_vm._v(_vm._s(_vm.Resources.Common.AddressLinesLabel))]
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass:
              "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
            attrs: { "data-qa": "value" },
          },
          [_vm._v(_vm._s(_vm.address.addressLine1))]
        ),
      ]
    ),
    _vm._v(" "),
    _vm.address.addressLine2
      ? _c(
          "div",
          {
            staticClass: "gfu-grid gfu-grid--small",
            attrs: { "data-qa": "address:AddressLine2" },
          },
          [
            _c("label", {
              staticClass:
                "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
              attrs: { "data-qa": "label" },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass:
                  "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
                attrs: { "data-qa": "value" },
              },
              [_vm._v(_vm._s(_vm.address.addressLine2))]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.address.addressLine3
      ? _c(
          "div",
          {
            staticClass: "gfu-grid gfu-grid--small",
            attrs: { "data-qa": "address:AddressLine3" },
          },
          [
            _c("label", {
              staticClass:
                "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
              attrs: { "data-qa": "label" },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass:
                  "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
                attrs: { "data-qa": "value" },
              },
              [_vm._v(_vm._s(_vm.address.addressLine3))]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "gfu-grid gfu-grid--small",
        attrs: { "data-qa": "address:PhoneNumber" },
      },
      [
        _c(
          "label",
          {
            staticClass:
              "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
            attrs: { "data-qa": "label" },
          },
          [_vm._v(_vm._s(_vm.Resources.Common.AddressPhoneLabel))]
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass:
              "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
            attrs: { "data-qa": "value" },
          },
          [_vm._v(_vm._s(_vm.address.phoneNumber))]
        ),
      ]
    ),
    _vm._v(" "),
    _vm.address.vatNumber
      ? _c(
          "div",
          {
            staticClass: "gfu-grid gfu-grid--small",
            attrs: { "data-qa": "address:VatNumber" },
          },
          [
            _c(
              "label",
              {
                staticClass:
                  "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
                attrs: { "data-qa": "label" },
              },
              [_vm._v(_vm._s(_vm.Resources.Common.AddressTaxNumberLabel))]
            ),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass:
                  "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
                attrs: { "data-qa": "value" },
              },
              [_vm._v(_vm._s(_vm.address.vatNumber))]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.address.notes
      ? _c(
          "div",
          {
            staticClass: "gfu-grid gfu-grid--small",
            attrs: { "data-qa": "address:UserNotes" },
          },
          [
            _c(
              "label",
              {
                staticClass:
                  "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
                attrs: { "data-qa": "label" },
              },
              [_vm._v(_vm._s(_vm.Resources.Common.AddressUserNotesLabel))]
            ),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass:
                  "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
                attrs: { "data-qa": "value" },
              },
              [_vm._v(_vm._s(_vm.address.notes))]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }