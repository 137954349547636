var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOrderPayable
    ? _c("adyen-order-payment-options", {
        attrs: {
          "is-instant": _vm.isInstant,
          "checkout-mode": _vm.checkoutMode,
          "effective-payable-amount": _vm.effectivePayableAmount,
          "currency-short-name": _vm.currencyShortName,
          "order-code": _vm.orderCode,
          "handle-tax": _vm.handleTax,
          "order-validation-id": _vm.orderValidationID,
          "payment-type": _vm.paymentType,
          reason: _vm.reason,
          "order-id": _vm.orderID,
          "data-qa": "payment-section",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "actions",
              fn: function ({ onSubmit }) {
                return [
                  _c("div", { staticClass: "gfu-box-actions" }, [
                    _c(
                      "div",
                      { staticClass: "gfu-box-actions__item _ml-a" },
                      [
                        _c(
                          "base-button",
                          {
                            staticClass: "gfu-btn--block gfu-btn--soft",
                            attrs: {
                              theme: "accent",
                              "data-qa": "create-order-btn:Submit",
                            },
                            on: { click: onSubmit },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.btnLabel) +
                                " "
                            ),
                            _c(
                              "span",
                              {
                                attrs: { "data-qa": "create-order-btn:Price" },
                              },
                              [_vm._v(" " + _vm._s(_vm.amount) + " ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              },
            },
          ],
          null,
          false,
          3485137572
        ),
      })
    : _c("div", [
        _c(
          "div",
          { staticClass: "gfu-box-actions" },
          [
            _c(
              "div",
              { staticClass: "gfu-box-actions__item _ml-a _pt-0" },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "gfu-btn--block",
                    attrs: {
                      theme: "accent",
                      "data-qa": "create-order-btn:Submit",
                    },
                    on: { click: _vm.submitChangedOrder },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.Resources.Checkout.CheckoutSubmitOrderButton
                        ) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }