var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["gfu-become-creator-stats", _vm.themeClass] }, [
    _c("div", { class: _vm.layoutClass }, [
      _c(
        "div",
        { staticClass: "gfu-grid _tac" },
        _vm._l(_vm.stats, function (item) {
          return _c(
            "div",
            {
              key: item.title,
              class: [
                "gfu-grid__cell gfu-1of2 gfu-1of4--m gfu-become-creator-divider _py-3",
                _vm.dividerClass,
              ],
            },
            [
              _c(
                "h5",
                { staticClass: "gfu-hd gfu-hd--h2 _tc--inherit _mt-5" },
                [_vm._v(_vm._s(item.value))]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "_ttu" },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item.title) +
                      "\n                    "
                  ),
                  item.tooltip
                    ? _c("tooltip", { attrs: { text: item.tooltip } }, [
                        _c("span", { staticClass: "_fa _fa--info" }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }