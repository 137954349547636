var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfua-edit-switch" },
    [
      !_vm.isEditing
        ? _c(
            "icon-base-button",
            {
              attrs: {
                icon: _vm.icon,
                enabled: _vm.enabled,
                "data-qa": "admin-edit-switch-btn-edit",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.edit.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _c(
            "span",
            [
              _c(
                "icon-base-button",
                {
                  attrs: {
                    theme: "accent",
                    icon: "check",
                    enabled: _vm.enabled,
                    "data-qa": "admin-edit-switch-btn-save",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.saveLabel))]
              ),
              _vm._v(" "),
              _c(
                "icon-base-button",
                {
                  attrs: {
                    icon: "times",
                    enabled: _vm.enabled,
                    "data-qa": "admin-edit-switch-btn-cancel",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.cancel.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.cancelLabel))]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }