import { toHash } from '@/Utils/StringUtility';
import { millisecondsInMinute } from '@/Utils/DateUtility';
import WebStorage, { storageTypes } from '@/Utils/WebStorage';

export function withCache(fn, options = {
    storageType: storageTypes.localStorage,
    key: null,
    cacheDuration: null,
    skipCache: false
}) {
    const skipCache = options.skipCache || false;
    const storageType = options.storageType || storageTypes.localStorage;
    const cacheDuration = options.cacheDuration || 5000;
    const expirationTime = Date.now() + cacheDuration;
    const storageKey = options.key || toHash(fn.name);
    const storage = new WebStorage(storageKey, storageType);
    const cache = storage.get() || {};

    return async (...args) => {
        const key = toHash(JSON.stringify(args));
        const cachedEntry = cache[key];
        const currentTime = Date.now();

        if (cachedEntry && currentTime <= cachedEntry.expiresAt && !skipCache) {
            return cachedEntry;
        }

        const result = await fn.apply(this, args);
        result.expiresAt = expirationTime;

        cache[key] = result;
        storage.set(cache);
        return result;
    };
}

export async function asyncTaskWithCache(serviceFunction, cacheOptions, storage = storageTypes.sessionStorage) {
    const defaultOptions = { key: 'cacheKey', cacheTime: 5 };
    const options = { ...defaultOptions, ...cacheOptions };

    const cache = new WebStorage(options.key, storage);
    const cachedEntry = cache.get();

    if (cachedEntry && cachedEntry.expiresAt >= Date.now()) {
        return cachedEntry.value;
    }
    try {
        const result = await serviceFunction();
        cache.set({
            value: result,
            expiresAt: Date.now() + options.cacheTime * millisecondsInMinute
        });
        return result;
    }
    catch (e) {
        throw new Error(e);
    }
}