var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-navbar-item gfu-navbar-dropdown _screen--not-m" },
    [
      _c(
        "base-button",
        {
          attrs: { width: "narrow", theme: "default" },
          on: { click: _vm.toggleMenu },
        },
        [_c("span", { staticClass: "gfu-icon gfu-icon--big _fa _fa--bars" })]
      ),
      _vm._v(" "),
      _vm.isVisible
        ? _c("teleport", { attrs: { to: "#modals" } }, [
            _c(
              "div",
              { staticClass: "gfu-header-mobile", attrs: { tabindex: "0" } },
              [
                _c("div", { staticClass: "gfu-header-mobile__logo" }, [
                  _c(
                    "a",
                    {
                      staticClass: "gfu-header-logo",
                      attrs: { href: _vm.homeUrl },
                    },
                    [
                      _c("base-image", {
                        staticClass: "gfu-header-logo__icon",
                        attrs: {
                          src: "/content/images/site-icon.svg",
                          alt: "Gamefound",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "gfu-header-mobile__close",
                    attrs: { type: "button" },
                    on: { click: _vm.toggleMenu },
                  },
                  [_c("span", { staticClass: "_fa _fa--times" })]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "gfu-header-mobile__wrapper" }, [
                  _c("div", { staticClass: "gfu-header-mobile__layout" }, [
                    _c(
                      "div",
                      { staticClass: "gfu-header-mobile__dropdown" },
                      [
                        _c("currency-selector", {
                          on: { "currency-changed": _vm.toggleMenu },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.hasNavigationItems
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "gfu-header-mobile__content gfu-header-mobile__content--top",
                          },
                          [
                            _vm.projectContext.hasProject
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gfu-header-mobile__title _mb-3 _ttu",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "gfu-link gfu-link--nofx gfu-link--block",
                                        attrs: { href: "" },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.projectContext.project.name
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.projectContext.canEnableTestMode
                              ? _c("mobile-menu-tester-mode", {
                                  staticClass: "_mb-3",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("header-mobile-box-group", {
                              attrs: { navigation: _vm.navigationItems },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.hasNavigationItems
                            ? "gfu-header-mobile__bottom"
                            : "gfu-header-mobile__content",
                        ],
                      },
                      [
                        _vm._l(_vm.userNavigationItems, function (link) {
                          return _c(
                            "base-button",
                            {
                              key: link.key,
                              staticClass: "_mt-3",
                              attrs: {
                                tag: "a",
                                href: link.url,
                                theme: link.theme,
                                display: "block",
                              },
                            },
                            [_vm._v(_vm._s(link.name))]
                          )
                        }),
                        _vm._v(" "),
                        _vm.isAuthenticated
                          ? _c("div", { staticClass: "_tac _mt-3" }, [
                              _c("span", { staticClass: "_fw-eb" }, [
                                _vm._v(_vm._s(_vm.fullName)),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.userEmail) + " "),
                              ]),
                              _vm._v(" "),
                              _vm.userProfileUrl
                                ? _c("div", [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "gfu-link-underline _tc--accent",
                                        attrs: { href: _vm.userProfileUrl },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "gfu-link-underline__content",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.Resources.Layout
                                                  .UserBoxProfileLink
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("base-icon", {
                                          attrs: { name: "chevron-right" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }