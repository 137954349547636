var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._t("label")]
              },
              proxy: true,
            },
            {
              key: "field-description",
              fn: function () {
                return [_vm._t("field-description")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      _vm._v(" "),
      !_vm.readOnly
        ? _c(
            "div",
            {
              class: [
                "gfu-field-select",
                {
                  "gfu-field-select--small": _vm.small,
                  "gfu-field-select--no-fx": _vm.noOptionCapitalize,
                  "gfu-field-select--transparent": _vm.hasTransparentBg,
                },
              ],
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model,
                      expression: "model",
                    },
                  ],
                  ref: "input",
                  attrs: {
                    name: _vm.name,
                    disabled: _vm.disabled,
                    "data-qa": "option-field-select",
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.model = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onChange,
                    ],
                  },
                },
                [
                  _vm.showPlaceholder
                    ? _c(
                        "option",
                        {
                          attrs: {
                            selected: "",
                            disabled: "",
                            hidden: "",
                            "data-qa": "option-field-option-placeholder",
                          },
                          domProps: { value: null },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.placeholder ||
                                _vm.Resources.Common.PleaseSelectPlaceholder
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.options, function (value, key) {
                    return _c(
                      "option",
                      {
                        key: key,
                        attrs: { "data-qa": `option-field-option:${value}` },
                        domProps: { value: value },
                      },
                      [_vm._v(_vm._s(key))]
                    )
                  }),
                ],
                2
              ),
            ]
          )
        : _c("input", {
            class: [
              "gfu-field__input gfu-field__input--readonly",
              { "gfu-field__input--small": _vm.small },
            ],
            attrs: {
              type: "text",
              readonly: _vm.readOnly,
              name: _vm.name,
              "data-qa": "input-field",
            },
            domProps: {
              value:
                _vm.findKey(_vm.options, (i) => i === _vm.value) ||
                _vm.readOnlyPlaceholder,
            },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }