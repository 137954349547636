var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "field-description",
              fn: function () {
                return [_vm._t("field-description")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      !_vm.readOnly
        ? _c("div", { staticClass: "gfu-flex__container" }, [
            _c("input", {
              ref: "dateInput",
              class: [
                "gfu-field__input _mr-1",
                { "gfu-field__input--small": _vm.small },
              ],
              attrs: {
                name: _vm.name,
                type: "date",
                min: _vm.minDate,
                max: _vm.maxDate,
                pattern: "\\d{4}-\\d{2}-\\d{2}",
                placeholder: "YYYY-MM-DD",
                "data-qa": "date-field",
              },
              domProps: { value: _vm.dateValue },
              on: { blur: _vm.onBlur },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.lazy",
                  value: _vm.timeValue,
                  expression: "timeValue",
                  modifiers: { lazy: true },
                },
              ],
              ref: "timeInput",
              class: [
                "gfu-field__input _ml-1",
                { "gfu-field__input--small": _vm.small },
              ],
              attrs: {
                name: _vm.name,
                pattern: "\\d{2}:\\d{2}",
                placeholder: "00:00",
                type: "time",
                "data-qa": "time-field",
              },
              domProps: { value: _vm.timeValue },
              on: {
                input: _vm.onTimeChange,
                change: function ($event) {
                  _vm.timeValue = $event.target.value
                },
              },
            }),
          ])
        : _c(
            "span",
            {
              staticClass: "gfu-field__readonly",
              attrs: { "data-qa": "date-field" },
            },
            [_vm._v(_vm._s(_vm.$format(_vm.model)))]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }