import ServiceBase from './ServiceBase';

export default class CartsService extends ServiceBase {
    constructor($http, errorHandler) {
        super($http, errorHandler);
    }

    addTipToCart(model) {
        return this.request('post', 'carts/addTipToCart', model);
    }

    addToCart(model) {
        return this.request('post', 'carts/addToCart', model);
    }

    checkCartItemsIntegrity(projectID, orderCode) {
        return this.request('get', 'carts/checkCartItemsIntegrity', { params: { projectID, orderCode } });
    }

    enablePledgeEdit(projectID) {
        return this.request('post', 'carts/enablePledgeEdit', { projectID });
    }

    getCartSummary(projectID) {
        if (!projectID) {
            return this.request('get', 'carts/getCartSummaries');
        }

        return this.request('get', 'carts/getCartSummary', { params: { projectID } });
    }

    getCartByActivationKey(projectID, activationKey) {
        return this.request('get', 'carts/getCartByActivationKey', { params: { projectID, activationKey } });
    }

    getCartDetails(model) {
        return this.request('get', 'carts/getCartDetails', { params: model });
    }

    getOrderAwaitingPayment(projectID) {
        return this.request('get', 'carts/getOrderAwaitingPayment', { projectID });
    }

    revertChanges(projectID) {
        return this.request('post', 'carts/revertChanges', { projectID });
    }

    setOrderItemOption(orderItemID, optionValueID, projectID) {
        return this.request('post', 'carts/setOrderItemOption', {
            orderItemID,
            optionValueID,
            projectID
        });
    }

    setOrderItemQuantity(orderItemID, quantity, projectID) {
        const model = {
            orderItemID,
            quantity,
            projectID
        };

        // TODO: in the future this should be two separate methods
        // but because on UI we allow deleting items by setting 0 quanitity it would actually reduce readability to do that
        if (quantity === 0) {
            return this.request('post', 'carts/removeOrderItem', model);
        }

        return this.request('post', 'carts/setOrderItemQuantity', model);
    }

    startPledgeFromScratch(projectID) {
        return this.request('post', 'carts/startPledgeFromScratch', { projectID });
    }
}
