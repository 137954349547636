
    export default {
        props: {
            title: { type: String, default: 'There is nothing to show.' },
            isBordered: { type: Boolean, default: false },
            isTall: { type: Boolean, default: false },
        },
        functional: true,
        render(createElement, { props, children, data }) {
            const title = createElement('span', { class: 'gfu-bt gfu-bt--b2 _mb-1 _mt-1 _pb-1 _pt-1' }, props.title);
            return createElement(
                'div',
                {
                    class: [
                        'gfua-empty-state',
                        {
                            'gfua-empty-state--bordered': props.isBordered,
                            'gfua-empty-state--tall': props.isTall,
                        },
                        data.class,
                        data.staticClass
                    ],
                    attrs: data.attrs
                },
                [ title, children ]);
        },
    };
