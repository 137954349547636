var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-grid gfu-grid--gutter",
      attrs: { "data-qa": "project-properties-box" },
    },
    [
      _c("div", { staticClass: "gfu-grid__cell gfu-1of1" }, [
        _c("div", { staticClass: "gfu-project-banner__field-label" }, [
          _vm._v(_vm._s(_vm.Resources.ProjectHome.ProjectStoryCreatorLabel)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-media _mt-1" }, [
          _vm.creatorAvatarUrl
            ? _c(
                "a",
                {
                  staticClass: "gfu-thumb _mr-3",
                  attrs: {
                    href: _vm.creatorUrl,
                    "data-qa": "project-properties:AvatarImage",
                  },
                },
                [
                  _c("base-image", {
                    staticClass: "gfu-thumb__item",
                    attrs: {
                      src: _vm.creatorAvatarUrl,
                      alt: _vm.creator.name,
                      width: "48",
                      height: "48",
                      loading: "lazy",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-media__body" }, [
            _c("h3", { staticClass: "gfu-hd gfu-hd--h2" }, [
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--nofx",
                  attrs: {
                    href: _vm.creatorUrl,
                    "data-qa": "project-properties:CreatorName",
                  },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.creator.name) +
                      "\n                    "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent _mr-2",
                  attrs: {
                    href: _vm.creatorUrl,
                    rel: "nofollow",
                    "data-qa": "project-properties:MoreInfo",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.Resources.ProjectHome.ProjectStoryMoreInfoLink)
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: {
                    href: _vm.projectContactUrl,
                    "data-qa": "project-properties:Contact",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.Resources.ProjectHome.ProjectStoryContactLink)
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.projectProperties.enableBoardGameProperties
        ? [
            _vm.playersLabelContent
              ? _c(
                  "div",
                  {
                    staticClass: "gfu-grid__cell gfu-4of12 gfu-2of12--l",
                    attrs: { "data-qa": "project-properties:Players" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "gfu-project-banner__field-label" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.ProjectHome
                              .ProjectPropertiesPlayersLabel
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "gfu-project-banner__field-value _mt-1" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.playersLabelContent) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.playTimeLabelContent
              ? _c(
                  "div",
                  {
                    staticClass: "gfu-grid__cell gfu-5of12 gfu-2of12--l",
                    attrs: { "data-qa": "project-properties:PlayTime" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "gfu-project-banner__field-label" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .ProjectPropertiesPlayTimeLabel
                            ) +
                            "\n                "
                        ),
                        _vm.projectProperties.playTimeDescription
                          ? _c("tooltip", {
                              staticClass: "gfu-project-banner__tooltip",
                              attrs: {
                                text: _vm.projectProperties.playTimeDescription,
                                "is-info-icon": true,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "gfu-project-banner__field-value _mt-1" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.playTimeLabelContent) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.projectProperties.minAge
              ? _c(
                  "div",
                  {
                    staticClass: "gfu-grid__cell gfu-3of12 gfu-2of12--l",
                    attrs: { "data-qa": "project-properties:Age" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "gfu-project-banner__field-label" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.ProjectHome.ProjectPropertiesAgeLabel
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "gfu-project-banner__field-value _mt-1" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.projectProperties.minAge) +
                            " +\n            "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.projectHasCategories
              ? _c(
                  "div",
                  {
                    class: ["gfu-grid__cell gfu-1of1", _vm.propertiesClasses],
                    attrs: { "data-qa": "project-properties:Genre" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "gfu-project-banner__field-label" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.ProjectHome
                              .ProjectPropertiesGenreLabel
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "gfu-project-banner__field-value gfu-project-banner__field-value--tags",
                      },
                      _vm._l(_vm.visibleProjectCategories, function (category) {
                        return _c(
                          "base-tag",
                          {
                            key: category.name,
                            staticClass: "_mr-1",
                            attrs: { href: category.url, tag: "a" },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(category.name) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }