import lazyScreen from '@/Components/Admin/LazyScreen';

const ProjectAnalyticsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project-analytics' */ './ProjectAnalyticsScreen.vue'));

const routes = [
    { path: '/project-analytics/:creator?/:project?', name: 'project-analytics', component: ProjectAnalyticsScreen },
];

export {
    ProjectAnalyticsScreen,
    routes
};