var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-success-box-stretch-goals" }, [
    _c(
      "div",
      {
        staticClass: "gfu-success-box-stretch-goals__col gfu-1of1 gfu-1of2--m",
      },
      [
        _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mb-6" }, [
          _vm._v(_vm._s(_vm.heading)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-success-box-stretch-goal-total _mb-6" }, [
          _c("h3", { staticClass: "gfu-hd gfu-hd--d1" }, [
            _c("span", [_vm._v(_vm._s(_vm.formattedCurrentFunds))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-progress-bar" }, [
            _c("div", {
              staticClass:
                "gfu-progress-bar__progression gfu-progress-bar__progression--accent",
              style: _vm.progressBarStyle,
            }),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass:
                "gfu-success-box-stretch-goal-total__cloud gfu-cloud gfu-cloud--accent",
              attrs: { "data-qa": "pledgeValue" },
            },
            [_vm._v("+" + _vm._s(_vm.totalCostConverted))]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.hasStretchGoals
      ? _c(
          "div",
          {
            staticClass:
              "gfu-success-box-stretch-goals__col gfu-1of1 gfu-1of2--m",
          },
          [
            _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mb-6" }, [
              _vm._v(
                _vm._s(
                  _vm.Resources.PaymentSuccess.UnlockStreatchGoalSectionHeader
                )
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.stretchGoalsModel, function (stretchGoal) {
              return _c(
                "div",
                {
                  key: stretchGoal.name,
                  staticClass:
                    "gfu-success-box-stretch-goal-item gfu-media gfu-media--middle",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-success-box-stretch-goal-item__body gfu-media__body",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "gfu-bt gfu-bt--overline _tc--light _ttu _mt-0",
                        },
                        [
                          _c(
                            "localize",
                            {
                              attrs: {
                                resource:
                                  _vm.Resources.PaymentSuccess
                                    .UnlockStretchGoalMessage,
                              },
                            },
                            [
                              _c("span", { attrs: { "format-key": "0" } }, [
                                _vm._v(_vm._s(stretchGoal.valueFormatted)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _ttu _toe" }, [
                        _vm._v(_vm._s(stretchGoal.name)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("base-image", {
                    staticClass: "gfu-success-box-stretch-goal-item__side",
                    attrs: {
                      width: "48",
                      height: "48",
                      src: stretchGoal.imageUrl,
                      loading: "lazy",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "gfu-success-box-stretch-goal-item__cloud gfu-cloud gfu-cloud--accent",
                    },
                    [_vm._v("+ " + _vm._s(stretchGoal.cloudValue))]
                  ),
                ],
                1
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }