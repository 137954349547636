var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "admin-router-link",
    _vm._b(
      {
        class: _vm.classNames,
        attrs: {
          to: _vm.to,
          enabled: _vm.enabled,
          "active-class": "gfu-sidenav__item--selected",
        },
      },
      "admin-router-link",
      _vm.$attrs,
      false
    ),
    [
      _vm.icon
        ? _c("base-icon", {
            class: _vm.iconClass,
            attrs: { "icon-namespace": _vm.iconNamespace, name: _vm.icon },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: [_vm.labelClass] }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.indicateRedirect
        ? _c("base-icon", {
            staticClass: "_tc--accent",
            attrs: {
              name: "chevron-right",
              "data-qa": "side-nav-link:RedirectIndicator",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }