var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-view",
    { attrs: { "is-processing": _vm.isProcessing } },
    [
      _c("account-navigation", { attrs: { "page-identity": _vm.pageID } }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "gfu-layout__main gfu-layout__main--narrow" },
        [
          _c("search-title", {
            attrs: {
              title: _vm.Resources.MyProjects.PageHeader,
              "total-item-count": _vm.totalItemCount,
              "data-qa": "search-result",
            },
          }),
          _vm._v(" "),
          _c("search-projects-header", {
            staticClass: "_mb-4",
            attrs: {
              "selected-filters": _vm.currentFilters,
              "total-item-count": _vm.totalItemCount,
              "sort-type": _vm.searchParams.sortType,
              narrow: true,
            },
            on: {
              "filters-open": _vm.onFiltersOpen,
              "filter-delete": _vm.onFilterDelete,
              "sort-open": _vm.onSortOpen,
            },
          }),
          _vm._v(" "),
          _c("search-projects-filter", {
            attrs: {
              "selected-filters": _vm.selectedFilters,
              "is-drawer-open": _vm.isFilterDrawerOpen,
              categories: _vm.projectCategories,
            },
            on: {
              search: _vm.onSearch,
              close: _vm.onFiltersClose,
              clear: _vm.onFilterClear,
            },
          }),
          _vm._v(" "),
          _c("search-projects-sort", {
            attrs: {
              "selected-filters": _vm.selectedFilters,
              "is-drawer-open": _vm.isSortDrawerOpen,
            },
            on: { search: _vm.onSearch, close: _vm.onSortClose },
          }),
          _vm._v(" "),
          _vm.totalItemCount
            ? _vm._l(_vm.pagedItems, function (project) {
                return _c(
                  "project-engagement-card",
                  _vm._b(
                    {
                      key: project.projectID,
                      staticClass: "_mb-6",
                      on: { action: _vm.onCardAction },
                    },
                    "project-engagement-card",
                    project,
                    false
                  )
                )
              })
            : _c(
                "box",
                {
                  staticClass: "_mt-2",
                  attrs: { "data-qa": "search-result:EmptyState" },
                },
                [
                  _c(
                    "empty-state",
                    {
                      attrs: {
                        title: _vm.Resources.MyProjects.EmptyStateBoxHeader,
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.Resources.MyProjects.EmptyStateBoxParagraph
                          ) +
                          "\n                "
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "_mt-3",
                          attrs: { theme: "accent" },
                          on: { click: _vm.onFiltersOpen },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.Resources.MyProjects
                                  .EmptyStateBoxActionButton
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _vm._v(" "),
          _c("project-recommendations", {
            staticClass: "_mt-6",
            attrs: {
              title: "Other projects you may like",
              quantity: 2,
              source: "backedProjects",
              "data-qa": `Recommendations`,
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }