import AnalyticsTracking from './AnalyticsTracking';
import Confettiful from './Confettiful';
import MagicAnchor from './MagicAnchor';
import Overlay from './Overlay';
import PageSectionAnchor from './PageSectionAnchor';
import Toggler from './Toggler';
import Typer from './Typer';
import StatisticsInterval from './StatisticsInterval';

export default ({
    AnalyticsTracking,
    Confettiful,
    MagicAnchor,
    Overlay,
    PageSectionAnchor,
    Toggler,
    Typer,
    StatisticsInterval
});
