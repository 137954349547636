import NavigationErrorScreen from '@/Apps/Admin/NavigationErrorScreen.vue';
import NavigationLoadingScreen from '@/Apps/Admin/NavigationLoadingScreen.vue';

export default function lazyScreen (importPromise) {
    const asyncHandler = () => ({
        component: importPromise,
        error: NavigationErrorScreen,
        loading: NavigationLoadingScreen,
        delay: 200,
        timeout: 5000
    });

    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            return h(asyncHandler, data, children);
        }
    });
}
