import { projectPhases, projectCampaignOutcomes, projectTypes } from '@/Models';
import { projectStatuses } from '@/Models';
import Resources from '@/Resources.js';

// map of project phases based on how they are managed in admin
export const projectDisplayPhaseMap = {
    [projectPhases.crowdfundingDraft]: projectPhases.crowdfundingDraft,
    [projectPhases.crowdfunding]: projectPhases.crowdfunding,
    [projectPhases.crowdfundingEnded]: projectPhases.pledgeManager,
    [projectPhases.pledgeManagerDraft]: projectPhases.pledgeManager,
    [projectPhases.pledgeManager]: projectPhases.pledgeManager,
    [projectPhases.pledgeManagerComplete]: projectPhases.pledgeManager,
    [projectPhases.latePledge]: projectPhases.pledgeManager,
};

export function getBadgeModels(formattedMinPledge, installmentCount) {
    return {
        stablePledge: {
            name: 'StablePledge',
            tooltipText: Resources.Homepage.ProjectCardStablePledgeTooltip,
            icon: '/content/images/static/stable-pledge/stable-pledge-badge.svg',
        },
        installments: {
            name: 'StretchPay',
            tooltipText: Resources.format(Resources.Homepage.ProjectCardStretchPayTooltip, formattedMinPledge, installmentCount),
            icon: '/content/images/static/stretch-pay/stretch-pay-badge.svg',
        }
    };
}

export function getProjectPreviewUrl(projectUrl, phase = null, languageIsoCode = 'en') {
    if (!projectUrl) {
        return null;
    }

    const url = new URL(projectUrl);

    if (languageIsoCode) {
        url.pathname = `/${ languageIsoCode }${ url.pathname }`;
    }

    if (phase !== null && projectPhases[phase]) {
        url.searchParams.append('previewphase', projectPhases[phase].name.toLowerCase());
    }

    return url.toString();
}

export function resolveDisplayPhase(project) {
    if (!project) {
        return null;
    }

    // if CF failed then they won't have PM available
    if (project.campaignOutcome === projectCampaignOutcomes.fail) {
        return projectPhases.crowdfunding;
    }

    if (project.phase === projectPhases.crowdfundingDraft && project.status === projectStatuses.published)
        return projectPhases.crowdfunding;

    return projectDisplayPhaseMap[project.phase];
}

export function shouldDisplayProjectUrlAsPreview(project, phase) {
    return !project.isPublished || project.phase !== phase;
}

export function isValidPreviewPhase(project, phase) {
    return phase >= project.phase;
}

export function shouldDisplayCFSnapshotUrl(project, phase) {
    return project.originalType === projectTypes.crowdfunding && phase === projectPhases.crowdfunding && project.phase > projectPhases.crowdfunding;
}

const badProjectStatuses = [projectStatuses.created, projectStatuses.unpublished];
const goodProjectStatuses = [projectStatuses.published];

export function getProjectStatusEvaluation(projectStatus) {
    if (badProjectStatuses.includes(projectStatus))
        return 'bad';

    if (goodProjectStatuses.includes(projectStatus))
        return 'good';

    return null;
}

export function buildProjectPreviewLabel(project, projectDisplayPhase = null, currentLanguageIsoCode = null) {
    const result = projectDisplayPhase !== null
    && shouldDisplayProjectUrlAsPreview(project, projectDisplayPhase)
        ? `view ${ projectPhases[projectDisplayPhase].displayName } preview`
        : 'view project page';

    if (currentLanguageIsoCode) {
        return `${ result } (${ currentLanguageIsoCode })`;
    }

    return result;
}