import { breakpoint } from '@/Types/StyleTypes.js';
import { getWindowWidth } from '@/Utils/WindowUtility.js';

export default {
    methods: {
        // TODO refactor to computed
        isMobile() {
            const width = getWindowWidth();
            return width < breakpoint.m;
        },
    }
};
