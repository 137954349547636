var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tooltip",
    { attrs: { text: _vm.text, "data-qa": _vm.dataQa } },
    [
      _c(
        "base-button",
        {
          staticClass: "_flex _jc-c _ai-c",
          attrs: {
            tag: "router-link",
            theme: "accent",
            shape: "circle",
            to: _vm.url,
          },
        },
        [_c("base-icon", { attrs: { name: "cog" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }