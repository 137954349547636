import { projectPermissions, categoryTypes, projectVersions } from '@/Models';
import { hasProjectPermissionCurried } from '@/Utils/RoleUtility';

const {
    editProjectSettings,
} = projectPermissions;

const { v1, v2 } = projectVersions;
const { addons } = categoryTypes;

const projectRouteBaseParams = (projectContext = null) => ({
    project: projectContext?.project?.urlName || null,
    creator: projectContext?.creator?.urlName || null
});

const getAddonsCategoryID = productCategories => {
    const category = productCategories.find(c => c.type === addons);
    return category ? category.categoryID : null;
};

export function buildProductsNavigation(user, projectContext, productCategories, isLoading) {
    const hasProjectPermission = hasProjectPermissionCurried(user, projectContext.creatorID, projectContext.projectID);
    const addonsCategoryID = getAddonsCategoryID(productCategories);
    const hasEditProjectSettingsPermission = hasProjectPermission(editProjectSettings);
    const project = projectContext?.project;

    if (!hasEditProjectSettingsPermission || !project) {
        return [];
    }

    const baseParams = projectRouteBaseParams(projectContext);
    const isLoadedAndV1 = !isLoading && project.version === v1;
    const isLoadedAndV2 = !isLoading && project.version === v2;

    return [
        {
            isEnabled: isLoadedAndV1,
            to: { name: 'products-list', params: baseParams },
            label: 'Products',
        },
        {
            isEnabled: isLoadedAndV2,
            to: { name: 'products-list-focus', params: { ...baseParams, ...{ categoryId: addonsCategoryID } } },
            label: 'Add-ons',
        },
        {
            isEnabled: isLoadedAndV2,
            to: { name: 'products-rewards', params: baseParams },
            label: 'Rewards',
        },
        {
            isEnabled: !isLoading,
            to: { name: 'products-groups', params: baseParams },
            label: 'Product groups',
        }
    ].filter( i=> i.isEnabled );
}

export function buildRewardNavigation(productId) {
    const params = { productId };
    const generalRoute = productId
        ? { name: 'rewards-general', params }
        : { name: 'rewards-new' };

    return [
        {
            label: 'General info',
            iconName: 'info',
            to: generalRoute,
            isEnabled: true,
        },
        {
            label: 'Reward items',
            iconName: 'cube',
            to: { name: 'rewards-items', params },
            isEnabled: productId > 0,
        },
        {
            label: 'Description',
            iconName: 'align-left',
            to: { name: 'rewards-description', params },
            isEnabled: productId > 0,
        },
        {
            label: 'Gallery',
            iconName: 'image',
            to: { name: 'rewards-gallery', params },
            isEnabled: productId > 0,
        },
        {
            label: 'Shipping',
            iconName: 'rocket',
            to: { name: 'rewards-shipping', params },
            isEnabled: productId > 0,
        },
        {
            label: 'Availability',
            iconName: 'cubes',
            to: { name: 'rewards-availability-settings', params },
            isEnabled: productId > 0,
        },
    ];
}