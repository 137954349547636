var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "side-nav-container",
    {
      attrs: { title: "Shipping model settings", icon: "rocket" },
      scopedSlots: _vm._u([
        {
          key: "navigation-link",
          fn: function () {
            return [
              _c(
                "side-nav-link-back",
                {
                  attrs: {
                    to: _vm.parentRoute,
                    "data-qa": "admin-product-sidenav-link:Back",
                  },
                },
                [_vm._v("Shipping models")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._l(_vm.links, function (link) {
        return _c(
          "side-nav-link",
          {
            key: link.urlName,
            attrs: {
              to: link.to,
              icon: link.iconName,
              "data-qa": `admin-shipping-model-sidenav-link:${link.label}`,
            },
          },
          [_vm._v("\n        " + _vm._s(link.label) + "\n    ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }