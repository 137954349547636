window.__TESTS_DATA__ = {};

class TestsDataStorage {
    constructor() {
        this.storage = window.__TESTS_DATA__;
    }

    setItem(key, payload) {
        this.storage[key] = payload;
    }

    getItem(key) {
        return this.storage[key];
    }
}

export default new TestsDataStorage();