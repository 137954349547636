var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "gfu-flex__container gfu-flex__container--fill gfu-flex__container--center",
    },
    [
      _c(
        "div",
        { staticClass: "gfu-layout-wrapper gfu-layout-wrapper--narrow" },
        [_c("progress-overlay", { attrs: { visible: true } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }