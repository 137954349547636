export const cookieNames = {
    analyticCookieKeyPolicyRejected: 'AnalyticCookieKeyPolicyRejected',
    currency: 'Currency',
    sameSiteCookieMigratedLax: 'SameSiteCookieMigratedLax',
    technicalCookiePolicyAccepted: 'TechnicalCookiePolicyAccepted',
    projectAdvertBanner: '_gfpaba',
    displayCurrency: '_gfdcc',
    localization: '_gfls',
};

// returns null if cookie doesn't exist
export function get(name) {
    try {
        const cookieName = `${ encodeURIComponent(name) }=`;
        const cookieStart = document.cookie.indexOf(cookieName);
        let cookieValue = null;
        let cookieEnd;

        if (cookieStart > -1) {
            cookieEnd = document.cookie.indexOf(';', cookieStart);
            if (cookieEnd === -1) {
                cookieEnd = document.cookie.length;
            }
            cookieValue = decodeURIComponent(document.cookie.substring(cookieStart + cookieName.length, cookieEnd));
        }

        return cookieValue;
    }
    catch (err) {
        return null;
    }
}

export function set(name, value, expires, path, domain, secure, sameSite = 'Lax') {
    let cookieText = `${ encodeURIComponent(name) }=${ encodeURIComponent(value) }`;

    if (expires instanceof Date) {
        cookieText += `; expires=${ expires.toUTCString() }`;
    }

    if (path) {
        cookieText += `; path=${ path }`;
    }

    if (domain) {
        cookieText += `; domain=${ domain }`;
    }
    else {
        cookieText += `; domain=${ window.location.hostname }`;
    }

    if (secure) {
        cookieText += '; secure';
    }

    cookieText += `; SameSite=${ sameSite }`;

    document.cookie = cookieText;
}

export function remove(name, path, domain, secure) {
    set(name, '', new Date(0), path, domain, secure);
}

export function hasCookie(name) {
    return !!get(name);
}

export default {
    get,
    set,
    remove,
    hasCookie
};