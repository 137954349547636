var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-table__row gfu-table__row--short",
      attrs: { "data-qa": `cart-table:${_vm.item.orderItemTypeName}` },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "gfu-table__cell gfu-table__cell-row _flexb50 _flexb75--s _flexb60--m _flexb60--xl _pa-0",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "_screen--none _screen--s gfu-table__cell--image _mr-3 _fl gfu-table__thumb",
            },
            [_c("base-image", { attrs: { src: _vm.imageUrl } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-table__cell--grow _pt-1 _pb-1" }, [
            _c(
              "span",
              {
                staticClass: "gfu-table__text--strong gfu-table__text--big",
                attrs: { "data-qa": "cart-table:ProductName" },
              },
              [_vm._v(_vm._s(_vm.item.displayName))]
            ),
            _vm._v(" "),
            _vm.item.displayName !== _vm.item.orderItemTypeName
              ? _c("div", [_vm._v(_vm._s(_vm.item.orderItemTypeName))])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "gfu-table__cell gfu-table__cell--actions _tac _flexb15 _flexb10--s",
          attrs: { "data-qa": "cart-table-item-row-quantity" },
        },
        [_c("span", [_vm._v(_vm._s(_vm.item.quantity))])]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "gfu-table__cell _tar _flexb35 _flexb25--s _flexb15--m",
          attrs: { "data-text": "Total price" },
        },
        [
          _c("div", [
            _vm._v(
              "\n            " + _vm._s(_vm.priceFormatted) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _vm.showTaxValue
            ? _c("div", [
                _vm._v(
                  "\n            " + _vm._s(_vm.taxFormatted) + "\n        "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.allowEditing
        ? _c(
            "div",
            {
              staticClass:
                "gfu-table__cell _tar _pa-0 _flexb5 _screen--none _screen--s",
            },
            [
              _vm.canRemove
                ? _c(
                    "button",
                    {
                      staticClass: "gfu-btn gfu-btn--icon",
                      attrs: { disabled: _vm.isProcessing },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setOrderItemQuantity(0, _vm.item)
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "_fa _fa--times-circle _tc--error",
                        attrs: {
                          "data-qa": "begin-order-form-button:RemoveItem",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }