import { isInternetExplorer } from './Browser';

export default {
    selectAllAndCopy(inputElement) {
        if (typeof inputElement.select === 'function' && document && typeof document.execCommand === 'function') {
            inputElement.select();
            document.execCommand('copy');
        }
    },
    escapeHtmlTags(text) {
        if (text) {
            const escape = document.createElement('textarea');
            escape.textContent = text;
            return escape.innerHTML;
        }

        return text;
    },
    createElement(tagName) {
        return document.createElement(tagName);
    },
    charactersGroup(inputText, characterCount) {
        const regex = new RegExp(`(.{${ characterCount }})`, 'g');
        return inputText.replace(/\W/gi, '')
            .replace(regex, '$1 ')
            .trim();
    },
    createRange() {
        return document.createRange();
    },
    getSelection() {
        return window.getSelection();
    },
    execCommand(commandID, showUI, value) {
        document.execCommand(commandID, showUI, value);
    },
    pasteHtmlAtCaret(html) {
        const isIE = isInternetExplorer();

        if (isIE) {
            if (window.getSelection) {
                // IE 11
                const sel = window.getSelection();
                if (sel.getRangeAt && sel.rangeCount) {
                    const range = sel.getRangeAt(0);
                    range.insertNode(range.createContextualFragment(html));
                }
            }
            else if (document.selection && document.selection.type != 'Control') {
                // IE <= 10
                document.selection.createRange().pasteHTML(html);
            }
        }
        else {
            this.execCommand('insertHtml', false, html);
        }
    },
    pasteTextAtCaret(text) {
        const isIE = isInternetExplorer();

        if (isIE) {
            this.execCommand('paste', false, text);
        }
        else {
            this.execCommand('insertText', false, text);
        }
    },
    replaceSpecialChars(str) {
        return str
            .replace(/[ẠÅÄẢḀẤẦẨȂẪẬẮẰẲẴẶĀȀǺȦÁǞǍÀÃǠȺÆǢǼⱯꜲꜸꜺ]/g, 'A')
            .replace(/[ạåäảḁấầẩȃẫậắằẳẵặāąȁǻȧáǟǎàãǡâⱥæǣǽꜳꜹꜻ]/g, 'a')
            .replace(/[ḂḄḆƁ]/g, 'B')
            .replace(/[ḃḅḇɃ]/g, 'b')
            .replace(/[ḈĆĈĊČÇƇȻ]/g, 'C')
            .replace(/[ḉćĉċčçƈȼ]/g, 'c')
            .replace(/[ḊḌḎḐḒĎƊƉĐ]/g, 'D')
            .replace(/[ḋḍḏḑḓďđȡ]/g, 'd')
            .replace(/[ḔḖḘḚḜẸẺẾẼỀỂỄỆĒĔĖĘĚÈÉÊËȄȨȆƎɆ]/g, 'E')
            .replace(/[ḕḗḙḛḝẹẻếẽềểễệēĕėęěèéêëȅȩȇⱻɇ]/g, 'e')
            .replace(/[ḞƑ]/g, 'F')
            .replace(/[ḟƒ]/g, 'f')
            .replace(/[ƓḠĜĞĠǤǦǴ⅁Ģ]/g, 'G')
            .replace(/[ḡĝğġǥǧℊǵģ]/g, 'g')
            .replace(/[ḢḤḦḨḪĤȞⱧꜦ]/g, 'H')
            .replace(/[ḣḥḧḩḫĥȟⱨ]/g, 'h')
            .replace(/[ḬḮĲÍÌÎÏĨĪĬĮǏƚ]/g, 'I')
            .replace(/[ḭḯĳíîìïĩīĭįǐıⱨ]/g, 'i')
            .replace(/[Ĵȷǰ]/g, 'J')
            .replace(/[ĵⱼ]/g, 'j')
            .replace(/[ḰḲḴĶƘꝀꝂꝄǨⱩ]/g, 'K')
            .replace(/[ḱḳḵķƙꝁꝃꝅǩⱪĸ]/g, 'k')
            .replace(/[ḶḸḺḼĹĻĽĿŁỈⱠȽꝈǇỊị⅃ȈȊ]/g, 'L')
            .replace(/[ḷḹḻḽĺļľŀłỉⱡꝉⱢǈǉİꞁ⅂ȉȋℓĸ]/g, 'l')
            .replace(/[MḾṀṂꟿⱮ]/g, 'M')
            .replace(/[mḿṁṃꟽƜ]/g, 'm')
            .replace(/[ṄṆṈṊŃŅŇǸÑȠŊƝǊǋ]/g, 'N')
            .replace(/[ṅṇṉṋńņňǹñƞŋŉǌȵ]/g, 'n')
            .replace(/[ÖṎṌṐṒȪȬȮȰǪǬỌỎỐỒỔỖỘỚỜỞỠỢƠŌŎŐÒÓÔÕǑȌȎŒØǾꝊꝎ⍥]/g, 'O')
            .replace(/[öṏṍṑṓȫȭȯȱǫǭọỏốồổỗộớờởỡợơōŏőòóôõǒȍȏœøǿꝏ⍤]/g, 'o')
            .replace(/[ṔṖƤⱣǷ]/g, 'P')
            .replace(/[ṕṗƥℙꟼ]/g, 'p')
            .replace(/[Ɋ]/g, 'Q')
            .replace(/[ɋ]/g, 'q')
            .replace(/[ŔŖŘṘṚṜṞȐȒɌƦꝚⱤ]/g, 'R')
            .replace(/[ŕŗřṙṛṝṟȑȓɍꝛ]/g, 'r')
            .replace(/[ṠṢṤṦṨŚŜŞŠȘȿƧϨẞ]/g, 'S')
            .replace(/[ṡṣṥṧṩśŝşšșꜱƨϩß]/g, 's')
            .replace(/[ṪṬṮṰŢŤŦȚȾƬƮ]/g, 'T')
            .replace(/[ṫṭṯṱţťŧțⱦƫƭẗȶ]/g, 't')
            .replace(/[ṲṴṶṸṺỦỤỨỪỮỰŨŪŬŮŰǙǗǛŲǓȔÛȖÙÚÜƯɄƲƱ]/g, 'U')
            .replace(/[ṳṵṷṹṻủụứừửữựũūŭůűǚǘǜųǔȕûȗùúüư]/g, 'u')
            .replace(/[ṼṾɅỼ]/g, 'V')
            .replace(/[ṽṿ℣ⱱⱴ]/g, 'v')
            .replace(/[WẀẂẄẆẈŴⱲϢ]/g, 'W')
            .replace(/[wẁẃẅẇẉŵⱳϣẘ]/g, 'w')
            .replace(/[ẊẌℵ]/g, 'X')
            .replace(/[ẋẍ×]/g, 'x')
            .replace(/[ẎỾỲỴỶỸŶƳŸÝɎȲƔ]/g, 'Y')
            .replace(/[ẏỿỳỵỷỹŷƴÿýɏȳẙy]/g, 'y')
            .replace(/[ẐẒẔŹŻŽȤⱫƵ]/g, 'Z')
            .replace(/[ẑẓẕźżžȥⱬƶɀ]/g, 'z')
            .replace(/[^a-z0-9-_]/gi, '');
    }
};
