export default {
    data: () => ({
        priceWithOptionModifiers: null,
        effectivePriceWithOptionModifiers: null,
    }),
    methods: {
        recalculatePriceByProductOptionModifiers(productData) {
            const productOptionsModel = this.buildProductOptionsModel(productData);
            const modifiersValue = productOptionsModel.reduce((acc, curr) => {
                return acc = acc + this.recalculateItemOptionModifier(curr.options, curr.quantity);
            }, 0);

            this.priceWithOptionModifiers = productData.price + modifiersValue;
            this.effectivePriceWithOptionModifiers = productData.effectivePrice + modifiersValue;
        },
        buildProductOptionsModel(productData) {
            const items = productData.productSetItems;
            const result = {
                options: productData.options,
                quantity: productData.quantity
            };

            if (items) {
                return [result, ...items.map(i => ({
                    options: i.setItem.options,
                    quantity: i.quantity
                }))];
            }

            return result;
        },
        recalculateItemOptionModifier(options, quantity) {
            return options.reduce((sum, curr) => {
                const selectedOption = curr.values.find(e => e.productOptionValueID === curr.productOptionValueID);
                return sum = sum + (selectedOption.priceModifier || 0) * quantity;
            }, 0);
        }
    }
};