import { defineStore } from 'pinia';
import { apiClient } from '@/Clients/ApiClient.js';
import UsersService from '@/Services/UsersService.js';

export const useAdminUserContextStore = defineStore({
    id: 'adminUserContext',
    state: () => ({
        userContext: {
            avatarUrl: null,
            hasAccessToAdminMode: false,
            impersonatingUser: null,
            isAuthenticated: false,
            isEditor: false,
            isImpersonated: false,
            isImpersonatedCustomer: false,
            isSiteAdmin: false,
            user: {
                creatorPermissions: {},
                email: null,
                fullName: null,
                globalPermissions: [],
                nickname: null,
                projectPermissions: {},
                quirkFlags: null,
                roleFlags: null,
                systemPermissions: [],
                userID: null
            }
        },
    }),
    actions: {
        async loadUserContext() {
            const response = await new UsersService(apiClient).getAdminUserContext();
            this.userContext =  response.data;
            return response;
        },
    }
});
