var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-processing-button",
        _vm.buttonStatusClass,
        { "is-processing": _vm.isProcessing },
      ],
    },
    [
      _c(
        "base-button",
        _vm._g(
          _vm._b(
            { attrs: { enabled: _vm.isEnabled } },
            "base-button",
            { ..._vm.$props, ..._vm.$attrs },
            false
          ),
          _vm.$listeners
        ),
        [
          _c(
            "span",
            { staticClass: "gfu-processing-button__content" },
            [_vm._t("default")],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.hasStatusChanged
        ? _c("div", {
            class: [
              "_fa _fa--lg",
              _vm.iconStatusClass,
              { "gfu-processing-button__spinner": !_vm.isProcessingCompleted },
            ],
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }