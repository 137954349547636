var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    ref: "fileInput",
    attrs: { type: "file", "data-qa": "file-input-field" },
    on: { change: _vm.onFileChange, click: _vm.onClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }