var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "gfu-flex__container gfu-flex__container--fill gfu-flex__container--center",
      },
      [
        _c(
          "div",
          { staticClass: "gfu-layout-wrapper gfu-layout-wrapper--narrow" },
          [
            _c("div", { staticClass: "_tac" }, [
              _c(
                "div",
                {
                  staticClass: "gfu-heading gfu-heading--decorative _ttr",
                  attrs: { "data-qa": "page-access-denied" },
                },
                [_vm._v("The requested resource does not exist")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "gfu-heading gfu-heading--soft" }, [
                _vm._v("(or you don't have sufficient permissions to view it)"),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }