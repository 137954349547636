const mobileMarginTop = 54; // Caused by mobile navbar
const standardMarginTop = 78; // Caused by standard navbar

export default {
    get windowRef() {
        return window;
    },

    getElementPositionFromTop(element, isMobile, rootMargin) {
        const bodyRect = window.document.body.getBoundingClientRect();
        const elemRect = element.getBoundingClientRect();
        const offsetTop = elemRect.top - bodyRect.top;
        const offsetAdjustment = (isMobile ? mobileMarginTop : standardMarginTop) + rootMargin;
        return offsetTop - offsetAdjustment;
    },

    get currentScrollTop() {
        return document.documentElement.scrollTop;
    }
};

export function addOnBeforeUnloadConfirmation(confirmationMessage, showConfirmationFunction) {
    window.addEventListener('beforeunload', (e) => {
        if (typeof showConfirmationFunction !== 'function' || showConfirmationFunction()) {
            (e || window.event).returnValue = confirmationMessage;
            return confirmationMessage;
        }
    });
}

export function getWindowHeight() {
    return window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
}

export function getWindowWidth() {
    return window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
}

export function isTouchDevice() {
    try {
        document.createEvent('TouchEvent');
        return true;
    }
    catch (e) {
        return false;
    }
}