import ServiceBase from './ServiceBase';

export default class ProjectRecommendationsService extends ServiceBase {
    constructor($http) {
        super($http);
    }

    getProjectRecommendations(quantity, excludeProjectIDs, source) {
        return this.request('post', 'projectRecommendations/getProjectRecommendations', { quantity, excludeProjectIDs, source });
    }
}