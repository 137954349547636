import '@/Utils/CustomValidators';
import Store from '../Store';
import { mapState as mapPiniaState, mapActions as mapPiniaActions } from 'pinia';
import { useUserContextStore } from '@/Stores/userContextStore';
import { useCartSummaryStore } from '@/Stores/cartSummaryStore';

import ValidatorHelpersMixin from '@/Mixins/ValidatorHelpers';
import NotificatorsMixin from '@/Mixins/Notificators';
import AsyncHelpersMixin from '@/Mixins/AsyncHelpers';
import { useProjectContextStore } from '@/Stores/Web/projectContextStore';

export default {
    mixins: [ValidatorHelpersMixin, AsyncHelpersMixin, NotificatorsMixin],
    store: Store,
    methods: {
        wrapApiAction(apiRequestPromise, fieldNamePrefix = '', preventHandleError = false) {
            return apiRequestPromise
                .then((response) => {
                    if (!response.data) {
                        return Promise.reject(Error('Invalid Api response.'));
                    }

                    return this.handleApiActionResult(response.data, fieldNamePrefix, preventHandleError);
                })
                .catch(error => {
                    if (error && error.response && error.response.data) {
                        return this.handleApiActionResult(error.response.data, fieldNamePrefix, preventHandleError);
                    }

                    return Promise.reject(error);
                });
        },
        handleApiActionResult(result, fieldNamePrefix = '', preventHandleError = false) {
            if (!result.success) {
                const unhandledFields = [];
                let responseHasErrors = false;

                if (result.fields?.length > 0) {
                    responseHasErrors = true;
                    for (const field of result.fields) {
                        if (!this.assignErrorToField(fieldNamePrefix, field.fieldName, field.message)) {
                            unhandledFields.push(field.fieldName);
                        }
                    }
                }
                else if (result.data?.errors && Object.keys(result.data.errors).length !== 0) {
                    responseHasErrors = true;
                    for (const key in result.data.errors) {
                        // only use first error because we don't support multiple errors on fields
                        const firstError = result.data.errors[key][0];
                        if (!this.assignErrorToField(fieldNamePrefix, key, firstError)) {
                            unhandledFields.push(key);
                        }
                    }
                }
                if (responseHasErrors) {
                    if (unhandledFields.length > 0) {
                        throw Error(`Invalid value in following field(s): ${ unhandledFields.join(', ') }`);
                    }

                    return !preventHandleError ? Promise.reject(Error('Invalid value in one of the fields.')) : Promise.reject();
                }

                return Promise.reject(result.message || 'Api operation failed.');
            }

            return Promise.resolve(result);
        },
        assignErrorToField(fieldNamePrefix, fieldName, fieldErrorMessage) {
            const key = fieldNamePrefix + fieldName;
            const valField = this.$validator.fields.find({ name: key });

            if (valField) {
                this.$validator.errors.add({
                    field: key,
                    msg: fieldErrorMessage || 'Field value is invalid.',
                    id: valField ? valField.id : null
                });

                return true;
            }

            return false;
        },
        ensureStoreInitialized() {
            return this.$store.dispatch('initialize');
        },
        getProjectContext() {
            return this.$store.state.admin.projectContext;
        },
        ...mapPiniaActions(useCartSummaryStore, ['updateCartSummary']),
        ...mapPiniaActions(useProjectContextStore, ['updateProjectContext'])
    },
    computed: {
        errors() {
            return this.$validator.errors;
        },
        ...mapPiniaState(useUserContextStore, ['userContext']),
        ...mapPiniaState(useProjectContextStore, ['projectContext'])
    },
    beforeMount() {
        this.initializeValidator();
    }
};
