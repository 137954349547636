import { defineStore } from 'pinia';
import { apiClient } from '@/Clients/ApiClient.js';
import { useProjectAdditionalInfoStore } from './projectAdditionalInfoStore';

import ProjectSetupCompletionService from '@/Services/Admin/ProjectSetupCompletionService';

import { projectPhases, projectStatuses } from '@/Models';

const { crowdfundingDraft, latePledge, pledgeManager } = projectPhases;

export const useProjectSetupCompletionStepsStore = defineStore({
    id: 'projectSetupCompletionSteps',
    state: () => ({
        projectSetupCompletionSteps: null,
    }),
    actions: {
        async refreshProjectSetupCompletionSteps(projectContext) {
            if (projectContext?.hasProject) {
                const projectAdditionalInfoStore = useProjectAdditionalInfoStore();
                try {
                    await projectAdditionalInfoStore.loadProjectAdditionalInfo(projectContext.projectID);

                    const desiredProjectPhase = projectAdditionalInfoStore.projectAdditionalInfo.currentSubmissionEntry?.desiredProjectPhase;
                    const isPostCrowdfundingSetupEnabled = [latePledge, pledgeManager].includes(desiredProjectPhase) && projectContext.project.phase !== desiredProjectPhase;
                    if (projectContext.project.phase === crowdfundingDraft
                        && (projectContext.project.status !== projectStatuses.published
                        || !projectContext.project.campaignStart)
                        || isPostCrowdfundingSetupEnabled) {
                        try {
                            const response = await new ProjectSetupCompletionService(apiClient)
                                .getRequiredTasks(projectContext.projectID);

                            if (response) {
                                const projectSetupCompletionSteps = response.data.map(value => ({
                                    isComplete: value.isComplete
                                }));

                                this.projectSetupCompletionSteps = projectSetupCompletionSteps;

                                return response;
                            }

                        }
                        catch (error) {
                            return Promise.resolve(); // Hack should be resolved as bugfix.
                        }
                    }
                }
                catch (error) {
                    return Promise.resolve(); // Hack should be resolved as bugfix.
                }
            }

            this.projectSetupCompletionSteps = null;

            return Promise.resolve();
        },
    }
});