import lazyScreen from '@/Components/Admin/LazyScreen';

const ProjectSettingsSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-locations' */  '../Projects/ProjectSettingsSideNav.vue'));
const ProjectLocationSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-locations' */  './ProjectLocationSettingsScreen.vue'));
const LocationsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-locations' */  './LocationsListScreen.vue'));
const ProjectLocationsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-locations' */  './ProjectLocationsListScreen.vue'));
const SubLocationsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-locations' */ './SubLocationsListScreen.vue'));
const LocationDetailsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-locations' */ './LocationDetailsScreen.vue'));

const routes = [
    { path: '/system/locations', name: 'system-locations', component: LocationsListScreen },
    { path: '/system/locations/:locationId/location-details', name: 'location-details', component: LocationDetailsScreen },
    { path: '/system/sub-locations', name: 'system-sub-locations', component: SubLocationsListScreen },
    { path: '/project-settings/:creator/:project/shipping/locations', name: 'project-settings-shipping-locations', components: { default: ProjectLocationsListScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/shipping/locations', redirect: { name: 'project-settings-shipping-locations' } },
    {
        path: '/project-settings/:creator/:project/shipping/locations/:projectLocationId/summary',
        name: 'project-location-summary',
        components: { default: ProjectLocationSettingsScreen, sidenav: ProjectSettingsSideNav },
        props: { default: route => ({ projectLocationId: parseInt(route.params.projectLocationId) }) }
    },
    { path: '/:creator?/:project?/locations', redirect: { name: 'project-settings-shipping-locations' } }
];

export {
    LocationsListScreen,
    ProjectLocationsListScreen,
    SubLocationsListScreen,
    LocationDetailsScreen,
    routes
};