import ServiceBase from '../ServiceBase';

export default class ProjectSetupCompletionController extends ServiceBase {

    getRequiredTasks(projectID) {
        return this.request('get', '/admin/projectSetupCompletion/getRequiredTasks', { projectID });
    }

    selectProjectSetupType(projectID, desiredProjectPhase) {
        const params = {
            projectID,
            desiredProjectPhase
        };

        return this.request('post', '/admin/projectSetupCompletion/selectProjectSetupType', params);
    }
}
