var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "side-nav-container",
    {
      attrs: {
        title: _vm.sideNavHeader || "Categories",
        icon: _vm.sideNavIcon || "cubes",
      },
      scopedSlots: _vm._u([
        {
          key: "navigation-link",
          fn: function () {
            return [
              _vm.goBackAction
                ? _c(
                    "side-nav-link-back",
                    { attrs: { to: _vm.goBackAction.to } },
                    [_vm._v(_vm._s(_vm.goBackAction.label))]
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._l(_vm.baseCategories, function (row) {
        return [
          _vm.childCategories(row).length ||
          (_vm.focus && row.canHaveChildCategories)
            ? [
                _c(
                  "toggle-trigger",
                  {
                    key: `toggle-trigger-category-${row.categoryID}`,
                    ref: `category-${row.categoryID}`,
                    refInFor: true,
                    class: [
                      "gfu-sidenav__item",
                      "gfu-sidenav__trigger",
                      {
                        "gfu-sidenav__item--selected":
                          row.categoryID === _vm.categoryId,
                      },
                    ],
                    attrs: {
                      "container-name": `container-category-${row.categoryID}`,
                      tag: "div",
                      "auto-collapse": false,
                      "active-class": "gfu-sidenav__trigger--expanded",
                      draggable: "false",
                      "data-qa": `product-categories-sidenav:${row.categoryID}`,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectCategory(row.categoryID)
                      },
                      drop: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleDrop(row)
                      },
                      dragenter: function ($event) {
                        return _vm.handleDragEnter(row)
                      },
                      dragleave: function ($event) {
                        return _vm.handleDragLeave(row)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "gfu-sidenav__content",
                        attrs: { "data-qa": "product-categories-sidenav:Name" },
                      },
                      [_vm._v(_vm._s(row.name))]
                    ),
                    _vm._v(" "),
                    _c("product-categories-side-nav-actions", {
                      attrs: {
                        "category-id": row.categoryID,
                        "can-create": row.canHaveChildCategories,
                        "category-type": row.type,
                        draggable: !_vm.focus,
                      },
                      on: {
                        delete: function ($event) {
                          return _vm.deleteCategory(row)
                        },
                        dragstart: (ev) => _vm.handleDragStart(ev, row),
                        dragend: _vm.handleDragEnd,
                        "create-child": function ($event) {
                          return _vm.createChild(row)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "toggle-container",
                  {
                    key: `toggle-container-category-${row.categoryID}`,
                    staticClass: "gfu-sidenav__collapsible",
                    attrs: {
                      tag: "div",
                      name: `container-category-${row.categoryID}`,
                      "active-class": "gfu-sidenav__collapsible--expanded",
                      tabindex: "0",
                      "show-expanded": _vm.focus,
                    },
                  },
                  [
                    _vm._l(_vm.childCategories(row), function (subRow) {
                      return [
                        subRow.categoryID
                          ? _c(
                              "a",
                              {
                                key: `sub-category-${subRow.categoryID}-product-list-link`,
                                ref: `category-${subRow.categoryID}`,
                                refInFor: true,
                                class: [
                                  "gfu-sidenav__subitem",
                                  {
                                    "gfu-sidenav__subitem--selected":
                                      subRow.categoryID === _vm.categoryId,
                                  },
                                ],
                                attrs: {
                                  href: "",
                                  draggable: "false",
                                  "data-qa": `product-categories-sidenav:${subRow.categoryID}`,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.selectCategory(subRow.categoryID)
                                  },
                                  drop: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.handleDrop(subRow)
                                  },
                                  dragover: _vm.handleDragOver,
                                  dragenter: function ($event) {
                                    return _vm.handleDragEnter(subRow)
                                  },
                                  dragleave: function ($event) {
                                    return _vm.handleDragLeave(subRow)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gfu-sidenav__content gfu-sidenav__content--no-icon",
                                    attrs: {
                                      "data-qa":
                                        "product-categories-sidenav:Name",
                                    },
                                  },
                                  [_vm._v(_vm._s(subRow.name))]
                                ),
                                _vm._v(" "),
                                _c("product-categories-side-nav-actions", {
                                  attrs: {
                                    "category-id": subRow.categoryID,
                                    "is-child-category": true,
                                  },
                                  on: {
                                    delete: function ($event) {
                                      return _vm.deleteCategory(subRow)
                                    },
                                    dragstart: (ev) =>
                                      _vm.handleDragStart(ev, subRow),
                                    dragend: _vm.handleDragEnd,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm.isCreating &&
                            subRow.parentID === _vm.editRow.parentID
                          ? _c(
                              "div",
                              {
                                key: "row-sub-category-" + subRow.categoryID,
                                staticClass: "gfu-flex__container _mt-1 _mb-1",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gfu-flex__item gfu-flex__item--grow _ml-6",
                                  },
                                  [
                                    _c("text-field", {
                                      ref: "editFocus",
                                      refInFor: true,
                                      staticClass: "_mt-0",
                                      attrs: {
                                        name: "editRow.name",
                                        small: true,
                                        "show-label": false,
                                        "display-name": "Name",
                                        "validator-layout": "tooltip",
                                        "data-qa":
                                          "admin-product-sidenav:SubcategoryName",
                                      },
                                      on: {
                                        keyup: [
                                          function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.submitCreating.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "esc",
                                                27,
                                                $event.key,
                                                ["Esc", "Escape"]
                                              )
                                            )
                                              return null
                                            return _vm.stopCreating.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        ],
                                      },
                                      model: {
                                        value: _vm.editRow.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editRow, "name", $$v)
                                        },
                                        expression: "editRow.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gfu-sidenav-actions gfu-sidenav-actions--editable",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "_fr _ml-1",
                                        attrs: {
                                          "data-qa":
                                            "product-categories-sidenav:Cancel",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.stopCreating.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "gfu-btn--toggler _fa _fa--times gfu-btn gfu-btn--circle gfu-btn--primary",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "_fr",
                                        attrs: {
                                          "data-qa":
                                            "product-categories-sidenav:Save",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.submitCreating.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "gfu-btn--toggler _fa _fa--tick gfu-btn gfu-btn--circle gfu-btn--accent",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                    _vm._v(" "),
                    !_vm.isCreating
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "gfu-sidenav__subitem gfu-sidenav__subitem--light",
                            attrs: {
                              draggable: "false",
                              href: "",
                              "data-qa":
                                "product-categories-sidenav:CreateSubcategory",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.createChild(row)
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass:
                                "gfu-sidenav__icon gfu-sidenav__icon--inline _fa _fa--plus",
                            }),
                            _vm._v("new subcategory\n                "),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : row.categoryID
            ? _c(
                "div",
                {
                  key: `category-${row.categoryID}-product-list-link`,
                  ref: `category-${row.categoryID}`,
                  refInFor: true,
                  class: [
                    "gfu-sidenav__item",
                    {
                      "gfu-sidenav__item--selected":
                        row.categoryID === _vm.categoryId,
                    },
                  ],
                  attrs: {
                    draggable: "false",
                    "data-qa": `product-categories-sidenav:${row.categoryID}`,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.selectCategory(row.categoryID)
                    },
                    drop: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.handleDrop(row)
                    },
                    dragover: _vm.handleDragOver,
                    dragenter: function ($event) {
                      return _vm.handleDragEnter(row)
                    },
                    dragleave: function ($event) {
                      return _vm.handleDragLeave(row)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "gfu-sidenav__content",
                        _vm.focus
                          ? "gfu-sidenav__trigger gfu-sidenav__trigger--puzzle-piece"
                          : "gfu-sidenav__content--no-icon",
                        "_flex",
                      ],
                      attrs: { "data-qa": "product-categories-sidenav:Name" },
                    },
                    [_vm._v(_vm._s(row.name))]
                  ),
                  _vm._v(" "),
                  _c("product-categories-side-nav-actions", {
                    attrs: {
                      "category-id": row.categoryID,
                      "can-create": row.canHaveChildCategories,
                      "category-type": row.type,
                      draggable: !_vm.focus,
                    },
                    on: {
                      delete: function ($event) {
                        return _vm.deleteCategory(row)
                      },
                      dragstart: (ev) => _vm.handleDragStart(ev, row),
                      dragend: _vm.handleDragEnd,
                      "create-child": function ($event) {
                        return _vm.createChild(row)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _vm.isCreateMainCategoryVisible
        ? _c(
            "a",
            {
              staticClass: "gfu-sidenav__item gfu-sidenav__item--light",
              attrs: {
                draggable: "false",
                href: "",
                "data-qa": "product-categories-sidenav:CreateCategory",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.startCreating()
                },
              },
            },
            [
              _c("span", {
                staticClass:
                  "gfu-sidenav__icon gfu-sidenav__icon--inline _fa _fa--plus",
              }),
              _vm._v(" new category\n    "),
            ]
          )
        : _vm.isCreating && _vm.editRow.parentID === null
        ? _c("div", { staticClass: "gfu-flex__container _mt-1 _mb-1" }, [
            _c(
              "div",
              { staticClass: "gfu-flex__item gfu-flex__item--grow _ml-6" },
              [
                _c("text-field", {
                  ref: "editFocus",
                  staticClass: "_mt-0",
                  attrs: {
                    name: "editRow.name",
                    inline: false,
                    small: true,
                    "show-label": false,
                    "display-name": "Name",
                    "validator-layout": "tooltip",
                    "data-qa": "product-categories-sidenav:NewCategoryName",
                  },
                  on: {
                    keyup: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.submitCreating.apply(null, arguments)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        return _vm.stopCreating.apply(null, arguments)
                      },
                    ],
                  },
                  model: {
                    value: _vm.editRow.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.editRow, "name", $$v)
                    },
                    expression: "editRow.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "gfu-sidenav-actions gfu-sidenav-actions--editable",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "_fr _ml-1",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.stopCreating.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass:
                        "gfu-btn--toggler _fa _fa--times gfu-btn gfu-btn--circle gfu-btn--primary",
                      attrs: { "data-qa": "product-categories-sidenav:Cancel" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "_fr",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submitCreating.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass:
                        "gfu-btn--toggler _fa _fa--tick gfu-btn gfu-btn--circle gfu-btn--accent",
                      attrs: { "data-qa": "product-categories-sidenav:Save" },
                    }),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }