import { productTypes } from '@/Models.js';
import { cloneDeep } from '@/Utils';

export default {
    data() {
        return {
            productData: null,
            maxQuantity: null,
            originalProductData: null,
            extraSetItems: []
        };
    },
    computed: {
        productSetItems() {
            if (this.productData && !this.isProductPiece(this.productData)) {
                return this.productData.productSetItems.filter(s => s.setItem.options.length > 0);
            }

            return [];
        },
        invalidQuantityLimits() {
            const result = [];

            if (this.originalProductData && this.maxQuantity && this.model.quantity) {
                if (this.originalProductData.maxQuantity != null
                    && this.model.quantity > this.originalProductData.maxQuantity) {
                    result.push(this.productData);
                }
                if (this.originalProductData.productSetItems) {
                    for (const item of this.originalProductData.productSetItems) {
                        if (item.setItem.maxQuantity != null
                            && this.getTotalSetItemQuantity(item) > item.setItem.maxQuantity) {
                            result.push(item.setItem);
                        }
                    }
                }
            }

            return result;
        }
    },
    methods: {
        setDefaultOptionValues() {
            if (this.productData) {
                for (const option of this.productData.options) {
                    if (!option.productOptionValueID) {
                        option.productOptionValueID = option.defaultProductOptionValueID;
                    }
                }
                for (const item of this.productData.productSetItems) {
                    for (const option of item.setItem.options) {
                        if (!option.productOptionValueID) {
                            option.productOptionValueID = option.defaultProductOptionValueID;
                        }
                    }
                }
            }
        },
        isProductPiece(product) {
            return product.type !== productTypes.set;
        },
        canProductBeRemoved(productSetItem) {
            return this.extraSetItems.includes(productSetItem);
        },
        onQuantityChanged(productSetItem, quantity) {
            const qty = Number(quantity);
            const difference = qty - productSetItem.quantity;
            productSetItem.quantity = qty;
            const sourceItem = this.getSourceItem(productSetItem, this.productData);
            if (this.getAvailableQuantity(productSetItem) < difference) {
                sourceItem.quantity = 1;
            }
            else if (difference < 0 && this.getMaxQuantity(productSetItem) < sourceItem.quantity - difference) {
                sourceItem.quantity = this.getMaxQuantity(productSetItem) - this.getTotalSplitItemsQuantity(productSetItem);
            }
            else {
                sourceItem.quantity -= difference;
            }
        },
        prepareProductData(productData) {
            this.extraSetItems = [];
            this.originalProductData = productData;
            this.productData = cloneDeep(productData);
            this.setDefaultOptionValues();
            this.model.productID = productData.productID;
            this.recalculateSetItemQuantities(this.model.quantity);
            this.maxQuantity = this.productData.maxQuantity;

            if (this.productData.productSetItems) {
                for (const item of this.productData.productSetItems) {
                    if (item.setItem.maxQuantity != null) {
                        this.maxQuantity = Math.min(this.maxQuantity || 9999,
                            Math.floor(item.setItem.maxQuantity / item.quantity));
                    }
                }
            }
        },
        clearProductData() {
            this.model.product = null;
            this.model.productID = null;
            this.productData = null;
            this.extraSetItems = [];
            this.quantityLimits = [];
            this.maxQuantity = null;

            this.$validator.reset();
        },
        canProductBeSplit(productSetItem) {
            return productSetItem.quantity > 1
                && productSetItem.setItem.options.length > 0
                && !this.canProductBeRemoved(productSetItem);
        },
        getAvailableQuantity(productSetItem) {
            return this.getMaxQuantity(productSetItem) - this.getTotalSplitItemsQuantity(productSetItem) - 1;
        },
        getSourceItem(productSetItem, productData) {
            return productData.productSetItems.find(p => p.setItemID === productSetItem.setItemID);
        },
        getTotalSetItemQuantity(productSetItem) {
            return this.productData ? this.productData.productSetItems.filter(i => i.setItemID === productSetItem.setItemID)
                .map(i => i.quantity)
                .reduce((a, b) => a + b, 0) : 0;
        },
        getTotalSplitItemsQuantity(productSetItem) {
            return this.extraSetItems.filter(i => i.setItemID === productSetItem.setItemID).map(i => i.quantity)
                .reduce((a, b) => a + b, 0);
        },
        getMaxQuantity(productSetItem) {
            return this.getSourceItem(productSetItem, this.originalProductData).quantity * this.model.quantity;
        },
        removeProductItem(productSetItem) {
            const rewardSetItem = this.originalProductData.productSetItems.find(i => i.productSetItemID === productSetItem.productSetItemID);
            const originalSetItem = this.productData.productSetItems.find(i => i.productSetItemID === productSetItem.productSetItemID);
            if (rewardSetItem && originalSetItem) {
                this.extraSetItems.splice(this.extraSetItems.indexOf(productSetItem), 1);
                this.productData.productSetItems.splice(this.productData.productSetItems.indexOf(productSetItem), 1);
                const extraSetItemsQuantity = this.extraSetItems.reduce((sum, item) => sum
                    + (item.productSetItemID === originalSetItem.productSetItemID ? item.quantity : 0), 0);
                originalSetItem.quantity = this.model.quantity * rewardSetItem.quantity - extraSetItemsQuantity;
            }
        },
        splitProductItem(productSetItem) {
            const index = this.productData.productSetItems.indexOf(productSetItem);
            const newItem = cloneDeep(productSetItem);
            newItem.quantity = 1;
            productSetItem.quantity -= 1;
            this.productData.productSetItems.splice(index + 1, 0, newItem);
            this.extraSetItems.push(newItem);
        },
        recalculateSetItemQuantities(mainQuantity) {
            for (const item of this.originalProductData.productSetItems) {
                const requiredQty = item.quantity * mainQuantity;
                let difference = requiredQty - this.getTotalSetItemQuantity(item);
                const sourceItem = this.getSourceItem(item, this.productData);

                let extraItem;
                if (difference > 0) {
                    for (extraItem in this.productData.productSetItems.filter(i => i.setItemID === item.setItemID && i.quantity === 0)) {
                        extraItem.quantity = 1;
                        difference--;
                        if (difference === 0) {
                            break;
                        }
                    }

                    sourceItem.quantity += difference;
                }
                else if (difference < 0) {
                    if (sourceItem.quantity + difference > 0) {
                        sourceItem.quantity += difference;
                    }
                    else {
                        difference += sourceItem.quantity - 1;
                        sourceItem.quantity = 1;

                        for (extraItem in this.productData.productSetItems.filter(i => i.setItemID === item.setItemID && i.quantity === 0)) {
                            extraItem.quantity = 0;
                            difference++;
                            if (difference === 0) {
                                break;
                            }
                        }
                    }
                }
            }
        },
        quantityIsExceeded() {
            if (this.maxQuantity != null && this.maxQuantity < this.model.quantity) {
                return false;
            }

            return true;
        }
    }
};
