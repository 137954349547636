var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-slider", {
    class: [
      "gfu-testimonial-slider",
      { "gfu-testimonial-slider--wide": _vm.isMarketingServicesPage },
    ],
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ onAction, currentItem }) {
            return _vm._l(_vm.testimonials, function (item, index) {
              return _c(
                "div",
                {
                  key: item.name,
                  class: [
                    "gfu-base-slider-slide gfu-creator-testimonial",
                    { "is-active": index === currentItem },
                  ],
                  on: { click: onAction },
                },
                [
                  _c("div", { staticClass: "gfu-creator-testimonial__side" }, [
                    _c("img", {
                      staticClass: "gfu-creator-testimonial__image",
                      attrs: { src: item.imageUrl, alt: item.company },
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "h4",
                        { staticClass: "gfu-hd gfu-hd--h4 _tc--inherit" },
                        [_vm._v(_vm._s(item.creator))]
                      ),
                      _vm._v(" "),
                      _c(
                        "h4",
                        {
                          staticClass:
                            "gfu-hd gfu-hd--h4 _tc--inherit _tc--mute _fw-n",
                        },
                        [_vm._v(_vm._s(item.company))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: [
                        "gfu-creator-testimonial__content",
                        {
                          "gfu-creator-testimonial__content--narrow":
                            _vm.isMarketingServicesPage,
                        },
                      ],
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.content) +
                          "\n                "
                      ),
                      !_vm.isMarketingServicesPage
                        ? _c(
                            "div",
                            {
                              staticClass: "gfu-media gfu-media--middle _mt-6",
                            },
                            [
                              _c("img", {
                                staticClass: "_mr-3",
                                attrs: {
                                  src: item.projectImageUrl,
                                  alt: item.projectName,
                                  height: "64",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "gfu-media__body" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$format(item.fundsGathered, "C0", "$")
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  "\n                        raised from "
                                ),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$format(item.backersCount, "N0"))
                                  ),
                                ]),
                                _vm._v(
                                  "\n                        backers during the crowdfunding campaign of " +
                                    _vm._s(item.projectName) +
                                    ".\n                    "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            })
          },
        },
        {
          key: "indicators",
          fn: function ({ onSetCurrentIndex, currentItem }) {
            return [
              _c(
                "nav",
                { staticClass: "gfu-base-slider-indicators" },
                _vm._l(_vm.testimonials, function (item, index) {
                  return _c(
                    "a",
                    {
                      key: index,
                      class: [
                        "gfu-base-slider-indicators__indicator",
                        { "is-active": index === currentItem },
                      ],
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return onSetCurrentIndex(index)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " + _vm._s(index) + "\n            "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          },
        },
        _vm.isMarketingServicesPage
          ? {
              key: "nav",
              fn: function ({ onNext, onPrev }) {
                return [
                  _c("base-slider-navigation", {
                    staticClass: "gfu-testimonial-slider-nav--desktop",
                    attrs: { type: "prev" },
                    on: { click: onPrev },
                  }),
                  _vm._v(" "),
                  _c("base-slider-navigation", {
                    staticClass: "gfu-testimonial-slider-nav--desktop",
                    attrs: { type: "next" },
                    on: { click: onNext },
                  }),
                ]
              },
            }
          : {
              key: "nav",
              fn: function ({ onNext, onPrev }) {
                return [
                  _c("span", {
                    staticClass:
                      "gfu-testimonial-slider-nav gfu-testimonial-slider-nav--prev",
                    on: { click: onPrev },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass:
                      "gfu-testimonial-slider-nav gfu-testimonial-slider-nav--next",
                    on: { click: onNext },
                  }),
                ]
              },
            },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }