var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "gfu-layout__main" }, [
    _c(
      "h1",
      { staticClass: "gfu-hd gfu-hd--h1 _mt-6 _mb-3" },
      [
        _c(
          "localize",
          { attrs: { resource: _vm.Resources.Layout.CreatorProjectsHeader } },
          [
            _c("span", { attrs: { "format-key": "0" } }, [
              _vm._v(_vm._s(_vm.creatorProjectsPluralized)),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "_tc--accent" }, [
          _vm._v(_vm._s(_vm.projectList.totalItemCount)),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gfu-card-list gfu-card-list--wrap" },
      _vm._l(_vm.projectList.pagedItems, function (project) {
        return _c(
          "div",
          {
            key: project.projectID,
            staticClass: "gfu-card-list__item gfu-1of1 gfu-1of3--m gfu-1of4--l",
          },
          [_c("project-card", _vm._b({}, "project-card", project, false))],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }