var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-search-title" }, [
    _c(
      "h1",
      {
        staticClass: "gfu-hd gfu-hd--h1 _whs-nw",
        attrs: { "data-qa": "search-title" },
      },
      [
        _vm._v("\n        " + _vm._s(_vm.title) + "\n        "),
        _c(
          "span",
          {
            staticClass: "_tc--light _fw-n _fa--xs",
            attrs: { "data-qa": `search-count:${_vm.totalItemCount}` },
          },
          [
            _vm._v(
              "(" +
                _vm._s(_vm.totalItemCount) +
                " " +
                _vm._s(_vm.resultLabel) +
                ")"
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }