var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-sidenav-actions", class: { _tar: !_vm.draggable } },
    [
      _vm.draggable
        ? _c("a", {
            staticClass:
              "gfu-sidenav-actions__item gfu-sidenav-actions__item--draggable _fa _fa--grip-lines",
            attrs: {
              href: "",
              draggable: "true",
              "data-qa": "admin-product-sidenav-drag:" + _vm.categoryId,
            },
            on: { dragstart: _vm.onDragStart, dragend: _vm.onDragEnd },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.canCreate || _vm.canDelete || _vm.canChangeSettings
        ? [
            _c("toggle-trigger", {
              staticClass: "gfu-sidenav-actions__item _fa _fa--cog",
              attrs: {
                tag: "a",
                href: "",
                "active-class": "gfu-sidenav-actions__item--selected",
                "data-qa": "admin-product-sidenav-settings:Cog",
              },
            }),
            _vm._v(" "),
            _c(
              "toggle-container",
              {
                staticClass:
                  "gfu-balloon gfu-balloon--right gfu-balloon--no-header gfu-balloon--compact gfu-table__dropdown is-hidden _mr-1",
                attrs: {
                  tag: "div",
                  tabindex: "0",
                  "active-class": "is-open",
                  "data-qa": "admin-product-sidenav-settings:Ballon",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "gfu-balloon__content" },
                  [
                    _vm.canCreate
                      ? _c(
                          "a",
                          {
                            staticClass: "gfu-balloon__content-link",
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.onCreateChild.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass:
                                "gfu-balloon__content-icon fa-fw _fa _fa--plus",
                              attrs: {
                                "data-qa":
                                  "admin-product-sidenav-create:" +
                                  _vm.categoryId,
                              },
                            }),
                            _vm._v("Add subcategory\n                "),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canDelete
                      ? _c(
                          "a",
                          {
                            staticClass: "gfu-balloon__content-link",
                            attrs: { href: "" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.deleteCategory.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass:
                                "gfu-balloon__content-icon fa-fw _fa _fa--trash-alt",
                              attrs: {
                                "data-qa":
                                  "admin-product-sidenav-delete:" +
                                  _vm.categoryId,
                              },
                            }),
                            _vm._v("Delete\n                "),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canChangeSettings
                      ? _c(
                          "admin-router-link",
                          {
                            staticClass: "gfu-balloon__content-link",
                            attrs: {
                              to: {
                                name: _vm.settingsRouteName,
                                params: { categoryId: _vm.categoryId },
                              },
                              "data-qa":
                                "admin-product-sidenav-settings:" +
                                _vm.categoryId,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.blur.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass:
                                "gfu-balloon__content-icon fa-fw _fa _fa--cog",
                            }),
                            _vm._v("Settings\n                "),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }