var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("fake-instant-payment-form", {
        ref: "fakeForm",
        attrs: {
          "order-code": _vm.orderCode,
          "checkout-mode": _vm.checkoutMode,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-box-actions" }, [
        _c("div", { staticClass: "gfu-box-actions__item" }, [
          !_vm.orderState
            ? _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: {
                    href: _vm.backAction,
                    "data-qa": "create-order-btn:Back",
                  },
                },
                [
                  _c("base-icon", {
                    staticClass: "_fl _lhb",
                    attrs: { name: "chevron-left" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "_ml-1" }, [
                    _vm._v(_vm._s(_vm.Resources.Checkout.CheckoutGoBackLink)),
                  ]),
                ],
                1
              )
            : _vm.orderCode
            ? _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: { href: "", "data-qa": "create-order-btn:Back" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.defaultBackAction.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("base-icon", {
                    staticClass: "_fl _lhb",
                    attrs: { name: "chevron-left" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "_ml-1" }, [
                    _vm._v(_vm._s(_vm.Resources.Checkout.CheckoutGoBackLink)),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gfu-box-actions__item _ml-a" },
          [
            _c(
              "tooltip",
              { attrs: { text: _vm.validationMessage } },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "gfu-btn--block",
                    attrs: {
                      href: "",
                      theme: "accent",
                      "data-qa": "create-order-btn:Submit",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.Resources.Checkout.CheckoutSubmitOrderButton
                        ) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }