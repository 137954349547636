var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "side-nav-container",
    {
      attrs: { title: "Product settings", icon: "cog" },
      scopedSlots: _vm._u([
        {
          key: "navigation-link",
          fn: function () {
            return [
              _vm.parentRoute
                ? _c(
                    "side-nav-link-back",
                    {
                      attrs: {
                        to: _vm.parentRoute.to,
                        "data-qa": "admin-product-sidenav-link:Back",
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.parentRoute.label) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._l(
        _vm.navLinks,
        function ({ id, linkName, iconName, dataQa, routeName }) {
          return _c(
            "side-nav-link",
            {
              key: id,
              attrs: {
                to: {
                  name: routeName + _vm.focusPostfix,
                  params: _vm.productParams,
                },
                icon: iconName,
                "data-qa": dataQa,
              },
            },
            [_vm._v("\n        " + _vm._s(linkName) + "\n    ")]
          )
        }
      ),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }