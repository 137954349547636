import { defineStore } from 'pinia';

import { apiClient } from '@/Clients/ApiClient';
import CheckoutService from '@/Services/CheckoutService';

const checkoutService = new CheckoutService(apiClient);

export const useInstallmentsStore = defineStore({
    id: 'installments',
    state: () => ({
        installments: [],
        currencySymbol: null,
        installmentValue: null,
        isChargingInstallments: false,
    }),
    actions: {
        async getInstallmentsData(projectId, exchangeRate, numberOfInstallments, orderCode = null) {
            try {
                return await checkoutService.calculateCartInstallmentsPlan(projectId, exchangeRate, numberOfInstallments, orderCode);
            }
            catch (error) {
                throw new Error(error.message);
            }
        },
        setInstallments({ data }) {
            this.installments = data.installments;
            this.currencySymbol = data.currencySymbol;
            // We take the last installment because the first installment may be larger if it cannot be divided evenly.
            this.installmentValue = data.installments[data.installments.length - 1].amountConverted;
        },
        setActionStatus(status) {
            this.isChargingInstallments = status;
        }
    },
    getters: {
        installmentsCount() {
            return this.installments.length;
        },
    }
});