import { projectUserRoles } from '@/Models';

export function userHasProjectPermission(user, permission, creatorID, projectID) {
    if (user) {
        if (user.globalPermissions.includes(permission))
            return true;

        if (creatorID && user.creatorPermissions[creatorID]?.includes(permission))
            return true;

        if (projectID && user.projectPermissions[projectID]?.includes(permission))
            return true;
    }

    return false;
}

export function userHasSystemPermission(user, permission) {
    if (user) {
        return user.systemPermissions.includes(permission);
    }

    return false;
}

export function getAvailableProjectUserRolesForProject(projectAdditionalInfo) {
    const allProjectUserRoles = Object.values(projectUserRoles)
        .filter(r => r.displayName);

    if (projectAdditionalInfo.testerRoleEnabled)
        return allProjectUserRoles;

    return allProjectUserRoles.filter(item => item.value !== projectUserRoles.tester);
}

export const hasProjectPermissionCurried = ( user, creatorID, projectID ) => permission => userHasProjectPermission(
    user,
    permission,
    creatorID,
    projectID
);