var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-recommendation-project-card" },
    [
      _c("base-card", {
        scopedSlots: _vm._u([
          {
            key: "labels",
            fn: function () {
              return _vm._l(_vm.labels, function (label) {
                return _c(
                  "base-badge",
                  {
                    key: label.name,
                    staticClass: "_mr-1 _my-1",
                    attrs: { theme: label.theme },
                  },
                  [_vm._v(_vm._s(label.name))]
                )
              })
            },
            proxy: true,
          },
          {
            key: "media",
            fn: function () {
              return [
                _c(
                  "a",
                  {
                    staticClass: "gfu-embed gfu-embed--1x1",
                    attrs: { href: _vm.url, title: _vm.name },
                  },
                  [
                    _c("base-image", {
                      staticClass: "gfu-embed__item",
                      attrs: {
                        src: _vm.imageUrl,
                        alt: _vm.name,
                        loading: "lazy",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "gfu-project-card__stamps" },
                      [
                        _vm.isFollowControlsVisible
                          ? _c("project-card-follow", {
                              staticClass: "_mr-2",
                              attrs: {
                                "project-id": _vm.projectID,
                                "project-url": _vm.url,
                                "project-name": _vm.name,
                                "follower-count": _vm.followerCount,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("div", { staticClass: "_mt-3" }, [
        _c("a", { attrs: { href: _vm.url } }, [_vm._v(_vm._s(_vm.name))]),
        _vm._v(" "),
        _c("div", { staticClass: "_mt-1" }, [
          _c("strong", [_vm._v(_vm._s(_vm.projectFacts))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "_mt-1" },
            [
              _vm.isStablePledgeBadgeVisible
                ? _c("project-card-badge", {
                    attrs: {
                      icon: _vm.stablePledgeBadge.icon,
                      "tooltip-text": _vm.stablePledgeBadge.tooltipText,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }