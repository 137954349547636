import { debounce } from '@/Utils';
import { getWindowWidth } from '@/Utils/WindowUtility.js';
import { breakpoint } from '@/Types/StyleTypes.js';

export default {
    data: () => ({
        isMobile: false,
    }),
    methods: {
        checkBreakpoint() {
            const width = getWindowWidth();
            this.isMobile = width < breakpoint.m;
        },
    },
    mounted() {
        this.checkBreakpoint = debounce(this.checkBreakpoint.bind(this), 100);
        window.addEventListener('resize', this.checkBreakpoint);
        this.checkBreakpoint();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkBreakpoint);
    }
};