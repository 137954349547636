const __filterQueryPrefix = 'filter-';
import { areObjectsEqual } from '@/Utils';
import { isDate } from '@/Utils';

// TODO: This entire thing should not be mixin
// I've moved all the code in one place so it's easier to rewrite it to filter class
// Consider usage of this.$route in code below when rewritting
export default {
    data() {
        return {
            filters: {},
            selectFilters: {}
        };
    },
    created() {
        const filters = this.createFiltersFromQuery(this.$route.query);
        this.initializeFilters(filters);
        this.filters = { ...filters };
        this.selectFilters = { ...filters };
    },
    computed: {
        anyFilterModified() {
            const defaultFilters = this.getDefaultFilters();
            for (const [key, value] of Object.entries(this.filters)) {
                const defaultFilterValue = defaultFilters[key];
                if (Array.isArray(defaultFilterValue)) {
                    if (!this.primitiveAreArraysEqual(defaultFilterValue, value)) {
                        return true;
                    }
                }
                else if (!this.primitiveAreFilterValuesEqual(defaultFilterValue, value)) {
                    return true;
                }
            }

            return false;
        },
    },
    methods: {
        primitiveAreArraysEqual(array, arrayToCompare) {
            if (array.length !== arrayToCompare.length) {
                return false;
            }

            // TODO: This is very naive way to compare arrays
            if (!array.every(i => arrayToCompare.includes(i))) {
                return false;
            }

            return true;
        },
        primitiveAreFilterValuesEqual(filterValue, filterValueToCompare) {
            if (filterValue === filterValueToCompare) {
                return true;
            }

            // treat empty strings as nulls
            if ((filterValue === '' || filterValue === null) && (filterValueToCompare === '' || filterValueToCompare === null)) {
                return true;
            }

            return false;
        },
        // this exist to be overriden
        getDefaultFilters() {
            return {};
        },
        updateFiltersQuery(newFilters) {
            const defaultFilters = this.getDefaultFilters();

            const newQuery = {
                ...this.$route.query
            };

            for (const [key, value] of Object.entries(newFilters)) {
                const defaultFilterValue = defaultFilters[key];
                const filterKey = __filterQueryPrefix + key;
                // TODO: Why is array not handled here?
                if (!this.primitiveAreFilterValuesEqual(defaultFilterValue, value)) {
                    newQuery[filterKey] = value;
                }
                else if (Object.keys(newQuery).includes(filterKey)) {
                    delete newQuery[filterKey];
                }
            }

            if (!areObjectsEqual(this.$route.query, newQuery)) {
                this.$router.replace({
                    path: this.$route.path,
                    query: newQuery
                });
            }

            this.$set(this, 'filters', { ...newFilters });
            this.$set(this, 'selectFilters', { ...newFilters });
        },
        createFiltersFromQuery(query) {
            const filters = this.getDefaultFilters();

            for (const [key, value] of Object.entries(query)) {
                if (key.startsWith(__filterQueryPrefix)) {
                    const valueKey = key.substring(__filterQueryPrefix.length);
                    if (Array.isArray(filters[valueKey]) && !Array.isArray(value)) {
                        filters[valueKey] = [value];
                    }
                    else if (isDate(value)) {
                        filters[valueKey] = new Date(value).toISOString();
                    }
                    else {
                        filters[valueKey] = value;
                    }
                }
            }

            return filters;
        },
        resetFiltersToDefaultValues() {
            const defaultFilters = this.getDefaultFilters();
            this.$set(this, 'filters', { ...defaultFilters });
            this.$set(this, 'selectFilters', { ...defaultFilters });
        },
        initializeFilters(filters) {
            // override in consumer components to add custom initialization
        }
    },
};