var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "gfu-layout__main gfu-layout__main--narrow" },
    [
      _vm.hasMoreThanOneStep
        ? _c("steps-indicator", {
            attrs: { steps: _vm.steps, "is-in-admin-mode": false },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h1",
        {
          class: [
            "gfu-heading gfu-heading--main",
            { "_mt-6": !_vm.hasMoreThanOneStep },
          ],
          attrs: { "data-qa": "page-title" },
        },
        [_vm._v(_vm._s(_vm.Resources.Checkout.CheckoutHeader))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-subheading gfu-subheading--main" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.Resources.Checkout.CheckoutSubHeader) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "box",
        { staticClass: "_mt-3 _mb-6" },
        [
          _c(
            "payment-checkout-header",
            _vm._b(
              {},
              "payment-checkout-header",
              _vm.paymentCheckoutHeaderPropsModel,
              false
            )
          ),
          _vm._v(" "),
          _c(
            "cart-table-view",
            _vm._b({}, "cart-table-view", _vm.cartTableModel, false)
          ),
          _vm._v(" "),
          _vm.shouldDisplayNotificator
            ? _c(
                "base-notificator",
                _vm._b(
                  { staticClass: "_mt-2" },
                  "base-notificator",
                  _vm.notificatorModel,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "checkout-return-actions",
            _vm._b(
              {},
              "checkout-return-actions",
              _vm.checkoutReturnActionsModel,
              false
            )
          ),
          _vm._v(" "),
          _c("order-summary-list", { attrs: { "order-id": _vm.orderId } }),
          _vm._v(" "),
          _c(
            "h2",
            { staticClass: "gfu-heading gfu-heading--top-divider _mt-5" },
            [
              _vm._v(
                _vm._s(
                  _vm.Resources.Checkout.CheckoutPaymentOptionsSectionTitle
                )
              ),
            ]
          ),
          _vm._v(" "),
          _c("dynamic-currency-conversion-wrapper", {
            attrs: {
              "project-id": _vm.projectID,
              "order-id": _vm.orderId,
              "order-code": _vm.dynamicCurrencyConversionOrderCode,
              reason: _vm.paymentMethodListReason,
              "can-be-paid-in-installments": _vm.canBePaidInInstallments,
              "payment-currency-strategy": _vm.paymentCurrencyStrategy,
            },
          }),
          _vm._v(" "),
          _vm.isPaymentTypesVisible && !_vm.isInTestMode
            ? _c("payment-types", {
                attrs: {
                  "current-payment-type": _vm.paymentType,
                  "effective-payable-amount": _vm.effectivePayableAmount,
                  "order-code": _vm.dynamicCurrencyConversionOrderCode,
                  "data-qa": "installments-wrapper",
                },
                on: { "payment-type-changed": _vm.onPaymentTypeChanged },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isPaymentRequired
            ? _c("h3", { staticClass: "gfu-hd gfu-hd--h3 _mt-5" }, [
                _vm._v(
                  _vm._s(
                    _vm.Resources.Checkout.CheckoutPaymentMethodSectionTitle
                  )
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isStoreDataInitialized
            ? _c(
                "adyen-create-order-payment-section",
                _vm._b(
                  { attrs: { "data-qa": "payment-section" } },
                  "adyen-create-order-payment-section",
                  _vm.paymentOptionsModel,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _c("checkout-footer"),
          _vm._v(" "),
          _c("progress-overlay", { attrs: { visible: _vm.isProcessingView } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }