import { mapState } from 'vuex';

import { mapState as mapPiniaState, mapActions as mapPiniaActions } from 'pinia';
import { useAdminUserContextStore } from '@/Stores/Admin/userContextStore';
import { useAvailableCreatorsStore } from '@/Stores/Admin/availableCreatorsStore';
import { useProjectSetupCompletionStepsStore } from '@/Stores/Admin/projectSetupCompletionStepsStore';
import { useProjectAdditionalInfoStore } from '@/Stores/Admin/projectAdditionalInfoStore';
import { useAvailableProjectsStore } from '@/Stores/Admin/availableProjectsStore';

import ComponentBase from '@/Components/ComponentBase';

import BaseButton from '@/Components/Base/BaseButton.vue';
import IconBaseButton from '@/Components/IconBaseButton.vue';
import AdminLink from './AdminLink.vue';
import AdminRouterButton from './AdminRouterButton.vue';
import AdminRouterLink from './AdminRouterLink.vue';

import Box from '@/Components/Box.vue';

import TabContainer from './Tabs/TabContainer.vue';
import TabContent from './Tabs/TabContent.vue';
import TabLabel from './Tabs/TabLabel.vue';

import Field from '@/Components/Field.vue';
import FieldLabel from '@/Components/FieldLabel.vue';
import TextAreaField from '@/Components/TextAreaField.vue';
import SecretField from '@/Components/SecretField.vue';
import TextField from '@/Components/TextField.vue';
import NumberField from '@/Components/NumberField.vue';
import OptionField from '@/Components/OptionField.vue';
import MultiSelectorField from '@/Components/MultiSelectorField.vue';
import CustomSelectField from '@/Components/CustomSelectField.vue';
import DateField from '@/Components/DateField.vue';
import DateTimeField from '@/Components/DateTimeField.vue';
import CheckboxField from '@/Components/CheckboxField.vue';
import SwitchField from '@/Components/SwitchField.vue';
import ImageField from '@/Components/ImageField.vue';
import RichTextField from './RichTextField.vue';
import TagsField from './TagsField.vue';
import Tooltip from '@/Components/Tooltip.vue';
import CurrencyField from '@/Components/CurrencyField.vue';
import AutoCompleteField from './AutoCompleteField.vue';
import UrlField from '@/Components/UrlField.vue';

import FileInput from '@/Components/FileInput.vue';

import EditSwitch from './EditSwitch.vue';

import Screen from './Screen.vue';
import ModalBox from '@/Components/ModalBox.vue';

import Notificator from '@/Components/Notificator.vue';
import ProgressOverlay from '@/Components/ProgressOverlay.vue';
import AdminFormWrapper from './AdminFormWrapper.vue';

import { userHasProjectPermission } from '@/Utils/RoleUtility';

export default {
    extends: ComponentBase,
    components: {
        Screen,
        ModalBox,

        BaseButton,
        IconBaseButton,
        AdminLink,
        AdminRouterButton,
        AdminRouterLink,

        FileInput,

        EditSwitch,

        TabContainer,
        TabContent,
        TabLabel,

        Field,
        FieldLabel,
        SecretField,
        TextField,
        CustomSelectField,
        NumberField,
        RichTextField,
        TextAreaField,
        TagsField,
        MultiSelectorField,
        OptionField,
        DateField,
        DateTimeField,
        CheckboxField,
        SwitchField,
        ImageField,
        Tooltip,
        CurrencyField,
        AutoCompleteField,
        UrlField,

        Box,

        Notificator,
        ProgressOverlay,
        AdminFormWrapper
    },
    computed: {
        ...mapState('admin', ['projectContext']),
        ...mapPiniaState(useAvailableProjectsStore, ['availableProjects']),
        ...mapPiniaState(useProjectAdditionalInfoStore, ['projectAdditionalInfo' ]),
        ...mapPiniaState(useAdminUserContextStore, ['userContext']),
        ...mapPiniaState(useAvailableCreatorsStore, ['availableCreators']),
        ...mapPiniaState(useProjectSetupCompletionStepsStore, ['projectSetupCompletionSteps'])
    },
    methods: {
        /**
         * Ensures that the store has been initialized. Returns a promise that resolves when the store is initialized.
         * @returns {Promise<any>}
         */
        async ensureStoreInitialized() {
            await Promise.resolve(this.$store.dispatch('initialize'));
        },
        // TODO: hasPermissionInProjectContext and hasPermission shouldn't be in AdminComponentBase. They should be in store
        // This should be imported manually as getter from store
        // Couldn't fix it right away because there are too many usages of those methods
        hasPermissionInProjectContext(userRole) {
            return userHasProjectPermission(this.userContext.user, userRole, this.projectContext.creatorID, this.projectContext.projectID);
        },
        hasPermission(userRole, creatorID, projectID) {
            return userHasProjectPermission(this.userContext.user, userRole, creatorID, projectID);
        },
        tryLoadProjectAdditionalInfo()  {
            if (this.projectContext.projectID)  {
                return this.loadProjectAdditionalInfo(this.projectContext.projectID);
            }

            return Promise.resolve();
        },
        ...mapPiniaActions(useProjectAdditionalInfoStore, ['loadProjectAdditionalInfo'])
    }
};
