var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer",
    {
      attrs: { "is-open": _vm.isDrawerOpen },
      on: { close: _vm.onCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "_flex _ai-c _jc-fe _pa-2" },
                [
                  _c(
                    "admin-link",
                    {
                      attrs: { theme: "light", "data-qa": "button:Clear" },
                      on: { click: _vm.onClear },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.Search
                            .SearchProjectsFiltersSearchClearButton
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "base-button",
                    {
                      staticClass: "_ml-3",
                      attrs: {
                        theme: "accent",
                        width: "compact",
                        enabled: _vm.canFilter,
                        "data-qa": "button:Search",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onSearch.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.Resources.Search
                              .SearchProjectsFiltersSearchApplyButton
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        {
          staticClass: "_pt-3 _pb-3 _px-3",
          attrs: { "data-qa": "search-filter-drawer" },
        },
        [
          _c(
            "h2",
            {
              staticClass: "gfu-hd gfu-hd--h1 _mb-3",
              attrs: { "data-qa": "filters-title" },
            },
            [_vm._v(_vm._s(_vm.Resources.Search.SearchFiltersHeader))]
          ),
          _vm._v(" "),
          _vm.isProjectContributionVisible
            ? _c("checkbox-group-field", {
                staticClass: "_mb-3",
                attrs: {
                  options: _vm.projectContributionTypeOptions,
                  "display-name":
                    _vm.Resources.Search.SearchProjectsFiltersContributionLabel,
                },
                model: {
                  value: _vm.model.projectContributionTypes,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "projectContributionTypes", $$v)
                  },
                  expression: "model.projectContributionTypes",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("checkbox-group-field", {
            staticClass: "_mb-3",
            attrs: {
              options: _vm.projectPhaseSearchTypeOptions,
              "display-name":
                _vm.Resources.Search.SearchProjectsFiltersStatusLabel,
            },
            model: {
              value: _vm.model.projectPhaseSearchTypes,
              callback: function ($$v) {
                _vm.$set(_vm.model, "projectPhaseSearchTypes", $$v)
              },
              expression: "model.projectPhaseSearchTypes",
            },
          }),
          _vm._v(" "),
          _c("multi-checkbox-field", {
            staticClass: "_mb-3",
            attrs: {
              options: _vm.categoriesOptions,
              "no-option-capitalize": true,
              placeholder:
                _vm.Resources.Search.SearchProjectsFiltersCategoryPlaceholder,
              "display-name":
                _vm.Resources.Search.SearchProjectsFiltersCategoryLabel,
            },
            model: {
              value: _vm.model.projectCategories,
              callback: function ($$v) {
                _vm.$set(_vm.model, "projectCategories", $$v)
              },
              expression: "model.projectCategories",
            },
          }),
          _vm._v(" "),
          _vm.model.projectCategories.length > 0
            ? _c(
                "div",
                { staticClass: "_flexwrap _mt-2 _mb-3" },
                _vm._l(_vm.selectedCategories, function (category) {
                  return _c(
                    "base-button",
                    {
                      key: category.value,
                      staticClass: "_mr-1 _mb-1",
                      attrs: { width: "compact", theme: "searchSecondary" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelectedCategoryDeleteClick(
                            category.value
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "strong",
                        {
                          staticClass: "_ttl",
                          attrs: {
                            "data-qa": `category-tile:${category.label}`,
                          },
                        },
                        [_vm._v(_vm._s(category.label))]
                      ),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "_fa _fa--window-close _ml-1",
                      }),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isUserCommunitySearchTypeVisible
            ? [
                _c("checkbox-group-field", {
                  staticClass: "_mb-3",
                  attrs: {
                    options: _vm.projectSearchUserCommunityOptions,
                    "display-name":
                      _vm.Resources.Search.SearchProjectsFiltersCommunityLabel,
                  },
                  model: {
                    value: _vm.model.userCommunitySearchTypes,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "userCommunitySearchTypes", $$v)
                    },
                    expression: "model.userCommunitySearchTypes",
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("checkbox-group-field", {
            staticClass: "_mb-3",
            attrs: {
              options: _vm.projectSearchPlayerAgeOptions,
              "display-name":
                _vm.Resources.Search.SearchProjectsFiltersAgeLabel,
            },
            model: {
              value: _vm.model.playerAges,
              callback: function ($$v) {
                _vm.$set(_vm.model, "playerAges", $$v)
              },
              expression: "model.playerAges",
            },
          }),
          _vm._v(" "),
          _c("checkbox-group-field", {
            staticClass: "_mb-3",
            attrs: {
              options: _vm.projectSearchPlayerCountOptions,
              "display-name":
                _vm.Resources.Search.SearchProjectsFiltersPlayableLabel,
            },
            model: {
              value: _vm.model.playerCounts,
              callback: function ($$v) {
                _vm.$set(_vm.model, "playerCounts", $$v)
              },
              expression: "model.playerCounts",
            },
          }),
          _vm._v(" "),
          _c("checkbox-group-field", {
            staticClass: "_mb-3",
            attrs: {
              options: _vm.playTimeSearchTypeOptions,
              "display-name":
                _vm.Resources.Search.SearchProjectsFiltersPlaytimeLabel,
            },
            model: {
              value: _vm.model.playTimes,
              callback: function ($$v) {
                _vm.$set(_vm.model, "playTimes", $$v)
              },
              expression: "model.playTimes",
            },
          }),
          _vm._v(" "),
          _c("auto-complete-field", {
            staticClass: "_mb-3",
            attrs: {
              name: "creator",
              "display-name":
                _vm.Resources.Search.SearchProjectsFiltersCreatorLabel,
              layout: "condensed",
              "min-chars": 3,
              "max-input-length": 55,
              items: _vm.searchCreatorPromise,
              "value-comparer": (valueA, valueB) => valueA.name === valueB.name,
              placeholder:
                _vm.Resources.Search.SearchProjectsFiltersCreatorPlaceholder,
            },
            on: { input: _vm.onClearCreator },
            model: {
              value: _vm.model.creator,
              callback: function ($$v) {
                _vm.$set(_vm.model, "creator", $$v)
              },
              expression: "model.creator",
            },
          }),
          _vm._v(" "),
          _c("text-field", {
            attrs: {
              "display-name":
                _vm.Resources.Search.SearchProjectsFiltersSearchPhraseLabel,
              placeholder:
                _vm.Resources.Search
                  .SearchProjectsFiltersSearchPhrasePlaceholder,
              maxlength: 55,
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.onSearch.apply(null, arguments)
              },
            },
            model: {
              value: _vm.model.term,
              callback: function ($$v) {
                _vm.$set(_vm.model, "term", $$v)
              },
              expression: "model.term",
            },
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }