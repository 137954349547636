var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["gfu-dropdown", { "gfua-editing-control": _vm.hasControlClass }],
    },
    [
      _c(
        "tooltip",
        {
          staticClass: "_flex",
          attrs: {
            text: "Manage this product.",
            width: "compact",
            "data-qa": `product-edit-menu:${_vm.productId}`,
          },
        },
        [
          _c("router-link", {
            staticClass:
              "gfu-btn gfu-btn--primary gfu-btn--circle gfu-btn--circle-big _fa _fa--cog",
            attrs: { to: _vm.to },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }