var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer",
    {
      attrs: { "is-open": _vm.isDrawerOpen, "is-filled": true },
      on: { close: _vm.onDrawerClose },
    },
    [
      _c(
        "div",
        {
          staticClass: "_pt-3 _pb-3 _px-3",
          attrs: { "data-qa": "search-filter-drawer" },
        },
        [
          _c("div", { staticClass: "_flex _jc-st _gap-1 _ai-fs" }, [
            _c("div", { staticClass: "_flexcol _grow-1 _mw-0" }, [
              _c("div", { staticClass: "gfu-hd gfu-hd--h2 _toe" }, [
                _vm._v(_vm._s(_vm.Resources.Comments.TagsSectionHeader)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "_tc--accent _pa-0 _whs-nw",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onDrawerClose.apply(null, arguments)
                  },
                },
              },
              [
                _c("span", { staticClass: "_mr-1" }, [
                  _vm._v(_vm._s(_vm.Resources.Common.ModalCloseButton)),
                ]),
                _vm._v(" "),
                _c("base-icon", { attrs: { name: "times" } }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "project-comments-streams",
            {
              attrs: {
                "comment-thread-id": _vm.commentThreadId,
                "current-tag": _vm.currentTag,
              },
              on: {
                "tag-deselected": _vm.onTagDeselected,
                "tag-selected": _vm.onTagSelected,
                "search-tags": _vm.onSearchTags,
              },
            },
            [
              _c(
                "div",
                { staticClass: "_mt-3 _flexwrap _gap-1" },
                _vm._l(_vm.filters, function (filter) {
                  return _c("comment-filter-item", {
                    key: filter.name,
                    class: filter.class,
                    attrs: {
                      name: filter.name,
                      "is-selected": filter.isSelected,
                      "data-qa": `comments-streams:${filter.name}`,
                    },
                    on: {
                      click: function ($event) {
                        return filter.fn(filter.value)
                      },
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gfu-comment-streams__tags _flexwrap _gap-1" },
                [
                  _vm._l(_vm.tags, function (tag) {
                    return _c("comment-filter-item", {
                      key: tag.name,
                      class: tag.class,
                      attrs: {
                        name: tag.name,
                        "is-selected": tag.isSelected,
                        "data-qa": `comments-streams:${tag.value}`,
                      },
                      on: {
                        click: function ($event) {
                          return tag.fn(tag.value)
                        },
                      },
                    })
                  }),
                  _vm._v(" "),
                  !_vm.tags || !_vm.tags.length
                    ? _c(
                        "div",
                        { staticClass: "gfu-comment-streams__paragraph" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.Resources.Comments.TagsEmptyStateHeader
                              ) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }