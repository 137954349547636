var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDataReady
    ? _c(
        _vm.currentComponent.name,
        _vm._b(
          { tag: "component" },
          "component",
          _vm.currentComponent.model,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }