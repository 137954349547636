
    import { useCurrencyStore } from '@/Stores/Currency/currencyStore';
    import { mapState } from 'vuex';
    import { mapState as mapPiniaState } from 'pinia';
    import { useCartSummaryStore } from '@/Stores/cartSummaryStore';
    import { usePageSectionsStore } from '@/Stores/pageSectionsStore';
    import { useProjectContextStore } from '@/Stores/Web/projectContextStore';
    import ComponentBase from '@/Components/ComponentBase';
    import { orderItemTypes, orderStates } from '@/Models';
    import Tooltip from '@/Components/Tooltip.vue';
    import CartsService from '@/Services/CartsService';
    import { cancelPledge } from '@/Utils/CancelPledgeUtility';
    import DisplayCurrencyPrice from '@/Components/DisplayCurrencyPrice';
    import GoToCheckout from '@/Components/Carts/GoToCheckout';
    import DetectOverflow from '@/Components/Utils/DetectOverflow';
    import ProgressOverlay from '@/Components/ProgressOverlay.vue';
    import MiniWizardItem from '@/Components/Projects/MiniWizard/MiniWizardItem.vue';
    import MiniWizardItemsContent from '@/Components/Projects/MiniWizard/MiniWizardItemsContent';
    import { useProjectStore } from '@/Stores/Web/projectStore';
    import { isPledgeImplicitEditMode } from '@/Utils/PledgeUtility';

    export default {
        extends: ComponentBase,
        components: {
            Tooltip,
            MiniWizardItem,
            MiniWizardItemsContent,
            GoToCheckout,
            DisplayCurrencyPrice,
            DetectOverflow,
            ProgressOverlay
        },
        props: {
            rewardCategories: { required: true, type: Array },
            addonCategories: { required: true, type: Array }
        },
        data() {
            return {
                cartsService: new CartsService(this.$http)
            };
        },
        methods: {
            onDeleteItem(item) {
                return this.wrapAsyncTask(this.wrapApiAction(this.cartsService.setOrderItemQuantity(
                    item.orderItemID, 0, this.pledge.projectID))
                    .then(() => this.updateCartSummary(this.projectContext.projectID)));
            },
            onRevertChanges() {
                return this.wrapAsyncTask(this.wrapApiAction(this.cartsService.revertChanges(this.pledge.projectID))
                    .then(() => this.updateCartSummary(this.projectContext.projectID)));
            },
            onCancelPledge() {
                return cancelPledge.call(this, this.projectContext.project.phase, this.currentPledge.cancelOrderUrl);
            },
            onEditPledge() {
                return this.wrapAsyncTask(this.wrapApiAction(this.cartsService.enablePledgeEdit(this.pledge.projectID))
                    .then(() => this.updateCartSummary(this.projectContext.projectID)));
            }
        },
        computed: {
            currentPledge() {
                return this.getCurrentPledge(this.projectContext.project.version, this.orderAwaitingPayment);
            },
            canSetShipping() {
                return !this.isProjectInFundingPhase && this.cartCandidate?.cart?.orderState === orderStates.pledgedAndPaid;
            },
            currencySymbol() {
                return this.pledge.symbol || this.currentCheckoutCurrency.symbol;
            },
            rewards() {
                return this.currentPledge.productOrderItems
                    ? this.currentPledge.productOrderItems.filter(p => this.rewardCategories.includes(p.product.categoryID))
                    : [];
            },
            addons() {
                return this.currentPledge.productOrderItems
                    ? this.currentPledge.productOrderItems.filter(p => this.addonCategories.includes(p.product.categoryID))
                    : [];
            },
            addonsSection() {
                return this.pageSections.find(s => s.urlName === 'addons');
            },
            rewardsSection() {
                return this.pageSections.find(s => s.urlName === 'rewards');
            },
            pledge() {
                return this.currentPledge.cart || {
                    hasSameItemsAsParent: true,
                    orderState: orderStates.unfinished,
                    parentOrder: null
                };
            },
            pledgeDate() {
                return this.isPledgePlaced ? this.pledge.createDate : null;
            },
            isUnfinishedOrder() {
                return this.pledge.orderState === orderStates.unfinished;
            },
            isPledgePlaced() {
                return !this.isUnfinishedOrder
                    || this.pledge.parentOrder !== null;
            },
            hasSameItemsAsParent() {
                return this.pledge.hasSameItemsAsParent;
            },
            isUnmodifiedPledge() {
                return !this.isUnfinishedOrder && this.pledge.parentOrder === null;
            },
            canDeleteItems() {
                return !this.isAwaitingPaymentPledge
                    && (this.isEditingPledge && (this.currentPledge.canEditPledgedItems || this.currentPledge.cart?.canAddNewItems)
                        || this.pledge.parentOrder === null && this.isUnfinishedOrder);
            },
            canAddNewItems() {
                return this.currentPledge.cart?.canAddNewItems;
            },
            isPledgeEmpty() {
                return this.currentPledge.itemsCount === 0;
            },
            allItemsRemoved() {
                return this.pledge.parentOrder !== null && this.isPledgeEmpty;
            },
            allChargeableItemsRemoved() {
                return this.pledge.parentOrder !== null && this.pledge.orderItems?.every(i => i.promoItemID !== null);
            },
            tips() {
                return this.pledge.orderItems?.filter(c => c.orderItemType === orderItemTypes.tip) ?? [];
            },
            discounts() {
                return this.pledge.orderItems?.filter(c => c.orderItemType === orderItemTypes.discount) ?? [];
            },
            isEditingPledge() {
                return !!this.pledge.parentOrder;
            },
            isAwaitingPaymentPledge() {
                return this.projectUserContext?.hasOrderAwaitingPayment;
            },
            hasTaxHandlingEnabled() {
                return this.currentPledge.cart?.hasTaxHandlingEnabled;
            },
            taxesSummaryLabel() {
                return this.currentPledge.cart?.hasTaxInfoDefined
                    ? this.Resources.ProjectHome.MiniWizardTaxSummaryInclTaxLabel
                    : this.Resources.ProjectHome.MiniWizardTaxSummaryPlusTaxLabel;

            },
            canEditPledge() {
                return this.isPledgeEditable(this.orderAwaitingPayment?.canEditPledgedItems);
            },
            isPledgeImplicitEditMode() {
                return isPledgeImplicitEditMode(this.projectContext.project, this.currentPledge.cart?.parentOrder?.orderState);
            },
            ...mapState('web', ['projectUserContext', 'orderAwaitingPayment']),
            ...mapPiniaState(usePageSectionsStore, ['pageSections'] ),
            ...mapPiniaState(useProjectStore, ['promoItem']),
            ...mapPiniaState(useCurrencyStore, ['currentCheckoutCurrency']),
            ...mapPiniaState(useProjectContextStore, ['isProjectInFundingPhase']),
            ...mapPiniaState(useCartSummaryStore, ['getCurrentPledge', 'cartCandidate', 'isPledgeEditable'])
        }
    };
