import ServiceBase from './ServiceBase';

export default class PollsService extends ServiceBase {

    getPoll(pollID) {
        const params = {
            pollID
        };

        return this.request('get', 'polls/getPoll', params);
    }

    voteOnPollOption(pollOptionID) {
        return this.request('post', 'polls/voteOnPollOption', { pollOptionID });
    }
}
