import ServiceBase from './ServiceBase';

export default class ProjectsService extends ServiceBase {

    deleteProject(creatorID, projectID) {
        return this.request('post', 'projects/deleteProject', { creatorID, projectID });
    }

    getAvailableProjects() {
        return this.request('get', 'projects/getAvailableProjects');
    }

    getContactRelevantProjects(searchHints, previouslyFetchedProjectIDs, pageSize = null, pageIndex = null) {
        const params = {
            pageSize,
            pageIndex,
            previouslyFetchedProjectIDs,
            ...searchHints.referrerValueDictionary || {}
        };

        return this.request('post', 'projects/getContactRelevantProjects', params);
    }

    getExternalProjectInfo(externalProjectPageUrl, creatorID) {
        return this.request('get', 'projects/getExternalProjectInfo', { params: { externalProjectPageUrl, creatorID } });
    }

    getNewProjectSetupInfo(creatorID) {
        return this.request('get', 'projects/getNewProjectSetupInfo', { params: { creatorID } });
    }

    getProjectAdditionalInfo(projectID) {
        return this.request('get', 'projects/getProjectAdditionalInfo', { params: { projectID } });
    }

    getProjectResources(projectID) {
        return this.request('get', 'admin/projects/getProjectResources', { params: { projectID } });
    }

    getProjectRewardsAdvancedSettings(projectID) {
        return this.request('get', 'projects/getProjectRewardsAdvancedSettings', { projectID });
    }

    getProjectSetupCompletionStatus(projectID) {
        return this.request('get', 'projects/GetProjectSetupCompletionStatus', { params: { projectID } });
    }

    getProjectExtraStepsCompletionStatus(projectID) {
        return this.request('get', 'projects/getProjectExtraStepsCompletionStatus', { params: { projectID } });
    }

    getGeneralSettings(projectID) {
        return this.$http.get('projects/getGeneralSettings', { params: { projectID } });
    }

    updateProjectRewardsAdvancedSettings(settings) {
        return this.request('post', 'projects/updateProjectRewardsAdvancedSettings', settings);
    }

    updateGeneralSettings(projectSettings) {
        return this.$http.post('projects/updateGeneralSettings', projectSettings);
    }

    isProjectKeyValid(projectID, key) {
        return this.request('get', 'projects/isProjectKeyValid', { params: { projectID, key } });
    }

    isProjectUrlValid(creatorID, projectID, urlName) {
        return this.request('get', 'projects/isProjectUrlValid', { params: { creatorID, projectID, urlName } });
    }

    searchProjects(filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            pageSize,
            pageIndex,
            ...filters || {}
        };

        return this.request('post', 'projects/searchProjects', params);
    }

    searchProjectsForContact(filters = {}) {
        const params = {
            ...filters
        };

        return this.request('post', 'projects/searchProjectsForContact', params);
    }

    searchProjectsForCards(filters = {}) {
        const params = {
            ...filters
        };

        return this.request('post', 'projects/searchProjectsForCards',  params);
    }

    getCreatorProjects(creatorID, orderCriteria) {
        const params = {
            creatorID,
            orderCriteria
        };

        return this.request('get', 'projects/getCreatorProjects', params);
    }

    partialSearch(searchParams) {
        return this.request('get', 'projects/partialSearch', { params: searchParams });
    }

    getTrackingSettings(projectID) {
        return this.$http.get('projects/getTrackingSettings', { params: { projectID } });
    }

    updateTrackingSettings(settings) {
        return this.$http.post('projects/updateTrackingSettings', settings);
    }

    getProjectAboutSettings(projectID) {
        return this.$http.get('projects/getProjectAboutSettings', { params: { projectID } });
    }

    updateProjectAboutSettings(aboutSettings) {
        return this.$http.post('projects/updateProjectAboutSettings', aboutSettings);
    }

    getProjectGallery(projectID, projectPhase, languageIsoCode) {
        return this.$http.get('projectGallery/getProjectGallery', { params: { projectID, projectPhase, languageIsoCode } });
    }

    reorderGalleryItem(galleryItem, projectID) {
        const params = {
            projectID,
            ...galleryItem
        };

        return this.$http.post('projectGallery/reorderGalleryItem', params);
    }

    getProjectDescriptionSections(projectID, projectPhase) {
        return this.$http.get('admin/projects/getProjectDescriptionSections', { params: { projectID, projectPhase } });
    }

    updateProjectDescriptionSections(projectID, projectPhase, sections) {
        const params = {
            projectID,
            projectPhase,
            sections
        };

        return this.$http.post('admin/projects/updateProjectDescriptionSections', params);
    }

    getProjectFaqSections(projectID) {
        return this.$http.get('projects/getProjectFaqSections', { params: { projectID } });
    }

    getPublicProjectFaqSections(projectID) {
        return this.$http.get('projects/getPublicProjectFaqSections', { params: { projectID } });
    }

    updateProjectFaqSections(projectID, sections) {
        const params = {
            projectID,
            sections
        };

        return this.$http.post('projects/updateProjectFaqSections', params);
    }

    markFaqSectionUsefulness(projectFaqSectionID, isPositive) {
        const params = {
            projectFaqSectionID,
            isPositive
        };

        return this.$http.post('projects/markFaqSectionUsefulness', params);
    }

    markFaqSectionAsWatched(projectFaqSectionID) {
        const params = {
            projectFaqSectionID
        };

        return this.$http.post('projects/markFaqSectionAsWatched', params);
    }

    static get TimeUnitValues() {
        return {
            Minutes: 0,
            Hours: 1
        };
    }
}
