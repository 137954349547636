import { defineStore } from 'pinia';

import CreatorsService from '@/Services/CreatorsService';
import { apiClient } from '@/Clients/ApiClient.js';

export const useAvailableCreatorsStore = defineStore({
    id: 'availableCreators',
    state: () => ({
        availableCreators: [],
    }),
    actions: {
        async loadAvailableCreators() {
            const response = await new CreatorsService(apiClient).getAvailableCreators();

            this.availableCreators = response.data;

            return response;
        },
    }
});