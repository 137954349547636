var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-collapsible gfu-issue__option-wrapper",
      class: {
        "gfu-issue__option-wrapper--expanded gfu-collapsible--expanded":
          _vm.isOpen,
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "gfu-collapsible gfu-issue__option gfu-issue__option-collapsible",
          class: { "gfu-issue__option-collapsible--expanded": _vm.isOpen },
          on: { click: _vm.onHeaderClick },
        },
        [
          _c("div", {
            staticClass: "gfu-issue__option-collapsible-icon",
            class: {
              "gfu-issue__option-collapsible-icon--expanded": _vm.isOpen,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gfu-issue__option-header",
              attrs: { "data-qa": "issue-card-header" },
            },
            [
              _vm._t("header", function () {
                return [
                  _vm.header
                    ? _c("span", {
                        staticClass:
                          "gfu-issue__option-title gfu-issue__option-title--deemphasized",
                        domProps: { innerHTML: _vm._s(_vm.formattedHeader) },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "gfu-collapsible__content gfu-collapsible__content--no-rounded gfu-issue__option-collapsible-content",
          class: { "gfu-collapsible__content--expanded": _vm.isOpen },
        },
        [
          _vm.content
            ? _c("div", {
                staticClass: "gfu-richtext",
                attrs: { "data-qa": "issue-card-content" },
                domProps: { innerHTML: _vm._s(_vm.content) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.editedAt
            ? _c("div", { staticClass: "_tc--light _mt-3" }, [
                _vm._v(
                  _vm._s(_vm.Resources.Common.LastUpdatedAtLabel) +
                    " " +
                    _vm._s(_vm.editedAtDateFormatted)
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("subContent"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }