import { ShippingServiceBase } from './BaseServices';

export default class ShippingService extends ShippingServiceBase {
    constructor($http, errorHandler) {
        super($http, errorHandler);
    }

    calculateShippingCosts(projectID, products) {
        return this.request('post', 'shipping/calculateShippingCosts', { projectID, products });
    }

    getEnabledShippingModels(projectID, shippingLocationID, orderCode) {
        return this.request('get', 'shipping/getEnabledShippingModels', { projectID, shippingLocationID, orderCode });
    }

    getShippingModelZones(projectID, shippingModelID) {
        return this.request('get', 'shipping/getShippingModelZones', { projectID, shippingModelID });
    }

    getShippingZones(projectID) {
        return this.request('get', 'shipping/getShippingZones', { projectID });
    }

    isShippingModelNameValid(projectID, shippingModelID, name) {
        return this.request('get', 'shipping/isShippingModelNameValid', { projectID, shippingModelID, name });
    }

    searchProductShippingStages(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters
        };

        return this.request('get', 'shipping/searchProductShippingStages', params);
    }

    searchShippingClassZoneCosts(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters
        };

        return this.request('get', 'shipping/searchShippingClassZoneCosts', params);
    }

    searchShippingWeightRanges(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters
        };

        return this.request('get', 'shipping/searchShippingWeightRanges', params);
    }

    searchShippingZones(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters
        };

        return this.request('get', 'shipping/searchShippingZones', params);
    }

    updateShippingModelZone(projectID, shippingModelZoneID, isEnabled) {
        return this.request('post', 'shipping/updateShippingModelZone', { projectID, shippingModelZoneID, isEnabled });
    }
}