import { mapState as mapPiniaState, mapStores as mapPiniaStores } from 'pinia';
import { mapState } from 'vuex';
import { productPurchaseAvailabilities } from '@/Models';
import { getEffectivePurchaseLimit } from '@/Utils/ProductUtility';
import { useCartSummaryStore } from '@/Stores/cartSummaryStore';
import { useProjectContextStore } from '@/Stores/Web/projectContextStore';

export default {
    props: {
        tooltipText: { default: null, type: String },
        productId: { required: true, type: Number },
        purchaseLimit: { default: null, type: Number },
    },
    data() {
        return {
            model: {
                quantity: 1,
            },
            productData: null,
        };
    },
    validate() {
        return {
            model: {
                quantity() {
                    return {
                        required: true,
                        integer: true,
                        min_value: 1,
                        not_in: [0, 0.0, 0.0],
                        max_value: this.effectivePurchaseLimit.value
                    };
                }
            }
        };
    },
    computed: {
        effectivePurchaseLimit() {
            return getEffectivePurchaseLimit(this.effectivePurchaseLimitModel, this.getAvailableQuantityFromPledge, this.projectContextStore.projectContext.project.version);
        },
        effectivePurchaseLimitModel() {
            const hasStockLimits = this.stockLimits && Object.keys(this.stockLimits).length > 0;
            if (hasStockLimits) {
                const { remainingStockLimit, remainingPerUserLimit } = this.stockLimits;
                return {
                    productID: this.productId,
                    remainingStockLimit,
                    remainingPerUserLimit,
                };
            }

            if (this.productData) {
                const { productID, remainingStockLimit, remainingPerUserLimit } = this.productData;
                return { productID, remainingStockLimit, remainingPerUserLimit };
            }

            return {
                productID: this.productId,
                remainingStockLimit: this.purchaseLimit,
                remainingPerUserLimit: null,
            };
        },
        isDisabled() {
            return this.$validator.errors.any() || this.readOnly;
        },
        tooltipContent() {
            if (this.model.quantity < 1) {
                return this.Resources.Product.ProductValidationMinQuantityTooltipText;
            }

            if (this.tooltipText) {
                return this.tooltipText;
            }

            if (this.projectUserContext.hasOrderAwaitingPayment) {
                return this.Resources.Product.ProductValidationPledgeNotFinishedTooltipText;
            }

            const purchaseLimit = this.effectivePurchaseLimit;

            if (purchaseLimit.status === productPurchaseAvailabilities.stockLimitReached)
                return this.Resources.Product.ProductValidationStockLimitReachedTooltipText;

            if (purchaseLimit.status === productPurchaseAvailabilities.perUserLimitReached)
                return this.Resources.Product.ProductValidationLimitReachedTooltipText;

            if (purchaseLimit.value !== null) {
                const pluralizedCount = this.Resources.pluralize(purchaseLimit.value, this.Resources.Product.ProductLimitPluralizedCount);
                return this.Resources.format(this.Resources.Product.ProductValidationLimitInfoTooltipText, pluralizedCount);
            }

            return this.Resources.Product.ProductValidationLimitReachedTooltipText;
        },
        ...mapState('admin', ['projectContext']),
        ...mapState('web', ['projectUserContext']),
        ...mapPiniaStores(useProjectContextStore),
        ...mapPiniaState(useCartSummaryStore, ['getAvailableQuantityFromPledge'])
    }
};