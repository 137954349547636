import ServiceBase from './ServiceBase';

export default class ProjectUpdatesService extends ServiceBase {

    searchProjectUpdates(projectID, filters, lowestFetchedSequenceNumber) {
        const params = {
            projectID,
            lowestFetchedSequenceNumber,
            ...filters
        };

        return this.request('post', 'projectUpdates/searchProjectUpdates', params);
    }

    toggleLikeUpdate(projectUpdateID) {
        return this.request('post', 'projectUpdates/toggleLikeUpdate', { projectUpdateID });
    }
}
