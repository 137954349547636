var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible,
          expression: "isVisible",
        },
      ],
      staticClass: "gfua-tabs__content",
      class: { "gfua-tabs__content--shadow": _vm.shadow },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }