import lazyScreen from '@/Components/Admin/LazyScreen';

const ProjectTaxTransactionsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ './ProjectTaxTransactionsListScreen.vue'));
const SystemTaxTransactionsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-system' */ './SystemTaxTransactionsListScreen.vue'));

const routes = [
    { path: '/project/:creator/:project/tax-transactions', name: 'project-tax-transactions', component: ProjectTaxTransactionsListScreen },
    { path: '/system/tax-transactions', name: 'system-tax-transactions', component: SystemTaxTransactionsListScreen }
];

export {
    routes
};