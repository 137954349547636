var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.projectContext.canEnableTestMode
    ? _c("div", { staticClass: "_mb-3" }, [
        _vm._v("\n    " + _vm._s(_vm.description) + "\n    "),
        _c(
          "span",
          {
            staticClass: "gfu-link gfu-link--accent",
            on: { click: _vm.toggleTesterMode },
          },
          [_vm._v(" " + _vm._s(_vm.text) + " ")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }