export default {
    name: 'Toggle',
    props: {
        expanded: { type: Boolean, default: false }
    },
    data() {
        return {
            isExpanded: this.expanded
        };
    },
    watch: {
        expanded(newVal) {
            if (newVal !== this.isExpanded) {
                this.isExpanded = newVal;
            }
        }
    },
    methods: {
        onCollapse() {
            this.isExpanded = false;
        },
        onExpand() {
            this.isExpanded = true;
        },
        onToggle() {
            this.isExpanded = !this.isExpanded;
        }
    },
    render() {
        const { isExpanded, onCollapse, onExpand, onToggle } = this;
        return this.$scopedSlots.default({
            isExpanded,
            onCollapse,
            onExpand,
            onToggle
        });
    }
};