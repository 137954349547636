var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-form-holder",
      attrs: { "data-qa": "admin-form-wrapper:holder" },
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      !_vm.disabled
        ? _c(
            "div",
            {
              class: [
                "gfu-form-actions",
                { "is-sticky": _vm.isSticky, "is-stuck": _vm.isStuck },
              ],
            },
            [
              _vm.shouldShow
                ? _c(
                    "div",
                    {
                      staticClass: "_tc--light",
                      attrs: { "data-qa": "admin-form-wrapper:text" },
                    },
                    [_vm._v("\n            You have unsaved changes\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "_ml-a" },
                [
                  _c(
                    "admin-link",
                    {
                      attrs: {
                        theme: "light",
                        enabled: _vm.canCancel,
                        "data-qa": "button:Cancel",
                      },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v("cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "icon-base-button",
                    {
                      staticClass: "_ml-3",
                      attrs: {
                        theme: "accent",
                        size: "default",
                        width: "compact",
                        "text-transform": "none",
                        wide: true,
                        bold: true,
                        icon: "tick",
                        enabled: _vm.canSave,
                        "data-qa": "button:Save",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onSave.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n                Save\n            ")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { ref: "actions", staticClass: "gfu-form-actions-trigger" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }