var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-field__label-wrapper",
      class: { "gfu-field__label-wrapper--inline": _vm.inline },
    },
    [
      _c(
        "label",
        {
          staticClass: "gfu-field__label",
          class: {
            "gfu-field__label--columns-layout": _vm.layout === "columns",
          },
        },
        [
          _c(
            "span",
            { attrs: { "data-qa": "field-label-text" } },
            [
              _vm._t("default", function () {
                return [_vm._v(_vm._s(_vm.text))]
              }),
            ],
            2
          ),
          _c(
            "em",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isRequired,
                  expression: "isRequired",
                },
              ],
              attrs: { "data-qa": "field-required-icon" },
            },
            [_vm._v("*")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.description || _vm.$slots.description
        ? _c(
            "div",
            {
              staticClass: "gfu-field__description",
              class: {
                "gfu-field__description--columns-layout":
                  _vm.layout === "columns",
              },
            },
            [
              _c(
                "span",
                { attrs: { "data-qa": "field-label-description" } },
                [
                  _vm._t("description", function () {
                    return [_vm._v(_vm._s(_vm.description))]
                  }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }