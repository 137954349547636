
    export default {
        props: {
            number: { type: Number, required: true },
            format: { type: String, default: 'N0' },
            currencySymbol: { type: String, default: null },
            duration: { type: Number, default: 0.5 },
            stepTime: { type: Number, default: 1000 / 60 },
            tag: { type: String, default: 'span' },
        },
        data: () => ({
            tweeningValue: 0,
            interval: null
        }),
        mounted() {
            this.tweeningValue = this.number;
        },
        computed: {
            stepCount() {
                const duration = this.duration * 1000;
                return Math.floor(duration / this.stepTime);
            }
        },
        methods: {
            tweenValue(newValue) {
                const isPositive = newValue > this.tweeningValue;
                const delta = this.tweeningValue - newValue;
                const stepValue = Math.abs(delta / this.stepCount);

                return setInterval(()=> {
                    if (isPositive) {
                        this.tweeningValue = this.tweeningValue + stepValue;
                        if (this.tweeningValue >= newValue - stepValue) {
                            this.tweeningValue = newValue;
                            clearInterval(this.interval);
                        }
                    }
                    else {
                        this.tweeningValue = this.tweeningValue - stepValue;
                        if (this.tweeningValue <= newValue + stepValue) {
                            this.tweeningValue = newValue;
                            clearInterval(this.interval);
                        }
                    }

                }, this.stepTime);
            }
        },
        watch: {
            number(newVal) {
                if (this.interval) {
                    clearInterval(this.interval);
                }

                if (newVal !== this.tweeningValue) {
                    if (this.tweeningValue === 0) {
                        this.tweeningValue = newVal;
                    }
                    else {
                        this.interval = this.tweenValue(newVal);
                    }
                }
            }
        },
        render(h) {
            return h(this.tag, this.$format(this.tweeningValue, this.format, this.currencySymbol));
        }
    };
