import BeginOrderForm from '@/Components/Orders/BeginOrderForm.vue';
import OrderSummaryList from '@/Components/Orders/OrderSummaryList.vue';
import OrderDetailsLocation from '@/Components/Orders/OrderDetailsLocation.vue';
import CheckoutFooter from '@/Components/Payments/CheckoutFooter.vue';

export default {
    BeginOrderForm,
    OrderSummaryList,
    OrderDetailsLocation,
    CheckoutFooter
};
