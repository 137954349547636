var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isExtended && _vm.productData
        ? [
            _vm.showOptions &&
            _vm.productData.options.length &&
            !_vm.effectiveReadonly
              ? _c(
                  "div",
                  { staticClass: "gfu-grid gfu-grid--gutter-columns _mb-3" },
                  _vm._l(_vm.productData.options, function (option) {
                    return _c(
                      "div",
                      {
                        key:
                          "toggle-trigger-category-" +
                          option.productOptionValueID,
                        staticClass: "gfu-grid__cell gfu-1of1",
                        attrs: { "data-qa": "add-product-to-cart-options" },
                      },
                      [
                        option.productOptionValueID
                          ? _c("product-option-field", {
                              attrs: {
                                option: option,
                                "selected-option-value-id":
                                  option.productOptionValueID,
                                "currency-sumbol": _vm.currencySymbol,
                                "relative-factor-with-commission":
                                  _vm.relativeFactorWithCommission,
                              },
                              on: { change: _vm.setSelectedProductOption },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
              _vm.showQuantityInput && !_vm.effectiveReadonly
                ? _c(
                    "div",
                    {
                      staticClass:
                        "gfu-product__amount gfu-grid__cell gfu-1of1 gfu-1of3--s gfu-right-0of0--s gfu-2of3--m gfu-right-1of3--m gfu-1of3--l gfu-right-0of0--l _fc",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "gfu-product__amount-btn gfu-product__amount-btn--plus gfu-btn gfu-btn--icon gfu-btn--primary",
                          attrs: {
                            type: "button",
                            "data-qa": "add-product-to-cart-button:Plus",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.changeQuantity(1)
                            },
                          },
                        },
                        [_c("span", { staticClass: "_fa _fa--plus" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "gfu-product__amount-btn gfu-product__amount-btn--minus gfu-btn gfu-btn--icon gfu-btn--primary",
                          attrs: {
                            type: "button",
                            "data-qa": "add-product-to-cart-button:Minus",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.changeQuantity(-1)
                            },
                          },
                        },
                        [_c("span", { staticClass: "_fa _fa--minus" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "gfu-product__amount-input _cf" },
                        [
                          _c("number-field", {
                            staticClass: "_mt-0",
                            attrs: {
                              name: "model.quantity",
                              "show-label": false,
                              integer: true,
                              min: "1",
                              "validator-layout": "none",
                              "data-qa": "add-product-to-cart-quantity",
                            },
                            on: { enter: _vm.displayProductSetOrSubmit },
                            model: {
                              value: _vm.model.quantity,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "quantity", $$v)
                              },
                              expression: "model.quantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "gfu-product__submit gfu-grid__cell gfu-1of1 gfu-2of3--s gfu-right-1of3--m gfu-2of3--l gfu-right-0of0--l",
                  attrs: { "data-qa": "add-product-to-cart-section" },
                },
                [
                  _vm.isDisabled
                    ? _c(
                        "tooltip",
                        {
                          attrs: {
                            text: _vm.tooltipMessage,
                            "data-qa": "add-product-to-cart-button-tooltip",
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "gfu-btn gfu-btn--disabled gfu-btn--block",
                              class: _vm.getButtonClasses(),
                              attrs: {
                                disabled: "",
                                "data-qa":
                                  "add-product-to-cart-button:AddToCart",
                              },
                            },
                            [
                              _c("span", { staticClass: "gfu-btn__text" }, [
                                _vm._v(_vm._s(_vm.buttonText)),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "gfu-btn gfu-btn--block",
                          class: [
                            _vm.getButtonClasses(),
                            _vm.accented
                              ? "gfu-btn--accent"
                              : "gfu-btn--primary",
                          ],
                          attrs: {
                            "data-qa": "add-product-to-cart-button:AddToCart",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.displayProductSetOrSubmit.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "gfu-btn__text" }, [
                            _vm._v(_vm._s(_vm.buttonText)),
                          ]),
                          _vm._v(" "),
                          _vm.showIcon
                            ? _c("span", {
                                class: [
                                  _vm.getIconPositionClass(),
                                  "_ga _ga--plus-bold-icon",
                                ],
                              })
                            : _vm._e(),
                        ]
                      ),
                ],
                1
              ),
            ]),
          ]
        : _vm.isDisabled
        ? _c("div", [
            _vm.tooltipMessage
              ? _c(
                  "div",
                  [
                    _c(
                      "tooltip",
                      {
                        staticClass: "gfu-1of1",
                        attrs: {
                          text: _vm.tooltipMessage,
                          "show-on-init": _vm.forceShowTooltip,
                          "data-qa": "add-product-to-cart-button-tooltip",
                        },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "gfu-btn gfu-btn--disabled gfu-btn--block",
                            class: _vm.getButtonClasses(),
                            attrs: {
                              disabled: "",
                              "data-qa": "add-product-to-cart-button:AddToCart",
                            },
                          },
                          [
                            _c("span", { staticClass: "gfu-btn__text" }, [
                              _vm._v(_vm._s(_vm.buttonText)),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "gfu-btn gfu-btn--disabled gfu-btn--block",
                      class: _vm.getButtonClasses(),
                      attrs: {
                        disabled: "",
                        "data-qa": "add-product-to-cart-button:AddToCart",
                      },
                    },
                    [
                      _c("span", { staticClass: "gfu-btn__text" }, [
                        _vm._v(_vm._s(_vm.buttonText)),
                      ]),
                    ]
                  ),
                ]),
          ])
        : _c(
            "button",
            {
              staticClass: "gfu-btn gfu-btn--block add-product-button-marker",
              class: [
                _vm.getButtonClasses(),
                _vm.accented ? "gfu-btn--accent" : "gfu-btn--primary",
              ],
              attrs: { "data-qa": "add-product-to-cart-button:AddToCart" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.displayOptionsOrSubmit.apply(null, arguments)
                },
              },
            },
            [
              _vm._t("buttonContents", function () {
                return [
                  _c("span", { staticClass: "gfu-btn__text" }, [
                    _vm._v(_vm._s(_vm.buttonText)),
                  ]),
                  _vm._v(" "),
                  _vm.showIcon
                    ? _c("span", {
                        class: [
                          _vm.getIconPositionClass(),
                          "_ga _ga--plus-bold-icon",
                        ],
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { visible: _vm.isProcessing, sticky: true },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }