import ProjectContextModalEmpty from './ProjectContextModalEmpty.vue';
import ProjectContextModalProduct from './ProjectContextModalProduct.vue';
import ProjectContextModalStretchGoal from './ProjectContextModalStretchGoal.vue';

const parseParams = (params) => (route) => {
    const result = Object.keys(params).reduce((acc, key) => {
        if (route.params[key]) {
            acc[key] = parseInt(route.params[key]);
        }
        return acc;
    }, {});

    return { ...route.params, ...result };
};

export default [
    {
        path: '/product/:id',
        name: 'product',
        components: { projectContextModal: ProjectContextModalProduct },
        meta: { isModalVisible: true },
        props: { projectContextModal: parseParams({ id: parseInt }) }
    },
    {
        path: '/stretch-goal/:id',
        name: 'stretchGoal',
        components: { projectContextModal: ProjectContextModalStretchGoal },
        meta: { isModalVisible: true },
        props: { projectContextModal: parseParams({ id: parseInt }) }
    },
    {
        path: '/404',
        name: 'not-found',
        components: { projectContextModal: ProjectContextModalEmpty },
        meta: { isModalVisible: true }
    }
];