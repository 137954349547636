var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "field",
      class: [
        "gfu-field",
        {
          "has-error": _vm.hasError,
          "gfu-field--disabled": _vm.disabled,
          "gfu-field--inline": _vm.inline,
          "gfu-field--bad": _vm.evaluation === "bad",
          "gfu-field--good": _vm.evaluation === "good",
          "gfu-field--columns-layout": _vm.layout === "columns",
          "gfu-field__prepend-wrapper":
            !_vm.readOnly && _vm.prepend && _vm.layout === "condensed",
          "gfu-field__append-wrapper":
            !_vm.readOnly && _vm.append && _vm.layout === "condensed",
          "gfu-field--small": _vm.small,
          "gfu-field--compact": _vm.compact,
          "gfu-field--validator-layout-tooltip": _vm.hasTooltipValidator,
        },
      ],
      attrs: { "data-qa": _vm.$attrs["data-qa"] },
    },
    [
      _vm.layout === "condensed"
        ? [
            _vm.showLabel && _vm.displayName
              ? _vm._t("label", function () {
                  return [
                    _c("field-label", {
                      class: _vm.labelClass,
                      attrs: {
                        text: _vm.displayName,
                        "is-required": _vm.isRequired,
                        description: _vm.description,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "description",
                            fn: function () {
                              return [_vm._t("field-description")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "gfu-field__holder",
                class: {
                  "_mt-1": _vm.hasDescription,
                  "gfu-field__prepend-wrapper": !_vm.readOnly && _vm.prepend,
                  "gfu-field__append-wrapper": !_vm.readOnly && _vm.append,
                },
              },
              [
                _vm.prepend && !_vm.readOnly
                  ? _c(
                      "div",
                      {
                        class: [
                          "gfu-field__prepend",
                          { "gfu-field__prepend--small": _vm.small },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.prepend))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("default"),
                _vm._v(" "),
                _vm.append && !_vm.readOnly
                  ? _c(
                      "div",
                      {
                        class: [
                          "gfu-field__append",
                          { "gfu-field__append--small": _vm.small },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.append))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasError
                  ? _c(
                      "field-error",
                      {
                        attrs: {
                          name: _vm.name,
                          "validator-layout": _vm.validatorLayout,
                          "data-qa": "field-error",
                        },
                      },
                      [_vm._v(_vm._s(_vm.parentErrors.first(_vm.name)))]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.layout === "columns"
        ? _c(
            "div",
            {
              class: [
                "gfu-grid",
                { "gfu-grid--gutter-columns": _vm.firstColumnWidth !== 4 },
              ],
            },
            [
              _c(
                "div",
                {
                  class: [
                    "gfu-grid__cell gfu-1of1 _mt-1",
                    "gfu-" + _vm.firstColumnWidth + "of12--m",
                  ],
                },
                [
                  _vm.showLabel
                    ? _vm._t("label", function () {
                        return [
                          _c("field-label", {
                            class: _vm.labelClass,
                            attrs: {
                              "is-required": _vm.isRequired,
                              text: _vm.displayName,
                              description: _vm.description,
                              layout: _vm.layout,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "description",
                                  fn: function () {
                                    return [_vm._t("field-description")]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      })
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    "gfu-grid__cell gfu-1of1 gfu-left-1of12--m _mt-1 _pos-r",
                    "gfu-" + (12 - 1 - _vm.firstColumnWidth) + "of12--m",
                    {
                      "gfu-field__prepend-wrapper":
                        !_vm.readOnly && _vm.prepend,
                      "gfu-field__append-wrapper": !_vm.readOnly && _vm.append,
                      "gfu-field__append-wrapper--currency": _vm.isCurrency,
                    },
                  ],
                },
                [
                  _vm.prepend && !_vm.readOnly
                    ? _c(
                        "div",
                        {
                          class: [
                            "gfu-field__prepend",
                            { "gfu-field__prepend--small": _vm.small },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.prepend))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t("default"),
                  _vm._v(" "),
                  _vm.append && !_vm.readOnly
                    ? _c(
                        "div",
                        {
                          class: [
                            {
                              "gfu-field__append": true,
                              "gfu-field__append--small": _vm.small,
                              "gfu-field__prepend--currency": _vm.isCurrency,
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.append))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasError
                    ? _c(
                        "field-error",
                        {
                          attrs: {
                            name: _vm.name,
                            "validator-layout": _vm.validatorLayout,
                            "data-qa": "field-error",
                          },
                        },
                        [_vm._v(_vm._s(_vm.parentErrors.first(_vm.name)))]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }