import { defineStore } from 'pinia';

import { projectPhases, projectCampaignOutcomes, projectTypes } from '@/Models';
import InitialState from '@/InitialState';

export const useProjectContextStore = defineStore({
    id: 'projectContext',
    state: () => ({
        projectContext: InitialState.projectContext,
    }),
    actions: {
        updateProjectContext(state, projectContext) {
            state.projectContext = projectContext;
        },
        loadProjectContext() {
            return Promise.resolve();
        },
    },
    getters: {
        isProjectInLatePledgePhase: state => {
            return state.projectContext.project?.phase === projectPhases.latePledge;
        },
        isProjectInCrowdfundingPhase: state => {
            return state.projectContext.project?.phase === projectPhases.crowdfunding;
        },
        isProjectInCrowdfundingEndedPhase: state => {
            return state.projectContext.project?.phase === projectPhases.crowdfundingEnded;
        },
        isProjectInPledgeManagerPhase: state => {
            return state.projectContext.project?.phase === projectPhases.pledgeManager;
        },
        isProjectInFundingPhase: state => { // @todo: find better name, late pledge ain't really funding phase
            return [projectPhases.crowdfunding, projectPhases.latePledge]
                .includes(state.projectContext.project?.phase);
        },
        isProjectInPhasePreview: state => {
            return state.projectContext && state.projectContext.previewPhase !== null;
        },
        isProjectCampaignFunded: state => {
            return state.projectContext.project?.campaignOutcome === projectCampaignOutcomes.success;
        },
        isExternalCampaign: state => {
            return state.projectContext.project?.campaignOutcome === projectCampaignOutcomes.none
                && state.projectContext.project?.originalType === projectTypes.pledgeManager;
        },
    },
});