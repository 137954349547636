var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.creditBalance > 0
    ? _c(
        "div",
        { attrs: { "data-qa": "mini-wizard-credits-box" } },
        [
          _vm._t(
            "default",
            function () {
              return [
                _c("div", { staticClass: "_flex _ai-c" }, [
                  _c(
                    "span",
                    { staticClass: "gfu-bt gfu-bt--caption _ttu" },
                    [
                      _vm._v("Your credits "),
                      _c(
                        "tooltip",
                        {
                          attrs: {
                            text: "This amount will reduce your final payment.",
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "_fa _fa--exclamation-circle _mr-1",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "strong",
                    {
                      staticClass: "gfu-bt gfu-bt--b1 _ml-a",
                      attrs: { "data-qa": "available-credits-box:Amount" },
                    },
                    [_vm._v(_vm._s(_vm.creditBalanceFormatted))]
                  ),
                ]),
              ]
            },
            { creditBalance: _vm.creditBalanceFormatted }
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }