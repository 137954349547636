import AdminComponentBase from './AdminComponentBase';
import RouterIndicator from '../RouterIndicator';
import ToggleTrigger from '../ToggleTrigger';
import ToggleContainer from '../ToggleContainer';
import SideNavLink from './SideNavLink.vue';
import SideNavLinkBack from './SideNavLinkBack.vue';
import SideNavContainer from './SideNavContainer.vue';

export default {
    extends: AdminComponentBase,
    components: {
        SideNavContainer,
        RouterIndicator,
        ToggleContainer,
        ToggleTrigger,
        SideNavLink,
        SideNavLinkBack
    }
};