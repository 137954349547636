var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass:
        "gfu-project-mini-wizard-item gfu-btn gfu-btn--block gfu-btn--thin gfu-btn--light-outline _fo",
      attrs: { "data-qa": `mini-wizard-item:${_vm.name}` },
    },
    [
      _vm.canBeDeleted
        ? _c(
            "span",
            {
              class: [
                "gfu-project-mini-wizard-item__icon-wrapper",
                {
                  "gfu-project-mini-wizard-item__icon-wrapper--visible":
                    _vm.showDeleteButton,
                },
              ],
            },
            [
              _c("span", {
                class: [
                  "gfu-project-mini-wizard-item__icon gfu-icon _fa _fa--times-circle",
                  _vm.disabledClass,
                ],
                attrs: { "data-qa": "mini-wizard-item-btn:Remove" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.onRemoveItem.apply(null, arguments)
                  },
                },
              }),
            ]
          )
        : _vm.isLocked
        ? _c(
            "span",
            {
              class: [
                "gfu-project-mini-wizard-item__icon-wrapper",
                {
                  "gfu-project-mini-wizard-item__icon-wrapper--visible":
                    _vm.showDeleteButton,
                },
              ],
            },
            [
              _c(
                "tooltip",
                {
                  staticClass: "gfu-project-mini-wizard-item__icon",
                  attrs: { text: _vm.lockedReason },
                },
                [
                  _c("span", {
                    staticClass: "gfu-icon _fa _fa--lock _tc--lighter",
                    attrs: { "data-qa": "mini-wizard-item-btn:Lock" },
                  }),
                ]
              ),
            ],
            1
          )
        : _vm.isConfirmed
        ? _c(
            "span",
            {
              staticClass:
                "gfu-project-mini-wizard-item__icon-wrapper gfu-project-mini-wizard-item__icon-wrapper--visible",
            },
            [
              _c("span", {
                staticClass:
                  "gfu-project-mini-wizard-item__icon gfu-icon _fa _fa--tick _tc--accent",
                attrs: { "data-qa": "mini-wizard-item-btn:Tick" },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          class: [
            "gfu-project-mini-wizard-item__label",
            { "_tc--accent": _vm.isNew },
          ],
          attrs: { "data-qa": "mini-wizard-item-label" },
        },
        [
          _vm._v("\n        " + _vm._s(_vm.formattedAmount) + " "),
          _c(
            "strong",
            { staticClass: "gfu-project-mini-wizard-item__label-text" },
            [_vm._v(_vm._s(_vm.name))]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }