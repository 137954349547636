import { loadScript } from '@/Utils/ScriptUtility';
import { ExternalSignInErrorTypes } from '@/Types/ExternalSignInErrorTypes';
import { ExternalSignInError } from '@/Utils/ExternalSignInUtility';
import { userIdentityProviders, externalScripts } from '@/Config';
import Resources from '@/Resources.js';

const googleSignInUrl = externalScripts.googleSignInUrl;
const clientID = userIdentityProviders.google.clientID;
export default class GoogleSignIn {

    async initAsync() {
        try {
            const result = await loadScript(googleSignInUrl);
            if (result) {
                return this;
            }
        }
        catch (error) {
            throw new Error(error);
        }
    }

    async signInAsync () {
        return new Promise((resolve, reject) => {
            try {
                const oauth2 = window.google.accounts.oauth2;
                oauth2.initTokenClient({
                    client_id: clientID,
                    scope: 'email profile',
                    callback(tokenResponse) {
                        if (tokenResponse.error) {
                            reject(new ExternalSignInError(ExternalSignInErrorTypes.error, tokenResponse.error_description));
                        }
                        if (oauth2.hasGrantedAllScopes(tokenResponse, 'email', 'profile')) {
                            resolve(tokenResponse.access_token);
                        }
                        else {
                            const msg = Resources.Account.ExternalSignInGooglePermissionDeniedErrorMessage;
                            reject(new ExternalSignInError(ExternalSignInErrorTypes.permissionDenied, msg));
                        }
                    }
                }).requestAccessToken();
            }
            catch (error) {
                throw new Error(error);
            }
        });
    }
}
