export default {
    data() {
        return {
            isProcessingView: false
        };
    },
    provide() {
        return {
            withViewProcessing: this.withViewProcessing
        };
    },
    methods: {
        withViewProcessing(task) {
            return (...args) => {
                this.isProcessingView = true;
                return task.apply(this, args)
                    .finally(() => this.isProcessingView = false);
            };
        },
    }
};