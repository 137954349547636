import { defineStore } from 'pinia';

import InitialState from '@/InitialState';

export const useProjectStore = defineStore({
    id: 'project',
    state: () => ({
        projectFollowerFacts: InitialState.projectFollowerFacts,
        projectTrackingModel: InitialState.projectTracking,
        trackingModel: InitialState.analyticsTracking,
        promoItem: InitialState.promoItemState,
        featuredReward: InitialState.featuredReward,
    }),
    actions: {
        setProjectFollowerFacts(data) {
            const { projectID, followersCount } = data;
            this.projectFollowerFacts = {
                ...this.projectFollowerFacts,
                projectID,
                followersCount
            };
        },
        setProjectTrackingModel(trackingModel) {
            this.projectTrackingModel = trackingModel;
        },
        setAnalyticsTrackingModel(trackingModel) {
            this.trackingModel = trackingModel;
        },
        setPromoItem(promoItem) {
            this.promoItem = promoItem;
        },
        setFeaturedReward(reward) {
            this.featuredReward = reward;
        },
    },
    getters: {
        getProjectFollowerFacts: state => state.projectFollowerFacts
    }
});