var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    _vm._g({ attrs: { name: _vm.transitionName } }, _vm.$listeners),
    [
      _vm.rewardsSection && _vm.isVisible
        ? _c(
            "div",
            {
              staticClass:
                "gfu-project-bottom-bar gfu-project-bottom-bar--compact",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "gfu-project-bottom-bar__content gfu-project-bottom-bar__content--absolute",
                },
                [_c("back-project-button")],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }