import { usePageSectionsStore } from '@/Stores/pageSectionsStore';

import router from '@/Router';
import MagicAnchor from '@/Plugins/MagicAnchor.js';

router.addRoute({ path: '/section/:section/:subSection?', name: 'page-section', meta: { noScroll: true } });

let lastTopLevelSection = null;

export default class PageSectionAnchor {
    constructor(anchorElement, name, urlName, isTopLevel, anchorScrollOffset = null) {
        addPageSection(name, urlName, isTopLevel || !lastTopLevelSection);

        const anchorRoute = {
            name: 'page-section',
            params: isTopLevel || !lastTopLevelSection
                ? { section: urlName }
                : { section: lastTopLevelSection.urlName, subSection: urlName }
        };

        new MagicAnchor(anchorElement, anchorRoute, anchorScrollOffset);
    }
}

function addPageSection(name, urlName, isTopLevel) {
    const section = {
        name,
        urlName,
        sections: []
    };

    const pageSectionStore = usePageSectionsStore();
    pageSectionStore.addPageSection({ section, parentSection: isTopLevel ? null : lastTopLevelSection });

    if (isTopLevel)
        lastTopLevelSection = section;

    return section;
}