import CookiesUtility, { cookieNames } from '@/Utils/CookiesUtility.js';

let initialState = {
    internalAnalyticsTrackingEnabled: false,
    analyticsTracking: null,
    checkoutCurrencies: [],
    currencySelectorVisible: false,
    currentCheckoutCurrencyId: null,
    displayCurrencies: [],
    featuredReward: null,
    preventCartLoading: false,
    projectContext: null,
    projectFollowerFacts: null,
    userAdditionalInfo: {
        followedProjectsIDs: [],
        followedUsersIDs: [],
        backedProjectsIDs: [],
    },
    projectState: {
        facts: null,
        projectAdditionalSettings: {
            pledgeManagerAvailability: null,
            enableShippingOnlyMode: false,
            allowNewOrders: false,
            hasAccessToLimitedPledgeManager: false
        },
        stretchGoals: []
    },
    projectTracking: null,
    promoItemState: null,
    userContext: null,
    availableLanguages: []
};

const cookieCurrentDisplayCurrencyId = CookiesUtility.get(cookieNames.displayCurrency);

initialState.cookie = {
    currentDisplayCurrencyId: cookieCurrentDisplayCurrencyId ? parseInt(cookieCurrentDisplayCurrencyId) : null
};

initialState = { ...initialState, ...window.__INITIAL_STATE__ };

export default initialState;