var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-floating-message",
        "gfu-floating-notification-box",
        "gfu-floating-notification-box--fixed",
        _vm.themeClassName,
      ],
    },
    [
      _c(
        "div",
        { staticClass: "gfu-media" },
        [
          _vm._t("icon"),
          _vm._v(" "),
          _c("div", { staticClass: "_ml-1" }, [
            _c("h2", { staticClass: "gfu-hd gfu-bt--b2" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "gfu-bt gfu-bt--caption _tc--gray" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.description) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "_flexaicntr" }, [_vm._t("actions")], 2),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }