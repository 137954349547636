import UserProfileViewComments from './UserProfileViewComments.vue';
import UserProfileViewFollowers from './UserProfileViewFollowers.vue';
import UserProfileViewFollowing from './UserProfileViewFollowing.vue';
import UserProfileViewProjects from './UserProfileViewProjects.vue';
import UserProfileViewOverview from './UserProfileViewOverview.vue';

const routes = [
    {
        path: '/overview',
        name: 'user-profile-overview',
        components: { profile: UserProfileViewOverview },
    },
    {
        path: '/projects',
        name: 'user-profile-projects',
        components: { profile: UserProfileViewProjects },
    },
    {
        path: '/followers',
        name: 'user-profile-followers',
        components: { profile: UserProfileViewFollowers },
    },
    {
        path: '/following',
        name: 'user-profile-following',
        components: { profile: UserProfileViewFollowing },
    },
    {
        path: '/comments',
        name: 'user-profile-comments',
        components: { profile: UserProfileViewComments },
    },
];

export {
    routes
};