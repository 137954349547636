var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.steps.length
    ? _c("div", { staticClass: "gfu-steps-indicator" }, [
        _c(
          "div",
          {
            class: [
              "gfu-steps-indicator__container",
              `gfu-steps-indicator__container-${_vm.steps.length}`,
            ],
            attrs: { "data-qa": "steps-indicator" },
          },
          [
            _c(
              "ul",
              { staticClass: "gfu-steps-indicator__progressbar" },
              _vm._l(_vm.steps, function (step, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: [
                      "gfu-steps-indicator__step",
                      { "gfu-steps-indicator__step--active": step.isActive },
                    ],
                  },
                  [
                    !_vm.isInAdminMode && step.url
                      ? _c(
                          "a",
                          {
                            staticClass: "gfu-steps-indicator__link",
                            attrs: {
                              href: step.url,
                              "data-qa": `steps-indicator-step:${step.text}`,
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(step.text))])]
                        )
                      : _vm.isInAdminMode && step.url
                      ? _c(
                          "router-link",
                          {
                            staticClass: "gfu-steps-indicator__link",
                            attrs: {
                              to: step.url,
                              "data-qa": `steps-indicator-step:${step.text}`,
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(step.text))])]
                        )
                      : typeof step.onClick === "function"
                      ? _c(
                          "a",
                          {
                            staticClass: "gfu-steps-indicator__link",
                            attrs: {
                              href: "",
                              "data-qa": `steps-indicator-step:${step.text}`,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return step.onClick(index)
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(step.text))])]
                        )
                      : _c("span", [_vm._v(_vm._s(step.text))]),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }