var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-product-set-item-wrapper",
      attrs: { "data-qa": "product-set-item-details:" + _vm.productSetItemID },
    },
    [
      _c("base-thumb", {
        attrs: { size: "small", "image-url": _vm.imageUrl, alt: _vm.name },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "gfu-product-set-item-wrapper__content",
            {
              "gfu-product-set-item-wrapper__content--middle": !_vm.hasOptions,
            },
          ],
        },
        [
          _c("div", { staticClass: "gfu-product-set-item-details" }, [
            _c("span", { attrs: { "data-qa": "quantity" } }, [
              _vm._v(_vm._s(_vm.quantity) + "x "),
            ]),
            _vm._v(" "),
            _c("span", [
              _c("strong", { attrs: { "data-qa": "product-name" } }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.hasOptions
            ? _c("div", { staticClass: "_pa-0 _flex _grow-1" }, [
                _c(
                  "div",
                  { staticClass: "_grow-1" },
                  _vm._l(_vm.options, function (option, index) {
                    return _c(
                      "div",
                      {
                        key:
                          "product-set-item-option-" +
                          option.productOptionValueID,
                      },
                      [
                        option.productOptionValueID
                          ? _c("product-option-field", {
                              class: { "_mt-0": index === 0 },
                              attrs: {
                                option: option,
                                "selected-option-value-id":
                                  option.productOptionValueID,
                                "currency-symbol": _vm.currencySymbol,
                                "relative-factor-with-commission":
                                  _vm.relativeFactorWithCommission,
                                "label-class": "gfu-field__label--light",
                              },
                              on: { change: _vm.setSelectedProductOption },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.showSplitColumn
                  ? _c(
                      "div",
                      { staticClass: "_ml-1 _mt-4" },
                      [
                        _vm.canProductBeSplit
                          ? _c("icon-base-button", {
                              attrs: {
                                "icon-namespace": "ga",
                                icon: "split-icon",
                                "only-icon": true,
                                theme: "lightFlat",
                                shape: "circle",
                                title: "Split item",
                                "data-qa": "split-button",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.splitProductItem.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canProductBeRemoved
                          ? _c("icon-base-button", {
                              attrs: {
                                icon: "times",
                                "only-icon": true,
                                theme: "lightFlat",
                                shape: "circle",
                                title: "Undo split",
                                "data-qa": "remove-button",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeProductItem.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }