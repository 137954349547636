import lazyScreen from '@/Components/Admin/LazyScreen';

const ProjectSettingsSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ '@/Components/Admin/Projects/ProjectSettingsSideNav'));

const ProjectStretchGoalsSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectStretchGoalsSettingsScreen.vue'));
const ProjectStretchGoalSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './ProjectStretchGoalSummaryScreen.vue'));

const routes = [
    {
        path: '/project-settings/:creator/:project/stretchgoals', name: 'project-settings-stretch-goals',
        components: { default: ProjectStretchGoalsSettingsScreen, sidenav: ProjectSettingsSideNav }
    },
    {
        path: '/project-settings/:creator/:project/stretchgoals/:stretchGoalId/summary', name: 'project-stretch-goals-summary',
        components: { default: ProjectStretchGoalSummaryScreen, sidenav: ProjectSettingsSideNav }, props: { default: route => ({ stretchGoalId: parseInt(route.params.stretchGoalId) }) }
    },
];

export {
    routes
};
