var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-box" },
    [
      _c("contact-back", {
        staticClass: "_mb-3",
        on: {
          click: function ($event) {
            return _vm.$emit("back")
          },
        },
      }),
      _vm._v(" "),
      _c(
        "contact-current-group-header",
        _vm._b(
          {},
          "contact-current-group-header",
          {
            currentCategoryGroup: _vm.currentCategoryGroup,
            currentProject: _vm.currentProject,
          },
          false
        )
      ),
      _vm._v(" "),
      _c(
        "h2",
        {
          staticClass: "gfu-hd gfu-hd--h1 _mb-4",
          attrs: { "data-qa": "contact-form-title" },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Contact.TopicsSectionHeader) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "field",
        {
          attrs: {
            name: "model.email",
            "display-name": _vm.Resources.Contact.FormEmailFieldLabel,
            "data-qa": "contact-form-message:Email",
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.email,
                expression: "model.email",
              },
            ],
            ref: "emailInput",
            staticClass: "gfu-field__input",
            class: { "gfu-field__input--readonly": _vm.isEmailReadOnly },
            attrs: {
              name: "email",
              type: "email",
              readonly: _vm.isEmailReadOnly,
            },
            domProps: { value: _vm.model.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.model, "email", $event.target.value)
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "field",
        {
          attrs: {
            "display-name": _vm.Resources.Contact.FormSubjectFieldLabel,
          },
        },
        [
          _c("input", {
            staticClass: "gfu-field__input gfu-field__input--readonly",
            attrs: {
              type: "text",
              readonly: "readonly",
              "data-qa": "contact-form-message:Subject",
            },
            domProps: { value: _vm.messageSubject },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "field",
        {
          attrs: {
            name: "model.message",
            "display-name": _vm.Resources.Contact.FormMessageFieldLabel,
            "data-qa": "contact-form-message:Message",
          },
        },
        [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.message,
                expression: "model.message",
              },
            ],
            ref: "messageInput",
            staticClass: "gfu-field__textarea",
            attrs: { cols: "60", rows: "4" },
            domProps: { value: _vm.model.message },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.model, "message", $event.target.value)
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "gfu-field gfu-contact-form__actions",
          attrs: { "data-qa": "contact-form-section:Captcha" },
        },
        [
          _vm.isCaptchaEnabled
            ? _c(
                "div",
                { staticClass: "gfu-bt gfu-bt--caption _tc--light" },
                [
                  _c(
                    "localize",
                    {
                      attrs: {
                        resource:
                          _vm.Resources.Contact.CaptchaDisclaimerCaption,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "gfu-link gfu-link--accent",
                          attrs: {
                            "format-key": "0",
                            href: "https://policies.google.com/privacy",
                            target: "_blank",
                            rel: "nofollow noopener noreferrer",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.Contact.CaptchaPrivacyPolicyLink
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "gfu-link gfu-link--accent",
                          attrs: {
                            "format-key": "1",
                            href: "https://policies.google.com/terms",
                            target: "_blank",
                            rel: "nofollow noopener noreferrer",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.Contact.CaptchaTermsOfServiceLink
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "base-button",
            {
              staticClass: "gfu-contact-form__submit",
              attrs: {
                type: "submit",
                theme: "accent",
                width: "wide",
                enabled: _vm.isInitialized,
                "data-qa": "contact-form-message-btn:Submit",
              },
              on: { click: _vm.onSubmit },
            },
            [_vm._v(_vm._s(_vm.Resources.Common.FormSubmitButton))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("contact-back", {
        staticClass: "_mt-3",
        on: {
          click: function ($event) {
            return _vm.$emit("back")
          },
        },
      }),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { sticky: true, visible: _vm.isProcessing },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }