var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-layout__main gfu-layout__main--narrow _mt-6" },
    [
      _c(
        "h1",
        {
          staticClass: "gfu-heading gfu-heading--main",
          attrs: { "data-qa": "page-title" },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Checkout.CheckoutHeader) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-subheading gfu-subheading--main" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.Resources.Checkout.CheckoutSubHeader) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "box",
        { staticClass: "_mt-3 _mb-6", attrs: { "data-qa": "cart-box" } },
        [
          _c(
            "cart-table",
            _vm._b(
              {
                on: {
                  "cart-changed": _vm.onCartChanged,
                  "location-change": _vm.onCartChanged,
                },
              },
              "cart-table",
              _vm.cartTableProps,
              false
            )
          ),
          _vm._v(" "),
          _vm.isProjectInLatePledgePhase
            ? _c(
                "div",
                {
                  staticClass: "gfu-grid gfu-grid--gutter",
                  attrs: {
                    "data-qa": `begin-order-form-button:${_vm.checkoutReturnActionDataQa}`,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-grid__cell gfu-1of1 gfu-1of2--s gfu-1of3--m",
                    },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: {
                            tag: "a",
                            href: _vm.checkoutReturnActionUrl,
                            shape: "default",
                            display: "block",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.Resources.Checkout.ManageYourPledgeButton
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.handleTaxWithoutShipping && _vm.isStoreDataInitialized
            ? _c(
                "order-details-location",
                _vm._b(
                  {},
                  "order-details-location",
                  _vm.orderDetailsLocationProps,
                  false
                )
              )
            : [
                _c(
                  "h2",
                  {
                    staticClass:
                      "gfu-heading gfu-heading--top-divider _mt-3 _mb-2",
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.Resources.Checkout.CheckoutShippingStepLabel
                        ) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "_mb-4" }, [
                  _c("span", { staticClass: "_fa _fa--info" }),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.shippingInfoMessage) +
                      "\n            "
                  ),
                ]),
              ],
          _vm._v(" "),
          _c("order-summary-list", { attrs: { "order-id": _vm.orderId } }),
          _vm._v(" "),
          _c(
            "h2",
            { staticClass: "gfu-heading gfu-heading--top-divider _mt-3 _mb-2" },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.Resources.Checkout.CheckoutPaymentOptionsSectionTitle
                  ) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("dynamic-currency-conversion-wrapper", {
            attrs: {
              "project-id": _vm.projectId,
              "order-id": _vm.orderId,
              reason: _vm.paymentMethodListReason,
              "can-be-paid-in-installments": _vm.canBePaidInInstallments,
              "handle-tax-without-shipping": _vm.handleTaxWithoutShipping,
              "payment-currency-strategy": _vm.paymentCurrencyStrategy,
            },
          }),
          _vm._v(" "),
          _vm.isPaymentTypesVisible
            ? _c("payment-types", {
                attrs: {
                  "current-payment-type": _vm.paymentType,
                  "effective-payable-amount": _vm.effectivePayableAmount,
                  "data-qa": "installments-wrapper",
                },
                on: { "payment-type-changed": _vm.onPaymentTypeChanged },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isOrderToPay
            ? _c("h3", { staticClass: "gfu-hd gfu-hd--h3 _mt-5" }, [
                _vm._v(
                  _vm._s(
                    _vm.Resources.Checkout.CheckoutPaymentMethodSectionTitle
                  )
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isStoreDataInitialized
            ? _c(
                "adyen-order-payment-form",
                _vm._b(
                  { attrs: { "data-qa": "payment-section" } },
                  "adyen-order-payment-form",
                  _vm.paymentFormProps,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _c("checkout-footer"),
          _vm._v(" "),
          _c("progress-overlay", { attrs: { visible: _vm.isProcessingView } }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }