import { checkElementViewportFit } from '@/Utils';

export default {
    props: {
        activeClass: { type: String, default: 'is-active' },
        name: { type: [Number, String], default: null },
        tag: { type: String, default: 'div' },
        viewportOverflowLeftClass: { type: String },
        viewportOverflowRightClass: { type: String },
        viewportOverflowTopClass: { type: String },
        viewportOverflowBottomClass: { type: String },
        showExpanded: { type: Boolean, default: false }
    },
    data() {
        return {
            isActive: this.showExpanded,
            overflowsViewportLeft: false,
            overflowsViewportRight: false,
            overflowsViewportTop: false,
            overflowsViewportBottom: false
        };
    },
    methods: {
        collapse() {
            this.isActive = false;
        },
        expand() {
            this.isActive = true;
        }
    },
    watch: {
        isActive(isActive) {
            if (isActive) {
                this.overflowsViewportLeft = false;
                this.overflowsViewportRight = false;
                this.overflowsViewportTop = false;
                this.overflowsViewportBottom = false;

                this.$nextTick(() => {
                    const fit = checkElementViewportFit(this.$el);

                    this.overflowsViewportLeft = !fit.left;
                    this.overflowsViewportRight = !fit.right;
                    this.overflowsViewportTop = !fit.top;
                    this.overflowsViewportBottom = !fit.bottom;
                });
            }

            this.$emit('active-change', isActive);
        }
    },
    render(createElement) {
        const data = {
            class: {
            }
        };

        data.class[this.activeClass] = this.$data.isActive;

        if (this.viewportOverflowLeftClass) {
            data.class[this.viewportOverflowLeftClass] = this.$data.overflowsViewportLeft;
        }

        if (this.viewportOverflowRightClass) {
            data.class[this.viewportOverflowRightClass] = this.$data.overflowsViewportRight;
        }

        if (this.viewportOverflowTopClass) {
            data.class[this.viewportOverflowTopClass] = this.$data.overflowsViewportTop;
        }

        if (this.viewportOverflowBottomClass) {
            data.class[this.viewportOverflowBottomClass] = this.$data.overflowsViewportBottom;
        }

        return createElement(this.tag, data, this.$slots.default);
    }
};
