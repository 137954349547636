var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-table__row" }, [
    _c(
      "div",
      {
        staticClass:
          "gfu-table__cell gfu-table__cell-row _flexb50 _flexb75--s _flexb60--m _flexb60--xl _pa-0",
      },
      [
        _c("div", { staticClass: "_screen--none _screen--s" }, [
          _c("div", { staticClass: "gfu-table__cell--image _mr-3 _fl" }, [
            _c("div", { staticClass: "gfu-table__thumb" }, [
              _c("img", {
                attrs: {
                  src: _vm.item.product.imageUrl,
                  alt: _vm.item.displayName,
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-table__cell--grow _pt-1 _pb-1" }, [
          _c("div", [
            _c(
              "p",
              {
                staticClass: "gfu-table__text--strong gfu-table__text--big",
                attrs: { "data-qa": "cart-table:ProductName" },
              },
              [_vm._v(_vm._s(_vm.item.displayName))]
            ),
            _vm._v(" "),
            _vm.canDelete
              ? _c(
                  "button",
                  {
                    staticClass: "_pl-0 _mt-0 _tc--error _screen--not-s",
                    attrs: { disabled: _vm.isProcessing },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setOrderItemQuantity(0, _vm.item)
                      },
                    },
                  },
                  [_vm._v("remove item")]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.canEditOption
            ? _c(
                "div",
                _vm._l(
                  _vm.item.product.options.filter((o) => o.values.length > 0),
                  function (option) {
                    return _c(
                      "div",
                      {
                        key: "product-option-" + option.productOptionID,
                        attrs: { "data-qa": "cart-table:ProductOptions" },
                      },
                      [
                        _c("product-option-field", {
                          ref:
                            "option-" +
                            _vm.item.orderItemID +
                            "-" +
                            option.productOptionID,
                          refInFor: true,
                          attrs: {
                            "label-class": "gfu-field__label--light",
                            "field-class":
                              "gfu-field-select--tiny-text gfu-field-select--squeezed gfu-5of6 gfu-2of3--m",
                            option: option,
                            "selected-option-value-id":
                              _vm.getProductOptionValueID(
                                _vm.item.options,
                                option.productOptionID
                              ),
                            "order-item-id": _vm.item.orderItemID,
                          },
                          on: { change: _vm.setOrderItemOption },
                        }),
                      ],
                      1
                    )
                  }
                ),
                0
              )
            : _vm.item.product.options.length > 0
            ? _c(
                "div",
                { attrs: { "data-qa": "cart-table:ProductOptionValue" } },
                [
                  _vm._v(
                    _vm._s(_vm.getReadonlyProductOptionValuesList(_vm.item))
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "gfu-table__cell gfu-table__cell--actions _tac _flexb15 _flexb10--s",
        attrs: { "data-qa": "cart-table-item-row-quantity" },
      },
      [
        _vm.canEditQuantity
          ? _c(
              "div",
              { staticClass: "gfu-product__amount gfu-1of1" },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "gfu-table__amount-btn gfu-table__amount-btn--plus gfu-btn gfu-btn--icon gfu-btn--primary",
                    attrs: {
                      type: "button",
                      disabled: _vm.isProcessing,
                      "data-qa": "begin-order-form-button:Plus",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setOrderItemQuantityPreventingZero(
                          _vm.item.quantity + 1,
                          _vm.item
                        )
                      },
                    },
                  },
                  [_c("span", { staticClass: "_fa _fa--plus" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "gfu-table__amount-btn gfu-table__amount-btn--minus gfu-btn gfu-btn--icon gfu-btn--primary",
                    attrs: {
                      type: "button",
                      disabled: _vm.isProcessing,
                      "data-qa": "begin-order-form-button:Minus",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setOrderItemQuantityPreventingZero(
                          _vm.item.quantity - 1,
                          _vm.item
                        )
                      },
                    },
                  },
                  [_c("span", { staticClass: "_fa _fa--minus" })]
                ),
                _vm._v(" "),
                _c("number-field", {
                  attrs: {
                    name: "totalQuantity",
                    "show-label": false,
                    integer: true,
                    min: 1,
                    max: _vm.maxQuantity,
                    maxlength: _vm.maxQuantityDigits,
                    negative: false,
                    "validator-layout": "none",
                    disabled: _vm.isProcessing,
                  },
                  on: {
                    focusout: function ($event) {
                      return _vm.setOrderItemQuantity(
                        _vm.item.quantity,
                        _vm.item
                      )
                    },
                    enter: function ($event) {
                      $event.preventDefault()
                      return _vm.setOrderItemQuantity(
                        _vm.item.quantity,
                        _vm.item
                      )
                    },
                  },
                  model: {
                    value: _vm.item.quantity,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "quantity", $$v)
                    },
                    expression: "item.quantity",
                  },
                }),
                _vm._v(" "),
                _vm.isQuantityWrong
                  ? _c(
                      "tooltip",
                      {
                        staticClass: "gfu-table__amount-error-wrapper",
                        attrs: { text: _vm.quantityErrorText },
                      },
                      [
                        _c("span", {
                          staticClass: "gfu-table__amount-error",
                          attrs: {
                            "data-qa":
                              "cart-table-item-row-quantity-error:icon",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("span", [_vm._v(_vm._s(_vm.item.quantity))]),
                _vm._v(" "),
                _vm.isQuantityWrong
                  ? _c(
                      "tooltip",
                      {
                        staticClass: "gfu-table__amount-error-wrapper",
                        attrs: { text: _vm.quantityErrorText },
                      },
                      [
                        _c("span", {
                          staticClass: "gfu-table__amount-error",
                          attrs: {
                            "data-qa":
                              "cart-table-item-row-quantity-error:icon",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "gfu-table__cell _tar _flexb35 _flexb25--s _flexb15--m",
        attrs: { "data-text": "Total price" },
      },
      [
        _c("div", { attrs: { "data-qa": "cart-table-item-row:Price" } }, [
          _vm._v("\n            " + _vm._s(_vm.priceFormatted) + "\n        "),
        ]),
        _vm._v(" "),
        _vm.showTaxValue
          ? _c(
              "div",
              {
                staticClass: "gfu-bt--overline _tc--light",
                attrs: { "data-qa": "cart-table-item-row:Tax" },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.taxFormatted) + "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _vm.allowEditing
      ? _c(
          "div",
          {
            staticClass:
              "gfu-table__cell _tar _pa-0 _flexb5 _screen--none _screen--s",
          },
          [
            _vm.canDelete
              ? _c(
                  "button",
                  {
                    staticClass: "gfu-btn gfu-btn--icon",
                    attrs: { disabled: _vm.isProcessing },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setOrderItemQuantity(0, _vm.item)
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "_fa _fa--times-circle _tc--error",
                      attrs: {
                        "data-qa": "begin-order-form-button:RemoveItem",
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }