import lazyScreen from '@/Components/Admin/LazyScreen';

const CreatorSettingsSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-creator' */ './CreatorSettingsSideNav.vue'));

const CreatorDashboard = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-creator' */ './CreatorDashboard.vue'));
const CreatorGeneralSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-creator' */ './CreatorGeneralSettingsScreen.vue'));
const CreatorBillingSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-creator' */ './CreatorBillingSettingsScreen.vue'));
const CreatorIdentitySettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-creator' */ './CreatorIdentitySettingsScreen.vue'));
const CreatorCommunicationSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-creator' */ './CreatorCommunicationSettingsScreen.vue'));
const CreatorFeatureSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-creator' */ './CreatorFeatureSettingsScreen.vue'));

const CreatorsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-creator' */ './CreatorsListScreen.vue'));
const ProjectsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-creator' */ '@/Components/Admin/Projects/ProjectsListScreen.vue'));

const CommentsManagementListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-creator' */ '@/Components/Admin/Comments/CommentsManagementListScreen.vue'));

const routes = [
    { path: '/system/creators', name: 'system-creators', component: CreatorsListScreen },

    { path: '/creator/:creator/projects', name: 'creator-projects', component: ProjectsListScreen },
    { path: '/:creator/projects', redirect: { name: 'creator-projects' } },

    { path: '/creator/:creator/dashboard', name: 'creator-dashboard', components: { default: CreatorDashboard } },
    { path: '/creator-settings/:creator/general', name: 'creator-settings-general', components: { default: CreatorGeneralSettingsScreen, sidenav: CreatorSettingsSideNav } },
    { path: '/creator/:creator/settings', redirect: { name: 'creator-settings-general' } },

    { path: '/creator/:creator/comments', name: 'creator-comments', component: CommentsManagementListScreen },

    { path: '/creator-settings/:creator/billing', name: 'creator-settings-billing', components: { default: CreatorBillingSettingsScreen, sidenav: CreatorSettingsSideNav } },
    { path: '/creator-settings/:creator/identity', name: 'creator-settings-identity', components: { default: CreatorIdentitySettingsScreen, sidenav: CreatorSettingsSideNav } },
    { path: '/creator-settings/:creator/communication', name: 'creator-settings-communication', components: { default: CreatorCommunicationSettingsScreen, sidenav: CreatorSettingsSideNav } },
    { path: '/creator-settings/:creator/features', name: 'creator-settings-features', components: { default: CreatorFeatureSettingsScreen, sidenav: CreatorSettingsSideNav } },

    { path: '/:creator/settings', redirect: { name: 'creator-settings' } }
];

export {
    routes
};
