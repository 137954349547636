export default {
    mounted() {
        const keydownHandler = (e) => {
            if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
                this.onEscapePress();
            }
        };

        document.addEventListener('keydown', keydownHandler);

        this.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', keydownHandler);
        });
    }
};
