import MessageBox from '@/Utils/MessageBox';
import { projectPhases } from '@/Models';
import Resources from '@/Resources.js';

export function cancelPledge(phase, url) {
    const question = phase === projectPhases.crowdfunding ? Resources.YourPledge.CancelPledgeCFModalParagraph : Resources.YourPledge.CancelPledgeModalParagraph;

    return MessageBox.question(question, Resources.YourPledge.CancelPledgeModalHeader, () => {
        this.startProcessing();
        // @TODO: move those MVC redirects to API calls in #6521
        window.location = url;
    });
}