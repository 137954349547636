var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      { attrs: { "is-currency": true, append: _vm.symbol } },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      !_vm.readOnly
        ? _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model",
                  },
                ],
                ref: "input",
                class: [
                  "gfu-field__input gfu-field__input--text-" + _vm.textAlign,
                  { "gfu-field__input--small": _vm.small },
                ],
                attrs: {
                  type: "number",
                  placeholder: _vm.placeholder,
                  maxlength: _vm.maxlength,
                  name: _vm.name,
                  disabled: _vm.disabled,
                  "data-qa": "number-field-input",
                },
                domProps: { value: _vm.model },
                on: {
                  keypress: _vm.onKeyPress,
                  keyup: _vm.onKeyUp,
                  focusout: _vm.onFocusOut,
                  blur: _vm.onBlur,
                  keydown: _vm.onKeydown,
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.model = $event.target.value
                    },
                    _vm.handleChange,
                  ],
                },
              },
              "input",
              { min: _vm.min, max: _vm.max, step: _vm.step },
              false
            )
          )
        : _vm.model && _vm.readOnly
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formattedCurrency,
                expression: "formattedCurrency",
              },
            ],
            ref: "input",
            class: [
              "gfu-field__input gfu-field__input--readonly",
              { "gfu-field__input--small": _vm.small },
            ],
            attrs: {
              type: "text",
              disabled: _vm.disabled,
              readonly: "readonly",
              name: _vm.name,
              "data-qa": "number-field-input",
            },
            domProps: { value: _vm.formattedCurrency },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.formattedCurrency = $event.target.value
              },
            },
          })
        : _c(
            "span",
            {
              class: [
                "gfu-field__input gfu-field__input--faux gfu-field__input--readonly",
                { "gfu-field__input--small": _vm.small },
              ],
            },
            [_vm._t("default")],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }