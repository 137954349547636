var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal-box", {
    attrs: {
      title: _vm.title,
      icon: "user",
      "is-visible": _vm.isVisible,
      "has-backdrop": true,
      "show-action-close": true,
    },
    on: { "action-close": _vm.onClose, "after-enter": _vm.onAfterEnter },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "_mb-3" }, [_vm._v(_vm._s(_vm.message))]),
            _vm._v(" "),
            _vm.isNicknameFieldVisible
              ? _c("text-field", {
                  ref: "nickname",
                  staticClass: "_mt-0 _mr-1 _grow-1",
                  attrs: {
                    name: "model.nickname",
                    "is-required": true,
                    "display-name":
                      _vm.Resources.Profile.UserFollowSettingsNicknameLabel,
                    placeholder:
                      _vm.Resources.Profile.UserFollowSettingsNicknameLabel,
                    "show-label": true,
                    "data-qa": "modal-field:Nickname",
                  },
                  on: {
                    keyup: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.onSave.apply(null, arguments)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        return _vm.onCancel.apply(null, arguments)
                      },
                    ],
                  },
                  model: {
                    value: _vm.model.nickname,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "nickname", $$v)
                    },
                    expression: "model.nickname",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isPublicFieldVisible
              ? _c("switch-field", {
                  staticClass: "_mt-5",
                  attrs: {
                    name: "model.isPublic",
                    "show-label": false,
                    "on-label":
                      _vm.Resources.Profile.UserFollowSettingsPublicStatusLabel,
                    "off-label":
                      _vm.Resources.Profile
                        .UserFollowSettingsMakeMyProfilePublicLabel,
                    "display-name":
                      _vm.Resources.Profile.UserFollowSettingsPublicStatusLabel,
                    "data-qa": "modal-slider:IsProfilePublic",
                  },
                  model: {
                    value: _vm.model.isPublic,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "isPublic", $$v)
                    },
                    expression: "model.isPublic",
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "gfu-link gfu-link--accent",
                attrs: { type: "button", "data-qa": "modal-button:Cancel" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onClose.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.Resources.Profile.UserFollowSettingsCancelButton)
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "tooltip",
              {
                attrs: {
                  text: _vm.followRestrictionReason,
                  enabled: _vm.isFollowDisabled,
                },
              },
              [
                _c(
                  "base-button",
                  {
                    attrs: {
                      theme: "accent",
                      type: "submit",
                      disabled: _vm.isFollowDisabled,
                      "data-qa": "modal-button:Follow",
                    },
                    on: { click: _vm.onFollow },
                  },
                  [_vm._v(_vm._s(_vm.Resources.Profile.UserFollowAction))]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }