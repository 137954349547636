var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "slider-container",
    { attrs: { centered: false, "percentage-to-scroll": 50 } },
    [
      _c(
        "a",
        {
          staticClass: "gfu-link gfu-link-underline _mr-3",
          attrs: {
            href: _vm.projectUrl,
            "data-qa": "product-breadcrumb:Project",
          },
        },
        [
          _c("span", { staticClass: "gfu-link-underline__content" }, [
            _vm._v(_vm._s(_vm.projectName)),
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "_ml-3 _fa _fa--chevron-right _tc--accent",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "gfu-link gfu-link-underline _mr-3",
          attrs: {
            href: _vm.categoryUrl,
            "data-qa": "product-breadcrumb:Category",
          },
        },
        [
          _c("span", { staticClass: "gfu-link-underline__content" }, [
            _vm._v(_vm._s(_vm.categoryName)),
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "_ml-3 _fa _fa--chevron-right _tc--accent",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "gfu-link",
          attrs: {
            href: _vm.productUrl,
            "data-qa": "product-breadcrumb:Product",
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.productName))])]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }