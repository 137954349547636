var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-grid__cell gfu-1of1",
      attrs: { "data-qa": `creatorID:${_vm.creatorID}` },
    },
    [
      _c(
        "box",
        [
          _c(
            "div",
            { staticClass: "gfu-bt gfu-bt--caption _tc--light _mt-0 _ttu" },
            [
              _vm._v(
                _vm._s(
                  _vm.Resources.SubscriptionManagement
                    .CreatorNotificationsSectionHeader
                )
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gfu-hd gfu-hd--h2",
              attrs: { "data-qa": "creator-name" },
            },
            [_vm._v(_vm._s(_vm.creatorName))]
          ),
          _vm._v(" "),
          _vm._l(_vm.availableOptions, function (option) {
            return _c("option-field", {
              key: option.dataQa,
              attrs: {
                options: option.options,
                "first-column-width": 8,
                "display-name": option.displayName,
                layout: "columns",
                description: option.description,
                "data-qa": option.dataQa,
              },
              on: { input: option.onInput },
              model: {
                value: _vm.model[option.model],
                callback: function ($$v) {
                  _vm.$set(_vm.model, option.model, $$v)
                },
                expression: "model[option.model]",
              },
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.filteredProjects, function (project) {
            return _c(
              "subscribed-project-management-settings",
              _vm._b(
                {
                  key: project.projectID,
                  attrs: { "data-qa": `projectID:${project.projectID}` },
                  on: {
                    "project-notification-preference-change": _vm.handleEmit,
                  },
                },
                "subscribed-project-management-settings",
                project,
                false
              )
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }