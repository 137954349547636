import lazyScreen from '@/Components/Admin/LazyScreen';

const ProjectAdvertBannerListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-adverts' */ './ProjectAdvertBannerListScreen.vue'));
const ProjectAdvertBannerSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-adverts' */ './ProjectAdvertBannerSummaryScreen.vue'));
const ProjectsSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-adverts' */ '../System/Projects/ProjectsSideNav.vue'));

const routes = [
    {
        path: '/system/project-adverts',
        name: 'project-adverts',
        components: { default: ProjectAdvertBannerListScreen, sidenav: ProjectsSideNav }
    },
    {
        path: '/system/project-adverts/new',
        name: 'project-adverts-new',
        components: { default: ProjectAdvertBannerSummaryScreen, sidenav: ProjectsSideNav },
        props: { projectAdvertId: 0 }
    },
    {
        path: '/system/project-adverts/:projectAdvertId',
        name: 'project-adverts-summary',
        components: { default: ProjectAdvertBannerSummaryScreen, sidenav: ProjectsSideNav },
        props: {
            default: route => ({ projectAdvertId: parseInt(route.params.projectAdvertId) })
        }
    }
];

export {
    routes
};