import { defineStore } from 'pinia';
import InitialState from '@/InitialState';

import { paymentMethodReasons } from '@/Models.js';

import CookiesUtility, { cookieNames } from '@/Utils/CookiesUtility';
import { apiClient } from '@/Clients/ApiClient.js';
import PledgeService from '@/Services/PledgeService';
import { dccType } from '@/Types/DccTypes.js';
import { useCartsDetailsStore } from '@/Stores/cartsDetailsStore';

const pledgeService = new PledgeService(apiClient);

export const useCurrencyStore = defineStore({
    id: 'currency',
    state: () => ({
        displayCurrencies: InitialState.displayCurrencies,
        checkoutCurrencies: InitialState.checkoutCurrencies,
        currentCheckoutCurrencyId: InitialState.currentCheckoutCurrencyId,
        currentDisplayCurrencyId: InitialState.cookie.currentDisplayCurrencyId,
        isCurrencySelectorVisible: InitialState.currencySelectorVisible,
        availableCurrenciesList: [],
        selectedCurrency: null,
        suggestedCurrency: null,
        selectedOption: 0,
    }),
    actions: {
        async setCurrentDisplayCurrencyID(displayCurrencyId) {
            if (displayCurrencyId) {
                const date = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);
                const hostname = window.location.hostname;
                CookiesUtility.set(cookieNames.displayCurrency, displayCurrencyId, date, '/', hostname, false);
            }
            else {
                CookiesUtility.remove(cookieNames.displayCurrency, '/', window.location.hostname, false);
            }

            this.currentDisplayCurrencyId = displayCurrencyId;
        },
        setDisplayCurrencies(displayCurrencies) {
            this.displayCurrencies = displayCurrencies;
        },
        setCheckoutCurrencies(checkoutCurrencies) {
            this.checkoutCurrencies = checkoutCurrencies;
        },
        setCurrentCheckoutCurrencyID(checkoutCurrencyId) {
            this.currentProjectCurrencyId = checkoutCurrencyId;
        },
        setCurrencySelectorVisibility(isVisible) {
            this.isCurrencySelectorVisible = isVisible;
        },
        setAvailableCurrenciesList(data) {
            this.availableCurrenciesList = data;
        },
        setSuggestedCurrency(suggestedCurrency) {
            this.suggestedCurrency = suggestedCurrency;
        },
        setSelectedOption(option) {
            this.selectedOption = option;
        },
        async setSelectedCurrency(currency = null, reason = paymentMethodReasons.unknown) {
            if (currency?.amountConverted === this.selectedCurrency?.amountConverted) return;

            const cartsStore = useCartsDetailsStore();
            this.selectedCurrency = currency;

            await cartsStore.getAvailablePaymentMethods(cartsStore.locationIsoCode, reason);
        },
        async getAvailableCurrenciesList(projectID, projectLocationID = null, orderCode = null, reason = paymentMethodReasons.unknown) {
            try {
                const { data } = await pledgeService.getDynamicConversionCurrencies(projectID, projectLocationID, orderCode);
                this.setAvailableCurrenciesList(data);

                const suggestedCurrency = data.find( ( { isSuggested } ) => isSuggested).shortName;
                this.setSuggestedCurrency(suggestedCurrency);

                const systemSelectedCurrency = data.find( ( { isSelected } ) => isSelected);
                if (systemSelectedCurrency) {
                    this.setSelectedOption(dccType.systemSuggestion);
                    await this.setSelectedCurrency(this.dropdownSelectedCurrency, reason);
                }
                else {
                    this.setSelectedOption(dccType.projectCurrency);
                    await this.setSelectedCurrency(null, reason);
                }
            }
            catch (error) {
                throw new Error(error);
            }
        },
    },
    getters: {
        currentCurrency: state => {
            const currencyId = state.currentDisplayCurrencyId && state.displayCurrencies.length > 0
                ? state.currentDisplayCurrencyId : state.currentCheckoutCurrencyId;

            if (currencyId) {
                return [...state.displayCurrencies, ...state.checkoutCurrencies]
                    .find(c => c.currencyID === currencyId);
            }

            return null;
        },
        currentCheckoutCurrency: state => {
            if (state.currentCheckoutCurrencyId && state.checkoutCurrencies.length > 0) {
                return state.checkoutCurrencies
                    .find(c => c.currencyID === state.currentCheckoutCurrencyId);
            }

            return null;
        },
        currentDisplayCurrency: state => {
            if (state.currentDisplayCurrencyId && state.displayCurrencies.length > 0) {
                return state.displayCurrencies
                    .find(c => c.currencyID === state.currentDisplayCurrencyId);
            }

            return null;
        },
        getSystemSelectedCurrency: state => {
            return state.availableCurrenciesList.find( ( { isSelected } ) => isSelected) || null;
        },
        dropdownSelectedCurrency: state => {
            return state.availableCurrenciesList
                .find(c => c.shortName === state.suggestedCurrency) || null;
        },
        conversionRate: state => {
            if (state.selectedCurrency) {
                return state.selectedCurrency.exchangeRateWithCommission;
            }

            return null;
        }
    }
});