import ComponentBase from './ComponentBase';
import CheckboxField from './CheckboxField.vue';
import Field from './Field.vue';
import OptionField from './OptionField.vue';
import SwitchField from './SwitchField.vue';
import TextAreaField from './TextAreaField.vue';
import TextField from './TextField.vue';
import ToggleField from './ToggleField.vue';
import Tooltip from './Tooltip.vue';

import Notificator from './Notificator.vue';
import ProgressOverlay from './ProgressOverlay.vue';

export default {
    extends: ComponentBase,
    components: {
        CheckboxField,
        Field,
        OptionField,
        SwitchField,
        TextAreaField,
        TextField,
        ToggleField,
        Tooltip,
        Notificator,
        ProgressOverlay
    }
};
