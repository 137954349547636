import lazyScreen from '@/Components/Admin/LazyScreen';

const ProjectSettingsSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ '../Projects/ProjectSettingsSideNav.vue'));
const MerchantReviewSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-business' */ './GamefoundKyc/Review/MerchantReviewSideNav.vue'));

const LegacyOnboardingBusinessAccountScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './LegacyOnboarding/BusinessAccountScreen.vue'));

const BusinessAccountDetailsManager = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './BusinessAccountDetailsManager.vue'));

const MerchantBankAccountSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './GamefoundKyc/MerchantBankAccountSettingsScreen.vue'));
const MerchantBusinessDataSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './GamefoundKyc/MerchantBusinessDataSettingsScreen.vue'));
const MerchantIndividualDataSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './GamefoundKyc/MerchantIndividualDataSettingsScreen.vue'));
const MerchantShareholderDataSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-project' */ './GamefoundKyc/MerchantShareholderDataSettingsScreen.vue'));

const MerchantReviewListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-business' */ './MerchantReviewListScreen.vue'));
const MerchantReviewDetailsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-business' */ './GamefoundKyc/Review/MerchantReviewDetailsScreen.vue'));
const MerchantReviewLogsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-business' */ './GamefoundKyc/Review/Logs/MerchantReviewLogsScreen.vue'));
const MerchantReviewLogSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-business' */ './GamefoundKyc/Review/Logs/MerchantReviewLogSummaryScreen.vue'));

const MerchantReviewAdyenHopDetailsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-business' */ './AdyenHop2/MerchantReviewDetailsScreen.vue'));

const routes = [
    {
        path: '/project-settings/:creator/:project/business-account',
        name: 'project-settings-business-account',
        components: { default: LegacyOnboardingBusinessAccountScreen, sidenav: ProjectSettingsSideNav }
    },

    {
        path: '/project-settings/:creator/:project/merchant-details',
        name: 'project-settings-merchant-details',
        components: { default: BusinessAccountDetailsManager, sidenav: ProjectSettingsSideNav }
    },

    {
        path: '/project-settings/:creator/:project/merchant-details/bank-account',
        name: 'project-settings-merchant-bank-account',
        components: { default: MerchantBankAccountSettingsScreen, sidenav: ProjectSettingsSideNav }
    },
    {
        path: '/project-settings/:creator/:project/merchant-details/business-data',
        name: 'project-settings-business-data-settings',
        components: { default: MerchantBusinessDataSettingsScreen, sidenav: ProjectSettingsSideNav },
    },
    {
        path: '/project-settings/:creator/:project/merchant-details/individual-data',
        name: 'project-settings-merchant-individual-data',
        components: { default: MerchantIndividualDataSettingsScreen, sidenav: ProjectSettingsSideNav }
    },
    {
        path: '/project-settings/:creator/:project/merchant-details/shareholder-new',
        name: 'project-settings-merchant-shareholder-new',
        components: { default: MerchantShareholderDataSettingsScreen, sidenav: ProjectSettingsSideNav }
    },
    {
        path: '/project-settings/:creator/:project/merchant-details/shareholder/:shareholderId',
        name: 'project-settings-merchant-shareholder-details',
        components: { default: MerchantShareholderDataSettingsScreen, sidenav: ProjectSettingsSideNav },
        props: { default: route => ({ shareholderId: parseInt(route.params.shareholderId) }) }
    },
    {
        path: '/business/onboarding',
        name: 'merchant-review',
        components: { default: MerchantReviewListScreen }
    },
    {
        path: '/business/review-details/:onboardingMode/:merchantAccountId/:currentEntity?',
        name: 'merchant-review-details',
        components: { default: MerchantReviewDetailsScreen, sidenav: MerchantReviewSideNav },
        props: {
            default: route => ({
                merchantAccountId: parseInt(route.params.merchantAccountId),
                tabIndex: parseInt(route.params.tabIndex)
            }),
            sidenav: route => ({
                onboardingMode: parseInt(route.params.onboardingMode),
                merchantAccountId: parseInt(route.params.merchantAccountId)
            })
        }
    },
    {
        path: '/business/adyen-review-details/:onboardingMode/:merchantAccountId/:currentEntity?',
        name: 'merchant-adyen-review-details',
        components: { default: MerchantReviewAdyenHopDetailsScreen, sidenav: MerchantReviewSideNav },
        props: {
            default: route => ({
                merchantAccountId: parseInt(route.params.merchantAccountId),
                tabIndex: parseInt(route.params.tabIndex)
            }),
            sidenav: route => ({
                onboardingMode: parseInt(route.params.onboardingMode),
                merchantAccountId: parseInt(route.params.merchantAccountId)
            })
        }
    },
    {
        path: '/business/review-logs/:onboardingMode/:merchantAccountId',
        name: 'merchant-review-logs',
        components: { default: MerchantReviewLogsScreen, sidenav: MerchantReviewSideNav },
        props: {
            default: route => ({ merchantAccountId: parseInt(route.params.merchantAccountId) }),
            sidenav: route => ({
                onboardingMode: parseInt(route.params.onboardingMode),
                merchantAccountId: parseInt(route.params.merchantAccountId)
            })
        }
    },
    {
        path: '/business/review-logs/:merchantAccountId/log/:adyenPlatformNotificationLogId',
        name: 'merchant-review-log',
        components: { default: MerchantReviewLogSummaryScreen, sidenav: MerchantReviewSideNav },
        props: {
            default: route => ({
                merchantAccountId: parseInt(route.params.merchantAccountId),
                adyenPlatformNotificationLogId: parseInt(route.params.adyenPlatformNotificationLogId)
            }),
            sidenav: route => ({
                onboardingMode: parseInt(route.params.onboardingMode),
                merchantAccountId: parseInt(route.params.merchantAccountId)
            })
        }
    }
];

export {
    routes
};
