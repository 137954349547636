import { getWindowWidth, getWindowHeight } from '@/Utils/WindowUtility.js';
import { getHorizontalPosition, getVerticalPosition } from '@/Utils/BoundaryUtility';

export default {
    methods: {
        setTooltipPosition() {
            const winHeight = getWindowHeight();
            const winWidth = getWindowWidth();
            const triggerElement = this.$refs.trigger;
            const contentElement = this.$refs.content;

            if (contentElement && triggerElement) {
                const triggerBounding = triggerElement.getBoundingClientRect();
                const contentBounding = contentElement.getBoundingClientRect();
                const verticalPosition = getVerticalPosition(winHeight, triggerBounding, contentBounding, this.verticalPosition);
                const horizontalPosition = getHorizontalPosition(winWidth, triggerBounding, contentBounding, this.position);
                this.direction = verticalPosition.direction;
                this.anchor = horizontalPosition.anchor;

                this.$emit('position-update', this.direction, this.anchor);

                this.$nextTick(() => {
                    const style = { ...verticalPosition.style, ...horizontalPosition.style };
                    this.setStyles(contentElement, style);
                });
            }
        },
        setStyles(element, newStyle) {
            const initStyle = { top: 'auto', bottom: 'auto', left: 'auto' };
            const style = { ...initStyle, ...newStyle };

            Object.keys(style).forEach(prop => {
                const value = style[prop];
                const strValue = typeof value === 'number' ? `${ Math.floor(value) }px` : value;
                element.style.setProperty(prop, strValue);
            });
        },
    }
};