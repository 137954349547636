var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-card",
    {
      staticClass: "gfu-reward-card",
      attrs: {
        id: _vm.rewardCardID,
        orientation: "horizontal",
        "data-qa": `reward-box:${_vm.productID}`,
      },
      scopedSlots: _vm._u([
        {
          key: "labels",
          fn: function () {
            return _vm._l(_vm.labels, function (label) {
              return _c(
                "base-badge",
                {
                  key: label.name,
                  staticClass: "_mr-1 _my-1",
                  attrs: {
                    theme: label.theme,
                    "data-qa": `reward-card:${label.theme}Badge`,
                  },
                },
                [_vm._v(_vm._s(label.name))]
              )
            })
          },
          proxy: true,
        },
        {
          key: "media",
          fn: function () {
            return [
              _c(
                "router-link",
                {
                  staticClass: "gfu-embed gfu-embed--1x1",
                  attrs: {
                    to: _vm.route,
                    "data-qa": "reward-box-element:Image",
                  },
                },
                [
                  _c("base-image", {
                    staticClass: "gfu-embed__item",
                    attrs: {
                      loading: "lazy",
                      src: _vm.imageUrl,
                      alt: _vm.name,
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm.editRoute
        ? _c(
            "div",
            {
              staticClass: "gfu-reward-card__edit",
              attrs: { "data-qa": `reward-edit-menu:${_vm.productID}` },
            },
            [
              _c("product-edit-link", {
                attrs: { to: _vm.editRoute, "product-id": _vm.productID },
              }),
              _vm._v(" "),
              _c("product-restricted-visibility-info", {
                attrs: {
                  "product-state": _vm.productState,
                  "visible-to-user-group-names": _vm.visibleToUserGroupNames,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-reward-card__body" }, [
        _c(
          "div",
          { staticClass: "gfu-reward-card__content gfu-box__content" },
          [
            _c(
              "h3",
              {
                staticClass:
                  "gfu-reward-card__title gfu-hd gfu-hd--h1 gfu-hd--decorative",
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "gfu-link gfu-link--nofx",
                    attrs: {
                      to: _vm.route,
                      "data-qa": "reward-box-element:Name",
                    },
                  },
                  [_vm._v(_vm._s(_vm.name))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "gfu-reward-card__price gfu-link gfu-link--nofx",
                attrs: { to: _vm.route, "data-qa": "product-price" },
              },
              [
                _c("display-currency-price", {
                  staticClass: "_flex _ai-c",
                  attrs: {
                    "effective-price": _vm.effectivePrice,
                    price: _vm.price,
                    "is-discounted": _vm.isDiscounted,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "effective-price",
                      fn: function ({ effectivePrice }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "gfu-price gfu-hd gfu-hd--h2 _mr-1",
                              class: [
                                { "gfu-price--discounted": _vm.isDiscounted },
                              ],
                              attrs: { "data-qa": "price-type:Effective" },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(effectivePrice) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "price",
                      fn: function ({ price }) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "gfu-price gfu-price--old _fw-n",
                              attrs: { "data-qa": "price-type:Old" },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(price) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isDiscounted
              ? _c(
                  "localize",
                  {
                    staticClass:
                      "_flex _ai-b gfu-bt gfu-bt--caption _tc--lighter",
                    attrs: {
                      resource:
                        _vm.Resources.Product.RewardCardLowestPriceInLast30Days,
                      tag: "div",
                    },
                  },
                  [
                    _c("display-currency-price", {
                      attrs: {
                        "format-key": "0",
                        "effective-price": _vm.effectivePrice,
                        price: _vm.price,
                        tag: "span",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "effective-price",
                            fn: function ({ effectivePrice }) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "_mx-1",
                                    attrs: {
                                      "data-qa": "price-type:ThirtyDaysLowest",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(effectivePrice) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        173952906
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "gfu-reward-card__desc",
                attrs: { "data-qa": "reward-box-element:Abstract" },
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "gfu-link gfu-link--nofx",
                    attrs: { to: _vm.route },
                  },
                  [_vm._v(_vm._s(_vm.abstract))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "_mt-2 _cf" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "gfu-link gfu-link--accent _fl",
                    attrs: {
                      to: _vm.route,
                      "data-qa": "reward-box-element:MoreInfo",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.Resources.Product.RewardCardMoreInfoLink) +
                        "\n                    "
                    ),
                    _c("span", {
                      staticClass:
                        "gfu-icon gfu-icon--small gfu-icon--baseline _fa _fa--angle-right _fr _ml-1",
                    }),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.purchasedCopiesCount || _vm.estimatedDeliveryAt
              ? _c(
                  "div",
                  {
                    staticClass:
                      "gfu-reward-card__stats _tc--light _flexwrap _col-gap-3 _row-gap-1 _mt-2",
                  },
                  [
                    _vm.purchasedCopiesCount
                      ? _c("div", { staticClass: "_flex" }, [
                          _c("span", {
                            staticClass: "_ga _ga--gamefound-outline _mr-1",
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.foundersText))]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.estimatedDeliveryAt
                      ? _c(
                          "div",
                          { staticClass: "_flex" },
                          [
                            _c("span", {
                              staticClass: "_fa _fa--shipping-fast",
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "_mx-1" }, [
                              _vm._v(_vm._s(_vm.estimatedDeliveryDateToLabel)),
                            ]),
                            _vm._v(" "),
                            _vm.deliveryDateRemarks
                              ? _c(
                                  "tooltip",
                                  {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "content",
                                          fn: function () {
                                            return [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.deliveryDateRemarks
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3721022686
                                    ),
                                  },
                                  [_c("span", { staticClass: "_fa _fa--info" })]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isInstallmentInfoVisible
          ? _c(
              "div",
              {
                staticClass: "gfu-reward-card__info-bar",
                attrs: { "data-qa": "reward-box-element:StretchPayInfo" },
              },
              [
                _c(
                  "span",
                  { staticClass: "gfu-hd gfu-hd--h6" },
                  [
                    _c(
                      "localize",
                      {
                        attrs: {
                          resource:
                            _vm.Resources.Product
                              .RewardCardStretchPayGetItForText,
                        },
                      },
                      [
                        _c("display-currency-price", {
                          attrs: {
                            "format-key": "0",
                            tag: "span",
                            "effective-price": _vm.installmentCost,
                            "is-discounted": false,
                            "display-tooltip": true,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "effective-price",
                                fn: function ({ effectivePrice }) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "gfu-price",
                                        attrs: {
                                          "data-qa":
                                            "price-type:InstallmentCost",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(effectivePrice) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            584328195
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "tooltip",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("display-currency-value", {
                                    attrs: {
                                      "display-tooltip": false,
                                      tag: "span",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "content",
                                          fn: function ({ displayCurrency }) {
                                            return [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.getInstallmentTooltipText(
                                                      displayCurrency
                                                    )
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.getInstallmentTooltipText(
                                                      _vm.currentCheckoutCurrency
                                                    )
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1450721863
                                    ),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4028957999
                        ),
                      },
                      [_c("span", { staticClass: "_fa _fa--info" })]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("base-image", {
                  attrs: {
                    src: "/content/images/installments-logo.svg",
                    alt: "stretchPay",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasAction
          ? _c(
              "div",
              {
                staticClass: "gfu-reward-card__action",
                attrs: { "data-qa": "reward-box-action" },
              },
              [_vm._t("action")],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }