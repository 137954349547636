export default {
    getThumbnail(url) {
        if (this._isYoutube(url)) {
            const id = this._getYoutubeID(url);
            if (id) {
                return `http://img.youtube.com/vi/${ id }/hqdefault.jpg`;
            }
        }

        else if (this._isDailymotion(url)) {
            const id = this._getDailymotionID(url);

            if (id) {
                return `https://www.dailymotion.com/thumbnail/video/${ id }`;
            }
        }

        else if (this._isVimeo(url)) {
            return null;
        }

        return null;
    },
    normalizeURL(url) {
        if (this._isYoutube(url)) {
            const id = this._getYoutubeID(url);
            if (id) {
                return `https://www.youtube.com/embed/${ id }?iv_load_policy=3&enablejsapi=1`;
            }
        }

        else if (this._isDailymotion(url)) {
            const id = this._getDailymotionID(url);
            if (id) {
                return `https://www.dailymotion.com/embed/video/${ id }`;
            }
        }

        else if (this._isVimeo(url)) {
            const id = this._getVimeoID(url);
            if (id) {
                return `https://player.vimeo.com/video/${ id }`;
            }
        }

        else if (this._isTwitch(url)) {
            const twitchLinkInfo = this._getTwitchLinkParams(url);
            const hostname = window?.location.hostname;
            const urls = {
                'clip': `https://clips.twitch.tv/embed?clip=${ twitchLinkInfo.id }&parent=${ hostname }&autoplay=true`,
                'video': `https://player.twitch.tv/?video=${ twitchLinkInfo.id }&parent=${ hostname }&autoplay=true`,
                'channel': `https://player.twitch.tv/?channel=${ twitchLinkInfo.id }&parent=${ hostname }&autoplay=true`
            };
            if (twitchLinkInfo) {
                return urls[twitchLinkInfo.type];
            }
        }

        return null;
    },
    validateURL(url) {
        return this._isYoutube(url) && this._getYoutubeID(url) || this._isDailymotion(url) && this._getDailymotionID(url) || this._isVimeo(url) && this._getVimeoID(url) || this._isTwitch(url) && this._getTwitchLinkParams(url).id;
    },
    _isYoutube(url) {
        return url ? url.includes('youtu') : false;
    },
    _getYoutubeID(url) {
        const match = url.match('^(?:https?:)?//[^/]*(?:youtube(?:-nocookie)?\.com|youtu\.be).*[=/]([-\\w]{11})(?:\\?|=|&|$)');

        if (match && match[1].length == 11) {
            return match[1];
        }
    },
    _isDailymotion(url) {
        return url.includes('dai');
    },
    _getDailymotionID(url) {
        const match = url.match(/(?:dailymotion\.com(?:\/video|\/hub|\/embed\/video)|dai\.ly)\/([0-9a-z]+)(?:[\-_0-9a-zA-Z]+#video=([a-z0-9]+))?/);

        if (match) {
            return match[1];
        }
    },
    _isVimeo(url) {
        return url.includes('vimeo');
    },
    _isTwitch(url) {
        return url.includes('twitch');
    },
    _getVimeoID(url) {
        const match = url.match(/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/);
        if (match) {
            return match[1];
        }

    },
    _getTwitchLinkParams(url) {
        const patterns = [
            {
                pattern: /twitch\.tv\/.*?\/clip\/([a-zA-Z0-9_-]+)/,
                type: 'clip'
            },
            {
                pattern: /twitch\.tv\/videos\/(\d+)/,
                type: 'video'
            },
            {
                pattern: /twitch\.tv\/(?!videos\/)([a-zA-Z0-9_]+)/,
                type: 'channel'
            }
        ];

        for (const p of patterns) {
            const match = url.match(p.pattern);
            if (match) {
                return {
                    id: match[1],
                    type: p.type
                };
            }
        }

        return null; // Return null for invalid URLs
    }
};