var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b({}, "field", { ..._vm.$attrs, ..._vm.$props }, false),
    [
      _c(
        "div",
        { staticClass: "gfu-tags", attrs: { "data-qa": "tags-field-items" } },
        [
          _vm._l(_vm.model, function (item, index) {
            return _c(
              "div",
              {
                key: `${item.name}-${index}`,
                staticClass: "gfu-tag _mt-1 _mr-1",
                class: {
                  "_fa _fa--times gfu-tag--has-icon": !_vm.readOnly,
                  [item.accentClass]: _vm.multicolor,
                  "gfu-tag--no-background": _vm.noBackgroundTags,
                  "gfu-tag--compact": _vm.compactTags,
                },
                attrs: { "data-qa": "tags-field-items:" + item.name },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.deleteTag(item)
                  },
                },
              },
              [_vm._v("\n            " + _vm._s(item.name) + "\n        ")]
            )
          }),
          _vm._v(" "),
          !_vm.readOnly &&
          _vm.allowType &&
          (!_vm.maxItems || _vm.maxItems > _vm.model.length)
            ? _c(
                "div",
                { staticClass: "gfu-1of1" },
                [
                  _c("auto-complete-field", {
                    ref: "newTagInput",
                    staticClass: "_mt-0",
                    attrs: {
                      name: "newTag",
                      "show-label": false,
                      "allow-create": _vm.allowCreate,
                      "display-name": "Tags",
                      placeholder: _vm.placeholder,
                      items: _vm.remainingTags,
                      "max-input-length": _vm.maxTagLength,
                      "clear-on-collapse": true,
                      "create-new-label": "create a new tag",
                      "matching-items-label": "Matching tags",
                      "no-matches-label": "There are no matching tags",
                    },
                    on: { input: _vm.onSelectedTag },
                    model: {
                      value: _vm.newTag,
                      callback: function ($$v) {
                        _vm.newTag = $$v
                      },
                      expression: "newTag",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }