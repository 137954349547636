import TestsDataStorage from '@/Utils/TestsDataStorage.js';
import CookiesUtility, { cookieNames } from '@/Utils/CookiesUtility.js';
import { useProjectStore } from '@/Stores/Web/projectStore';
import { useCurrencyStore } from '@/Stores/Currency/currencyStore';

// Facebook Pixel Standard Events https://www.facebook.com/business/help/402791146561655
// Default Google Analytics Events  https://developers.google.com/analytics/devguides/collection/gtagjs/events#default_google_analytics_events - technically this for gtag.js but it's basically the same thing and we might upgrade someday

export const googleAnalyticsGroups = {
    gamefound: 'gamefound', // Global analiticys group send to gamefound trackers (UA, GA4)
    creator: 'creator', // Creator group send to creator trackers (UA, GA4)
    gamefoundAds: 'gamefound-ads' // Globals google ads
};

// TODO: Rewrite this class in task #6315
export default class AnalyticsTracking {
    constructor() {
        const projectStore = useProjectStore();
        this.trackingModel = projectStore.trackingModel;
        this.projectTrackingModel = projectStore.projectTrackingModel || {};

        // trackingModel is null if analytics cookie is rejected
        this.isEnabled = !!this.projectTrackingModel && !!this.trackingModel && CookiesUtility.get(cookieNames.analyticCookieKeyPolicyRejected) === 'false';
    }

    // TODO: We could send payload with custom data here but we don't have any specification for now
    sendCustomEvent(
        category, // The category of the event.
        action, // The value that will appear as the event action in Google Analytics Event reports.
        label,
        nonInteraction = false,
        value = null, // A non-negative integer that will appear as the event value.
        sendTo = [googleAnalyticsGroups.gamefound, googleAnalyticsGroups.creator]
    ) {
        if (!this.isEnabled) {
            return;
        }

        if (category && action) {
            if (this.trackingModel.googleAnalytics.enabled) {

                window.gtag('event', action, {
                    'send_to': sendTo,
                    'event_category': category,
                    'event_label': label,
                    value,
                    'non_interaction': nonInteraction
                });
            }

            if (this.trackingModel.facebookPixel.enabled) {
                // Custom event names must be strings, and cannot exceed 50 characters in length.
                // https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/ - Tracking Custom Events

                this.trackFbCustom(action, {
                    category,
                    label
                });
            }
        }
        else {
            throw 'Category and action arguments are required.';
        }

        TestsDataStorage.setItem('customEvent', {
            category,
            action,
            label,
            nonInteraction,
            value
        });
    }

    addToCartTracking(orderItem) {
        if (!this.isEnabled) {
            return;
        }
        const { googleAds } = this.projectTrackingModel;
        const currencyStore = useCurrencyStore();
        const currentCurrency = currencyStore.currentCurrency;

        if (this.trackingModel.facebookPixel.enabled) {
            this.trackFb('AddToCart', {
                content_name: `[${ this.trackingModel.project.name }] ${ orderItem.displayName }`,
                content_type: 'product',
                value: orderItem.quantity * orderItem.priceConverted,
                currency: currentCurrency.shortName,
                contents: [
                    {
                        id: `${ orderItem.productID }`,
                        quantity: orderItem.quantity,
                        item_price: orderItem.priceConverted
                    }
                ]
            });
        }

        if (this.trackingModel.googleAnalytics.enabled) {
            window.gtag('event', 'add_to_cart', {
                items: [
                    {
                        id: orderItem.productID,
                        name: `[${ this.trackingModel.project.name }] ${ orderItem.displayName }`,
                        variant: orderItem.options.map(o => o.valueText).join(', '),
                        quantity: orderItem.quantity,
                        price: orderItem.priceConverted
                    }
                ],
                event_label: this.trackingModel.project.urlName,
                send_to: [googleAnalyticsGroups.gamefound, googleAnalyticsGroups.creator]
            });

            if (googleAds?.enabled && !!googleAds?.addToCartEventID) {
                window.gtag('event', 'conversion', {
                    send_to: [googleAds.addToCartEventID, googleAnalyticsGroups.gamefoundAds],
                    value: orderItem.priceConverted,
                    currency: currentCurrency.shortName
                });
            }
        }

        TestsDataStorage.setItem('addToCart', orderItem);
    }

    registrationCompletedTracking({ email, value, currency }) {
        if (!this.isEnabled) {
            return;
        }

        if (this.trackingModel.googleAnalytics.enabled) {
            window.gtag('event', 'sign_up', {
                send_to: [googleAnalyticsGroups.gamefound]
            });
        }

        if (this.trackingModel.facebookPixel.enabled) {
            this.trackFb('CompleteRegistration', {
                // TODO: This is against our privacy policy
                content_name: email,
                value,
                currency
            });
        }

        TestsDataStorage.setItem('registrationCompleted', { email, value, currency });
    }

    followTracking({ projectName, source = 'Button' }) {
        if (!this.isEnabled) {
            return;
        }

        const { googleAds } = this.projectTrackingModel;
        this.sendCustomEvent('project', 'follow', `${ source }:${ projectName }`);

        if (this.trackingModel.googleAnalytics?.enabled && googleAds?.enabled && !!googleAds?.followEventID) {
            window.gtag('event', 'conversion', {
                send_to: [googleAds.followEventID, googleAnalyticsGroups.gamefoundAds]
            });
        }
        TestsDataStorage.setItem('follow', { projectName });
    }

    unfollowTracking({ projectName, source = 'Button' }) {
        if (!this.isEnabled) {
            return;
        }

        this.sendCustomEvent('project', 'unfollow', `${ source }:${ projectName }`);
        TestsDataStorage.setItem('unfollow', { projectName });
    }

    purchaseTracking(transaction) {
        if (!this.isEnabled) {
            return;
        }

        const { googleAds } = this.projectTrackingModel;
        const orderLocalKey = 'Gamefound.Tracking.OrderCode';
        const orderLocalCode = sessionStorage.getItem(orderLocalKey);

        if (this.trackingModel.googleAnalytics?.enabled && orderLocalCode !== transaction.transactionId) {
            window.gtag('event', 'purchase', {
                event_label: transaction.projectUrlName,
                transaction_id: transaction.transactionId,
                affiliation: transaction.affiliation,
                value: transaction.revenue,
                currency: transaction.currency,
                shipping: transaction.shipping,
                items: transaction.items,
                metric1: transaction.metric1,
                send_to: [googleAnalyticsGroups.gamefound, googleAnalyticsGroups.creator]
            });

            if (googleAds?.enabled && !!googleAds?.purchaseEventID) {
                window.gtag('event', 'conversion', {
                    send_to: [googleAds.purchaseEventID, googleAnalyticsGroups.gamefoundAds],
                    value: transaction.revenue,
                    currency: transaction.currency,
                    transaction_id: transaction.transactionId
                });
            }

            sessionStorage.setItem(orderLocalKey, transaction.transactionId);
        }

        if (this.trackingModel.facebookPixel.enabled) {
            this.trackFb('Purchase', {
                content_type: 'product',
                value: transaction.revenue,
                currency: transaction.currency,
                contents: transaction.items.map(item => ({
                    id: item.productId,
                    item_price: item.price,
                    quantity: item.quantity
                }))
            });
        }

        TestsDataStorage.setItem('purchase', transaction);
    }

    getPixelsToTrack() {
        return window.facebookPixels
            .filter(c => c.shouldTrackEvents)
            .map(c => c.code);
    }

    trackFb(eventName, params) {
        const pixels = this.getPixelsToTrack();
        for (const pixelId of pixels) {
            // we have to do .toString() because otherwise facebook treats number as undefined
            // IMPORTANT: problems related to invalid pixel code show up as warnings not errors. If you have warnings filtered out you will not see them
            window.fbq('trackSingle', pixelId.toString(), eventName, params);
        }
    }

    trackFbCustom(action, params) {
        const pixels = this.getPixelsToTrack();
        for (const pixelId of pixels) {
            // we have to do .toString() because otherwise facebook treats number as undefined
            // IMPORTANT: problems related to invalid pixel code show up as warnings not errors. If you have warnings filtered out you will not see them
            window.fbq('trackSingleCustom', pixelId.toString(), action, params);
        }
    }
}
