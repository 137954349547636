import lazyScreen from '@/Components/Admin/LazyScreen';
import ShippingModelSettingsSideNav from './ShippingModels/ShippingModelSettingsSideNav.vue';

const ProjectSettingsSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-shipping' */ '../Projects/ProjectSettingsSideNav.vue'));

const ShippingCalculationScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-shipping' */ './ShippingCalculationScreen.vue'));
const ShippingModelsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-shipping' */ './ShippingModels/ShippingModelsListScreen.vue'));
const ShippingModelGeneralSettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-shipping' */ './ShippingModels/ShippingModelGeneralSettingsScreen.vue'));
const ShippingModelZoneAvailabilitySettingsScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-shipping' */ './ShippingModels/ShippingModelZoneAvailabilitySettingsScreen.vue'));
const ShippingStagesListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-shipping' */ './ShippingStagesListScreen.vue'));
const ShippingStageSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-shipping' */ './ShippingStageSummaryScreen.vue'));
const ShippingZonesListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-shipping' */ './ShippingZonesListScreen.vue'));
const ShippingZoneSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-shipping' */ './ShippingZoneSummaryScreen.vue'));
const ShippingClassListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-shipping' */ './ShippingClassListScreen.vue'));
const ShippingClassSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-shipping' */ './ShippingClassSummaryScreen.vue'));

const routes = [
    { path: '/project-settings/:creator/:project/shipping/models', name: 'project-settings-shipping-models', components: { default: ShippingModelsListScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/shipping/models', redirect: { name: 'project-settings-shipping-models' } },
    { path: '/:creator/:project/shippingModels', redirect: { name: 'project-settings-shipping-models' } },

    {
        path: '/project-settings/:creator/:project/shipping/models/:shippingModelId/summary',
        name: 'project-shipping-model-summary',
        components: { default: ShippingModelGeneralSettingsScreen, sidenav: ShippingModelSettingsSideNav },
        props: {
            default: route => ({ shippingModelId: parseInt(route.params.shippingModelId) }),
            sidenav: route => ({ shippingModelId: parseInt(route.params.shippingModelId) })
        }
    },
    { path: '/project/:creator/:project/shipping/models/:shippingModelId/summary', redirect: { name: 'project-shipping-model-summary' } },
    { path: '/shippingModels/:shippingModelId/summary', redirect: { name: 'project-shipping-model-summary' } },

    {
        path: '/project-settings/:creator/:project/shipping/models/:shippingModelId/zone-availability',
        name: 'project-shipping-model-zone-availability',
        components: { default: ShippingModelZoneAvailabilitySettingsScreen, sidenav: ShippingModelSettingsSideNav },
        props: {
            default: route => ({ shippingModelId: parseInt(route.params.shippingModelId) }),
            sidenav: route => ({ shippingModelId: parseInt(route.params.shippingModelId) })
        }
    },

    { path: '/project-settings/:creator/:project/shipping/stages', name: 'project-settings-shipping-stages', components: { default: ShippingStagesListScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/shipping/stages', redirect: { name: 'project-settings-shipping-stages' } },
    { path: '/:creator/:project/shippingStages', redirect: { name: 'project-settings-shipping-stages' } },

    { path: '/project-settings/:creator/:project/shipping/stages/:shippingStageId/summary', name: 'project-shipping-stage-summary', components: { default: ShippingStageSummaryScreen, sidenav: ProjectSettingsSideNav }, props: { default: route => ({ shippingStageId: parseInt(route.params.shippingStageId) }) } },
    { path: '/project/:creator/:project/shipping/stages/:shippingStageId/summary', redirect: { name: 'project-shipping-stage-summary' } },
    { path: '/shippingStages/:shippingStageId/summary', redirect: { name: 'project-shipping-stage-summary' } },

    { path: '/project-settings/:creator/:project/shipping/zones', name: 'project-settings-shipping-zones', components: { default: ShippingZonesListScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/shipping/zones', redirect: { name: 'project-settings-shipping-zones' } },
    { path: '/:creator/:project/shippingZones', redirect: { name: 'project-settings-shipping-zones' } },

    { path: '/project-settings/:creator/:project/shipping/zones/:shippingZoneId/summary', name: 'project-shipping-zone-summary', components: { default: ShippingZoneSummaryScreen, sidenav: ProjectSettingsSideNav }, props: { default: route => ({ shippingZoneId: parseInt(route.params.shippingZoneId) }) } },
    { path: '/project/:creator/:project/shipping/zones/:shippingZoneId/summary', redirect: { name: 'project-shipping-zone-summary' } },
    { path: '/shippingZones/:shippingZoneId/summary', redirect: { name: 'project-shipping-zone-summary' } },

    { path: '/project-settings/:creator/:project/shipping/cost-rules', name: 'project-settings-shipping-cost-rules', components: { default: ShippingClassListScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/shipping/cost-rules', redirect: { name: 'project-settings-shipping-cost-rules' } },
    { path: '/:creator/:project/shippingClasses', redirect: { name: 'project-settings-shipping-cost-rules' } },

    { path: '/project-settings/:creator/:project/shipping/cost-rules/:shippingClassId/summary', name: 'project-shipping-cost-rules-summary', components: { default: ShippingClassSummaryScreen, sidenav: ProjectSettingsSideNav }, props: { default: route => ({ shippingClassId: parseInt(route.params.shippingClassId) }) } },
    { path: '/project/:creator/:project/shipping/cost-rules/:shippingClassId/summary', redirect: { name: 'project-shipping-model-summary' } },
    { path: '/shippingClasses/:shippingClassId/summary', redirect: { name: 'project-shipping-model-summary' } },

    { path: '/project-settings/:creator/:project/shipping/calculation', name: 'project-settings-shipping-calculation', components: { default: ShippingCalculationScreen, sidenav: ProjectSettingsSideNav } },
    { path: '/project/:creator/:project/shipping/calculation', redirect: { name: 'project-settings-shipping-calculation' } },
    { path: '/:creator/:project/shippingCalculation', redirect: { name: 'project-settings-shipping-calculation' } }
];

export {
    ShippingCalculationScreen,
    ShippingModelsListScreen,
    ShippingModelGeneralSettingsScreen,
    ShippingStagesListScreen,
    ShippingStageSummaryScreen,
    ShippingZonesListScreen,
    ShippingZoneSummaryScreen,
    ShippingClassListScreen,
    ShippingClassSummaryScreen,
    routes
};