var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("search-title", {
        staticClass: "_mb-1 gfu-layout-wrapper",
        attrs: {
          title: _vm.Resources.Search.SearchProjectsResultsTitle,
          "total-item-count": _vm.totalItemCount,
          "data-qa": "search-result",
        },
      }),
      _vm._v(" "),
      _c("search-projects-header", {
        attrs: {
          "selected-filters": _vm.currentFilters,
          "total-item-count": _vm.totalItemCount,
          "sort-type": _vm.searchParams.sortType,
        },
        on: {
          "filters-open": _vm.onFiltersOpen,
          "sort-open": _vm.onSortOpen,
          "filter-delete": _vm.onFilterDelete,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-layout__main" },
        [
          _c("search-projects-filter", {
            attrs: {
              "selected-filters": _vm.selectedFilters,
              "is-drawer-open": _vm.isFilterDrawerOpen,
              categories: _vm.projectCategories,
            },
            on: {
              search: _vm.onSearch,
              close: _vm.onFiltersClose,
              clear: _vm.onFilterClear,
            },
          }),
          _vm._v(" "),
          _c("search-projects-sort", {
            attrs: {
              "selected-filters": _vm.selectedFilters,
              "is-drawer-open": _vm.isSortDrawerOpen,
            },
            on: { search: _vm.onSearch, close: _vm.onSortClose },
          }),
          _vm._v(" "),
          _vm.totalItemCount
            ? _c(
                "div",
                { staticClass: "gfu-card-list gfu-card-list--wrap" },
                _vm._l(_vm.pagedItems, function (project) {
                  return _c(
                    "div",
                    {
                      key: project.projectID,
                      staticClass:
                        "gfu-card-list__item gfu-1of1 gfu-1of3--m gfu-1of4--xl",
                      attrs: {
                        "data-qa": `search-result-project:${project.projectID}`,
                      },
                    },
                    [
                      _c(
                        "project-card",
                        _vm._b({}, "project-card", project, false)
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "box",
                {
                  staticClass: "_mt-2",
                  attrs: { "data-qa": "search-result:EmptyState" },
                },
                [
                  _c(
                    "empty-state",
                    {
                      attrs: {
                        title:
                          _vm.Resources.Search
                            .SearchProjectsResultsEmptyStateTitle,
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.Resources.Search
                              .SearchProjectsResultsEmptyStateParagraph
                          ) +
                          "\n                "
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "_mt-3",
                          attrs: { theme: "accent" },
                          on: { click: _vm.onFiltersOpen },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.Resources.Search
                                  .SearchProjectsResultsEmptyStateParagraphButton
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _vm._v(" "),
          _vm.isMoreVisible
            ? _c(
                "div",
                { staticClass: "_mt-3 _tac" },
                [
                  _c(
                    "base-button",
                    { attrs: { theme: "outline" }, on: { click: _vm.onMore } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.Resources.Common.LoadMoreButton) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.canLoadMoreRows
            ? _c("load-more-trigger", { on: { "load-more": _vm.onMore } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { visible: _vm.isProcessing, sticky: true },
      }),
      _vm._v(" "),
      _c("return-to-element", { attrs: { "element-id": "magic-anchor-top" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }