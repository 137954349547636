var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-slider",
      attrs: { "data-qa": "breadcrumb-navigation" },
    },
    [
      _vm.shouldShowEdges
        ? _c(
            "div",
            {
              class: [
                "gfu-slider__edge gfu-slider__edge--left",
                {
                  "gfu-slider__edge--visible": _vm.isOverflownLeft,
                  "gfu-slider__edge--lighter":
                    _vm.backgroundStyle === "lighter",
                  "gfu-slider__edge--dark": _vm.backgroundStyle === "dark",
                  "gfu-slider__edge--transparent":
                    _vm.backgroundStyle === "transparent",
                  "gfu-slider__edge--recommendation": _vm.isArrowPositionTop,
                },
              ],
            },
            [
              _c("a", {
                class: [
                  "gfu-slider__arrow gfu-slider__arrow--prev",
                  {
                    "gfu-slider__arrow--visible": _vm.displayArrows,
                    "gfu-slider__arrow--circle": _vm.arrowStyle === "circle",
                  },
                ],
                attrs: { href: "", draggable: "false" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.scrollLeft.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _c("div", {
                class: [
                  "gfu-slider__overlay gfu-slider__overlay--left",
                  {
                    "gfu-slider__overlay--visible":
                      _vm.blurEdges && _vm.backgroundStyle === "light",
                    "gfu-slider__overlay--has-arrow": _vm.displayArrows,
                    "gfu-slider__overlay--offset": _vm.edgeMargin,
                  },
                ],
              }),
              _vm._v(" "),
              _c("div", {
                class: [
                  "gfu-slider__overlay gfu-slider__overlay--left gfu-slider__overlay--fixed",
                  {
                    "gfu-slider__overlay--visible":
                      _vm.blurEdges && _vm.backgroundStyle === "lighter",
                    "gfu-slider__overlay--has-arrow": _vm.displayArrows,
                    "gfu-slider__overlay--offset": _vm.edgeMargin,
                  },
                ],
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "gfu-slider__slides-wrapper",
            { "gfu-slider__slides-wrapper--thin": _vm.edgeMargin },
            _vm.slidesWrapperClass,
          ],
        },
        [
          _c(
            "div",
            {
              ref: "slides",
              class: [
                "gfu-slider__slides",
                {
                  "gfu-slider__slides--center":
                    _vm.centered && !_vm.isOverflown,
                  "gfu-slider__slides--no-scroll": _vm.preventScroll,
                },
                _vm.slidesClass,
              ],
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.shouldShowEdges
        ? _c(
            "div",
            {
              class: [
                "gfu-slider__edge gfu-slider__edge--right",
                {
                  "gfu-slider__edge--visible": _vm.isOverflownRight,
                  "gfu-slider__edge--lighter":
                    _vm.backgroundStyle === "lighter",
                  "gfu-slider__edge--dark": _vm.backgroundStyle === "dark",
                  "gfu-slider__edge--transparent":
                    _vm.backgroundStyle === "transparent",
                  "gfu-slider__edge--recommendation": _vm.isArrowPositionTop,
                },
              ],
            },
            [
              _c("div", {
                class: [
                  "gfu-slider__overlay gfu-slider__overlay--right",
                  {
                    "gfu-slider__overlay--visible":
                      _vm.blurEdges && _vm.backgroundStyle === "light",
                    "gfu-slider__overlay--has-arrow": _vm.displayArrows,
                    "gfu-slider__overlay--offset": _vm.edgeMargin,
                  },
                ],
              }),
              _vm._v(" "),
              _c("div", {
                class: [
                  "gfu-slider__overlay gfu-slider__overlay--right gfu-slider__overlay--fixed",
                  {
                    "gfu-slider__overlay--visible":
                      _vm.blurEdges && _vm.backgroundStyle === "lighter",
                    "gfu-slider__overlay--has-arrow": _vm.displayArrows,
                    "gfu-slider__overlay--offset": _vm.edgeMargin,
                  },
                ],
              }),
              _vm._v(" "),
              _c("a", {
                class: [
                  "gfu-slider__arrow gfu-slider__arrow--next",
                  {
                    "gfu-slider__arrow--visible": _vm.displayArrows,
                    "gfu-slider__arrow--circle": _vm.arrowStyle === "circle",
                  },
                ],
                attrs: { href: "", draggable: "false" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.scrollRight.apply(null, arguments)
                  },
                },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }