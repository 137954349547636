import router from '@/Router';

import { routes as blogPosts } from '@/Components/Admin/BlogPosts';
import { routes as businessAccount } from '@/Components/Admin/BusinessAccount';
import { routes as creator } from '@/Components/Admin/Creator';
import { routes as currencies } from '@/Components/Admin/Currencies';
import { routes as invoices } from '@/Components/Admin/Invoices';
import { routes as locations } from '@/Components/Admin/Locations';
import { routes as notifications } from '@/Components/Admin/Notifications';
import { routes as orders } from '@/Components/Admin/Orders';
import { routes as payments } from '@/Components/Admin/Payments';
import { routes as finances } from '@/Components/Admin/Projects/Finances';
import { routes as products } from '@/Components/Admin/Products';
import { routes as projectAdverts } from '@/Components/Admin/ProjectAdverts';
import { routes as projectAnalytics } from '@/Components/Admin/Projects/Analytics';
import { routes as projectBackupPaymentMethod } from '@/Components/Admin/Projects/BackupPaymentMethod';
import { routes as projectModeration } from '@/Components/Admin/Projects/ProjectModeration';
import { routes as projects } from '@/Components/Admin/Projects';
import { routes as projectStretchGoals } from '@/Components/Admin/ProjectStretchGoals';
import { routes as shipping } from '@/Components/Admin/Shipping';
import { routes as system } from '@/Components/Admin/System';
import { routes as taxes } from '@/Components/Admin/Taxes';
import { routes as terms } from '@/Components/Admin/Terms';
import { routes as users } from '@/Components/Admin/Users';

export const prefix = '/admin';

addRoutes(blogPosts);
addRoutes(businessAccount);
addRoutes(creator);
addRoutes(currencies);
addRoutes(invoices);
addRoutes(locations);
addRoutes(notifications);
addRoutes(orders);
addRoutes(payments);
addRoutes(finances);
addRoutes(products);
addRoutes(projectAdverts);
addRoutes(projectAnalytics);
addRoutes(projectBackupPaymentMethod);
addRoutes(projectModeration);
addRoutes(projects);
addRoutes(projectStretchGoals);
addRoutes(shipping);
addRoutes(system);
addRoutes(taxes);
addRoutes(terms);
addRoutes(users);

function addRoutes(routeCollection) {
    routeCollection.forEach(route => {
        route.path = prefix + route.path;
        route.meta = {
            ...route.meta,
            hasProjectContext: !route.meta || !(route.meta.hasProjectContext === false),
        };
        router.addRoute(route);
    });
}
