var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        title: _vm.Resources.ProjectHome.CrowdfundingDraftInfoTitle,
        icon: "info",
        "is-visible": _vm.isVisible,
        "is-processing": _vm.isProcessing,
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "icon-base-button",
                {
                  attrs: {
                    size: "default",
                    width: "compact",
                    "text-transform": "none",
                    wide: true,
                    bold: true,
                    theme: "accent",
                    icon: "check",
                    enabled: !_vm.isProcessing,
                    "data-qa": "modal-button:Continue",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.Resources.Common.ModalContinueButton) +
                      "\n        "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(
        "\n    " +
          _vm._s(_vm.Resources.ProjectHome.CrowdfundingDraftInfoModalSubtitle) +
          "\n    "
      ),
      _c("div", { staticClass: "gfu-media gfu-media--middle _mt-3" }, [
        _c("div", { staticClass: "gfu-fab" }, [
          _c("span", { staticClass: "_fa _fa--space-station-moon-alt" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-media__body _ml-3" }, [
          _c("div", { staticClass: "gfu-bt gfu-bt--b2" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.Resources.ProjectHome
                    .CrowdfundingDraftInfoModalSection1Header
                ) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-bt gfu-bt--caption _tc--light" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.Resources.ProjectHome
                    .CrowdfundingDraftInfoModalSection1Text
                ) +
                "\n            "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-media gfu-media--middle _mt-3" }, [
        _c("span", { staticClass: "gfu-fab" }, [
          _c("span", { staticClass: "_fa _fa--heart" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-media__body _ml-3" }, [
          _c("div", { staticClass: "gfu-bt gfu-bt--b2" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.Resources.ProjectHome
                    .CrowdfundingDraftInfoModalSection2Header
                ) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-bt gfu-bt--caption _tc--light" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.Resources.ProjectHome
                    .CrowdfundingDraftInfoModalSection2Text
                ) +
                "\n            "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-media gfu-media--middle _mt-3" }, [
        _c("div", { staticClass: "gfu-fab" }, [
          _c("span", { staticClass: "_fa _fa--spinner" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-media__body _ml-3" }, [
          _c("div", { staticClass: "gfu-bt gfu-bt--b2" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.Resources.ProjectHome
                    .CrowdfundingDraftInfoModalSection3Header
                ) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-bt gfu-bt--caption _tc--light" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.Resources.ProjectHome
                    .CrowdfundingDraftInfoModalSection3Text
                ) +
                "\n            "
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }