import lazyScreen from '@/Components/Admin/LazyScreen';

const InvoicesListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-invoices' */ './InvoicesListScreen.vue'));

const routes = [
    { path: '/orders/:creator?/:project?/invoices', name: 'orders-invoices', component: InvoicesListScreen },
    { path: '/:creator?/:project?/invoices', redirect: { name: 'orders-invoices' } }
];

export {
    InvoicesListScreen,
    routes
};