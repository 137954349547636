var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-project-summary-stretch-goal",
      attrs: { "data-qa": "project-summary-stretch-goal" },
    },
    [
      _c("div", { staticClass: "gfu-hd gfu-hd--h4 gfu-hd--light _ttu _mb-1" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.Resources.ProjectHome.ProjectStretchGoalTitle) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-media gfu-media--middle" },
        [
          _vm.imageUrl
            ? _c(
                "router-link",
                {
                  staticClass: "gfu-thumb _mr-3 _as-fs _pa-0",
                  attrs: { to: _vm.route },
                },
                [
                  _c("img", {
                    staticClass: "gfu-thumb__item",
                    attrs: {
                      src: _vm.imageUrl,
                      alt: _vm.name,
                      width: "72",
                      height: "72",
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gfu-media__body" },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "gfu-hd gfu-hd--h4 gfu-hd--decorative gfu-link gfu-link--nofx _line-clamp-2",
                  attrs: {
                    to: _vm.route,
                    title: _vm.name,
                    "data-qa": "project-stretch-goal-name",
                  },
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(_vm.name) + "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isBackedFundsType
                ? _c("display-currency-value", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "tooltip-content",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.Resources.format(
                                      _vm.Resources.ProjectHome
                                        .ProjectApproximateCurrencyConversionTooltip,
                                      _vm.$format(_vm.targetValue, "C0")
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "content",
                          fn: function ({ displayCurrency }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "gfu-hd gfu-hd--h4 gfu-hd--light",
                                  attrs: {
                                    "data-qa": "project-stretch-goal:Target",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.unlocksAtText(
                                          displayCurrency.symbol,
                                          displayCurrency.relativeFactor
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "gfu-hd gfu-hd--h3 gfu-hd--light",
                                  attrs: {
                                    "data-qa": "project-stretch-goal:Target",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.unlocksAtText()) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      678468758
                    ),
                  })
                : _c(
                    "div",
                    {
                      staticClass: "gfu-hd gfu-hd--h4 gfu-hd--light",
                      attrs: { "data-qa": "project-stretch-goal:Target" },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.unlocksAtText()) +
                          "\n            "
                      ),
                    ]
                  ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gfu-project-summary-stretch-goal__progress" },
                [
                  _c("progress-bar", {
                    staticClass: "gfu-project-summary-stretch-goal__bar",
                    attrs: {
                      value: _vm.value,
                      max: _vm.targetValue,
                      "progression-class":
                        "gfu-progress-bar__progression--accent gfu-progress-bar--animated",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass:
                      "gfu-project-summary-stretch-goal__icon _fa _fa--lock",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }