var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-general-message-info",
      attrs: { "data-qa": "project-test-mode-info" },
    },
    [
      _vm._m(0),
      _vm._v("\n    " + _vm._s(_vm.message) + " "),
      _c(
        "a",
        {
          staticClass: "_tdu",
          attrs: {
            href: _vm.projectUrl,
            "data-qa": "project-test-mode-info:DisableTestingMode",
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleTestingMode.apply(null, arguments)
            },
          },
        },
        [_vm._v("Disable testing mode")]
      ),
      _vm._v(".\n"),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "gfu-general-message-info__icon" }, [
      _c("span", { staticClass: "fa-fw _fa _fa--sliders-h _mr-1" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }