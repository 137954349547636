var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("project-summary-card", {
    attrs: { "is-flipped": _vm.isCardFlipped },
    scopedSlots: _vm._u(
      [
        {
          key: "front",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "gfu-project-summary-pledge" },
                [
                  _c("project-summary-box", {
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "gfu-1of1" },
                              [
                                _c("span", {
                                  staticClass:
                                    "_ga _fa--2x _ga--gamefound-outline _tc--accent",
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "_mt-1 _tac _mb-3" },
                                  [
                                    _c(
                                      "h4",
                                      {
                                        staticClass: "gfu-hd gfu-hd--h3",
                                        attrs: {
                                          "data-qa":
                                            _vm.projectSummaryHeaderDataQa,
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.projectSummaryHeader))]
                                    ),
                                    _vm._v(" "),
                                    _vm.isPledgeManager
                                      ? [
                                          _c("p", {
                                            staticClass: "_mt-1 _mb-0",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.subHeaderContent
                                              ),
                                            },
                                          }),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "base-link",
                                  {
                                    staticClass:
                                      "gfu-project-summary-action gfu-btn gfu-btn--primary gfu-btn--soft gfu-btn--block _px-3 gfu-btn--wide",
                                    attrs: {
                                      url: _vm.originalCampaignUrl,
                                      "data-qa": "project-summary:ViewOriginal",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "gfu-btn__text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.Resources.ProjectHome
                                              .ProjectSummaryBoxOriginalCampaignButton
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "_tac" }, [
                              _c(
                                "h3",
                                {
                                  staticClass: "gfu-project-summary-box__title",
                                  attrs: {
                                    "data-qa":
                                      "project-summary-box:TotalFundedAmount",
                                  },
                                },
                                [
                                  _c("display-currency-value", {
                                    attrs: {
                                      "display-tooltip": false,
                                      tag: "span",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "content",
                                        fn: function ({ displayCurrency }) {
                                          return [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$format(
                                                    _vm.fundsGatheredConverted /
                                                      displayCurrency.relativeFactor,
                                                    "C0",
                                                    displayCurrency.symbol
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "default",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$format(
                                                    _vm.fundsGatheredConverted,
                                                    "C0"
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "h4",
                                  { staticClass: "gfu-hd gfu-hd--h4" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.backersCountConverted) +
                                        "\n                                "
                                    ),
                                    _c(
                                      "tooltip",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "content",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.fundsGatheredTooltipInfo
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "_fa _fa--info",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "actions",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "gfu-project-summary-actions" },
                              [
                                _vm.showBackerInfo
                                  ? _c("project-summary-backer", {
                                      attrs: {
                                        number: _vm.backerNumber,
                                        href: _vm.backProjectUrl,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isCreditBalancePositive &&
                                _vm.isPledgeAvailable
                                  ? _c("project-summary-credits", {
                                      attrs: {
                                        "credit-balance": _vm.creditBalance,
                                        href: _vm.backProjectUrl,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.showBackerInfo && _vm.isPledgeAvailable
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gfu-project-summary-action",
                                      },
                                      [
                                        _c("back-project-button", {
                                          attrs: { href: _vm.backProjectUrl },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.showBackerInfo
                                  ? _c("project-summary-follow")
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.canUpgradePledge ||
                                _vm.isPledgeImplicitEditMode
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gfu-project-summary-action",
                                      },
                                      [
                                        !_vm.isProjectShippingOnly
                                          ? _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "gfu-btn gfu-btn--primary gfu-btn--soft gfu-btn--block _px-3 gfu-btn--wide _mb-2",
                                                attrs: {
                                                  href: _vm.backProjectUrl,
                                                  "data-qa":
                                                    "project-summary:UpgradePldge",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gfu-btn__text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.Resources
                                                          .ProjectHome
                                                          .ProjectSummaryBoxUpgradePledgeButton
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isPledgeManager
                                          ? _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "gfu-btn gfu-btn--accent gfu-btn--soft gfu-btn--block _px-3 gfu-btn--wide",
                                                attrs: {
                                                  href: _vm.cart
                                                    .manageShippingUrl,
                                                  "data-qa":
                                                    "project-summary:CompletePledge",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gfu-btn__text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.Resources
                                                          .ProjectHome
                                                          .ProjectSummaryBoxCompletePledgeButton
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "_pt-2 _tac",
                                    attrs: {
                                      "data-qa":
                                        "project-summary:ViewCampaignSummary",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "gfu-link gfu-link--accent",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.toggleCard.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.Resources.ProjectHome
                                              .ProjectSummaryBoxCrowdfundingSummaryButton
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        _vm.isCardFlipped
          ? {
              key: "back",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "gfu-project-summary-pledge" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "gfu-project-summary-pledge__close",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.toggleCard.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.Resources.Layout.CloseButton) +
                              " "
                          ),
                          _c("span", {
                            staticClass:
                              "_fa _fa--base _fa--lg _fa--times _ml-1 _fr",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("project-summary-completed", {
                        attrs: { "project-last-update": null },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }