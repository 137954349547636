var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentPledge
    ? _c(
        "div",
        {
          ref: "bottomBar",
          staticClass: "gfu-layout-bar gfu-layout-bar--bottom",
        },
        [
          _vm.isBottomBarVisible
            ? _c(
                "bottom-bar-empty-pledge",
                _vm._b(
                  { on: { "after-enter": _vm.afterEnter } },
                  "bottom-bar-empty-pledge",
                  _vm.bottomBarModel,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "mini-wizard-expandable",
            _vm._b(
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isMiniWizardVisible,
                    expression: "isMiniWizardVisible",
                  },
                ],
              },
              "mini-wizard-expandable",
              _vm.$props,
              false
            )
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }