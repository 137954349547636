import smartQueue from './smartQueue';
import Log from '@/Utils/Log';

function preparePayload(payload) {
    const projectContext = payload.projectContext;
    const preparedPayload = {
        event: payload.event,
        type: payload.type,
        properties: payload.properties,
        timestamp: new Date().toISOString(),
    };

    const { project, creator } = projectContext;
    if (project && creator) {
        preparedPayload['ProjectID'] =  project.projectID;
        preparedPayload['ProjectOriginalType'] =  project.originalType;
        preparedPayload['ProjectPhase'] = project.phase;
        preparedPayload['ProjectType'] =  project.type;
        preparedPayload['ProjectUrlName'] =  project.urlName;
        preparedPayload['ProjectVersion'] =  project.version;

        preparedPayload['CreatorID'] =  creator.creatorID;
        preparedPayload['CreatorUrlName'] =  creator.urlName;
    }

    return preparedPayload;
}

function gamefoundPlugin(pluginConfig = {}) {
    const options = {
        max: 50,
        interval: 6 * 1000,
        throttle: false
    };

    const queue = smartQueue((items, restOfQueue) => {
        if (items.length < 1) {
            return;
        }

        try {
            fetch('/api/a/t', {
                method: 'POST',
                body: JSON.stringify(items),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                }
            });
        }
        catch {}
    }, options);

    window.onunload = function() {
        try {
            queue.flush(true);
        }
        catch (error)  {
            Log.warn(error.message);
        }
    };

    return {
        name: 'gamefound-plugin',
        config: pluginConfig,
        track: (event) => {
            try {
                queue.push(preparePayload(event));
            }
            catch (error)  {
                Log.warn(error.message);
            }
        }
    };
}

export default gamefoundPlugin;