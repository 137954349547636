export const languageTypes = {
    en: {
        value: 'English',
        icon: '/content/images/localization/en.svg',
        code: 'EN'
    },
    pl: {
        value: 'Polski',
        icon: '/content/images/localization/pl.svg',
        code: 'PL'
    },
    it: {
        value: 'Italiano',
        icon: '/content/images/localization/it.svg',
        code: 'IT'
    },
    fr: {
        value: 'Français',
        icon: '/content/images/localization/fr.svg',
        code: 'FR'
    },
    de: {
        value: 'Deutsch',
        icon: '/content/images/localization/de.svg',
        code: 'DE'
    },
    es: {
        value: 'Español',
        icon: '/content/images/localization/es.svg',
        code: 'ES'
    },
};