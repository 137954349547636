import Vue from 'vue';
import { guid } from '@/Utils';
import ErrorUtility from '@/Utils/ErrorUtility.js';
import { notificationTypes }  from '@/Types/NotificatorTypes';
import { breakpoint } from '@/Types/StyleTypes.js';
import { getWindowWidth } from '@/Utils/WindowUtility.js';
import Resources from '@/Resources.js';

function createElement(component, props, container = document.body) {
    const element = document.createElement('div');
    container.appendChild(element);

    return new Vue({
        name: 'Plugin.Notify',
        el: element,
        render(h) {
            return h(component, props);
        }
    });
}

function checkBreakpoint() {
    const width = getWindowWidth();
    return width < breakpoint.m;
}

class Notificator extends Function {
    notifications = null;
    defaultProps = {
        duration: 4,
        persist: true
    };

    constructor(initialProps) {
        super();
        const observable = Vue.observable({ notifications: [] });
        this.notifications = observable.notifications;
        this.defaultProps = { ...this.defaultProps, ...initialProps };
        this.isMobile = checkBreakpoint();
        return new Proxy(this, {
            apply: (target, thisArg, args) => target.show(...args)
        });
    }

    show(props) {
        const id = guid();
        const title = ErrorUtility.getResponseErrorMessage(props.title);
        const model = { ...this.defaultProps, ...props, id, title };

        this.isMobile ? this.notifications.push(model) : this.notifications.unshift(model);
        return props;
    }

    hide(notification) {
        const index = this.notifications.indexOf(notification);
        if (index > -1) {
            this.notifications.splice(index, 1);
        }
    }

    hideAll() {
        this.notifications.splice(0, this.notifications.length);
    }

    hideNotPersisted() {
        this.notifications.filter(n => !n.persist)
            .forEach(n => this.hide(n));
    }

    error(title, options = {}) {
        const type = notificationTypes.error;
        const duration = null;
        return this.show({ title, ...options, type, duration });
    }

    serverError(title, options = {}) {
        const description = Resources.Common.ServerErrorToastSubtitle;
        const type = notificationTypes.error;
        const duration = null;
        return this.show({ title, ...options, description, type, duration });
    }

    popupServerError(title, options = {}) {
        const description = Resources.Common.ServerErrorToastSubtitle;
        const type = notificationTypes.error;
        const duration = null;
        const persist = false;

        return this.show({
            title,
            description,
            type,
            duration,
            persist,
            ...options,
        });
    }

    info(title, options = {}) {
        const type = notificationTypes.info;
        return this.show({ title, ...options, type });
    }

    warning(title, options = {}) {
        const type = notificationTypes.warning;
        return this.show({ title, ...options, type });
    }

    success(title, options = {}) {
        const type = notificationTypes.success;
        return this.show({ title, ...options, type });
    }

    popupInfo(title, options = {}) {
        const type = notificationTypes.info;
        const duration = 10;
        return this.show({ title, ...options, type, duration });
    }

    popupSuccess(title, options = {}) {
        const type = notificationTypes.success;
        return this.show({ title, ...options, type });
    }
}

const notify = {
    install: (app, options = {
        extensionName: '$notify',
        components: []
    }) => {
        app.prototype[options.extensionName] = new Notificator();
        options.components
            .forEach( c => createElement(c, options));
    }
};

export default notify;
