var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-dropdown gfua-editing-control" },
    [
      _c(
        "tooltip",
        { attrs: { text: "Manage this blog post", width: "compact" } },
        [
          _c("router-link", {
            staticClass:
              "gfu-btn gfu-btn--primary gfu-btn--circle _fa _fa--cog",
            attrs: { to: _vm.path },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }