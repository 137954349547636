import CartsService from '@/Services/CartsService';
import Bus from '@/Bus';
import { apiClient } from '@/Clients/ApiClient.js';
import { defineStore } from 'pinia';
import { projectVersions, orderItemPaymentStatuses, orderItemTypes, orderStates } from '@/Models';
import { getTotalProductQuantity } from '@/Utils/ProductUtility';

const { v2 } = projectVersions;
const { product, productSet, tip } = orderItemTypes;

export const useCartSummaryStore = defineStore({
    id: 'cartSummary',
    state: () => ({
        cartSummary: {
            backerNumber: 0,
            canEditPledgedItems: false,
            canProceedToCheckoutValidationResult: { isValid: false, message: null },
            canCancelPledge: false,
            cancelOrderUrl: null,
            cart: null,
            cartDetailsUrl: '',
            checkoutUrl: '',
            itemsCount: 0,
            productOrderItems: null,
            productsCount: 0,
            projectsCount: 0,
            totalQuantity: 0,
            totalValueConverted: 0
        },
        cartCandidate: null,
    }),
    actions: {
        async loadCartSummary(projectID, preventCartLoading) {
            if (preventCartLoading) {
                return Promise.resolve(true);
            }
            else {
                return await new CartsService(apiClient)
                    .getCartSummary(projectID)
                    .then(response => {
                        if (projectID) {
                            this.cartSummary = response.data.cart;
                            this.cartCandidate = response.data.cartCandidate;
                        }
                        else {
                            this.cartSummary = response.data;
                        }
                    })
                    .catch(_ => {
                        return Promise.resolve(true);
                    });
            }
        },
        async updateCartSummary(projectID) {

            return await new CartsService(apiClient)
                .getCartSummary(projectID)
                .then(response => {
                    this.cartSummary = response.data.cart;
                    Bus.Topics.Web.Notify.CartModified.publish();

                    this.cartCandidate = response.data.cartCandidate;
                });
        },
    },
    getters: {
        getAvailableQuantityFromPledge: state => ({ productID, remainingStockLimit, deductQuantityFromCurrentItems }, projectVersion) => {
            if (remainingStockLimit === null)
                return null;

            let quantityAvailable = 0;
            const cartSummary = state.cartSummary;

            // In V2 we should include quantity used in editing pledge
            if (projectVersion === v2 && cartSummary?.cart?.parentOrder) {
                quantityAvailable = getTotalProductQuantity(cartSummary.cart.parentOrder.orderItems, false, productID);
            }

            if (cartSummary?.cart) {
                let currentCartQuantity = 0;
                //  deduct quantity from all items
                if (deductQuantityFromCurrentItems) {
                    currentCartQuantity = getTotalProductQuantity(cartSummary.cart.orderItems, false, productID);
                }
                // deduct quantity from paid items (non editable)
                else if (cartSummary.cart.parentOrder?.orderState === orderStates.placedAndPaid && !cartSummary.canEditPledgedItems) {
                    currentCartQuantity = getTotalProductQuantity(cartSummary.cart.orderItems, true, productID);
                }
                quantityAvailable -= currentCartQuantity;
            }

            return quantityAvailable + remainingStockLimit;
        },
        isPledgePlaced: state => {
            return !!state.cartSummary?.cart?.parentOrder || state.cartCandidate !== null;
        },
        cartSummaryUnpaidItems: state => {
            if (state.cartSummary?.cart) {
                return state.cartSummary?.cart?.orderItems
                    .filter(obj => {
                        const isStatusUnpaid = obj.paymentStatus === orderItemPaymentStatuses.unpaid;
                        const isValidType = [
                            product,
                            tip,
                            productSet
                        ].includes(obj.orderItemType);

                        return isStatusUnpaid && isValidType;
                    });
            }
            return null;
        },
        getCurrentPledge: state => (projectVersion, orderAwaitingPayment) => {
            if (projectVersion === v2
                && orderAwaitingPayment) {
                return orderAwaitingPayment;
            }
            return state.cartSummary?.cart?.orderID === 0 && state.cartCandidate ? state.cartCandidate : state.cartSummary;
        },
        isPledgeEditable: state => orderAwaitingPaymentCanEditPledgedItems => {
            return orderAwaitingPaymentCanEditPledgedItems === true || state.cartCandidate?.canEditPledgedItems === true;
        },
        canAddNewItemsToPledge: state => orderAwaitingPaymentCanAddNewItems => {
            return orderAwaitingPaymentCanAddNewItems === true || state.cartCandidate?.cart.canAddNewItems === true;
        },
    }
});