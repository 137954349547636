import lazyScreen from '@/Components/Admin/LazyScreen';
const CommunicationSideNav = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-blog' */ '../System/Communication/CommunicationSideNav'));
const BlogPostsListScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-blog' */ './BlogPostsListScreen.vue'));
const BlogPostSummaryScreen = () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-blog' */ './BlogPostSummaryScreen.vue'));

const routes = [
    {
        path: '/system/blog-posts',
        name: 'blog-posts',
        components: { default: BlogPostsListScreen, sidenav: CommunicationSideNav }
    },
    { path: '/blog-posts', redirect: { name: 'blog-posts' } },
    {
        path: '/system/blog-posts/:blogPostId/summary',
        name: 'blog-post-summary',
        components: { default: BlogPostSummaryScreen, sidenav: CommunicationSideNav },
        props: { default: route => ({ blogPostId: parseInt(route.params.blogPostId) }) }
    },
    { path: '/blog-posts/:blogPostId/summary', redirect: { name: 'blog-post-summary' } }
];

export {
    routes
};