import lazyScreen from '@/Components/Admin/LazyScreen';

const routes = [
    {
        path: '/system/terms',
        name: 'terms',
        component: () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-terms' */ './TermsDocumentsListScreen.vue'))
    },
    {
        path: '/system/terms/:termsDocumentId/summary',
        name: 'terms-document-summary',
        component: () => lazyScreen(import(/* webpackChunkName: 'gamefound-admin-terms' */ './TermsDocumentSummaryScreen.vue')),
        props: route => ({ termsDocumentId: parseInt(route.params.termsDocumentId) })
    },
];

export {
    routes
};