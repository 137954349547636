var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "grid-table-row",
    { staticClass: "gfu-table__row" },
    [
      _c(
        "grid-table-cell",
        {
          staticClass: "_tal _flexb30 _flexb20--l",
          attrs: { "data-qa": "payment-history:Date" },
        },
        [_vm._v("\n        " + _vm._s(_vm.item.completionDate) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "grid-table-cell",
        {
          staticClass: "_tal gfu-table__cell--grow _flexwrap",
          attrs: { "data-qa": "payment-history:PaymentMethod" },
        },
        [
          _c("div", [
            _vm._v("\n            " + _vm._s(_vm.paymentName) + "\n        "),
          ]),
          _vm._v(" "),
          _vm.paymentCardNumber
            ? _c("div", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.paymentCardNumber) +
                    "\n        "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "grid-table-cell",
        {
          staticClass: "_pa-0",
          attrs: { width: 30, "data-qa": "payment-history:Amount" },
        },
        [_vm._v("\n        " + _vm._s(_vm.item.amount) + "\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }