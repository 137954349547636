export function convertSearchParamsToObject(urlSearchParams) {
    if (urlSearchParams instanceof URLSearchParams) {
        const params = {};

        for (const k of urlSearchParams.keys()) {
            params[k] = urlSearchParams.get(k);
        }

        return params;
    }
    else {
        throw new Error('Parameter should be type of URLSearchParams');
    }
}