var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "is-visible": _vm.isModalVisible,
        "show-action-close": true,
        width: "wide",
      },
      on: { "action-close": _vm.close },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "_flexcntr _flexaicntr _tc--lighter gfu-bt gfu-bt--overline",
                },
                [
                  _vm._v("\n            payments powered by "),
                  _c("base-image", {
                    staticClass: "_ml-1",
                    attrs: {
                      src: "/content/images/payments/logo-adyen.png",
                      alt: "Adyen",
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { ref: "adyenAction", staticClass: "adyen-checkout" }),
      _vm._v(" "),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { visible: _vm.isProcessing, fixed: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }