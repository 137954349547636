var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "show-action-close": true,
        "is-visible": _vm.isOpen,
        title: _vm.title,
        width: "wide",
        "data-qa": "Payment history",
      },
      on: { "action-close": _vm.onClose },
    },
    [
      _c("grid-table", {
        staticClass: "_mt-3",
        scopedSlots: _vm._u([
          {
            key: "head",
            fn: function () {
              return [
                _c(
                  "grid-table-row",
                  [
                    _c(
                      "grid-table-cell",
                      { staticClass: "_tal _flexb30 _flexb20--l" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.Resources.Common.DateTableHeader) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "grid-table-cell",
                      { staticClass: "_tal gfu-table__cell--grow" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.Resources.Common.PaymentMethodTableHeader
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("grid-table-cell", { attrs: { width: 30 } }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.Resources.Common.AmountTableHeader) +
                          "\n                "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._l(_vm.userPaymentHistory, function (item) {
                  return _c("pledge-payment-history-modal-row", {
                    key: item.completionDate,
                    attrs: { item: item },
                  })
                }),
                _vm._v(" "),
                !_vm.hasPaymentHistory
                  ? _c("empty-state", {
                      attrs: {
                        title:
                          _vm.Resources.YourPledge
                            .PaymentHistoryModalEmptyStateText,
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }