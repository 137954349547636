var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      class: ["gfu-base-slider-nav", _vm.navClassName, _vm.themeClassName],
      attrs: { href: "#" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("click")
        },
      },
    },
    [_c("span", { class: ["_fa", _vm.iconClassName] })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }