var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-grid__cell gfu-1of1" },
    [
      _c(
        "box",
        [
          _c("option-field", {
            staticClass: "_mb-3",
            attrs: {
              "first-column-width": 8,
              "read-only": true,
              options: _vm.notificationPreferenceOptions,
              "display-name":
                _vm.Resources.SubscriptionManagement.SystemMessagesFieldLabel,
              name: "model.systemMessages",
              layout: "columns",
              description:
                _vm.Resources.SubscriptionManagement
                  .SystemMessagesFieldDescription,
              "data-qa": "general-notifications:SystemMessages",
            },
            on: { input: _vm.onSystemMessageNotificationPreferenceChange },
            model: {
              value: _vm.model.systemMessages,
              callback: function ($$v) {
                _vm.$set(_vm.model, "systemMessages", $$v)
              },
              expression: "model.systemMessages",
            },
          }),
          _vm._v(" "),
          _c("option-field", {
            staticClass: "_mb-3",
            attrs: {
              "first-column-width": 8,
              options: _vm.notificationPreferenceOptions,
              "display-name":
                _vm.Resources.SubscriptionManagement.GamefoundFeastFieldLabel,
              name: "model.gamefoundFeast",
              layout: "columns",
              description:
                _vm.Resources.SubscriptionManagement
                  .GamefoundFeastFieldDescription,
              "data-qa": "general-notifications:GamefoundFeast",
            },
            on: { input: _vm.onGamefoundFestNotificationPreferenceChange },
            model: {
              value: _vm.model.gamefoundFeast,
              callback: function ($$v) {
                _vm.$set(_vm.model, "gamefoundFeast", $$v)
              },
              expression: "model.gamefoundFeast",
            },
          }),
          _vm._v(" "),
          _c("option-field", {
            staticClass: "_mb-3",
            attrs: {
              "first-column-width": 8,
              options: _vm.notificationPreferenceOptions,
              "display-name":
                _vm.Resources.SubscriptionManagement.NewsletterFieldLabel,
              name: "model.newsletter",
              layout: "columns",
              description:
                _vm.Resources.SubscriptionManagement.NewsletterFieldDescription,
              "data-qa": "general-notifications:Newsletter",
            },
            on: { input: _vm.onNewsletterNotificationPreferenceChange },
            model: {
              value: _vm.model.newsletter,
              callback: function ($$v) {
                _vm.$set(_vm.model, "newsletter", $$v)
              },
              expression: "model.newsletter",
            },
          }),
          _vm._v(" "),
          _c("option-field", {
            staticClass: "_mb-3",
            attrs: {
              "first-column-width": 8,
              options: _vm.notificationPreferenceOptions,
              "display-name":
                _vm.Resources.SubscriptionManagement.CommentRepliesFieldLabel,
              name: "model.commentReply",
              layout: "columns",
              description:
                _vm.Resources.SubscriptionManagement
                  .CommentRepliesFieldDescription,
              "data-qa": "general-notifications:CommentReply",
            },
            on: { input: _vm.onCommentReplyNotificationPreferenceChange },
            model: {
              value: _vm.model.commentReply,
              callback: function ($$v) {
                _vm.$set(_vm.model, "commentReply", $$v)
              },
              expression: "model.commentReply",
            },
          }),
          _vm._v(" "),
          _c("option-field", {
            staticClass: "_mt-0",
            attrs: {
              "first-column-width": 8,
              options: _vm.digestFrequencyPreferenceOptions,
              "display-name":
                _vm.Resources.SubscriptionManagement.CommunityLifeFieldLabel,
              name: "model.preferredDigestFrequency",
              layout: "columns",
              description:
                _vm.Resources.SubscriptionManagement
                  .CommunityLifeFieldDescription,
              "data-qa": "general-notifications:CommunityLife",
            },
            on: { input: _vm.onDigestFrequencyNotificationPreferenceChange },
            model: {
              value: _vm.model.preferredDigestFrequency,
              callback: function ($$v) {
                _vm.$set(_vm.model, "preferredDigestFrequency", $$v)
              },
              expression: "model.preferredDigestFrequency",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }