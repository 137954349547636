import LazyComponentLoader from '@/Components/LazyComponentLoader';
import LazyComponentError from '@/Components/LazyComponentError';

export default function lazyComponent(importPromise, loadingComponent = LazyComponentLoader, delay = 100) {
    const asyncHandler = () => ({
        component: importPromise,
        error: LazyComponentError,
        loading: loadingComponent,
        delay,
        timeout: 5000
    });

    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            return h(asyncHandler, data, children);
        }
    });
}
