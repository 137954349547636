var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-installments-summary" },
    [
      _c("p", { staticClass: "_ttu _tc--lighter _mt-0 _mb-1" }, [
        _vm._v("Stretch Pay progress"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "_fw-b _tc--base" }, [
        _vm._v(_vm._s(_vm.installmentsProgressBarHeader)),
      ]),
      _vm._v(" "),
      _c("progress-bar", {
        attrs: {
          value: _vm.numberOfCompletedPayments,
          max: _vm.numberOfInstallments,
          "progression-class": "gfu-progress-bar__progression--accent",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-installments-summary__values-list" },
        _vm._l(_vm.installmentsSummaryListData, function (summaryListItem) {
          return _c(
            "summary-list-item",
            _vm._b(
              { key: summaryListItem.title },
              "summary-list-item",
              summaryListItem,
              false
            )
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.isPaymentAvailable
        ? _c(
            "base-button",
            {
              staticClass: "_mt-1",
              attrs: {
                width: "wide",
                theme: "accent",
                disabled: _vm.isActionInProgress,
                "data-qa": "StretchPay:payRemaining",
              },
              on: { click: _vm.onPayAllRemaining },
            },
            [_vm._v("\n        " + _vm._s(_vm.remainingAmountToPay) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("remaining-installments-payment-modal", {
        attrs: {
          "is-open": _vm.isPayAllRemainingModalVisible,
          "order-code": _vm.orderCode,
          "remaining-amount": _vm.formattedRemainingAmount,
        },
        on: {
          "modal-close": _vm.onModalClose,
          "installments-paid": _vm.onInstallmentsPaid,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }