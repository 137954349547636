var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("base-summary-list", {
        staticClass: "_mt-5",
        attrs: { "summary-list-data": _vm.summaryListModel },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  "h2",
                  {
                    staticClass:
                      "gfu-heading gfu-heading--top-divider _mt-3 _mb-2",
                  },
                  [_vm._v(_vm._s(_vm.Resources.YourPledge.OrderSummaryHeader))]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: !_vm.hasCartDetails } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }