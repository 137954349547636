var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    _vm._g(
      {
        tag: "component",
        class: _vm.classes,
        attrs: { type: _vm.effectiveType, disabled: !_vm.enabled, to: _vm.to },
      },
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }